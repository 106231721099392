import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CommercialPolicyNumberQuestion' })((theme) => ({
  subQuestion: {
    maxWidth: '43%',
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    padding: 16,
  },
}));
