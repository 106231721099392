import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DeltaDriverCard' })((theme) => ({
  root: {},
  driverCardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  headerRight: { ...theme.typography.h3, paddingTop: 20 },
  driverDiv: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
}));
