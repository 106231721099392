import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePniFirstName, usePropertyField } from '../../utils';

export const YearOccupiedQuestion: React.FC<QuestionProps> = (props) => {
  const yearOccupiedField = usePropertyField('yearOccupied');
  const label = usePniFirstName(yearOccupiedField?.question?.title || '');

  const { trackingName = 'year_occupied_question', dataTestId = 'yearOccupied' } = props;

  if (!yearOccupiedField) return null;

  return (
    <NumberFormat
      {...yearOccupiedField.props}
      groupLabel={<Typography variant='body4'>{label}</Typography>}
      format='####'
      id='YearOccupied'
      placeholder='YYYY'
      data-testid={dataTestId}
      trackingName={trackingName}
      fullWidth={false}
    />
  );
};
