/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo, useRef } from 'react';

import { createSelector } from 'reselect';

import { trackDefault } from '@ecp/utils/analytics/tracking';
import { emptyArray, isEqual, noop, unique } from '@ecp/utils/common';

import { getVehicleDescription } from '@ecp/features/sales/quotes/auto';
import {
  getAnswers,
  getDrivers,
  getFieldsByKeys,
  getOfferProductsSelectedByType,
  getPrimaryInsuredStateCode,
  getShownFields,
  getVehicles,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AnswerValue,
  CoverageItem,
  CoveragesFields,
  Driver,
  OptionMetadataWithKey,
  Vehicle,
} from '@ecp/features/sales/shared/types';
import type { AutoProduct } from '@ecp/features/shared/product';
import type { Field, OptionsMetadata } from '@ecp/types';

import { useCoveragesFormValidationFactory } from '../../../../common';
import { getAvailableObjectForDriverVehicleDiscount } from '../../../../common/selectors';
import type { CoveragesSharedProps, VehicleCoverages } from '../../../../common/types';
import { buildCoverageItems, useCoveragesAnalytics } from '../../../../common/utils/coverageUtil';
import {
  AutoPolicyCoverageMetadata,
  DriverDiscountMetadata,
  VehicleCoverageMetadata,
  VehicleDiscountMetadata,
} from './metadata';
import type {
  AutoCoveragesFormProps,
  AutoParentCoverageGroupKey,
  AutoPolicyCoverageGroupKey,
  AutoVehicleCoverageGroupKey,
} from './types';
import type { DiscountMetadata } from './VehicleAndDriverDiscounts';

/** Logs vehicle-level auto coverages object initially and on every change to it. */
const useAutoVehicleLevelCoveragesAnalytics = (autoVehicleCoverages: VehicleCoverages[]): void => {
  const prevVehicleCoverageFields = useRef({});

  useEffect(() => {
    if (!autoVehicleCoverages) return;
    const trackVehicleCoverages: { [title: string]: string }[] = [];

    autoVehicleCoverages.forEach(({ coverages, ref }) => {
      const trackVehicleCoveragesAndDeductibles: { [title: string]: string } = {};
      trackVehicleCoveragesAndDeductibles.ref = ref;
      [...coverages].forEach(({ title, displayValue }) => {
        if (title && displayValue) trackVehicleCoveragesAndDeductibles[title] = displayValue;
      });
      trackVehicleCoverages.push(trackVehicleCoveragesAndDeductibles);
    });

    if (!isEqual(prevVehicleCoverageFields.current, trackVehicleCoverages)) {
      prevVehicleCoverageFields.current = trackVehicleCoverages;
      trackVehicleCoverages.forEach((each) =>
        trackDefault({ action: 'DefaultVehicleLevelCoverages', label: JSON.stringify(each) }),
      );
    }
  }, [autoVehicleCoverages]);
};

export const useGetAutoPolicyCoverageFields = (
  selectedAutoProduct?: string,
  drivers?: Driver[],
): CoveragesFields => {
  const dispatch = useDispatch();

  const autoPolicyCoverageMetadata = selectedAutoProduct
    ? AutoPolicyCoverageMetadata[selectedAutoProduct]
    : {};
  const AutoPolicyCoverageMetadataKeys = Object.keys(autoPolicyCoverageMetadata).reduce(
    (acc, curr) => {
      acc.push(curr);

      const subCoverages = autoPolicyCoverageMetadata[curr]?.subCoverages;
      if (subCoverages) {
        acc.push(...Object.keys(subCoverages));
      }

      const stateOptions = autoPolicyCoverageMetadata[curr]?.stateOptions;
      if (stateOptions) {
        Object.keys(stateOptions).forEach((state) => {
          const stateSubCoverages = stateOptions[state].subCoverages;
          if (stateSubCoverages) {
            acc.push(...Object.keys(stateSubCoverages));
          }
        });
      }

      return unique(acc);
    },
    [] as string[],
  );

  const policyCoverageFields = useSelector((state: RootStore) => {
    // goes through metaDatakeys if matches <> adds new keys with driver id
    // example: pipExcludeDriver-<driver.id>  => pipExcludeDriver-0ntkc2ya
    AutoPolicyCoverageMetadataKeys.forEach((key) => {
      if (/<.+>/.test(key)) {
        drivers?.forEach((driver) => {
          const driverId = driver.ref.split('.')[1];
          AutoPolicyCoverageMetadataKeys.push(key.replace(/<.+>/, driverId));
        });
      }
    });

    const fields = getFieldsByKeys(
      state,
      `${selectedAutoProduct}.coverages.policy`,
      [...AutoPolicyCoverageMetadataKeys],
      dispatch,
    );

    return fields;
  }) as CoveragesFields;

  const policyCoverageFieldsFiltered = dispatch(getShownFields({ fields: policyCoverageFields }));

  return policyCoverageFieldsFiltered as CoveragesFields;
};

export const buildAutoPolicyCoverage = (
  productKey: AutoProduct,
  policyCoverageFields: CoveragesFields,
  stateCode = '',
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
  drivers?: Driver[],
): CoverageItem[] => {
  const metaData = AutoPolicyCoverageMetadata[productKey];

  // goes through metaData subCoverages if matches <> adds new subCoverages with driverId key and existing title and primaryText
  // example key: pipExcludeDriver-<driver.id>  => pipExcludeDriver-0ntkc2ya
  Object.keys(metaData).forEach((covKey) => {
    const subCoverages = metaData[covKey].subCoverages;
    if (subCoverages) {
      Object.keys(subCoverages).forEach((subCovKey) => {
        if (/<.+>/.test(subCovKey)) {
          if (drivers) {
            drivers.forEach((driver) => {
              const driverId = driver.ref.split('.')[1];
              const driverIdKey = subCovKey.replace(/<.+>/, driverId);
              subCoverages[driverIdKey] =
                metaData[covKey].stateOptions?.[stateCode]?.subCoverages?.[subCovKey] ||
                subCoverages[subCovKey];
            });
          }
        }
      });
    }
  });

  return buildCoverageItems(
    AutoPolicyCoverageMetadata[productKey],
    policyCoverageFields,
    stateCode,
    handleCoverageItemChange,
  );
};

export const buildVehicleCoverages = (
  productKey: AutoProduct,
  vehicles: Vehicle[],
  vehicleCoverageFields: { [key: string]: CoveragesFields },
  stateCode?: string,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): VehicleCoverages[] => {
  return vehicles.map((vehicle) => {
    const { year, make, model, ref } = vehicle;
    const coveragesFields = vehicleCoverageFields[ref];

    return {
      year,
      make,
      model,
      type: model,
      ref,
      coverages: buildCoverageItems(
        VehicleCoverageMetadata[productKey],
        coveragesFields,
        stateCode,
        handleCoverageItemChange,
      ),
      description: getVehicleDescription(vehicle),
    };
  });
};

export const useVehicleCoverageFields = (
  vehicles: Vehicle[],
  selectedAutoProduct?: AutoProduct,
): { [key: string]: CoveragesFields } => {
  const dispatch = useDispatch();
  const coverageMetadata = selectedAutoProduct ? VehicleCoverageMetadata[selectedAutoProduct] : {};
  const vehicleCoverageMetadataKeys = Object.keys(coverageMetadata).reduce((acc, curr) => {
    acc.push(curr);

    const subCoverages = coverageMetadata[curr]?.subCoverages;
    if (subCoverages) {
      acc.push(...Object.keys(subCoverages));
    }

    return acc;
  }, [] as string[]);

  const vehicleCoverageFields: { [key: string]: CoveragesFields } = useSelector(
    (state: RootStore) =>
      Object.assign(
        {},
        ...vehicles.map(({ ref }) => {
          const fields = getFieldsByKeys(
            state,
            `${selectedAutoProduct}.coverages.${ref}`,
            [...vehicleCoverageMetadataKeys],
            dispatch,
          );

          return { [ref]: fields };
        }),
      ),
  );

  const vehicleCoverageFieldsFiltered = Object.keys(vehicleCoverageFields).reduce(
    (acc, vehicle) => {
      const fields = dispatch(getShownFields({ fields: vehicleCoverageFields[vehicle] }));
      acc[vehicle] = fields as CoveragesFields; // FIXME ASAP

      return acc;
    },
    {} as { [key: string]: CoveragesFields },
  );

  return vehicleCoverageFieldsFiltered;
};

/* eslint-disable no-param-reassign */
export const useAutoCoveragesForm = (
  fields: CoveragesSharedProps['fields'][AutoProduct],
  props: Pick<AutoCoveragesFormProps, 'onFormValidChange'>,
): {
  autoPolicyCoverages: CoverageItem[];
  autoVehicleCoverages: VehicleCoverages[];
  handleCoverageItemChange: (field: Field) => (value: AnswerValue) => Promise<void>;
} => {
  const { onFormValidChange = noop } = props;
  const initValues = useRef({});
  const drivers = useSelector(getDrivers);
  const vehicles = useSelector(getVehicles);
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  const { handleCoverageItemChange } = useCoveragesFormValidationFactory(
    initValues,
    fields,
    onFormValidChange,
  );

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  /** Policy-level auto coverages and deductibles */
  const autoPolicyCoverages = useMemo(
    () =>
      autoOfferProduct && fields?.policyCoverageFields
        ? buildAutoPolicyCoverage(
            autoOfferProduct,
            fields.policyCoverageFields,
            stateCode,
            handleCoverageItemChange,
            drivers,
          )
        : (emptyArray as unknown as CoverageItem[]),
    [autoOfferProduct, fields?.policyCoverageFields, stateCode, handleCoverageItemChange, drivers],
  );
  /** Vehicle-level auto coverages and deductibles */
  const autoVehicleCoverages = useMemo(
    () =>
      autoOfferProduct && fields?.vehicleCoverageFields
        ? buildVehicleCoverages(
            autoOfferProduct,
            vehicles,
            fields.vehicleCoverageFields,
            stateCode,
            handleCoverageItemChange,
          )
        : (emptyArray as unknown as VehicleCoverages[]),
    [
      autoOfferProduct,
      fields?.vehicleCoverageFields,
      vehicles,
      stateCode,
      handleCoverageItemChange,
    ],
  );

  // Analytics
  useCoveragesAnalytics(autoPolicyCoverages, 'DefaultAutoPolicyLevelCoverages');
  useAutoVehicleLevelCoveragesAnalytics(autoVehicleCoverages);

  return {
    autoPolicyCoverages,
    autoVehicleCoverages,
    handleCoverageItemChange,
  };
};

const makeApplicableDiscounts = <T extends Driver[] | Vehicle[]>(
  state: RootStore,
  availableDiscounts: OptionMetadataWithKey[],
  driversOrVehicles: T,
): DiscountMetadata[] =>
  availableDiscounts
    .map<DiscountMetadata>((discount) => {
      const { subDiscount, entityKey } = discount;
      if (subDiscount) {
        Object.keys(subDiscount).forEach((key) => {
          subDiscount[key].availableObjects = getAvailableObjectForDriverVehicleDiscount<T[number]>(
            state,
            key,
            driversOrVehicles,
            subDiscount[key].entityKey,
          );
          subDiscount[key].parentKey = discount.key;
        });
      }

      return {
        ...discount,
        availableObjects: getAvailableObjectForDriverVehicleDiscount<T[number]>(
          state,
          discount.key!,
          driversOrVehicles,
          entityKey,
        ),
      };
    })
    .filter(({ availableObjects }) => !!availableObjects.length);

export const getStateSpecificAvailableDriverVehicleDiscountstMetadata = (
  options: OptionsMetadata,
  stateCode: string,
): OptionsMetadata => {
  const opt = options;
  Object.keys(options).forEach((key) => {
    if (options[key].stateOptions) {
      const { stateOptions } = options[key];
      opt[key] = { ...options[key], ...stateOptions?.[stateCode] };
      delete opt[key].stateOptions;
    }
  });

  return opt;
};

export const getAvailableDriverVehicleDiscounts = (
  state: RootStore,
  type: 'driver' | 'vehicle',
  stateCode: string,
): OptionMetadataWithKey[] => {
  const { auto: autoOfferProduct } = getOfferProductsSelectedByType(state);
  if (autoOfferProduct) {
    const answers = getAnswers(state);
    const discountsMetadata =
      type === 'driver'
        ? DriverDiscountMetadata[autoOfferProduct]
        : VehicleDiscountMetadata[autoOfferProduct];

    const discountMetadata = getStateSpecificAvailableDriverVehicleDiscountstMetadata(
      discountsMetadata,
      stateCode,
    );

    // this will get all the available discounts that are applied regardless of driver/vehicle
    const answersKeys = Object.keys(answers).filter((key) =>
      new RegExp(`discount.${type}`, 'ig').test(key),
    );

    // this will hold a list of unique discount keys
    const totalAvailableDiscountKeys: string[] = [];

    for (let i = 0; i < answersKeys.length; i += 1) {
      const splitKey = answersKeys[i].split('.');
      const key = splitKey[splitKey.length - 1];

      if (totalAvailableDiscountKeys.indexOf(key) === -1) {
        totalAvailableDiscountKeys.push(key);
      }
    }

    // Since we have unique discount keys we can use the metadata
    // to return the OptionMetadata object
    return (
      totalAvailableDiscountKeys
        // if we get any unexpected keys we need to make sure we aren't sending them
        .filter((key) => discountMetadata[key] !== undefined)
        .map((key) => {
          const { subDiscount } = discountMetadata[key];
          // Get all available sub dicount key from answer available keys
          if (subDiscount) {
            discountMetadata[key].subDiscount = totalAvailableDiscountKeys.reduce((acc, curr) => {
              const temp = acc;

              return subDiscount[curr] ? { ...temp, [curr]: subDiscount[curr] } : temp;
            }, {});
          }

          return { ...discountMetadata[key], key };
        })
    );
  }

  return emptyArray as unknown as OptionMetadataWithKey[];
};

export const getApplicableDriverDiscounts = createSelector(
  (state: RootStore) => state,
  (state: RootStore) => getPrimaryInsuredStateCode(state),
  (state: RootStore) => getDrivers(state),
  (state, stateCode, drivers) => {
    const availableDriverDiscounts = getAvailableDriverVehicleDiscounts(state, 'driver', stateCode);

    return makeApplicableDiscounts(state, availableDriverDiscounts, drivers);
  },
);

export const getApplicableVehicleDiscounts = createSelector(
  (state: RootStore) => state,
  (state: RootStore) => getPrimaryInsuredStateCode(state),
  (state: RootStore) => getVehicles(state),
  (state, stateCode, vehicles) => {
    const availableVehicleDiscounts = getAvailableDriverVehicleDiscounts(
      state,
      'vehicle',
      stateCode,
    );

    return makeApplicableDiscounts(state, availableVehicleDiscounts, vehicles);
  },
);

export const getAutoPolicyCoverageGroupNames = (): Record<
  AutoPolicyCoverageGroupKey,
  string
> | null => {
  return {
    MANDATORY: 'Required Coverages',
    OPTIONAL: 'Optional Coverages',
  };
};

export const getAutoVehicleCoverageGroupNames = (): Record<
  AutoVehicleCoverageGroupKey,
  string
> | null => {
  return {
    DEDUCTIBLES: 'Deductibles',
    COVERAGESANDBENEFITS: 'Coverages And Benefits',
  };
};

export const getAutoParentGroupNames = (
  vehicleDescription?: string,
): Record<AutoParentCoverageGroupKey, string> | null => {
  return {
    VEHICLE: `Coverages for the ${vehicleDescription}`,
    POLICY: 'Coverages for Everyone on the Policy',
  };
};
