import { useCallback } from 'react';

import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { updateAnswers } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/types';

import { usePropertyField } from '../../utils';

export const YearBuiltQuestion: React.FC<QuestionProps> = (props) => {
  const yearBuiltField = usePropertyField('yearBuilt');
  const dispatch = useDispatch();

  const {
    trackingName = 'year_built_question',
    groupLabel = yearBuiltField?.question?.title,
    dataTestId = 'yearBuilt',
  } = props;

  const handleYearBuiltComplete = useCallback(
    async (value: AnswerValue) => {
      yearBuiltField?.validateUpdateAndPatch(value);
      if (value && (value as number) > 1940) {
        await dispatch(
          updateAnswers({
            answers: {
              'property.isHistoricHome': 'false',
              'property.historicHomeDesignation': null,
            },
          }),
        );
      }
    },
    [dispatch, yearBuiltField],
  );

  if (!yearBuiltField) return null;

  return (
    <NumberFormat
      {...yearBuiltField.props}
      groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
      format='####'
      id='YearBuilt'
      placeholder='YYYY'
      data-testid={dataTestId}
      trackingName={trackingName}
      // eslint-disable-next-line react/jsx-no-bind
      actionOnComplete={(value) => handleYearBuiltComplete(value)}
      fullWidth={false}
    />
  );
};
