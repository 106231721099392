import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PrivacyPolicyPage' })((theme) => ({
  root: {},
  privacyPageContainer: {
    maxWidth: 639,
    padding: '40px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 15px',
    },
    '& a': {
      margin: 0,
    },
  },
  pageTitle: {
    ...theme.typography.h1,
    paddingBottom: 10,
  },
  effectiveDate: {
    textAlign: 'left',
  },
  heading: {
    ...theme.typography.h2,
    marginTop: 40,
    marginBottom: 10,
  },
  subHeading: {
    ...theme.typography.h5,
    marginBottom: 10,
  },
  paragraph: {
    marginBottom: 20,
  },
  subSection: {
    marginBottom: 10,
  },
  list: {
    paddingLeft: 20,
    margin: '0px 0px 20px 0px',
  },
  noStyleList: {
    listStyle: 'none',
    padding: 0,
    margin: '0px 0px 20px 0px',
  },
  orderedList: {
    listStyleType: 'lower-num',
  },
  withListInside: {
    listStyleType: 'none',
  },
  link: theme.typography.body1Underline,
  boldText: theme.typography.body1Bold,
  italicText: theme.typography.body1Italics,
  thinHeader: theme.typography.body1,
  boldForMobile: {
    [theme.breakpoints.down('md')]: {
      ...theme.typography.body1Bold,
    },
  },
  noDisc: {
    listStyle: 'none',
  },
}));
