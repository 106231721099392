import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PropertyDeltaFormQuestion' })((theme) => ({
  ...theme.mixins.formAll,

  body: {
    padding: '0 0 20px 8px',
    width: '100%',
    marginLeft: 0,
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      padding: '0 20px 20px 8px',
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
    height: 4,
    borderBottomWidth: 0,
  },
}));
