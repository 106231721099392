import { memo } from 'react';

import { Divider, Grid, Typography } from '@mui/material';

import { GridItem } from '@ecp/components';
import { getCoveragePremiumAmount } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import type { CoverageGroupProps } from '../../types';
import { useStyles } from './PolicySummaryCoveragesCard.styles';

export const PolicySummaryCoveragesCardItem: React.FC<CoverageGroupProps> = memo(
  ({ title, items }) => {
    const { classes, cx } = useStyles();

    const newItems = items?.filter((item) => item?.isCovered);

    const newItemsWithPremium = useSelector((state: RootStore) =>
      newItems.map((item) => {
        const coverageKeyWithOutTermIndx = item.field?.key.lastIndexOf('.');
        const premiumAmountKey =
          item.field?.key.substring(0, coverageKeyWithOutTermIndx) + '.premiumAmount';
        const premiumValue = getCoveragePremiumAmount(state, premiumAmountKey);

        return {
          ...item,
          premiumValue: premiumValue ? `$${premiumValue}` : '',
        };
      }),
    );

    // EDSP-11027 Display Premium and Limits for coverages
    // Display Limits in seperate line with customized text added in metadata
    const renderLimits = (
      title: string,
      hasLimits: string | boolean | undefined,
      limitText: string | undefined = 'Limit',
      displayValue: string | undefined,
    ): React.ReactElement => {
      const value = hasLimits ? displayValue?.split('/') : [displayValue];

      const limitTexts = hasLimits ? limitText?.split('/') : [limitText];

      return (
        <>
          {value?.map((value, index) => (
            <>
              <GridItem xs={6}>
                <p className={classes.limitItems}>{limitTexts && limitTexts[index]}</p>
              </GridItem>
              <Grid item xs={6}>
                <Typography variant='body2'>{value}</Typography>
              </Grid>
            </>
          ))}
        </>
      );
    };

    if (!newItems || !newItems.length) return null;

    return (
      <GridItem topSpacing='sm'>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <h5>{title}</h5>
          </Grid>
          <Grid item xs={4} className={cx(classes.premiumAndCovTitle, classes.columnRight)}>
            Premium
          </Grid>
        </Grid>
        {newItemsWithPremium.map(
          ({ title, displayValue, premiumValue, limitText, hasLimits }, index) => (
            <GridItem xs={12} key={`${index} ${displayValue}`} className={classes.marginTop}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant='body2'>{title}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.columnRight}>
                  <Typography variant='body2'>{premiumValue}</Typography>
                </Grid>
                {renderLimits(title, hasLimits, limitText, displayValue)}
              </Grid>
              {newItems.length - 1 !== index && <Divider orientation='horizontal' />}
            </GridItem>
          ),
        )}
      </GridItem>
    );
  },
);
