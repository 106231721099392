import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AffiliationQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    margin: '15px 0px 15px',
  },
  affiliationContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    padding: 16,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
  },
  hasAffiliationContainer: {
    overflowX: 'auto',
    border: `1px solid ${theme.palette.grey['600']}`,
    borderRadius: '4px',
    marginBottom: 16,
    padding: 12,
  },
  affiliationQuestionContainer: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  affinityTypeContainer: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '15px',
  },
  divider: {
    backgroundColor: theme.palette.other.divider,
    margin: '40px 0px',
  },
  hasAffiliationsContainer: {
    overflowX: 'auto',
  },
  headerText: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
  },
  itemText: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
}));
