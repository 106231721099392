import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useRef } from 'react';

import { FormHelperText, FormLabel, Grid } from '@mui/material';
import type { NumberFormatProps } from 'react-number-format';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { normalizeValue } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { GridItem, NumberFormat } from '@ecp/components';
import { DatePicker, Resource, Select } from '@ecp/features/sales/shared/components';
import { setFormErrorsChangedByField, useForm } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { type PolicyLoss, useGetPolicyLossFields } from '../../../../common/utils';
import { useStyles } from './PolicyLossQuestions.styles';

export interface PolicyLossQuestionsProps {
  policyLossRef: string;
  setAddingPolicyLoss: Dispatch<SetStateAction<boolean>>;
  setPolicyLossActionInfoMessage: Dispatch<SetStateAction<string>>;
  editPolicyLossRef: string;
  setPolicyLossActionInfoType: Dispatch<SetStateAction<'NONE' | 'ADD' | 'REMOVE'>>;
  setOpenPolicyLossDialog: Dispatch<SetStateAction<boolean>>;
  policyLossList: Array<PolicyLoss>;
  onNext: () => void;
  onCancel: (policyLossRef: string) => void;
}

export const PolicyLossQuestions: React.FC<PolicyLossQuestionsProps> = (props) => {
  const {
    policyLossRef,
    setAddingPolicyLoss,
    setPolicyLossActionInfoMessage,
    editPolicyLossRef,
    setPolicyLossActionInfoType,
    setOpenPolicyLossDialog,
    onNext,
    onCancel,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const initValues = useRef({});
  const currentPolicyLossRef = policyLossRef;
  const policyLossFields = useGetPolicyLossFields(editPolicyLossRef || currentPolicyLossRef || '');

  const {
    accidentOrClaim: {
      lossDate: policyLossDate,
      lossDescription: policyLossDescription,
      lossAmountUserEntered,
    },
  } = policyLossFields;

  const { patchFormValues } = useForm({
    fields: policyLossFields,
    initValues,
    conditions: [],
  });

  const { validateForm } = useForm({
    fields: policyLossFields.accidentOrClaim,
    initValues,
    conditions: [],
  });

  /**
   * Implemented validation for required fields since SAPI doesn't have strict validation for requiredness.
   */
  const onValidatePolicyLossFields = useCallback(
    (lossDescription: Field, lossAmount: Field, lossDate: Field) => {
      let isValid = true;
      [lossDescription, lossAmount, lossDate].forEach((field) => {
        if (!field.value && field.exists) {
          dispatch(setFormErrorsChangedByField({ key: field.key, errors: ['Required field'] }));
          isValid = false;
        }
      });

      return isValid;
    },
    [dispatch],
  );

  const handleAddPolicyLoss = useEvent(async () => {
    const formValid = validateForm().isValid;
    const policyLossFieldValid = onValidatePolicyLossFields(
      policyLossDescription,
      lossAmountUserEntered,
      policyLossDate,
    );
    if (policyLossFieldValid && formValid) {
      await patchFormValues();
      onNext();
      setPolicyLossActionInfoMessage('Loss saved.');
      setPolicyLossActionInfoType('ADD');
    }
  });

  const handleCancel = useCallback(() => {
    if (policyLossDescription.props.value) {
      if (editPolicyLossRef) {
        setOpenPolicyLossDialog(false);
        onCancel('');
      } else if (policyLossDescription.props.value) {
        setOpenPolicyLossDialog(true);
        onCancel(editPolicyLossRef || currentPolicyLossRef);
      }
    } else {
      setAddingPolicyLoss(false);
      setOpenPolicyLossDialog(false);
      onCancel('');
    }
  }, [
    policyLossDescription.props.value,
    editPolicyLossRef,
    setOpenPolicyLossDialog,
    onCancel,
    currentPolicyLossRef,
    setAddingPolicyLoss,
  ]);

  /**
   * handleOnblur in NumberFormat has issues in normalizeValue function
   * where it is not able to normalize value when we have -ve value with prefix
   */
  const handlerOnBlur: NonNullable<NumberFormatProps['onBlur']> = useEvent((event) => {
    const value = normalizeValue({
      value: event.target.value,
    });
    lossAmountUserEntered.props.actionOnComplete(value);
  });

  const renderPolicyLosses = (): React.ReactElement => (
    <Grid container item xs={12}>
      {policyLossDate.exists && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            <DatePicker
              {...policyLossDate.props}
              fullWidth
              id='LossDate'
              hidePicker
              trackingName='policy_loss_date'
              trackingLabel={policyLossDate.props.value}
              label='Loss Date'
            />
          </GridItem>
        </Grid>
      )}
      {policyLossDescription.exists && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            <Select
              {...(policyLossDescription.props as OptionProps)}
              id='PolicyLossDescription'
              inputButtonAriaLabel='PolicyLoss Description'
              label='Loss Description'
              trackingName='policy_loss_description_selection'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
        </Grid>
      )}
      {lossAmountUserEntered.exists && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            <NumberFormat
              {...lossAmountUserEntered.props}
              ariaLabel='Amount'
              prefix='$'
              placeholder='$0.00'
              thousandSeparator
              label='Amount'
              trackingName='policy_loss_amount'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              onBlur={handlerOnBlur}
            />
          </GridItem>
        </Grid>
      )}
      <GridItem xs={12}>
        <div className={classes.actionButtons}>
          <Resource.CancelButton
            onClick={handleCancel}
            data-testid='policyLossCancel'
            trackingName='policyLoss_cancel_button'
            trackingLabel='policyLoss_cancel'
            analyticsElement='choice.policyLoss.cancelButton'
          >
            Cancel
          </Resource.CancelButton>
          <Resource.SaveButton
            onClick={handleAddPolicyLoss}
            data-testid='policyLossSave'
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='policyLoss_save'
            analyticsElement='choice.policyLoss.saveButton'
          >
            SAVE LOSS
          </Resource.SaveButton>
        </div>
      </GridItem>
    </Grid>
  );

  return (
    <GridItem topSpacing='lg'>
      <FormLabel component='legend' focused={false}>
        Tell us about the policy loss.
        <FormHelperText error={false}>
          We’ll confirm the details with third-party reports prior to purchase to determine your
          final quote, which may impact your premium.
        </FormHelperText>
      </FormLabel>
      {renderPolicyLosses()}
    </GridItem>
  );
};
