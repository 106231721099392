import { emptyArray } from '@ecp/utils/common';

import { LineOfBusiness } from '@ecp/features/shared/product';

export const getLineOfBusinessFromProducts = (
  selectedProducts: LineOfBusiness[],
): LineOfBusiness | null => {
  const hasAuto = selectedProducts.includes(LineOfBusiness.AUTO);
  const hasHome = selectedProducts.includes(LineOfBusiness.HOME);
  const hasRenters = selectedProducts.includes(LineOfBusiness.RENTERS);

  // TODO: temporary hack to allow agent flow to continue until sapi makes changes to support a multi-select product
  if (hasAuto && hasHome) return LineOfBusiness.BUNDLE;
  if (hasAuto && hasRenters) return LineOfBusiness.BUNDLE_AUTO_RENTERS;
  if (hasAuto) return LineOfBusiness.AUTO;
  if (hasHome) return LineOfBusiness.HOME;
  if (hasRenters) return LineOfBusiness.RENTERS;

  return null;
};

const productsFromLineOfBusiness: Record<LineOfBusiness, LineOfBusiness[]> = {
  [LineOfBusiness.AUTO]: [LineOfBusiness.AUTO],
  [LineOfBusiness.HOME]: [LineOfBusiness.HOME],
  [LineOfBusiness.RENTERS]: [LineOfBusiness.RENTERS],
  [LineOfBusiness.BUNDLE]: [LineOfBusiness.AUTO, LineOfBusiness.HOME],
  [LineOfBusiness.BUNDLE_AUTO_RENTERS]: [LineOfBusiness.AUTO, LineOfBusiness.RENTERS],
};

export const getProductsFromLineOfBusiness = (lineOfBusiness: unknown): LineOfBusiness[] =>
  productsFromLineOfBusiness[lineOfBusiness as LineOfBusiness] ||
  (emptyArray as unknown as LineOfBusiness[]);
