import type { StateOption } from '@ecp/types';

const AllExceptColoradoIowaKansasNevadaOregon = `Consumer reports will be ordered to determine eligibility for insurance and rate your policy. These reports include information about claims, credit history and driving history, and may include information about current and former members of your household. Consumer reports may also be used for updates, renewals, or extensions of this insurance. We may also obtain and use a credit-based insurance score based on information contained in your credit report. We may use a third party in connection with the development of the insurance score. Personal information may be collected from sources other than yourself. This will include a loss history report showing your personal insurance claims history.

        Such information, as well as other information collected, may be disclosed in certain circumstances to third parties without authorization as allowed by law. A right to access and correction exists with respect to all personal information and a written notice will be furnished to you upon request.`;

const Colorado = `Consumer reports will be ordered to determine eligibility for insurance and rate your policy. These reports include information about claims, credit history and driving history, and may include information about current and former members of your household. Consumer reports may also be used for updates, renewals, or extensions of this insurance. We may also obtain and use a credit-based insurance score based on information contained in your credit report. We may use a third party in connection with the development of the insurance score. Personal information may be collected from sources other than yourself. This will include a loss history report showing your personal insurance claims history.

        Such information, as well as other information collected, may be disclosed in certain circumstances to third parties without authorization as allowed by law. A right to access and correction exists with respect to all personal information and a written notice will be furnished to you upon request.`;

const IowaKansasNevada = `Consumer reports will be ordered to determine eligibility for insurance and rate your policy. These reports include information about claims, credit history, and driving history, and may include information about current and former members of your household. Consumer reports may also be used for updates, renewals, or extensions of this insurance. We may also obtain and use a credit-based insurance score based on information contained in your credit report. We may use a third party in connection with the development of the insurance score. Personal information may be collected from sources other than yourself. This will include a loss history report showing your personal insurance claims history.

        On rare occasions you may experience a catastrophic situation that negatively impacts your credit score. This could also impact your insurance rates. State law allows you to request an exception from the use of your credit score if you (or another named insured) have suffered one of the following life events:

        1. Catastrophic illness/injury.
        2. Death of a spouse, child or parent.
        3. Involuntary loss of employment of three months or more.
        4. Identity theft.
        5. Dissolution of marriage or involuntary interruption of legally owed alimony (maintenance).6. Involuntary interruption of legally owed child support.
        7. Catastrophic event as declared by the federal or state government.
        8. Military deployment overseas.
        9. Other events, as determined by the insurer.

        If any of these events applies to you or another named insured, and you believe it has negatively impacted your credit score, you may be eligible for an Extraordinary Life Events Exception. Please contact us to discuss the application process.
        Such information, as well as other information collected, may be disclosed in certain circumstances to third parties without authorization as allowed by law. A right to access and correction exists with respect to all personal information and a written notice will be furnished to you upon request.`;

const Oregon = `Consumer reports will be ordered to determine eligibility for insurance and rate your policy. These reports include information about claims, credit history, and driving history, and may include information about current and former members of your household. Consumer reports may also be used for updates, renewals, or extensions of this insurance. We may also obtain and use a credit-based insurance score based on information contained in your credit report. We may use a third party in connection with the development of the insurance score. We will only obtain your credit report when you apply for this insurance. If you request, we will obtain your credit report to recalculate your credit-based insurance score and will only use that information if it results in a lower rate/premium for your policy.

        You may request a written statement describing our use of credit histories or insurance scores. Personal information may be collected from sources other than yourself. This will include a loss history report showing your personal insurance claims history.

        Such information, as well as other information collected, may be disclosed in certain circumstances to third parties without authorization as allowed by law. A right to access and correction exists with respect to all personal information and a written notice will be furnished to you upon request.      `;

const stateOptions = {
  AZ: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  GA: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  ID: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  IL: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  IN: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  MN: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  MO: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  NE: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  ND: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  OH: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  SD: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  UT: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  WA: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  WI: {
    agreementContent: AllExceptColoradoIowaKansasNevadaOregon,
  },
  CO: {
    agreementContent: Colorado,
  },
  OR: {
    agreementContent: Oregon,
  },
  IA: {
    agreementContent: IowaKansasNevada,
  },
  KS: {
    agreementContent: IowaKansasNevada,
  },
  NV: {
    agreementContent: IowaKansasNevada,
  },
};

export interface BaseMetadata {
  agreementContent?: string;
}

export interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}
export const metadata: StateMetadata = {
  stateOptions: stateOptions,
};

export default metadata;
