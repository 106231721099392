import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { ReadOnlyField } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getDrivers, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

interface Props extends QuestionProps {
  driverRef: string;
  helperText?: string;
  isPni: boolean;
  groupLabel: string;
}

export const DriverRoleQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    id = 'DriverRole',
    label,
    groupLabel,
    trackingName = 'DriverRole',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    placeholder = 'Select Operator Role',
    helperText,
    isPni,
  } = props;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const driverStatus = useDriverField('driverStatus');
  const drivers = useSelector(getDrivers);
  // If PNI is the only driver, they have to be an operator
  const isReadOnlyField = isPni && drivers.length === 1;

  useInitValues({ [driverStatus.key]: 'OPERATOR' });
  useAddFields({ driverStatus });

  // PNI can't be Non-Operator for Advance
  let driverStatusOptions = driverStatus.props.options;
  if (isPni) {
    driverStatusOptions = driverStatusOptions?.filter((status) => status.label !== 'Non-Operator');
  }

  if (!driverStatus.exists) return null;

  const statusDisplayName =
    (driverStatus?.props?.options?.find((opt) => opt.value === driverStatus.value)
      ?.label as string) || '';
  const driverRole = isReadOnlyField ? (
    <ReadOnlyField
      label={groupLabel}
      value={statusDisplayName}
      helperText='A single operator cannot be excluded.'
      id='driverStatus'
    />
  ) : (
    <Select
      {...(driverStatus.props as OptionProps)}
      fullWidth={false}
      placeholder={placeholder}
      id={id}
      inputButtonAriaLabel='Drivers Role'
      label={label}
      groupLabel={groupLabel}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      helperText={helperText}
      options={driverStatusOptions}
    />
  );

  return driverRole;
};
