import { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import { isMasked } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';
import { useEvent } from '@ecp/utils/react';

import type { VehicleOption } from '@ecp/features/sales/quotes/auto';
import { gettingVehicleImage } from '@ecp/features/sales/quotes/auto';
import {
  fetchMakes,
  fetchModels,
  fetchSeries,
  fetchVehicleByVinNumber,
  fetchVehicleInfo as fethcVehicleInfoByYearMakeModelDetailId,
} from '@ecp/features/sales/quotes/auto';
import {
  fetchApi,
  getCustomerId,
  isAnyApiInProgress,
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { VehicleModel } from '@ecp/features/sales/shared/types';
import type { Field, Option } from '@ecp/types';

import type { VehicleOptions, VehicleOptionType } from './types';

interface VehicleVinHook {
  stubVin: string;
  stubVinError?: string;
}

interface VehicleInfoHookReturnType {
  isVehicleApiInProgress: boolean;
  infoError?: string;
  setInfoError: (infoError: string) => void;
  /** Returns true if there was any error during the function execution, otherwise - false. */
  fetchVehicleInfo: (year: string, vinNumber: string) => Promise<boolean>;
}

export const useVehicleStubVin = (
  year: string,
  make: string,
  model: string,
  vehicleDetailId: string,
  vehicleRef: string,
  vehicleInfoOrVinValue: string,
): VehicleVinHook => {
  const dispatch = useDispatch();
  const [stubVinError, setStubVinError] = useState<string>();
  const [stubVin, setStubVin] = useState('');
  const customerId = useSelector(getCustomerId);
  const getStubVin = useCallback(async (): Promise<void> => {
    let vehicleVin = '';
    let err;
    try {
      if (vehicleInfoOrVinValue === 'vehicleInfo' && year && make && model && vehicleDetailId) {
        const vehicleInfo = await fethcVehicleInfoByYearMakeModelDetailId({
          year,
          make,
          model,
          vehicleDetailId,
          referenceId: customerId,
        });
        if (vehicleInfo) {
          vehicleVin = vehicleInfo.vinPattern;
          await dispatch(
            updateAnswers({
              answers: {
                [`${vehicleRef}.stubVin`]: vehicleVin,
                [`${vehicleRef}.vehicleDetailId`]: vehicleInfo.vehicleDetailId,
                [`${vehicleRef}.msrpPrice`]: vehicleInfo.msrp,
              },
            }),
          );
          setStubVin(vehicleVin);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      datadogLog({
        logType: 'warn',
        message: `error caught getting vehicle info for vin stub - ${e?.message}`,
        context: {
          logOrigin:
            'apps/sales/edsp-asp/src/lob/autoLine/forms/AddEditVehicleForm/vehicleOptions.ts',
          functionOrigin: 'useVehicleStubVin',
          vehicleYear: year,
          vehicleMake: make,
          vehicleModel: model,
          vehicleDetailId,
          vehicleRef,
          // TODO: we cannot spread anything into DD logs
          // ...e,
        },
        error: e,
      });
      err = e;
    } finally {
      setStubVinError(err);
      if (err) setStubVin('');
    }
  }, [vehicleInfoOrVinValue, year, make, model, vehicleDetailId, customerId, dispatch, vehicleRef]);

  useEffect(() => {
    getStubVin();
  }, [getStubVin]);

  return { stubVin, stubVinError };
};

const EMPTY_OPTIONS: VehicleOptionType[] = [];
const API_PREFIX = 'vehicleAPI';

const getOptions = (options: string[] | VehicleModel[]): Option[] => {
  return options.map((opt) => {
    if (typeof opt === 'string') return { value: opt, label: opt };

    return { value: opt.name, label: opt.name, make: opt.make } as unknown as Option;
  });
};

// year options
const currentYear = dayjs().year();
export const yearOptions = Array.from({ length: 42 }, (_, i) => {
  const y = String(currentYear + 1 - i);

  return { label: y, value: y };
});

export const useVehicleOptions = (
  year: string,
  make: string,
  model: string,
  series: string,
  vehicleInfoOrVinValue: string,
  vehicleRef: string,
  ignoreChange: boolean,
): VehicleOptions & VehicleVinHook => {
  const dispatch = useDispatch();
  const isVehicleApiInProgress = useSelector((state: RootStore) =>
    isAnyApiInProgress(state, API_PREFIX),
  );
  const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];

  const fetchVehicleOptions = useCallback(
    (
      fn: () => Promise<string[] | VehicleModel[]>,
      setOptions: (options: VehicleOptionType[]) => void,
      setError: (error: string) => void,
      setApiInProgress?: (boolean: boolean) => void,
    ): void => {
      if (disableVehicleServiceCallFlag) return;

      dispatch(
        fetchApi({
          fn: async () => {
            let err;
            if (setApiInProgress) {
              setApiInProgress(true);
            }
            try {
              setOptions(await fn().then(getOptions));
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
              err = e;
            } finally {
              setError(err);
              if (err) setOptions([]);
              if (setApiInProgress) {
                setApiInProgress(false);
              }
            }
          },
          idPrefix: API_PREFIX,
        }),
      );
    },
    [dispatch, disableVehicleServiceCallFlag],
  );

  // make options
  const [makeError, setMakeError] = useState<string>();
  const [makeOptions, setMakeOptions] = useState<VehicleOptionType[]>([]);
  const customerId = useSelector(getCustomerId);
  const getMakeOptions = useCallback(async (): Promise<void> => {
    if (!year) {
      setMakeOptions(EMPTY_OPTIONS);
    } else if (!ignoreChange) {
      fetchVehicleOptions(
        () => fetchMakes({ year, referenceId: customerId }),
        setMakeOptions,
        setMakeError,
      );
    }
  }, [customerId, fetchVehicleOptions, ignoreChange, year]);

  useEffect(() => {
    if (vehicleInfoOrVinValue === 'vehicleInfo') getMakeOptions();
  }, [getMakeOptions, vehicleInfoOrVinValue]);

  // model options
  const [modelError, setModelError] = useState<string>();
  const [modelOptions, setModelOptions] = useState<Option[]>([]);
  const [isModelRequestInProgress, setModelRequestInProgress] = useState(false);
  const getModelOptions = useCallback(async (): Promise<void> => {
    if (!year || !make) setModelOptions(EMPTY_OPTIONS);
    else if (!ignoreChange) {
      fetchVehicleOptions(
        () => fetchModels({ year, make, referenceId: customerId }),
        setModelOptions,
        setModelError,
        setModelRequestInProgress,
      );
    }
  }, [customerId, fetchVehicleOptions, ignoreChange, make, year]);

  useEffect(() => {
    if (vehicleInfoOrVinValue === 'vehicleInfo') getModelOptions();
  }, [getModelOptions, vehicleInfoOrVinValue]);

  // series options
  const [seriesError, setSeriesError] = useState<string>();
  const [seriesOptions, setSeriesOptions] = useState<Option[]>([]);
  const [seriesOriginOptions, setSeriesOriginOptions] = useState<VehicleOption[]>([]);
  const [isSeriesRequestInProgress, setSeriesRequestInProgress] = useState(false);
  const getSeriesOptions = useCallback(async (): Promise<void> => {
    if (!year || !make || !model) setSeriesOptions(EMPTY_OPTIONS);
    else if (!ignoreChange) {
      fetchVehicleOptions(
        async (): Promise<string[]> => {
          const options = await fetchSeries({ year, make, model, referenceId: customerId });
          setSeriesOriginOptions(options);

          return options.map((option) => option.name);
        },
        setSeriesOptions,
        setSeriesError,
        setSeriesRequestInProgress,
      );
    }
  }, [fetchVehicleOptions, ignoreChange, make, model, year, customerId]);

  useEffect(() => {
    if (vehicleInfoOrVinValue === 'vehicleInfo') getSeriesOptions();
  }, [getSeriesOptions, vehicleInfoOrVinValue]);

  // car image url
  const [vehicleImageUrl, setVehicleImageUrl] = useState<string>();
  const [vehicleImageError, setVehicleImageError] = useState<string>();
  const getVehicleImage = useCallback(async (): Promise<void> => {
    let err;
    try {
      const url = await dispatch(gettingVehicleImage({ year, make, model }));
      setVehicleImageUrl(url || undefined);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      err = e;
    } finally {
      setVehicleImageError(err);
      if (err) setVehicleImageUrl(undefined);
    }
  }, [year, make, model, dispatch]);

  useEffect(() => {
    getVehicleImage();
  }, [getVehicleImage]);

  const vehicleDetailId = useMemo(() => {
    if (series && seriesOriginOptions.length > 0) {
      const option = seriesOriginOptions.find((op) => op.name === series);
      if (option && option.key) {
        return option.key.split('#')[0];
      }
    }

    return '';
  }, [seriesOriginOptions, series]);

  const { stubVin, stubVinError } = useVehicleStubVin(
    year,
    make,
    model,
    vehicleDetailId,
    vehicleRef,
    vehicleInfoOrVinValue,
  );

  return {
    isVehicleApiInProgress,
    isModelRequestInProgress,
    isSeriesRequestInProgress,
    makeError,
    makeOptions,
    modelError,
    modelOptions,
    seriesError,
    seriesOptions,
    vehicleImageUrl,
    vehicleImageError,
    stubVin,
    stubVinError,
  };
};

export const useVehicleInfo = (
  make: Field,
  model: Field,
  series: Field,
  vin: Field,
  stubVin: Field,
  vehicleDetailId: Field,
  msrpPrice: Field,
): VehicleInfoHookReturnType => {
  const dispatch = useDispatch();
  const [infoError, setInfoError] = useState<string>();
  const isVehicleApiInProgress = useSelector((state: RootStore) =>
    isAnyApiInProgress(state, API_PREFIX),
  );
  const customerId = useSelector(getCustomerId);

  const clearVehicleInfo = useCallback(() => {
    series.props.actionOnComplete('');
    model.props.actionOnComplete('');
    make.props.actionOnComplete('');
    stubVin.props.actionOnComplete('');
    vehicleDetailId.props.actionOnComplete('');
    msrpPrice.props.actionOnComplete('');
  }, [
    series.props,
    model.props,
    make.props,
    stubVin.props,
    vehicleDetailId.props,
    msrpPrice.props,
  ]);

  const fetchVehicleInfo = useCallback(
    async (year: string | undefined, vinNumber: string | undefined): Promise<boolean> => {
      let err = '';
      if (year && vinNumber) {
        await dispatch(
          fetchApi({
            fn: async () => {
              try {
                if (!isMasked(vinNumber)) {
                  const vehicleInfo = await fetchVehicleByVinNumber({
                    year,
                    vinNumber,
                    referenceId: customerId,
                  });
                  if (vehicleInfo) {
                    const {
                      make: makeValue,
                      model: modelValue,
                      vehicleCharacteristics: seriesValue,
                      vinPattern: stubVinValue,
                      vehicleDetailId: vehicleDetailIdValue,
                      msrp: msrpValue,
                    } = vehicleInfo;
                    make.props.actionOnComplete(makeValue);
                    model.props.actionOnComplete(modelValue);
                    series.props.actionOnComplete(seriesValue);
                    stubVin.props.actionOnComplete(stubVinValue);
                    vehicleDetailId.props.actionOnComplete(vehicleDetailIdValue);
                    msrpPrice.props.actionOnComplete(msrpValue);
                    dispatch(setFormErrorsResetByField({ key: vin.key }));
                  } else {
                    clearVehicleInfo();
                    err = 'VIN is invalid';
                    dispatch(
                      setFormErrorsChangedByField({
                        key: vin.key,
                        errors: ['VIN is invalid'],
                      }),
                    );
                  }
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (error: any) {
                clearVehicleInfo();
                dispatch(setFormErrorsChangedByField({ key: vin.key, errors: [error.message] }));
                err = error.message;
              } finally {
                setInfoError(err);
              }
            },
            idPrefix: API_PREFIX,
          }),
        );
      }
      if (err) return true;

      return false;
    },
    [
      dispatch,
      customerId,
      make.props,
      model.props,
      series.props,
      stubVin.props,
      vehicleDetailId.props,
      msrpPrice.props,
      vin.key,
      clearVehicleInfo,
    ],
  );

  return { isVehicleApiInProgress, infoError, setInfoError, fetchVehicleInfo };
};

export const useGroupPatchedVehicleInfo = (
  make: Field,
  model: Field,
  series: Field,
  vin: Field,
  stubVin: Field,
  vehicleDetailId: Field,
  vehicleRef: string,
): VehicleInfoHookReturnType => {
  const dispatch = useDispatch();
  const [infoError, setInfoError] = useState<string>();
  const isVehicleApiInProgress = useSelector((state: RootStore) =>
    isAnyApiInProgress(state, API_PREFIX),
  );
  const customerId = useSelector(getCustomerId);

  const clearVehicleInfo = useCallback(() => {
    dispatch(
      updateAnswers({
        answers: {
          [`${vehicleRef}.make`]: '',
          [`${vehicleRef}.model`]: '',
          [`${vehicleRef}.vehicleDetailId`]: '',
          [`${vehicleRef}.stubVin`]: '',
          [`${vehicleRef}.series`]: '',
          [`${vehicleRef}.msprPrice`]: '',
        },
      }),
    );
  }, [dispatch, vehicleRef]);

  const fetchVehicleInfo = useEvent(
    async (year: string | undefined, vinNumber: string | undefined): Promise<boolean> => {
      let err = '';
      if (year && vinNumber) {
        await dispatch(
          fetchApi({
            fn: async () => {
              try {
                if (!isMasked(vinNumber)) {
                  const vehicleInfo = await fetchVehicleByVinNumber({
                    year,
                    vinNumber,
                    referenceId: customerId,
                  });
                  if (vehicleInfo) {
                    const {
                      make: makeValue,
                      model: modelValue,
                      vehicleCharacteristics: seriesValue,
                      msrp: msrpValue,
                      vinPattern: stubVinValue,
                      vehicleDetailId: vehicleDetailIdValue,
                    } = vehicleInfo;
                    dispatch(
                      updateAnswers({
                        answers: {
                          [`${vehicleRef}.make`]: makeValue,
                          [`${vehicleRef}.model`]: modelValue,
                          [`${vehicleRef}.vehicleDetailId`]: vehicleDetailIdValue,
                          [`${vehicleRef}.msrpPrice`]: msrpValue,
                          [`${vehicleRef}.stubVin`]: stubVinValue,
                          [`${vehicleRef}.series`]: seriesValue,
                        },
                      }),
                    );
                    dispatch(setFormErrorsResetByField({ key: vin.key }));
                  } else {
                    clearVehicleInfo();
                    err = 'VIN is invalid';
                    dispatch(
                      setFormErrorsChangedByField({
                        key: vin.key,
                        errors: ['VIN is invalid'],
                      }),
                    );
                  }
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (error: any) {
                clearVehicleInfo();
                dispatch(setFormErrorsChangedByField({ key: vin.key, errors: [error.message] }));
                err = error.message;
              } finally {
                setInfoError(err);
              }
            },
            idPrefix: API_PREFIX,
          }),
        );
      }
      if (err) return true;

      return false;
    },
  );

  return { isVehicleApiInProgress, infoError, setInfoError, fetchVehicleInfo };
};
