import { TextField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { useSingleExteriorWall } from '@ecp/features/sales/quotes/property/home';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getShownFields, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Fields } from '@ecp/types';

export const ExteriorWallConstructionDescriptionQuestion: React.FC<QuestionProps> = (props) => {
  const { singleExteriorWallRef: exteriorWallRef } = useSingleExteriorWall();
  const exteriorWallConstructionDescriptionField = useFieldWithPrefix(
    exteriorWallRef,
    'exteriorWall.<id>',
  )('description');

  useAddFields({
    [exteriorWallConstructionDescriptionField.key]: exteriorWallConstructionDescriptionField,
  });

  // This is needed for fields with hide attribute depending on some other node
  const dispatch = useDispatch();
  const filteredFields = dispatch(
    getShownFields({
      fields: {
        [exteriorWallConstructionDescriptionField.key]: exteriorWallConstructionDescriptionField,
      },
    }),
  ) as Fields;

  if (
    !exteriorWallConstructionDescriptionField.exists ||
    (filteredFields && !filteredFields[exteriorWallConstructionDescriptionField.key])
  )
    return null;

  const {
    label = exteriorWallConstructionDescriptionField.question?.title || 'Other Description',
    trackingName = 'Other Description',
  } = props;

  return (
    <TextField
      {...exteriorWallConstructionDescriptionField.props}
      ariaLabel='Exterior Wall Construction Description'
      groupLabel={label}
      trackingLabel='exteriorWallConstructionDescription'
      trackingName={trackingName}
      required
    />
  );
};
