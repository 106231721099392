import { CircularProgress } from '@mui/material';

import { useSearchParams } from '@ecp/utils/routing';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { DriverType } from '@ecp/features/sales/quotes/auto';
import { useVehiclesForSidebar } from '@ecp/features/sales/quotes/auto';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { getAssignmentDrivers } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { DriverAssignmentForm } from '../../forms';
import { useGetDriverAssignmentFields } from '../../utils';
import { useStyles } from './DriverAssignmentPage.styles';

export const DriverAssignmentPage: React.FC = () => {
  const { classes } = useStyles();

  const assignmentDrivers = useSelector(getAssignmentDrivers);

  const vehicles = useVehiclesForSidebar();
  const { referencePage } = useSearchParams();

  const fields = useGetDriverAssignmentFields(vehicles, DriverType.PRIMARY);
  const occasionalFields = useGetDriverAssignmentFields(vehicles, DriverType.OCCASIONAL);
  const navigateToPriorCarrier = useNavigateToPage(PagePath.AUTO_PRIOR_INSURANCE);

  const navigateBack = useNavigateToPage(PagePath.ADD_EDIT_VEHICLES);

  const title = (
    <>
      Vehicle & driver assignment
      <h2 className={classes.subTitle}>
        Use this form to assign a primary driver for your vehicle(s)
      </h2>
    </>
  );

  const nextPageInstructions =
    referencePage === 'quotes'
      ? 'Your quote is ready on the next screen'
      : 'Your discounts are ready on the next screen';

  return (
    <div className={classes.root}>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.EDIT_AUTO_PROFILE} />,
        }}
        title={title}
        analyticsElement='choice.driverAssignmentPage.page'
      >
        {assignmentDrivers.length === 0 ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <DriverAssignmentForm
            drivers={assignmentDrivers}
            vehicles={vehicles}
            onNext={navigateToPriorCarrier}
            onBack={navigateBack}
            nextPageInstructions={nextPageInstructions}
            driverAssignmentFields={fields}
            occasionalDriverAssignmentFields={occasionalFields}
          />
        )}
      </Page>
    </div>
  );
};
