import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels, trackClick, trackHover } from '@ecp/utils/analytics/tracking';

import type { SelectProps } from '@ecp/components';
import { GridItem, TextField, TooltipWithIcon } from '@ecp/components';
import {
  useAddFields,
  useAddFieldsCallback,
  useGetConditionValues,
  useGetInitValues,
  useInitValues,
  useRemoveByFieldsCallback,
} from '@ecp/features/sales/form';
import { DatePicker, Form, Resource, Select } from '@ecp/features/sales/shared/components';
import {
  getOffersExist,
  setFormErrorsChangedByField,
  updateAnswers,
  useField,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps, PageErrors } from '@ecp/features/sales/shared/types';
import { CustomCheckbox } from '@ecp/sales/lob/auto';
import type { Field } from '@ecp/types';

import { useGetDriverInsuranceFields, useGetPriorInsuranceFields } from '../../utils';
import { useStyles } from './InsuranceQuestions.styles';

export interface PriorInsuranceQuestionsProps {
  itemRef: string;
  driverRef: string;
  isPni: boolean;
  priorInsuranceRef: string;
  userEnteredCurrentInsuranceLabel?: string;
  pageErrors: PageErrors[];
  onNext: () => void;
  onCancel: (priorInsuranceRef: string) => void;
}

export const PriorInsuranceQuestions: React.FC<PriorInsuranceQuestionsProps> = (props) => {
  const {
    itemRef: priorInsuranceRef,
    isPni,
    driverRef,
    userEnteredCurrentInsuranceLabel,
    pageErrors,
    onNext,
    onCancel,
  } = props;

  const { cx, classes } = useStyles();

  const priorInsuranceFields = useGetPriorInsuranceFields(priorInsuranceRef);
  const { lapse } = useGetDriverInsuranceFields(driverRef);

  const {
    state,
    carrier,
    carrierNameText,
    endDate,
    policyInceptionDate,
    limit,
    years,
    vehicleCollision,
    vehicleComprehensive,
  } = priorInsuranceFields;

  const fieldsToValidate = {
    state,
    carrier,
    years,
    endDate,
    policyInceptionDate,
    limit,
    vehicleCollision,
    vehicleComprehensive,
    lapse,
  };
  useAddFields(fieldsToValidate);

  useInitValues({
    [vehicleCollision.key]: false,
    [vehicleComprehensive.key]: false,
  });
  const getConditions = useGetConditionValues();
  const conditions = getConditions();
  const getInitValues = useGetInitValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: fieldsToValidate,
    conditions,
  });

  const dispatch = useDispatch();
  const offersExist = useSelector(getOffersExist);
  const requestCoveragesBI = useField('offer.requestedCoverages.policy.bodilyInjury');
  const addFields = useAddFieldsCallback();
  const removeByFields = useRemoveByFieldsCallback();

  useEffect(() => {
    if (carrier.value === 'CARRIER.OTHER' || carrier.value === 'CARRIER.PREFILL') {
      addFields({ carrierNameText });
    } else {
      removeByFields({
        carrierNameText,
      });
    }
  }, [addFields, removeByFields, carrier, carrierNameText]);

  const filteredCarrierProps = useMemo(() => {
    return {
      ...carrier.props,
      options: carrier.props?.options?.filter(
        (option) => option.value !== 'CARRIER.PREFILL' && option.value !== 'CARRIER.NONE',
      ),
    };
  }, [carrier.props]);

  filteredCarrierProps.actionOnChange = useCallback(
    (value: AnswerValue) => {
      carrier.props.actionOnChange(value);
    },
    [carrier.props],
  );
  filteredCarrierProps.actionOnComplete = useCallback(
    async (value: AnswerValue) => {
      carrier.props.actionOnComplete(value);
      /**
       * Because of SAPI removing hasPriorInsurance as a question, we have to handle the required fields on the UI
       *    Because they don't have that indicator, they are not able to use it to statically set the required property on each
       *    prior insurance question.
       */
      if (!value) {
        dispatch(
          setFormErrorsChangedByField({
            key: carrier.key,
            errors: ['Required field'],
          }),
        );
      }
    },
    [carrier.key, carrier.props, dispatch],
  );

  const [hoverTimer, setHoverTimer] = useState(Date.now());
  const prefixPniSni = useCallback(
    (value: string): string => {
      return `${isPni ? 'pni' : 'sni'}_${value}`;
    },
    [isPni],
  );

  useEffect(() => {
    trackClick({
      action: prefixPniSni('prior_bi_limit_selection'),
      label: limit.props.value,
    });
  }, [limit.props.value, prefixPniSni]);
  useEffect(() => {
    if (endDate.props.value !== 'Invalid date')
      trackClick({
        action: prefixPniSni('prior_policy_end_date'),
        label: endDate.props.value,
      });
  }, [endDate.props.value, prefixPniSni]);
  useEffect(() => {
    if (carrier.props.value !== 'CARRIER.OTHER' && carrier.props.value !== 'CARRIER.PREFILL') {
      carrierNameText.update('');
    }
  }, [carrier.props.value, carrierNameText]);

  const getRequestedOfferBI = useCallback(
    (priorBIValue: string): string => {
      const { options } = requestCoveragesBI.props;
      if (!priorBIValue || !options) return '';
      const last = priorBIValue.substring(priorBIValue.lastIndexOf('.') + 1);
      const match = options.find(
        (option) => option.value.substring(option.value.lastIndexOf('.') + 1) === last,
      );
      if (!match) {
        // only one that doesn't match
        return 'REQUESTED_COVERAGES.POLICY.BODILY_INJURY.TWOHUNDREDFIFTY_FIVEHUNDRED';
      }

      return match.value;
    },
    [requestCoveragesBI.props],
  );

  const handleBIComplete: NonNullable<SelectProps['actionOnComplete']> = useCallback(
    async (value) => {
      limit.props.actionOnComplete(value);
      if (value && isPni && !offersExist) {
        // not really a great way to default since request coverages BI already exists by the time PNI selects this
        // so it becomes a conditional "overwrite". We will overwrite it until we get offers. After that, we would run the risk
        // of overwriting a user-customized value on customize coverage feature
        await dispatch(
          updateAnswers({
            answers: { [requestCoveragesBI.key]: getRequestedOfferBI(value as string) },
          }),
        );
      }
    },
    [dispatch, getRequestedOfferBI, isPni, limit.props, offersExist, requestCoveragesBI.key],
  );

  const handleHover = useCallback((): void => {
    setHoverTimer(Date.now());
  }, []);
  const handleHoverEnd = useCallback((): void => {
    const duration = Math.floor((Date.now() - hoverTimer) / 1000);
    if (duration > 1) {
      trackHover({
        action: 'prior_bi_limit_hover',
        label: duration,
      });
    }
  }, [hoverTimer]);

  const flagToHidePriorInsuranceQuestion = carrier.props.options?.find(
    (i) => i.value === carrier.value,
  );
  const updateCarrierNameForNotListedCarrier = useCallback(() => {
    const notListedCarrierNameTextRefsKey = `${priorInsuranceRef}.carrierNameText`;
    dispatch(
      updateAnswers({
        answers: {
          [notListedCarrierNameTextRefsKey]: carrierNameText.props.value as string,
        },
      }),
    );
  }, [dispatch, carrierNameText.props, priorInsuranceRef]);

  const handleStateFieldBlur = useCallback(
    (value: AnswerValue) => {
      state.props.actionOnComplete(value);
      /**
       * Because of SAPI removing hasPriorInsurance as a question, we have to handle the required fields on the UI
       *    Because they don't have that indicator, they are not able to use it to statically set the required property on each
       *    prior insurance question.
       */
      if (!value) {
        dispatch(
          setFormErrorsChangedByField({
            key: state.key,
            errors: ['Required field'],
          }),
        );
      }
    },
    [dispatch, state.key, state.props],
  );

  const handleCarrierChange = useCallback(
    (value: AnswerValue) => {
      carrier.props.actionOnComplete(value);
      // Remove all prior insurance information as carrier 'None' has been selected
      if (value === 'CARRIER.NONE') {
        years.validateUpdateAndPatch('');
        policyInceptionDate.validateAndPatch('');
        endDate.validateUpdateAndPatch('');
        limit.validateUpdateAndPatch('');
        if (vehicleCollision.props.value || vehicleComprehensive.props.value) {
          vehicleCollision.validateUpdateAndPatch('false');
          vehicleComprehensive.validateUpdateAndPatch('false');
        }
      }
      // Reset lapse fields
      carrierNameText.validateUpdateAndPatch('');
      lapse.validateUpdateAndPatch('');
    },
    [
      carrier.props,
      carrierNameText,
      endDate,
      lapse,
      limit,
      vehicleCollision,
      vehicleComprehensive,
      years,
      policyInceptionDate,
    ],
  );

  // returns true if there are date errors, and displays the field level message
  const checkAndDisplayDateErrors = useCallback((): boolean => {
    // only set message if both date fields have input
    if (
      policyInceptionDate.value &&
      endDate.value &&
      (dayjs(endDate.value as string).isBefore(policyInceptionDate.value as string) ||
        dayjs(endDate.value as string).isSame(policyInceptionDate.value as string))
    ) {
      dispatch(
        setFormErrorsChangedByField({
          key: endDate.key,
          errors: ['Expiration Date must be after Effective Date'],
        }),
      );

      return true;
    } else return false;
  }, [dispatch, endDate.key, endDate.value, policyInceptionDate.value]);

  const handleDateChange = useCallback(
    async (value: AnswerValue, field: Field<AnswerValue>) => {
      await field.props.actionOnComplete(value);
      /**
       * Because of SAPI removing hasPriorInsurance as a question, we have to handle the required fields on the UI
       *    Because they don't have that indicator, they are not able to use it to statically set the required property on each
       *    prior insurance question.
       */
      if (!policyInceptionDate.value) {
        dispatch(
          setFormErrorsChangedByField({
            key: policyInceptionDate.key,
            errors: ['Required field'],
          }),
        );
      }
      checkAndDisplayDateErrors();
    },
    [checkAndDisplayDateErrors, dispatch, policyInceptionDate.key, policyInceptionDate.value],
  );

  const handleSubmit = useCallback(async () => {
    const priorCarrierFields = [state, carrier, endDate, policyInceptionDate, limit];
    let isFormValid = validateForm().isValid;
    isFormValid = !checkAndDisplayDateErrors(); // form is valid if no errors to display

    // add for other case
    if (carrier.value === 'CARRIER.OTHER' || carrier.value === 'CARRIER.PREFILL') {
      priorCarrierFields.push(carrierNameText);
    }
    priorCarrierFields.forEach((field) => {
      /**
       * Because of SAPI removing hasPriorInsurance as a question, we have to handle the required fields on the UI
       *    Because they don't have that indicator, they are not able to use it to statically set the required property on each
       *    prior insurance question.
       */
      if (!field.value) {
        dispatch(
          setFormErrorsChangedByField({
            key: field.key,
            errors: ['Required field'],
          }),
        );
        isFormValid = false;
      }
    });

    if (!isFormValid) {
      return false;
    }

    // send form values to answers endpoint
    await patchFormValues();
    onNext();

    return true;
  }, [
    carrier,
    carrierNameText,
    checkAndDisplayDateErrors,
    dispatch,
    endDate,
    limit,
    onNext,
    patchFormValues,
    policyInceptionDate,
    state,
    validateForm,
  ]);

  const handleCancelPriorRef = useCallback(async (): Promise<void> => {
    onCancel(priorInsuranceRef);
  }, [priorInsuranceRef, onCancel]);

  useEffect(() => {
    if (pageErrors.length < 1) return;
    // The prior insurance page was submitted while a form was open
    const openFormHasErrors = pageErrors.find((error) => error.id === priorInsuranceRef);
    // If the open prior insurance entity has the error, validate the form so that we will see any field level errors
    if (openFormHasErrors) {
      validateForm();
    }
    // Only execute when page errors changes from empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageErrors.length]);

  if (!priorInsuranceRef) return null; // no form is up for edit

  return (
    <Form showBackdrop={isPatchFormInProgress}>
      <p className={classes.formTitle}>Tell us about the policy.</p>
      <p className={classes.formSubtitle}>
        We’ll confirm the details with third-party reports prior to purchase to determine the final
        quote, which may impact the premium.
      </p>
      <Grid container spacing={2}>
        <GridItem topSpacing='lg' xs={6}>
          <GridItem topSpacing='none' xs={12}>
            <Typography variant='body4'>Effective Date</Typography>
          </GridItem>
          <GridItem topSpacing='sm' xs={12}>
            <DatePicker
              {...policyInceptionDate.props}
              fullWidth
              id='InceptionDate'
              hidePicker
              trackingName={prefixPniSni('prior_policy_inception_date')}
              trackingLabel={policyInceptionDate.props.value}
              label=' ' // blank label needed to add proper id
              // eslint-disable-next-line react/jsx-no-bind
              actionOnComplete={(value: AnswerValue) =>
                handleDateChange(value, policyInceptionDate)
              }
            />
          </GridItem>
        </GridItem>
        <GridItem topSpacing='none' xs={6}>
          <GridItem topSpacing='lg' xs={12}>
            <Typography variant='body4'>Expiration Date</Typography>
          </GridItem>
          <GridItem topSpacing='sm' xs={12}>
            <DatePicker
              {...endDate.props}
              fullWidth
              id='ExpirationDate'
              hidePicker
              trackingName={prefixPniSni('prior_policy_end_date')}
              trackingLabel={endDate.props.value}
              label=' ' // blank label needed to add proper id
              // eslint-disable-next-line react/jsx-no-bind
              actionOnComplete={(value: AnswerValue) => handleDateChange(value, endDate)}
            />
          </GridItem>
        </GridItem>
      </Grid>
      {state.exists && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <Select
            {...(state.props as OptionProps)}
            fullWidth={false}
            placeholder='Select one'
            groupLabel='State'
            id='CurrentInsuranceState'
            inputButtonAriaLabel='Insurance Carrier State'
            data-testid='state'
            trackingName={prefixPniSni('CurrentInsuranceState')}
            actionOnComplete={handleStateFieldBlur}
          />
        </GridItem>
      )}
      {carrier.exists && (
        <GridItem topSpacing='lg' xs={12}>
          <Select
            {...(filteredCarrierProps as OptionProps)}
            fullWidth={false}
            placeholder='Select one'
            groupLabel='Auto Insurance Company'
            id='CurrentAutoCompany'
            inputButtonAriaLabel='Auto Insurance Company'
            data-testid='carrier'
            actionOnChange={handleCarrierChange}
            trackingName='current_insurance_company_selection'
          />
        </GridItem>
      )}
      {carrierNameText.exists &&
        (carrier.value === 'CARRIER.OTHER' || carrier.value === 'CARRIER.PREFILL') && (
          <>
            <GridItem topSpacing='lg' xs={12}>
              <Typography variant='body4'>Enter the Auto Insurance Company.</Typography>
            </GridItem>
            <GridItem
              topSpacing='sm'
              xs={12}
              md={6}
              className={cx(classes.columnLeft, classes.hideLabel)}
            >
              <TextField
                {...carrierNameText.props}
                fullWidth={false}
                id='CarrierNameText'
                ariaLabel='carrier name text'
                maxLength={30}
                groupLabel=''
                onBlur={updateCarrierNameForNotListedCarrier}
                trackingName={prefixPniSni('other_priorcarrier_name')}
                label=' ' // blank label needed to add proper id
              />
            </GridItem>
          </>
        )}
      {(!userEnteredCurrentInsuranceLabel || flagToHidePriorInsuranceQuestion) && (
        <>
          {limit.exists && (
            <GridItem
              topSpacing='lg'
              xs={12}
              onMouseEnter={handleHover}
              onMouseLeave={handleHoverEnd}
            >
              <Select
                {...(limit.props as OptionProps)}
                actionOnComplete={handleBIComplete}
                fullWidth={false}
                groupLabel={
                  <>
                    Bodily Injury Liability Limits
                    <TooltipWithIcon title='Please choose the Bodily Injury Limits that are closest to what you currently have. This can be found on the declaration page of your current policy.' />
                  </>
                }
                id='CurrentAutoPolicyBILimits'
                inputButtonAriaLabel='Current Auto Policy BI Limits'
                data-testid='bodilyInjury'
                trackingName={prefixPniSni('prior_bi_limit_selection')}
              />
            </GridItem>
          )}

          <GridItem topSpacing='lg' xs={12}>
            <Typography variant='body4'>Other Policy Coverages</Typography>
            <span className={classes.textTertiary} key={`${driverRef}-optional`}>
              {' '}
              (optional)
            </span>
          </GridItem>
          <GridItem topSpacing='sm' xs={12}>
            <CustomCheckbox
              // eslint-disable-next-line react/jsx-no-bind
              actionOnChange={(newChecked: AnswerValue): void => {
                vehicleComprehensive.props.actionOnComplete(newChecked);
              }}
              tooltipText='When your vehicle is damaged from something other than a collision, comprehensive coverage may help pay for your losses (minus the deductible)'
              field={vehicleComprehensive}
              title='Comprehensive'
              key={`${driverRef}-${vehicleComprehensive.key}`}
            />
            <CustomCheckbox
              // eslint-disable-next-line react/jsx-no-bind
              actionOnChange={(newChecked: AnswerValue): void => {
                vehicleCollision.props.actionOnComplete(newChecked);
              }}
              tooltipText='If your vehicle collides with another vehicle or object, collision coverage would help pay (minus your deductible) for damage to your vehicle'
              field={vehicleCollision}
              title='Collision'
              key={`${driverRef}-${vehicleCollision.key}`}
            />
          </GridItem>
        </>
      )}
      <div>
        <Grid container justifyContent='flex-end'>
          <GridItem>
            <Resource.CancelButton
              onClick={handleCancelPriorRef}
              data-testid='autoVehicleCancel'
              trackingName='remove_vehicle_link'
              trackingLabel={priorInsuranceRef}
              analyticsElement='choice.priorInsurace.cancelButton'
              analyticsEventDetail={priorInsuranceRef}
            >
              Cancel
            </Resource.CancelButton>
            <Resource.SaveButton
              onClick={handleSubmit}
              data-testid='priorInsuranceSave'
              isProcessing={isPatchFormInProgress}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='prior_insurance_save'
              analyticsElement='choice.priorInsurace.saveButton'
            >
              SAVE POLICY
            </Resource.SaveButton>
          </GridItem>
        </Grid>
      </div>
    </Form>
  );
};
