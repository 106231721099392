import type { SyntheticEvent } from 'react';
import { forwardRef, useCallback } from 'react';

import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import type { CheckboxProps as MuiCheckboxProps } from '@mui/material';

import { GridItem, TooltipWithIcon } from '@ecp/components';
import {
  IconAfiCoverageCollision,
  IconAfiCoverageComprehensive,
  IconAfiDiscountDriverAwayAtSchool,
  IconAfiDiscountDriverDefensiveDriver,
  IconAfiDiscountDriverGoodStudent,
  IconAfiDiscountDriverYoungVolunteer,
} from '@ecp/themes/partners/amfam-adv';
import type { AnswerValue, Field } from '@ecp/types';

import { useStyles } from './CustomCheckbox.styles';

export type CustomCheckboxProps = MuiCheckboxProps & {
  actionOnChange: (checked: boolean) => void;
  field: Field<AnswerValue>;
  tooltipText: string;
  title: string;
};

export const CustomCheckbox: React.FC<CustomCheckboxProps> = forwardRef((props, ref) => {
  const { actionOnChange, field, tooltipText, title } = props;
  const { classes, cx } = useStyles();

  const cardClasses = field.props.value
    ? cx(classes.root, classes.checked)
    : cx(classes.root, classes.discountCard);

  let icon = null;
  if (field.key.includes('awayAtSchool')) {
    icon = <IconAfiDiscountDriverAwayAtSchool className={classes.discountIcon} />;
  } else if (field.key.includes('goodStudent')) {
    icon = <IconAfiDiscountDriverGoodStudent className={classes.discountIcon} />;
  } else if (field.key.includes('youngVolunteer')) {
    icon = <IconAfiDiscountDriverYoungVolunteer className={classes.discountIcon} />;
  } else if (field.key.includes('comprehensive')) {
    icon = <IconAfiCoverageComprehensive className={classes.discountIcon} />;
  } else if (field.key.includes('collision')) {
    icon = <IconAfiCoverageCollision className={classes.discountIcon} />;
  } else if (field.key.includes('defensiveDriver')) {
    icon = <IconAfiDiscountDriverDefensiveDriver className={classes.discountIcon} />;
  }

  const handleCheckboxChange = useCallback(
    (event: SyntheticEvent<Element, Event>, checked: boolean) => {
      event.preventDefault();
      actionOnChange(checked);
    },
    [actionOnChange],
  );

  return (
    <GridItem topSpacing='sm' xs={12} md={6} className={cardClasses}>
      <FormControlLabel
        className={classes.discountLabel}
        control={<MuiCheckbox ref={ref} id={`${field.key}-CustomCheckbox`} />}
        label={
          <p className={classes.labelWithTooltip}>
            {title}
            <TooltipWithIcon title={tooltipText} className={classes.toolTip} />
          </p>
        }
        onChange={handleCheckboxChange}
        checked={field.props.value || false} // if undefined initially, react will treat this as an uncontrolled component
      />
      {icon}
    </GridItem>
  );
});
