import { FormLabel } from '@mui/material';

import { TooltipWithIcon } from '@ecp/components';
import type { AnswerValue } from '@ecp/types';

import { useStyles } from './ReadOnlyField.styles';

interface ReadOnlyFieldProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  label: string;
  value: AnswerValue;
  helperText?: string | React.ReactElement;
  id?: string;
  dataTestId?: string;
}

export const ReadOnlyField: React.FC<ReadOnlyFieldProps> = (props) => {
  const { classes, cx } = useStyles(undefined, { props });

  const { label, value, helperText, dataTestId = label, id, classes: propsClasses } = props;

  return (
    <div>
      <FormLabel
        component='legend'
        error={false}
        focused={false}
        classes={{
          root: propsClasses ? cx(classes.label, propsClasses.label) : classes.label,
        }}
        id={id}
        data-testid={dataTestId}
      >
        {label}
        {helperText && <TooltipWithIcon className={classes.toolTip} title={helperText} />}
      </FormLabel>
      <FormLabel
        component='legend'
        error={false}
        focused={false}
        classes={{
          root: classes.value,
        }}
        id={`${id}-value`}
        data-testid={`${dataTestId}-value`}
      >
        {value}
      </FormLabel>
    </div>
  );
};
