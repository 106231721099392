import { useCallback, useEffect, useMemo, useState } from 'react';

import { Alert } from '@ecp/components';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { getPniPersonInfo } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  DeclinationDialog,
  getOfferErrors,
  QuotesError,
  useCheckoutCardProps,
  useDeclinationNotices,
  useIsOfferLocked,
} from '@ecp/features/sales/shell';
import { isProductAuto } from '@ecp/features/shared/product';
import { IconUIExclaimTriangle } from '@ecp/themes/base';

import { CoveragesForm } from '../../forms';
import { useQuotesPage } from '../../utils';
import { useStyles } from './QuotePage.styles';

export const QuotePage: React.FC = () => {
  const { classes } = useStyles();
  const { firstName } = useSelector(getPniPersonInfo);
  const { loading, dalSessionId, inquiryId, doOffersForSelectedLobExist, xRequestId } =
    useQuotesPage();
  const { erroredProducts } = useDeclinationNotices();
  const { isDNQError, isCNQError } = useSelector(getOfferErrors);
  // Show declination dialog only for auto offer failure
  const erroredAutoProduct = erroredProducts.find((product) => isProductAuto(product));
  const isAutoOfferDeclined =
    doOffersForSelectedLobExist && (isDNQError || isCNQError) && !!erroredAutoProduct;
  const [showDeclinationDialog, setShowDeclinationDialog] = useState<boolean>(isAutoOfferDeclined);
  // Introduce initialLoaded state to fine-control the displaying of the loading spinner
  // TODO: Investigate in a more wholistic approach to show/hide loading spinner to give smooth user experience in all edge cases
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const onNext = useNavigateToNextPage();

  const checkoutCardProps = {
    ...useCheckoutCardProps({ checkoutButtonText: 'Verify Premium', onNext }),
  };

  useEffect(() => {
    if (!loading) {
      // as soon is loading is turned off, we consider it's loaded
      setInitialLoaded(true);
    }
    if (checkoutCardProps.isProcessing) {
      setTimeout(() => setFetchingData(true), 2500);
    } else {
      setFetchingData(false);
    }
  }, [loading, checkoutCardProps.isProcessing]);

  // TODO: Look into removing this useEffect after fixing loading issues in Quotes page as mentioned above
  useEffect(() => {
    setShowDeclinationDialog(isAutoOfferDeclined);
  }, [isAutoOfferDeclined]);

  const handleDeclinationDialogClose = useCallback(() => {
    setShowDeclinationDialog(false);
  }, [setShowDeclinationDialog]);

  // Show sidebar only if there are any offers
  const sidebarProps = useMemo(
    () =>
      doOffersForSelectedLobExist
        ? {
            drawer: <NavbarDrawer pagePath={PagePath.QUOTES} />,
            forceSwipeable: false,
          }
        : undefined,
    [doOffersForSelectedLobExist],
  );

  const isOfferLocked = useIsOfferLocked();

  if ((loading && !initialLoaded) || fetchingData) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <Page
          analyticsElement='choice.quoteSummaryPage.page'
          sidebarProps={sidebarProps}
          title={
            doOffersForSelectedLobExist && (
              <h1 className={classes.header}>The Quote is Ready for {firstName}</h1>
            )
          }
          variant='normal'
        >
          {doOffersForSelectedLobExist ? (
            <>
              {isOfferLocked && (
                <div className={classes.alertContainer}>
                  <Alert
                    withIcon
                    icon={<IconUIExclaimTriangle className={classes.alertIcon} />}
                    type='error'
                  >
                    Your quote is being reviewed. Return in 14 days to retrieve your quote and
                    purchase a policy.
                  </Alert>
                </div>
              )}
              <CoveragesForm checkoutCardProps={checkoutCardProps} />
            </>
          ) : (
            <div className={classes.errorContainer}>
              <QuotesError
                dalSessionId={dalSessionId}
                inquiryId={inquiryId}
                xRequestId={xRequestId}
              />
            </div>
          )}
        </Page>
      </div>
      {showDeclinationDialog && erroredAutoProduct && (
        <DeclinationDialog
          onClose={handleDeclinationDialogClose}
          open={showDeclinationDialog}
          product={erroredAutoProduct}
        />
      )}
    </>
  );
};
