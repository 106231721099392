import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  showAcknowledgement: false,
  showSignature: true,
  showCarrierMessaging: false,
  showPolicySummarySection: false,
  collectRecurringPay: true,
};

export default metadata;
