import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isDlNumberMasked } from '@ecp/utils/common';

import type { SelectProps } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import {
  getInvalidDlRefs,
  INVALID_DL_DRIVER_REFS,
  useValidateDriverLicenseNumberForOneDriver,
} from '@ecp/features/sales/quotes/auto';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { updateAnswers, useDeltaField } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

interface Props extends QuestionProps {
  driverRef: string;
}

export const DeltaDriverLicenseStateQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    trackingName = 'delta_driver_license_state_question',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
  } = props;
  const dispatch = useDispatch();
  const dlNumber = useDeltaField(driverRef, 'license.number');
  const dlState = useDeltaField(driverRef, 'license.state');

  useAddFields({ [`${dlState.key}`]: dlState });
  const validateDriverLicenseNumber = useValidateDriverLicenseNumberForOneDriver(dlNumber, dlState);
  const invalidDlRefs = useSelector(getInvalidDlRefs);

  const stateActionOnChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    async (value) => {
      // skip if value is same
      if (value === dlState.value) return;
      if (dlNumber.value) {
        // if DL number is masked, clear out DL number and patch it
        if (isDlNumberMasked(dlNumber.value)) {
          dlNumber.props.actionOnComplete(null);
          // else validate DL number and state, and also store driver refs with invalid dl number
        } else if (dlNumber.props.error !== 'Duplicated driver’s license. Please re-enter.') {
          const isDlNumberValid = await validateDriverLicenseNumber();
          if (isDlNumberValid) {
            if (invalidDlRefs.includes(driverRef)) {
              invalidDlRefs.splice(invalidDlRefs.indexOf(driverRef), 1);
              await dispatch(
                updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }),
              );
            }
          } else {
            if (!invalidDlRefs.length) {
              await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: [driverRef] } }));
            } else if (!invalidDlRefs.includes(driverRef)) {
              invalidDlRefs.push(driverRef);
              await dispatch(
                updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }),
              );
            }
          }
        }
      }
      // change state value
      dlState.props.actionOnChange(value);
    },
    [
      dispatch,
      dlNumber.props,
      dlNumber.value,
      dlState.props,
      dlState.value,
      driverRef,
      invalidDlRefs,
      validateDriverLicenseNumber,
    ],
  );

  const stateActionOnComplete: NonNullable<SelectProps['actionOnComplete']> = useCallback(
    async (value) => {
      if (dlNumber.value) {
        if (dlNumber.props.error !== 'Duplicated driver’s license. Please re-enter.') {
          const isDlNumberValid = await validateDriverLicenseNumber();
          if (isDlNumberValid) {
            if (dlState.value) {
              dlNumber.props.actionOnComplete(dlNumber.value);
              dlState.props.actionOnComplete(value);
            }
            if (invalidDlRefs.includes(driverRef)) {
              invalidDlRefs.splice(invalidDlRefs.indexOf(driverRef), 1);
              await dispatch(
                updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }),
              );
            }
          } else {
            if (!invalidDlRefs.length) {
              await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: [driverRef] } }));
            } else if (!invalidDlRefs.includes(driverRef)) {
              invalidDlRefs.push(driverRef);
              await dispatch(
                updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }),
              );
            }
          }
        }
      }
    },
    [
      dispatch,
      dlNumber.props,
      dlNumber.value,
      dlState.props,
      dlState.value,
      driverRef,
      invalidDlRefs,
      validateDriverLicenseNumber,
    ],
  );

  return (
    <Select
      {...(dlState.props as OptionProps)}
      actionOnChange={stateActionOnChange}
      actionOnComplete={stateActionOnComplete}
      label='State Licensed'
      id='deltaLicenseState'
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};
