import { useCallback } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { updatePageStatus } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { AutoLossesAndViolationsPageForm } from '../../forms/AutoLossesAndViolationsPageForm';

export const AutoLossesAndViolationsPage: React.FC = () => {
  const navigateToDiscount = useNavigateToPage(PagePath.AUTO_DISCOUNTS);
  const dispatch = useDispatch();

  const navigateToNext = useCallback(async () => {
    dispatch(updatePageStatus(NavStatus.VALID));
    await navigateToDiscount();
  }, [dispatch, navigateToDiscount]);

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.AUTO_LOSSES_AND_VIOLATIONS} />,
        }}
        title='Review Auto Losses & Violations'
        analyticsElement='choice.lossesAndViolationsDetails.page'
      >
        <AutoLossesAndViolationsPageForm onNext={navigateToNext} />
      </Page>
    </div>
  );
};
