import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FormHelperText, FormLabel, Grid } from '@mui/material';
import dayjs from 'dayjs';
import type { NumberFormatProps } from 'react-number-format';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isDate } from '@ecp/utils/date';
import { useEvent } from '@ecp/utils/react';

import { DatePicker, GridItem, NumberFormat } from '@ecp/components';
import {
  INCIDENT_TYPE_CLAIM_OR_ACCIDENT,
  INCIDENT_TYPE_VIOLATION,
  useAddIncident,
  useAndEnsureCurrentIncidentRef,
  useAttachIncidentToDriver,
  useGetIncidentFields,
} from '@ecp/features/sales/quotes/auto';
import { Button, Select } from '@ecp/features/sales/shared/components';
import {
  getPrimaryInsuredStateCode,
  setFormErrorsChangedByField,
  useFieldWithPrefix,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Incident, OptionProps } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { useStyles } from './IncidentQuestions.styles';
import metadata from './metadata';

export interface IncidentQuestionsProps {
  driverRef: string;
  personRef: string;
  setAddingLossViolation: Dispatch<SetStateAction<boolean>>;
  setLossActionInfoMessage: Dispatch<SetStateAction<string>>;
  incidentTypeValue: string;
  editIncidentRef: string;
  setOpenLossViolationDialog: Dispatch<SetStateAction<string>>;
  setEditIncidentRef: Dispatch<SetStateAction<string>>;
  setLossAndViolationActionInfoType: Dispatch<SetStateAction<'NONE' | 'ADD' | 'REMOVE'>>;
  setIncidentTypeValue: Dispatch<SetStateAction<string>>;
  setIncidentDataBeforeEdit: Dispatch<SetStateAction<Incident | undefined>>;
  onNext: () => void;
  onCancel: (incidentRef: string) => void;
}

export const IncidentQuestions: React.FC<IncidentQuestionsProps> = (props) => {
  const {
    driverRef,
    personRef,
    setAddingLossViolation,
    incidentTypeValue,
    setLossActionInfoMessage,
    editIncidentRef,
    setOpenLossViolationDialog,
    setEditIncidentRef,
    setLossAndViolationActionInfoType,
    setIncidentTypeValue,
    setIncidentDataBeforeEdit,
    onNext,
    onCancel,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const initValues = useRef({});

  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const {
    props: { value: firstName },
  } = usePersonField('firstName');

  const currentIncidentRef = useAndEnsureCurrentIncidentRef(driverRef) || '';
  const incidentFields = useGetIncidentFields(editIncidentRef || currentIncidentRef || '');
  const addIncident = useAddIncident(driverRef);
  const addIncidentToDriver = useAttachIncidentToDriver(driverRef || '');
  const [checkDateError, setCheckDateError] = useState(false);

  const YEAR_INDEX = 0;
  const MONTH_INDEX = 1;
  const DAY_INDEX = 2;
  const MONTH_OFFSET = 1;

  const {
    incident: {
      type: incidentType,
      day: incidentDay,
      year: incidentYear,
      month: incidentMonth,
      date: incidentDate,
      violationDescription,
      claimDescription,
      lossAmountUserEntered,
    },
  } = incidentFields;
  const descriptionField =
    incidentType.value === INCIDENT_TYPE_VIOLATION ? violationDescription : claimDescription;

  const fieldsToValidate = {
    type: incidentType,
    violationDescription,
    claimDescription,
    lossAmountUserEntered,
  };

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const [invalidDateFormatError, setInvalidDateFormatError] = useState('');

  const { validateForm: validateIncidentForm } = useForm({
    fields: fieldsToValidate, // field validation on day field not working, so removed it from for validation
    initValues,
    conditions: [],
  });

  const { patchFormValues: patchIncidentFormValues } = useForm({
    fields: incidentFields.incident,
    initValues,
    conditions: [],
  });

  useEffect(() => {
    if (!incidentType.props.value) {
      incidentType.props.actionOnChange(incidentTypeValue);
    }
    if (editIncidentRef && incidentYear.value) {
      incidentDate.props.actionOnChange(
        `${incidentYear.value as string}-${incidentMonth.value as string}-${
          incidentDay.value as string
        }`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIncidentRef]);

  const onValidateIncidentFields = useCallback(
    (type: Field, incidentDesc: Field, amount: Field) => {
      let isValid = true;
      if (!incidentDesc.value) {
        dispatch(
          setFormErrorsChangedByField({ key: incidentDesc.key, errors: ['Required field'] }),
        );
        isValid = false;
      }
      if (type.value === INCIDENT_TYPE_CLAIM_OR_ACCIDENT && !amount.value && amount.exists) {
        dispatch(setFormErrorsChangedByField({ key: amount.key, errors: ['Required field'] }));
        isValid = false;
      }

      return isValid;
    },
    [dispatch],
  );

  const onValidateIncidentDate = useCallback((day: Field, month: Field, year: Field) => {
    const dayNumber = day.value as number;
    const monthNumber = month.value as number;
    const yearNumber = year.value as number;
    // months are zero indexed, years and days of the month are 1 indexed
    const monthIndex = monthNumber ? monthNumber - 1 : monthNumber;

    const dateIsInFuture = dayjs(new Date(yearNumber, monthIndex, dayNumber)).isAfter(dayjs(), 'D');
    if (dateIsInFuture) {
      return false;
    }

    return true;
  }, []);

  const accidentHelperText = metadata.accidentHelpText[stateCode];
  const incidentHelperText = metadata.helpText(stateCode);

  const handleAddIncident = useEvent(async () => {
    setIncidentTypeValue('');
    if (!incidentDay.props.value) {
      setCheckDateError(true);
    }
    const incidentFormValid = validateIncidentForm().isValid;
    // add any errors in incidentMonth and incidentYearErrors to incidentDate.errors for display
    const incidentDateValid = onValidateIncidentDate(incidentDay, incidentMonth, incidentYear);
    const incidentFieldValid = onValidateIncidentFields(
      incidentType,
      descriptionField,
      lossAmountUserEntered,
    );
    if (incidentFieldValid && incidentFormValid && incidentDateValid) {
      setCheckDateError(false);
      // patch incident only.
      await patchIncidentFormValues();
      // do not add new incident if editing
      if (!editIncidentRef) {
        addIncidentToDriver(currentIncidentRef);
        addIncident();
        incidentDate.reset();
        incidentDate.patch('');
      }
      onNext();
      setLossActionInfoMessage(
        incidentType.value === INCIDENT_TYPE_VIOLATION ? 'Violation saved.' : 'Loss saved.',
      );
      setLossAndViolationActionInfoType('ADD');
      setIncidentDataBeforeEdit(undefined);
    }
  });

  const handleIncidentDateChange = useCallback(
    (date: AnswerValue): void => {
      setInvalidDateFormatError('');
      if (date !== 'Invalid date') {
        const dateValues = (date as string)?.split('-');
        if (dateValues?.length > 1) {
          incidentMonth.props.actionOnChange(dateValues[MONTH_INDEX]);
          incidentYear.props.actionOnChange(dateValues[YEAR_INDEX]);
          incidentDay.props.actionOnChange(dateValues[DAY_INDEX]);
          incidentDate.props.actionOnChange(date?.toString());
        }
      }
    },
    [incidentDate.props, incidentDay.props, incidentMonth.props, incidentYear.props],
  );

  const handleIncidentDateComplete = useCallback(
    (date: AnswerValue): void => {
      if (date) {
        const dateValues = (date as string)?.split('-');
        const isValidDate = isDate(date as string, 'YYYY-MM-DD');

        if (!isValidDate) {
          setInvalidDateFormatError('Invalid format');

          return;
        }
        const dayNumber = Number(dateValues[DAY_INDEX]);
        const monthNumber = Number(dateValues[MONTH_INDEX]);
        const yearNumber = Number(dateValues[YEAR_INDEX]);
        // months are zero indexed, years and days of the month are 1 indexed
        const monthIndex = monthNumber ? monthNumber - MONTH_OFFSET : monthNumber;

        const dateIsInFuture = dayjs(new Date(yearNumber, monthIndex, dayNumber)).isAfter(
          dayjs(),
          'D',
        );
        if (dateIsInFuture) {
          const dateDesc =
            incidentType.value === INCIDENT_TYPE_VIOLATION ? 'Violation Date' : 'Loss Date';
          setInvalidDateFormatError(`${dateDesc} can't be a future date`);
        }
        incidentMonth.props.actionOnChange(dateValues[MONTH_INDEX]);
        incidentYear.props.actionOnChange(dateValues[YEAR_INDEX]);
        incidentDay.props.actionOnChange(dateValues[DAY_INDEX]);
        incidentDate.props.actionOnChange(date?.toString());
      }
    },
    [
      incidentDate.props,
      incidentDay.props,
      incidentMonth.props,
      incidentYear.props,
      incidentType.value,
    ],
  );

  const incidentDateValue = useMemo((): string => {
    const stringDate = `${incidentYear.value as string}-${incidentMonth.value as string}-${
      incidentDay.value as string
    }`;
    if (isDate(stringDate, 'YYYY-MM-DD')) {
      return dayjs(stringDate).format('YYYY-MM-DD');
    }

    return stringDate;
  }, [incidentDay.value, incidentMonth.value, incidentYear.value]);

  const handleCancel = useCallback(() => {
    if (
      incidentYear.props.value ||
      claimDescription.props.value ||
      violationDescription.props.value
    ) {
      setEditIncidentRef(editIncidentRef || currentIncidentRef);
      if (incidentType.props.value) {
        const typeLabel =
          incidentType.question.options?.find(
            (option) => option.value === (incidentType.props.value as string),
          )?.label || '';
        setOpenLossViolationDialog(typeLabel.toString());
        onCancel(editIncidentRef || currentIncidentRef);
      }
    } else {
      setIncidentTypeValue('');
      setAddingLossViolation(false);
      incidentType.validateUpdateAndPatch('');
      onCancel('');
    }
  }, [
    incidentYear.props.value,
    claimDescription.props.value,
    violationDescription.props.value,
    setEditIncidentRef,
    editIncidentRef,
    currentIncidentRef,
    incidentType,
    setOpenLossViolationDialog,
    onCancel,
    setIncidentTypeValue,
    setAddingLossViolation,
  ]);

  const normalizeValue = ({
    value,
    prefix,
    thousandSeparator,
  }: {
    value: string;
    prefix: string;
    thousandSeparator: boolean;
  }): string => {
    let result = value;
    if (prefix) {
      if (value.startsWith(`-${prefix}`)) {
        // if value is -ve it will come as "-$123" we will normalize it to "-123"
        result = '-' + result.substring(prefix.length + 1);
      } else {
        // if value is +ve it will come as "$123" we will normalize it to "123"
        result = result.substring(prefix.length);
      }
    }
    if (thousandSeparator) result = result.replace(/,/g, '');

    return result;
  };

  /**
   * handleOnblur in NumberFormat has issues in normalizeValue function
   * where it is not able to normalize value when we have -ve value with prefix
   */
  const handlerOnBlur: NonNullable<NumberFormatProps['onBlur']> = useEvent((event) => {
    const value = normalizeValue({
      value: event.target.value,
      prefix: '$',
      thousandSeparator: true,
    });
    lossAmountUserEntered.props.actionOnComplete(value);
  });

  const incidentRequiredDateError =
    checkDateError && !incidentYear.props.value ? 'Required field' : undefined;

  const renderIncidents = (): React.ReactElement => (
    <Grid container item xs={12}>
      {incidentDay.exists && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            <DatePicker
              actionOnComplete={handleIncidentDateComplete}
              actionOnChange={handleIncidentDateChange}
              label={
                incidentType.value === INCIDENT_TYPE_VIOLATION ? 'Violation Date' : 'Loss Date'
              }
              value={incidentDateValue}
              maxDate={dayjs().startOf('d')}
              error={incidentRequiredDateError || invalidDateFormatError}
            />
          </GridItem>
        </Grid>
      )}
      {descriptionField.exists && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            <Select
              {...(descriptionField.props as OptionProps)}
              id='IncidentDescription'
              inputButtonAriaLabel='Incident Description'
              placeholder='Select one'
              label={
                incidentType.value === INCIDENT_TYPE_VIOLATION
                  ? 'Violation Description'
                  : 'Loss Description'
              }
              trackingName='incident_description_selection'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
        </Grid>
      )}
      {lossAmountUserEntered.exists && incidentType.value === INCIDENT_TYPE_CLAIM_OR_ACCIDENT && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            <NumberFormat
              {...lossAmountUserEntered.props}
              ariaLabel='Amount'
              placeholder='$0.00'
              prefix='$'
              thousandSeparator
              label='Amount'
              trackingName='amount'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              onBlur={handlerOnBlur}
            />
          </GridItem>
        </Grid>
      )}
      <GridItem xs={12}>
        <div className={classes.actionButtons}>
          <Button
            type='button'
            color='primary'
            variant='iconTextMedium'
            onClick={handleCancel}
            trackingName='add_incident_button'
            trackingLabel='add_incident'
          >
            Cancel
          </Button>
          <Button
            type='button'
            color='primary'
            variant='primary'
            onClick={handleAddIncident}
            trackingName='add_incident_button'
            trackingLabel='add_incident'
          >
            {incidentType.value === INCIDENT_TYPE_VIOLATION ? 'SAVE VIOLATION' : 'SAVE LOSS'}
          </Button>
        </div>
      </GridItem>
    </Grid>
  );

  return (
    <GridItem topSpacing='lg'>
      <FormLabel component='legend' focused={false}>
        {`Tell us about ${firstName}'s ${
          incidentType.value === INCIDENT_TYPE_VIOLATION ? 'violation' : 'loss'
        }`}
        {incidentHelperText && <FormHelperText error={false}>{incidentHelperText}</FormHelperText>}
        {accidentHelperText && <FormHelperText error={false}>{accidentHelperText}</FormHelperText>}
      </FormLabel>
      {renderIncidents()}
    </GridItem>
  );
};
