import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RentersThirdPartyReportsPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  title: theme.typography.h3,
  icon: {
    marginLeft: 'auto',
    marginRight: 10,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
}));
