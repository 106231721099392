import { useCallback, useMemo } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, Select } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getAnswer, useField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Person } from '@ecp/features/sales/shared/types';

import { useStyles } from './SecondaryNamedInsuredForm.styles';

interface Props extends QuestionProps {
  sniQuestionLabel: string;
  secondaryText: string;
  sniFieldRef: string;
  nonPNIPersons: Person[];
}

export const SecondaryNamedInsuredDropdownQuestion: React.FC<Props> = ({
  sniQuestionLabel,
  secondaryText,
  sniFieldRef,
  nonPNIPersons,
}) => {
  const { classes } = useStyles();
  const sniField = useField(sniFieldRef);
  const sniFieldValue = sniField.props.value?.trim();

  // Retrieve the first and last name of the selected person
  const usePersonField = useFieldWithPrefix(sniFieldValue, 'person.<id>');
  const personFirstName = usePersonField('firstName');
  const personLastName = usePersonField('lastName');

  const sniAnswersValue = useSelector((state: RootStore) => getAnswer(state, sniFieldRef));

  // Memoize the person options to avoid unnecessary re-renders
  const personOptions = useMemo(() => {
    const options = nonPNIPersons.map((person) => {
      const formattedName =
        person.firstName && person.lastName ? `${person.firstName} ${person.lastName}` : '';

      return {
        label: formattedName,
        value: person.ref,
      };
    });

    // Add a "Not applicable" option
    options.push({
      label: 'Not applicable',
      value: '',
    });

    return options;
  }, [nonPNIPersons]);

  // Handle the selection of an SNI from the dropdown
  const handleSniSelection = useCallback(
    (value: string | undefined) => {
      sniField.props.actionOnComplete(value || '');
    },
    [sniField.props],
  );

  // Render the value of the dropdown
  const renderValue = useCallback(() => {
    if (!sniAnswersValue || !personFirstName.props.value || !personLastName.props.value) {
      return 'Not applicable';
    }

    return `${personFirstName.props.value} ${personLastName.props.value}`;
  }, [sniAnswersValue, personFirstName.props.value, personLastName.props.value]);

  // SNI question label and secondary text
  const sniQuestion = (
    <GridItem className={classes.sniText}>
      <p className={classes.primaryTextLabel}>{sniQuestionLabel}</p>
      <p className={classes.secondaryText}>{secondaryText}</p>
    </GridItem>
  );

  return (
    <Select
      key={nonPNIPersons.length}
      value={sniField.props.value || ''}
      id='SNI'
      label={sniQuestion}
      actionOnChange={handleSniSelection}
      options={personOptions}
      displayEmpty
      renderValue={renderValue}
      className={classes.select}
      trackingName='sni_selection'
      trackingLabel={GoogleAnalyticsLabels.REDACTED}
      disabled={nonPNIPersons.length < 1}
    />
  );
};
