import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const RoofShapeQuestion: React.FC<QuestionProps> = (props) => {
  const useRoofPropertyField = useFieldWithPrefix('property.roof', 'property.roof');
  const roofShape = useRoofPropertyField('shape');
  const { label = roofShape.question?.title, trackingName = 'roof_shape_selection' } = props;

  useAddFields({ roofShape });

  if (!roofShape.exists) return null;

  return (
    <RadioGroupWithOptions
      {...roofShape.props}
      options={roofShape.props.options}
      label={label}
      trackingName={trackingName}
      id='RoofShape'
      cardSize='small'
    />
  );
};
