import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, NumberFormat } from '@ecp/components';
import { Button, Form, Select, TextField } from '@ecp/features/sales/shared/components';
import {
  getFieldErrors,
  makeSurePrefillFlowInStore,
  useForm,
  useGetAddressFields,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';

import { useStyles } from './AddressCorrectionForm.styles';

interface Props {
  onNext: () => void;
}

interface SubmitParams {
  onNext: () => void;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  stateKey: string;
  validateForm: () => ValidateFormResult;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    stateKey,
    validateForm,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (...[dispatch, getState]) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();

      if (getFieldErrors(getState(), stateKey).length) {
        setIsSubmitting(false);
        goToFirstError();

        return;
      }
      await dispatch(makeSurePrefillFlowInStore());
      onNext();
    } else {
      setIsSubmitting(false);
    }
  };

export const AddressCorrectionForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fields = useGetAddressFields();
  const {
    address: { line1, line2, city, state, zipcode },
    primaryInsuredAddressLock,
  } = fields;

  const initValues = useRef({});
  // Avoid passing locks to the form since it doesn't need validation
  const { primaryInsuredAddressLock: _, ...fieldsWithoutLocks } = fields;
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues,
    fields: fieldsWithoutLocks,
  });

  const handleSubmit = useCallback(async () => {
    dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        stateKey: state.key,
        validateForm,
      }),
    );
  }, [validateForm, patchFormValues, dispatch, state.key, onNext]);

  return (
    <div className={classes.root} id='AddressForm'>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container columnSpacing={4}>
          {line1.exists && (
            <GridItem topSpacing='sm' xs={12} md={6}>
              <TextField
                {...line1.props}
                id='streetAddress'
                label='Street address'
                trackingName='address'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                disabled={primaryInsuredAddressLock.props.value || false}
                inputProps={{ 'data-testid': 'PersonAddressFormStreetTextField' }}
                autoComplete='address-line1'
              />
            </GridItem>
          )}
          {line2.exists && (
            <GridItem topSpacing='sm' xs={12} md={6}>
              <TextField
                {...line2.props}
                id='line2'
                label='Apt./Unit # (optional)'
                trackingName='address_line_2'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                disabled={primaryInsuredAddressLock.props.value || false}
                inputProps={{ 'data-testid': 'PersonAddressFormLine2TextField' }}
                autoComplete='address-line2'
              />
            </GridItem>
          )}
          {city.exists && (
            <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
              <TextField
                {...city.props}
                id='city'
                label='City'
                trackingName='city'
                disabled={primaryInsuredAddressLock.props.value || false}
                inputProps={{ 'data-testid': 'PersonAddressFormCityTextField' }}
                autoComplete='address-level2'
              />
            </GridItem>
          )}
          {state.exists && (
            <GridItem topSpacing='sm' xs={12} md={4}>
              <Select
                {...(state.props as OptionProps)}
                id='state'
                label='State'
                trackingName='state'
                disabled
                inputButtonAriaLabel='Person Address State Field'
                autoComplete='address-level1'
              />
            </GridItem>
          )}
          {zipcode.exists && (
            <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
              <NumberFormat
                {...zipcode.props}
                id='zipCode'
                formatType='zipcode'
                label='ZIP code'
                trackingName='zip_code'
                disabled
                data-testid='PersonAddressFormZipcodeField'
                autoComplete='postal-code'
              />
            </GridItem>
          )}
        </Grid>
        <GridItem topSpacing='sm' xs={12}>
          <Button
            id='submitAddressFormButton'
            variant='primary'
            onClick={handleSubmit}
            isProcessing={isPatchFormInProgress || isSubmitting}
            className={classes.next}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='person_address_continue'
            analyticsElement='choice.addressPage.continueButton'
            data-testid='PersonAddressContinueButton'
          >
            Continue
          </Button>
        </GridItem>
      </Form>
    </div>
  );
};
