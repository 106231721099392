import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'BusinessOnPremisesQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  marginTop: {
    marginTop: 40,
  },
  error: {
    marginBottom: 8,
  },
  snackBar: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  label: {
    ...theme.typography.body4,
    marginBottom: 15,
  },
}));
