import { useCallback, useState } from 'react';

import { Select } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import { PolicyCenterDialog } from '../../components';
import { useStyles } from './SewerSepticSumpCoverage.styles';

export const SewerSepticSumpCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const coveragePrefix = `${props.carrierName}.${props.product}.coverages`;
  const sewerSepticSumpCoverageField = useFieldWithPrefix(coveragePrefix)('waterBackup.sewer');

  useAddFields({ [sewerSepticSumpCoverageField.key]: sewerSepticSumpCoverageField });

  const [dialogStat, setDialogStat] = useState(false);

  const handleOnSelection = useCallback(
    async (value: boolean) => {
      setDialogStat(value);
      sewerSepticSumpCoverageField?.validateUpdateAndPatch(value);
    },
    [sewerSepticSumpCoverageField],
  );

  const toggleDialog = useCallback(() => {
    setDialogStat((prev: boolean) => {
      if (prev) sewerSepticSumpCoverageField?.validateUpdateAndPatch(false);

      return !prev;
    });
  }, [sewerSepticSumpCoverageField]);

  const handleDialogSubmit = useCallback(() => {
    setDialogStat(!dialogStat);
    // TODO: handle redirection to Policy Center Page
  }, [dialogStat]);

  if (!sewerSepticSumpCoverageField.exists) return null;

  return (
    <>
      <Select
        {...sewerSepticSumpCoverageField.props}
        className={classes.coveragesDropdown}
        labelId='sewerSepticSump-label'
        id={sewerSepticSumpCoverageField.props.name}
        displayEmpty
        actionOnComplete={handleOnSelection}
      />
      {dialogStat && (
        <PolicyCenterDialog
          dialogStat={dialogStat}
          toggleDialog={toggleDialog}
          dialogSubmit={handleDialogSubmit}
        />
      )}
    </>
  );
};
