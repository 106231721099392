import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DeltaVehicleCard' })((theme) => ({
  root: {},
  vehicleSection: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
  vehicleCardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  headerLeft: {
    verticalAlign: 'middle',
    height: 46,
    width: 46,
  },
  headerRight: { ...theme.typography.h3, paddingTop: 20 },
  icon: {
    width: 41.15,
    height: 'auto',
    marginTop: 10,
  },
}));
