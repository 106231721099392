import { useCallback } from 'react';

import {
  createRef,
  getAnswers,
  getMappedKeyDynamic,
  getPropertiesOfTemplateWithRef,
  removeAndDeleteAllRefs,
  removeAndDeleteRef,
  setProperty,
  updateAddedRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AnswerValue,
  Nested,
  PropertyList,
  SecondaryHeatingSource,
  Template,
} from '@ecp/features/sales/shared/types';

import { SECONDARY_HEATING_SOURCE_REF } from '../../constants';

export interface AddHeatingSourceResult {
  // the api has received the update
  done: Promise<[string]>;
  // the ref used
  heatingSourceRef: string;
}

export const useCreateSecondaryHeatingSource = (): (() => AddHeatingSourceResult) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    const heatingSourceRef = dispatch(createRef('secondaryHeatingSource'));
    const updateHeatingSourceWork = dispatch(
      updateAddedRef({ type: SECONDARY_HEATING_SOURCE_REF, newRef: heatingSourceRef }),
    );
    const done = Promise.all([updateHeatingSourceWork]).then((): [string] => [heatingSourceRef]);

    return {
      done,
      heatingSourceRef,
    };
  }, [dispatch]);
};

export const useRemoveSecondaryHeatingSource = (): ((ref: string) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (ref: string) => {
      await dispatch(removeAndDeleteRef({ refType: SECONDARY_HEATING_SOURCE_REF, ref }));
    },
    [dispatch],
  );
};

export const useRemoveAllSecondaryHeatingSources = (): (() => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await dispatch(
      removeAndDeleteAllRefs({
        refType: SECONDARY_HEATING_SOURCE_REF,
        refPrefix: 'secondaryHeatingSource',
      }),
    );
  }, [dispatch]);
};

const heatingSourceTemplate: Template<SecondaryHeatingSource> = {
  ref: '',
  fuelType: '',
  fuelTypeDescription: '',
  systemType: '',
};
const heatingSourceMapping: Record<string, string> = {};

const getSecondaryHeatingSourceProperties = (
  ref: string,
  ...prefix: string[]
): PropertyList<SecondaryHeatingSource> =>
  getPropertiesOfTemplateWithRef(heatingSourceTemplate, [ref, ...prefix]);

const getSecondaryHeatingSource = (state: RootStore, ref: string): SecondaryHeatingSource => {
  const answers = getAnswers(state);
  const properties = getSecondaryHeatingSourceProperties(ref);

  return properties.reduce(
    (result, property) => {
      const key = getMappedKeyDynamic(property, { [ref]: heatingSourceMapping });
      const value = answers[key];
      setProperty(result as unknown as Nested<AnswerValue>, property, value);

      return result;
    },
    { ref } as SecondaryHeatingSource,
  );
};

const getSecondaryHeatingSourceValues = (
  state: RootStore,
  refs: string[],
): Record<string, SecondaryHeatingSource> => {
  return refs.reduce((result, ref) => {
    result[ref] = getSecondaryHeatingSource(state, ref);

    return result;
  }, {} as Record<string, SecondaryHeatingSource>);
};

const useSecondaryHeatingSourceValues = (
  refs: string[],
): Record<string, SecondaryHeatingSource> => {
  return useSelector((state: RootStore) => getSecondaryHeatingSourceValues(state, refs));
};

export const useSecondaryHeatingSources = (refs: string[]): SecondaryHeatingSource[] => {
  return Object.values(useSecondaryHeatingSourceValues(refs));
};
