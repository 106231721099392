import { useCallback } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import {
  getNoOfMortgages,
  getPrimaryInsuredAddressInfo,
  navigateToAddTPI,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconCardHome, IconUIPlus } from '@ecp/themes/base';

import { useStyles } from './PropertyTPIPageForm.styles';

export const PropertyTPIPageForm: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const addressInformation = useSelector(getPrimaryInsuredAddressInfo);
  const noOfMortgages = useSelector(getNoOfMortgages);

  // TODO: pass in tpi id for property
  const handleAddTPI = useCallback(async () => {
    await dispatch(navigateToAddTPI({ ref: '', replace: false, referencePage: 'AddHomeTPI' }));
  }, [dispatch]);

  return (
    <GridItem>
      <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
        <Box>
          <div className={classes.productIcon}>
            <IconCardHome />
          </div>
        </Box>
        <Typography className={classes.productTitle}>Home Policy?</Typography>
      </GridItem>
      <Grid container className={classes.card}>
        <GridItem xs={12} md={6}>
          <Typography className={classes.contentTitle}>
            {addressInformation.line1 && (
              <>
                <div>
                  {addressInformation.line1}
                  {addressInformation.line2 ? ` ${addressInformation.line2},` : ','}{' '}
                </div>
                <div>
                  {`${addressInformation.city}, `}
                  {addressInformation.state}
                  {` ${addressInformation.zipcode}`}
                </div>
              </>
            )}
          </Typography>
          {noOfMortgages > 0 && (
            <Typography className={classes.contentSubTitle}>
              This home has {noOfMortgages} mortgages. All mortgagees must be added as a TPI.
            </Typography>
          )}
        </GridItem>
        <Grid item xs={12} md={6} className={classes.columnRight}>
          <Button
            className={classes.button}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => handleAddTPI()}
            variant='outlinePrimary'
            trackingName='sni_details_continue'
            trackingLabel={GoogleAnalyticsLabels.CONTINUE}
          >
            <IconUIPlus className={classes.plusIcon} />
            ADD TPI
          </Button>
        </Grid>
      </Grid>
    </GridItem>
  );
};
