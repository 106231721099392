import { useCallback } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { AUTO_POLICY_HOUSEHOLD_LOSSES, NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { getHasLossesInThePastFiveYears, updatePageStatus } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { AutoPriorCarrierPageForm } from '../../forms/AutoPriorCarrierPageForm';

export const AutoPriorCarrierPage: React.FC = () => {
  // If Prior Losses in the past is answered yes route to losses and violations page.
  const priorLossesAndViolations = useSelector((state: RootStore) =>
    getHasLossesInThePastFiveYears(state, AUTO_POLICY_HOUSEHOLD_LOSSES),
  );

  const dispatch = useDispatch();
  const nextPage = priorLossesAndViolations
    ? PagePath.AUTO_LOSSES_AND_VIOLATIONS
    : PagePath.AUTO_DISCOUNTS;
  const navigateToNextPage = useNavigateToPage(nextPage);
  const navigateToNext = useCallback(async () => {
    if (!priorLossesAndViolations) {
      dispatch(updatePageStatus(NavStatus.VALID, PagePath.AUTO_LOSSES_AND_VIOLATIONS));
    }
    await navigateToNextPage();
  }, [dispatch, navigateToNextPage, priorLossesAndViolations]);

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.AUTO_PRIOR_INSURANCE} />,
        }}
        title='Prior Insurance'
        analyticsElement='choice.priorCarrierDetails.page'
      >
        <AutoPriorCarrierPageForm onNext={navigateToNext} />
      </Page>
    </div>
  );
};
