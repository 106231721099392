import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ProtectiveDeviceFormQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  heading: {
    ...theme.typography.subtitle2,
  },
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  helperText: {
    '& .MuiFormHelperText-root': {
      fontStyle: 'normal',
      fontSize: 12,
      fontWeight: 400,
      marginTop: 3,
      lineHeight: '20px',
    },
  },
}));
