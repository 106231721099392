import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Garages' })((theme) => ({
  garageForm: {
    border: `1px solid ${theme.palette.other.border}`,
    marginBottom: 20,
    marginTop: 20,
    padding: 20,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
  },
}));
