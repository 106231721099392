import { memo } from 'react';

import type { CoverageGroupProps } from '../../types';
import { PolicySummaryCoveragesCardItem } from './PolicySummaryCoveragesCardItem';

export interface Props {
  coverageGroups: CoverageGroupProps[];
}

export const PolicySummaryCoveragesCard: React.FC<Props> = memo(({ coverageGroups }) => {
  return (
    <div>
      {coverageGroups.map(({ title, items }) => (
        <PolicySummaryCoveragesCardItem key={title} title={title} items={items} />
      ))}
    </div>
  );
});
