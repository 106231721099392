import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './PostBindPolicyCard.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        successIconContainer: css({
          backgroundColor: `${theme.palette.primary.main}40`,
        }),
        successIcon: css({
          ...theme.mixins.setColorSvg(theme.palette.primary.main),
        }),
        informationCard: css({
          backgroundColor: theme.palette.other.body,
          border: `1px solid ${theme.palette.other.border}`,
          borderRadius: '4px',
        }),
        leftWrapper: css({
          backgroundColor: theme.palette.other.body,
        }),
        policyCenterButton: css({
          marginLeft: 15,
          marginTop: 30,
          gap: 10,
          display: 'flex',
          justifyContent: 'left',
        }),
        divider: css({
          marginTop: 10,
          paddingBottom: 5,
          borderBottom: `1px solid ${theme.palette.other.divider}`,
        }),
        headerContainer: css({
          marginBottom: 10,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
