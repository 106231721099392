import { useEffect, useState } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';

import { INITIAL_OFFERS_REQUESTED, OfferType } from '@ecp/features/sales/shared/constants';
import {
  createOffers,
  getCreateOffersInProgress,
  getDalSessionId,
  getInquiryId,
  getOfferSetId,
  getOffersForSelectedLob,
  getRecalcProcessingValue,
  getRecalled,
  getUpdateOffersInProgress,
  setShouldRecalc,
  updateAnswers,
  updateOffers,
  updateOfferType,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

export const useQuotesPage = (): {
  doOffersForSelectedLobExist: boolean;
  doOffersForSelectedLobIncludeBundle: boolean;
  inquiryId: string | undefined;
  dalSessionId: string | undefined;
  loading: boolean;
  offerSetId: string | undefined;
  xRequestId: string;
} => {
  const dispatch = useDispatch();

  useEffect(() => {
    // A/B test metric tracking function for quote summary page view
    trackEvent(Events.QUOTE_SUMMARY_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inquiryId = useSelector(getInquiryId);
  const dalSessionId = useSelector(getDalSessionId);
  const offerSetId = useSelector(getOfferSetId);
  const isCreateOffersInProgress = useSelector(getCreateOffersInProgress);
  const isUpdateOffersInProgress = useSelector(getUpdateOffersInProgress);
  const recalled = useSelector(getRecalled);
  const isRecalcProcessing = useSelector(getRecalcProcessingValue);
  // Show full page loader for offers loading and hide for offers recalc as we show inline spinner
  const offersLoading =
    !offerSetId || isCreateOffersInProgress || (isUpdateOffersInProgress && !isRecalcProcessing);
  // TODO We need this local loading state due to user selection reset on useEffect. This will be removed soon after that logic is removed part of SAPIV4 migration
  const [isLoading, setIsLoading] = useState(true);
  // TODO This needs to be uniformly handled in the Error-specific component
  const [xRequestId, setXRequestId] = useState('');
  // TODO This needs to be encapsulated in useOffers hook
  useEffect(() => {
    const handleOffers = async (): Promise<void> => {
      if (!recalled) {
        // already has updated offers
        if (offerSetId || isCreateOffersInProgress) {
          // ADVECP-4010
          // Set offer type on answers so that SAPI knows when to rate vs when not to rate.
          // When OFFER_WITHOUT_PREMIUM SAPI would just return coverages but not premiums
          // Premiums are returned only when OFFER_WITH_PREMIUM
          await dispatch(updateOfferType({ offerType: OfferType.OFFER_WITHOUT_PREMIUM }));
          await dispatch(updateOffers({ clearUserSelection: true })).then((result) => {
            const requestId = result?.response;
            if (typeof requestId === 'string') setXRequestId(requestId);
          });
          dispatch(setShouldRecalc(true));
        } else if (dalSessionId) {
          // ADVECP-4010
          // Set offer type on answers so that SAPI knows when to rate vs when not to rate.
          // When OFFER_WITHOUT_PREMIUM SAPI would just return coverages but not premiums
          // Premiums are returned only when OFFER_WITH_PREMIUM
          await dispatch(updateOfferType({ offerType: OfferType.OFFER_WITHOUT_PREMIUM }));

          // If the offers are being created for first time, toggle the policy start date modal automatically
          await Promise.all([
            dispatch(
              updateAnswers({
                answers: {
                  [INITIAL_OFFERS_REQUESTED]: true,
                },
              }),
            ),
            dispatch(createOffers()).then((result) => {
              const requestId = result?.response;
              if (typeof requestId === 'string') setXRequestId(requestId);
            }),
          ]);
          dispatch(setShouldRecalc(true));
        }
      }
    };
    handleOffers().finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const doOffersForSelectedLobExist = Boolean(offersForSelectedLob);
  const doOffersForSelectedLobIncludeBundle = Boolean(offersForSelectedLob?.bundle);

  return {
    doOffersForSelectedLobExist,
    doOffersForSelectedLobIncludeBundle,
    inquiryId,
    dalSessionId,
    loading: isLoading || offersLoading,
    offerSetId,
    xRequestId,
  };
};
