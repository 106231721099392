import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LandingPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  divider: {
    margin: '60px 0',
  },
  recallButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 30,
    },
  },
  subtitle: theme.typography.body4,
  inquiryRoot: {
    position: 'relative',
    height: 630,
  },
  spinnerContainer: {
    width: 40,
    margin: 'auto',
    marginTop: 10,
  },
  alert: {
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  alertIcon: {
    ...theme.mixins.setColorSvg(theme.palette.error.dark),
  },
  hide: {
    display: 'none',
  },
  redirectSectionSubtitle: { ...theme.typography.body4, margin: '16px 0 0 0' },
  redirectSectionSubText: { ...theme.typography.body2, margin: '0 0 24px 0' },
  verticalDivider: {
    backgroundColor: theme.palette.other.divider,
    width: 1,
    margin: '0 40px 0 40px',
    height: 300,
  },
}));
