import { useEffect, useMemo, useRef } from 'react';

import { trackDefault } from '@ecp/utils/analytics/tracking';
import { emptyArray, isEqual } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { getCurrentPage, getOptionLabelByType } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
// TODO: Avoid using this meta when refactoring UX
import type {
  AnswerValue,
  CoverageItem,
  CoveragesFields,
  OptionData,
} from '@ecp/features/sales/shared/types';
import type { Field, Option, OptionMetadata, OptionsMetadata, SubCoverages } from '@ecp/types';

export const hasCoverageItemError = (item: CoverageItem): boolean =>
  Boolean(item.field?.errors.length);

export const useIsCustomizeCoveragesEnabled = (): boolean => {
  const currentPage = useSelector(getCurrentPage);

  const isEnabled = useMemo(() => {
    return currentPage === PagePath.COVERAGES;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isEnabled;
};

// Convert 100,000/300,000 to $100,000/$300,000
const getLabelByCurrency = (label: string): string => {
  return label
    .split('/')
    .map((e) => {
      const numericString = parseFloat(e.replace(/,/g, '')).toString();

      return /^-?\d+$/.test(numericString) ? getOptionLabelByType(e, 'CURRENCY') : e;
    })
    .join('/');
};

const getValue = (key: string, options?: Option[]): React.ReactElement | string | undefined =>
  options ? options?.find((e) => e.value === key)?.label : getOptionLabelByType(key, 'CURRENCY');

export const getDisplayValue = (label: React.ReactElement | string | undefined): string => {
  const labelStr = label?.toString() || 'Unavailable';
  const labelStrLowerCase = label?.toString().toLowerCase();
  switch (labelStrLowerCase) {
    case 'true':
      return 'Included';
    case 'no coverage':
    case 'not included':
      return env.static.isAgent ? labelStr : '+ Add Coverage';
    default:
      return getLabelByCurrency(labelStr);
  }
};
const buildCardData = (
  metaData: OptionMetadata,
  type: string,
  field: Field,
  options?: Option[],
  stateCode = '',
): OptionData => {
  const label = getValue(type, options);
  const stateOption = metaData.stateOptions?.[stateCode];

  if (stateOption) {
    const { subCoverages, ...metaDataWithoutSubCoverages } = metaData;
    /* eslint-disable no-param-reassign */
    metaData = { ...metaDataWithoutSubCoverages, ...stateOption };
    const stateSubCoverages = stateOption.subCoverages;
    if (stateSubCoverages) {
      metaData.subCoverages = { ...subCoverages, ...stateSubCoverages }; // deep merge
    } else if (subCoverages) {
      metaData.subCoverages = subCoverages; // copy it back
    }
    // Clean up the state options since we no longer need those
    delete metaData.stateOptions;
  }

  return {
    ...metaData,
    type,
    displayValue: getDisplayValue(label),
    isCovered:
      !!label &&
      !['not included', 'no coverage', 'not available', 'rejected', '+ Add Coverage'].includes(
        label.toString().toLowerCase(),
      ),
    editable: (options && options.length > 1) || !options,
    field,
  };
};

const buildSubCoverage = (
  coverageFields: CoveragesFields,
  metadataSubCoverages?: SubCoverages,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): SubCoverages | undefined => {
  const subCoverages: SubCoverages = {};

  if (metadataSubCoverages) {
    Object.keys(metadataSubCoverages).forEach((subCoverageKey) => {
      const field = coverageFields[subCoverageKey];
      if (field?.exists) {
        if (handleCoverageItemChange)
          field.props.actionOnComplete = handleCoverageItemChange(field);
        subCoverages[subCoverageKey] = {
          title: metadataSubCoverages[subCoverageKey].title,
          primaryText: metadataSubCoverages[subCoverageKey].primaryText,
          isPipSubCoverage: metadataSubCoverages[subCoverageKey].isPipSubCoverage,
          isExcludeDriver: metadataSubCoverages[subCoverageKey].isExcludeDriver,
          field,
          displayInfo: metadataSubCoverages[subCoverageKey].displayInfo,
        };
        field.noScrollError = !!metadataSubCoverages[subCoverageKey].isPipSubCoverage;
      }
    });
  }

  return isEqual(subCoverages, {}) ? undefined : subCoverages;
};

export const buildCoverageItems = (
  optionsMetadata: OptionsMetadata | undefined,
  coveragesFields: CoveragesFields,
  stateCode?: string,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): CoverageItem[] => {
  if (!optionsMetadata) return emptyArray as unknown as CoverageItem[];
  const items = Object.keys(optionsMetadata).reduce((acc, coverageKey) => {
    const field = coveragesFields[coverageKey];
    if (field?.exists) {
      if (handleCoverageItemChange) field.props.actionOnComplete = handleCoverageItemChange(field);

      const data = buildCardData(
        optionsMetadata[coverageKey],
        field.props.value,
        field,
        field.props.options,
        stateCode,
      );
      // !TODO Consider removing refKey logic everywhere, since we have refFieldKey logic to
      // link mutually dependent fields for validation purposes, whereas it should reside in SAPI questions validation criteria
      const refKey = optionsMetadata[coverageKey].refFieldKey;
      if (refKey) {
        data.refField = coveragesFields[refKey];
      }
      const metadataSubCoverages = data?.subCoverages;
      data.subCoverages = buildSubCoverage(
        coveragesFields,
        metadataSubCoverages,
        handleCoverageItemChange,
      );

      acc.push(data);
    }

    return acc;
  }, [] as CoverageItem[]);

  return items;
};

export const useCoveragesAnalytics = (coverages: CoverageItem[], action: string): void => {
  const prevPolicyCoverageFields = useRef({});
  useEffect(() => {
    if (!coverages) return;
    const trackPolicyCoverages: { [label: string]: string } = {};

    coverages.forEach(({ group, field }) => {
      if (group && field?.value) trackPolicyCoverages[group] = String(field.value);
    });

    if (!isEqual(prevPolicyCoverageFields.current, trackPolicyCoverages)) {
      prevPolicyCoverageFields.current = trackPolicyCoverages;
      trackDefault({
        action: action,
        label: JSON.stringify(trackPolicyCoverages),
      });
    }
  }, [action, coverages]);
};
