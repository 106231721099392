import { RadioGroupWithOptions } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useDeltaField } from '@ecp/features/sales/shared/store';
import type { Product } from '@ecp/features/shared/product';

import { useStyles } from './DeltaAFTAuthorizationQuestion.styles';
interface Props extends QuestionProps {
  product: Product;
}

export const DeltaAFTAuthorizationQuestion: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { product, trackingName = 'delta_aft_authorization_question' } = props;
  const autoPayType = useDeltaField(`coverageOption.${product}`, 'autoPayType');
  const autoPayAFTAuthorization = useDeltaField(`coverageOption.${product}`, 'aftAuthorization');

  useAddFields({ [`${autoPayAFTAuthorization.key}`]: autoPayAFTAuthorization });
  useInitValues({ [autoPayAFTAuthorization.key]: 'true' });

  const AFTOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  if (!autoPayAFTAuthorization.exists || autoPayType.value !== 'AFT') return null;

  return (
    <>
      <RadioGroupWithOptions
        {...autoPayAFTAuthorization.props}
        label='Is the person signing the policy documents also the financial institution account holder?'
        options={AFTOptions}
        trackingName={trackingName}
      />
      {autoPayAFTAuthorization.value === 'false' && (
        <p className={classes.aftDisclaimerText}>
          AFT Authorization form will not be included in policy packet. Complete the AFT
          Authorization form separately.
        </p>
      )}
    </>
  );
};
