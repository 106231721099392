import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import type { TextFieldProps } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { useValidateVin, validateVinFormat } from '@ecp/features/sales/quotes/auto';
import { TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
  useDeltaField,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Vehicle } from '@ecp/features/sales/shared/types';

export interface DeltaVehicleVinQuestionProps extends QuestionProps {
  vehicle: Vehicle;
}

export const DeltaVehicleVinQuestion: React.FC<DeltaVehicleVinQuestionProps> = (props) => {
  const {
    vehicle,
    trackingName = 'delta_vehicle_vin_question',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
  } = props;
  const dispatch = useDispatch();
  const validateVinNum = useValidateVin();

  const vin = useDeltaField(vehicle.ref, 'vin');
  useAddFields({ [`${vin.key}`]: vin });

  const vinActionOnChange: NonNullable<TextFieldProps['actionOnChange']> = useCallback(
    (value) => {
      if (isMasked(vin.value)) {
        vin.props.actionOnChange(null);
        // asterisk is not allowed to be typed/pasted in by user here
      } else if (!isMasked(value)) {
        vin.props.actionOnChange(value);
      }
    },
    [vin.props, vin.value],
  );

  const vinActionOnComplete: NonNullable<TextFieldProps['actionOnComplete']> = useCallback(
    async (value) => {
      let err = '';
      if (!isMasked(value)) {
        const isRequired = true;
        const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];
        err = !disableVehicleServiceCallFlag
          ? (await validateVinNum(value, vehicle)).vinErr
          : validateVinFormat(value as string, isRequired) || '';
        if (err) {
          dispatch(
            setFormErrorsChangedByField({
              key: vin.key,
              errors: [err],
            }),
          );
        } else {
          dispatch(setFormErrorsResetByField({ key: vin.key }));
        }
        if (err !== '') return err;
        // we will not be patching the vin here in any flow
        // vin will be patched on submit on auto delta page (if it is changed)
      }

      return '';
    },
    [dispatch, validateVinNum, vehicle, vin.key],
  );

  return (
    <TextField
      {...vin.props}
      actionOnChange={vinActionOnChange}
      actionOnComplete={vinActionOnComplete}
      label='VIN'
      fullWidth={false}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};
