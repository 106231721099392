import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { VehicleInfoOrVinQuestion } from './VehicleInfoOrVinQuestion';
import type { VehicleInfoProps } from './VehicleInfoOrVinQuestion/types';
import { VehicleYearVinMakeModelQuestion } from './VehicleYearVinMakeModelQuestion';

export const VehicleInfoQuestions: React.FC<VehicleInfoProps> = (props) => {
  // TODO: Remove PLPC doesn't need this flag
  const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];

  return disableVehicleServiceCallFlag ? (
    <VehicleYearVinMakeModelQuestion {...props} />
  ) : (
    <VehicleInfoOrVinQuestion {...props} />
  );
};
