import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const RenovationYearQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('renovation.renovationYear', true);

  if (!field) return null;

  const { trackingName = 'renovation_year_question', label = field?.question?.title } = props;

  return (
    <NumberFormat
      {...field.props}
      id='RenovationYear'
      label={<Typography variant='body4'>{label}</Typography>}
      format='####'
      placeholder='YYYY'
      trackingName={trackingName}
      fullWidth={false}
    />
  );
};
