import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const RoofClassDocumentQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('roof.roofClassDocumentProvidedInd', true);

  const { label = field?.question?.title, trackingLabel = 'roof_class_document_button' } = props;

  if (!field) return null;

  return (
    <RadioGroupWithOptions
      {...field.props}
      id='roofClassDocument'
      label={label}
      variant='yesNoButton'
      trackingName={trackingLabel}
    />
  );
};
