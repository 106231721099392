import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CustomCheckbox' })((theme) => ({
  root: {
    marginBottom: 18,
  },
  zipCode: {
    marginTop: 10,
  },
  classicCheckbox: {
    pointerEvents: 'auto',
  },
  discountCard: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      background: theme.palette.grey['50'],
    },
    alignItems: 'center',
    paddingLeft: 7,
  },
  discountIcon: {
    marginLeft: 'auto',
    marginRight: 10,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
  discountLabel: {
    margin: 10,
  },
  checked: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      background: theme.palette.grey['50'],
    },
    alignItems: 'center',
    paddingLeft: 7,
  },
  labelWithTooltip: {
    ...theme.typography.body4,
  },
  toolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
  },
}));
