import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GridItem, RadioGroupWithOptions } from '@ecp/components';
import {
  DRIVER_INTL,
  DRIVER_UNLICENSED,
  DRIVER_VALID,
  DriverLicenseAgeFirstLicensedQuestion,
  useIsSniValue,
  useUpdateSecondaryPolicyHolderRef,
} from '@ecp/features/sales/quotes/auto';
import {
  DateOfBirthQuestion,
  FirstNameQuestion,
  GenderQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import {
  DriverFinancialResponsibilityQuestion,
  DriverLicenseQuestion,
  DriverLicenseRelationToInsuredQuestion,
  DriverLicenseStateQuestion,
  DriverLicenseStatusQuestion,
  DriverRoleQuestion,
} from '@ecp/sales/lob/auto';
import type { AnswerValue } from '@ecp/types';

import { MaritalStatusQuestion, RelationshipQuestion } from '../../../../../common';
import { useStyles } from './DriverFormQuestions.styles';

export interface DriverFormQuestionsProps {
  isPni: boolean;
  driverRef: string;
  personRef: string;
}

export const DriverFormQuestions: React.FC<DriverFormQuestionsProps> = (props) => {
  const { isPni, driverRef, personRef } = props;
  const { classes } = useStyles();
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const firstName = usePersonField('firstName');
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const dlStatus = useDriverField('license.status');
  const middleName = usePersonField('middleName');
  const suffix = usePersonField('suffix');
  const dlNumberEntered = useDriverField('license.number').value;
  const {
    props: { value: name = '' },
  } = firstName;

  const nameOrYou = name ? `Does ${name}` : 'Do you';
  const wasNameOrWereYou = name ? `was ${name}` : 'were you';
  const possessiveName = name ? `${name}'s` : 'your';
  const ifNameOrYou = name ? `If ${name}` : 'If you';

  // SNI functionality added to be able to test EDSP-11981, likely will ne changed when EDSP-11979 is worked on.
  const isSniValue = useIsSniValue(personRef);
  const updateSNI = useUpdateSecondaryPolicyHolderRef();
  const handleUpdateSecondaryPolicyHolder = useCallback(
    async (value: AnswerValue) => {
      if (value) await updateSNI(driverRef);
      else {
        updateSNI('');
      }
    },
    [driverRef, updateSNI],
  );

  return (
    <Grid container>
      <GridItem xs={12} md={6} className={classes.columnLeft}>
        <FirstNameQuestion personRef={personRef} disabled={isPni} />
      </GridItem>
      {middleName.exists && (
        <GridItem xs={12} md={6} className={classes.columnRight}>
          <MiddleNameQuestion personRef={personRef} disabled={isPni} />
        </GridItem>
      )}
      <GridItem
        topSpacing='sm'
        xs={12}
        md={6}
        className={middleName.exists ? classes.columnLeft : classes.columnRight}
      >
        <LastNameQuestion personRef={personRef} disabled={isPni} />
      </GridItem>
      {suffix.exists && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <SuffixQuestion personRef={personRef} disabled={isPni} />
        </GridItem>
      )}
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <DateOfBirthQuestion id='DOB' personRef={personRef} disabled={isPni} staticValueWithRef />
      </GridItem>
      {dlStatus.value !== DRIVER_UNLICENSED && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <DriverLicenseQuestion driverRef={driverRef} />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        {/* TODO add help text when clicking on the help icon */}
        <GenderQuestion personRef={personRef} label={`What's ${possessiveName} gender?`} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <MaritalStatusQuestion
          personRef={personRef}
          label={`What's ${possessiveName} marital status?`}
          readOnly={isPni}
        />
      </GridItem>
      {!isPni && (
        <GridItem topSpacing='lg' xs={12}>
          <RelationshipQuestion personRef={personRef} groupLabel='Relationship to PNI' />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        <DriverLicenseStatusQuestion
          driverRef={driverRef}
          possessiveName={possessiveName}
          ifNameOrYou={ifNameOrYou}
        />
      </GridItem>
      {dlNumberEntered &&
        dlStatus.value !== DRIVER_UNLICENSED &&
        dlStatus.value !== DRIVER_INTL && (
          <GridItem topSpacing='lg' xs={12}>
            <DriverLicenseStateQuestion
              driverRef={driverRef}
              groupLabel={`What's ${possessiveName} driver license state?`}
            />
          </GridItem>
        )}
      {(dlStatus.value === DRIVER_VALID || dlStatus.value === DRIVER_INTL) && (
        <GridItem topSpacing='lg' xs={12}>
          <DriverLicenseAgeFirstLicensedQuestion
            personRef={personRef}
            driverRef={driverRef}
            groupLabel={`At what age ${wasNameOrWereYou} first licensed in the United States?`}
          />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        <DriverLicenseRelationToInsuredQuestion driverRef={driverRef} personRef={personRef} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <DriverRoleQuestion
          driverRef={driverRef}
          isPni={isPni}
          groupLabel={`What type of driver is ${name}?`}
        />
      </GridItem>
      {
        // TODO: Update condition with EDSP-11979
        !isPni /* && allowAddSNI */ && (
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              id='secondaryDriver'
              value={isSniValue}
              label={
                <>
                  {`Will ${name} be a secondary policy holder? `}
                  <span>(optional)</span>
                </>
              }
              variant='yesNoButton'
              data-testid='isSni'
              actionOnComplete={handleUpdateSecondaryPolicyHolder}
              trackingName='SecondaryPolicyHolderButton'
            />
          </GridItem>
        )
      }
      <GridItem topSpacing='lg' xs={12}>
        <DriverFinancialResponsibilityQuestion driverRef={driverRef} nameOrYou={nameOrYou} />
      </GridItem>
    </Grid>
  );
};
