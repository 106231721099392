import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoCoveragesForm' })((theme) => ({
  addSpacing: theme.mixins.addSpacing,
  productIcon: {
    marginRight: 18,
    width: 37,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
  productHeading: {
    marginTop: 40,
    alignItems: 'center',
  },
  isgScoreContainer: {
    marginTop: 20,
  },
  isgScore: {
    ...theme.typography.body4,
  },
  policyToolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
  },
}));
