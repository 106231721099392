import { useEffect } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { HomeEligibilityPageForm } from '../../forms';

export const HomeEligibilityPage: React.FC = () => {
  useEffect(() => {
    trackSapiAnalyticsEvent({
      element: 'choice.homeEligibilityPage.page',
      event: 'render',
      eventDetail: 'true',
    });
  }, []);

  const handleNext = useNavigateToNextPage();

  return (
    <div>
      <Page
        title='Eligibility'
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.HOME_ELIGIBILITY} />,
        }}
        mainWithoutSidebar
      >
        <HomeEligibilityPageForm onNext={handleNext} />
      </Page>
    </div>
  );
};
