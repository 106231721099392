import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';
import { useStyles } from './LivingAreaQuestion.styles';

export const LivingAreaQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const livingArea = usePropertyField('livingArea');

  if (!livingArea) return null;

  const { trackingName = 'square_footage', label = livingArea.question?.title } = props;

  return (
    <NumberFormat
      {...livingArea.props}
      label={<Typography variant='body4'>{label}</Typography>}
      format='####'
      id='LivingArea'
      trackingName={trackingName}
      classes={{ input: classes.numberInput }}
    />
  );
};
