import { useCallback, useState } from 'react';

import { Button } from '@ecp/features/sales/shared/components';
import { setShouldRecalc, updateAnswers } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';
import type { SubDiscountItem } from '@ecp/types';

import { useStyles } from './DiscountCard.styles';
import type { DiscountMetadata } from './DiscountCard.types';
import type { DiscountCardModalProps } from './DiscountCardModal';
import { DiscountCardModal } from './DiscountCardModal';

export interface Props {
  discounts: DiscountMetadata[];
  autoProductKey: DiscountCardModalProps['product'];
  objRef: string;
  title: string;
  image: React.ReactElement;
}

export const DiscountCard: React.FC<Props> = (props) => {
  const { discounts, autoProductKey, objRef, title, image } = props;
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const objName = objRef.split('.')[0];
  const trackingName = `review_${objName}_discounts_link`;

  const availableDiscounts = discounts.filter((discount) => {
    return discount.availableObjects.find((obj) => obj.ref === objRef);
  });

  const appliedDiscounts = availableDiscounts.filter((discount) => {
    return discount.availableObjects
      .filter((obj) => obj.ref === objRef)
      .find(
        (obj) =>
          !!obj.question.value && obj.question.value !== 'false' && obj.question.value !== 'None',
      );
  });

  const allSubDiscount = availableDiscounts.reduce((acc, curr) => {
    if (curr.subDiscount) {
      const formatted = Object.entries(curr.subDiscount).map(([key, subDiscount]) => ({
        key,
        ...subDiscount,
      }));
      acc.push(...formatted);
    }

    return acc;
  }, [] as ({ key: string } & SubDiscountItem)[]);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal: DiscountCardModalProps['onClose'] = useCallback(
    async (selected: AnswerValue[], radioObj) => {
      const changes: Answers = [...availableDiscounts, ...allSubDiscount].reduce(
        // @ts-ignore FIXME ASAP
        (output, { key = '', availableObjects, fieldType }) => {
          const questionKey = `${autoProductKey}.discount.${objRef}.${key}`;
          const radioValue = radioObj[key] || 'None';
          const value = fieldType === 'radiogroup' ? radioValue : selected.includes(key);
          const obj = availableObjects?.find((obj) => obj.ref === objRef);
          if (value.toString() === obj?.question.value) return output;
          output[questionKey] = value;

          return output;
        },
        {} as Answers,
      );

      if (Object.keys(changes).length) {
        await dispatch(updateAnswers({ answers: changes }));
        dispatch(setShouldRecalc(true));
      }

      setOpen(false);
    },
    [autoProductKey, availableDiscounts, allSubDiscount, dispatch, objRef],
  );

  const getDiscounts = (): React.ReactElement => {
    if (appliedDiscounts.length === 0) {
      return <p className={classes.discount}>No discounts applied</p>;
    }

    return (
      <div className={classes.discountContainer}>
        <p className={classes.discount}>
          {appliedDiscounts.map((discount, i) => {
            return `${(i ? ', ' : '') + discount.title}`;
          })}
        </p>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.discountTitleAndNameDiv}>
        <p className={classes.discountTitle}>{title}</p>
        {getDiscounts()}
      </div>
      {/*
        showReviewDiscountLink is a temporary solution to make this component read only and requires the user to go back to discounts page to edit.
        Currently when on Coverages and user updates Discounts, we are Patching connect.auto.discount… (carrier specific) keys.
        When on Discounts page, we are Patching driver.<id>.discount… and vehicle.<id>.discount… keys.
        SAPI made a change for Customize Coverages work to send carrier discount keys to the Carrier when on Coverages and to send platform keys when on any other page.
        This creates an issue where platform keys get out of sync.  So when a user navigates back to Discounts after changing discounts on the Coverages page, those changes are no longer reflected.
        Also, when a user goes back to Discounts page from Coverages and makes updates to Discounts, those discounts do not get reflected on the Coverages since the Recalc is being triggered on the Coverages page.
      */}
      {availableDiscounts.length > 0 && (
        <Button
          variant='iconTextMedium'
          trackingName={trackingName}
          trackingLabel={objRef}
          onClick={openModal}
          className={classes.reviewButton}
          data-testid={`discountCardLink-${objRef}`}
        >
          Review discounts
        </Button>
      )}
      <DiscountCardModal
        open={open}
        onClose={closeModal}
        product={autoProductKey}
        // @ts-ignore FIXME ASAP
        discounts={[...availableDiscounts, ...allSubDiscount]}
        objRef={objRef}
        title={title}
        image={image}
      />
    </div>
  );
};
