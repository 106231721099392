import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';
import { useStyles } from './RoofClassQuestion.styles';

export const RoofClassQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const field = usePropertyField('roof.roofClass');

  const { trackingName = 'roof_class_drop_down', groupLabel = field?.question?.title } = props;

  if (!field) return null;

  return (
    <RadioGroupWithOptions
      {...field.props}
      className={classes.roofClassWidth}
      id='roofClass'
      label={groupLabel}
      trackingName={trackingName}
      variant='radioCard'
      cardSize='small'
      row={false}
    />
  );
};
