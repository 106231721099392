import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoThirdPartyReportsPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 8,
  },
  secondaryText: {
    ...theme.typography.body1,
    color: theme.palette.text.tertiary,
    margin: '8px 0px',
  },
  policyLossesContainer: {
    paddingTop: '10px',
    marginTop: 32,
  },
  policyHeader: {
    ...theme.typography.h4,
  },
}));
