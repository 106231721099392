import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { parseDollar } from '@ecp/utils/common';

import { Alert, GridItem, Snackbar, SnackbarAlert } from '@ecp/components';
import { Dialog } from '@ecp/features/sales/shared/components';
import {
  Button,
  Form,
  NextPageInstructions,
  Resource,
  type ResourceFormProps,
} from '@ecp/features/sales/shared/components';
import { AUTO_POLICY_HOUSEHOLD_LOSSES } from '@ecp/features/sales/shared/constants';
import { getDriverInfo, getHasLossesInThePastFiveYears } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import {
  type PolicyLoss,
  useAddPolicyLoss,
  useGetPolicyLossFields,
  usePolicyLoss,
  usePolicyLosses,
  useRemovePolicyLoss,
} from '../../../../common/utils';
import { PolicyLossQuestions } from '../../components';
import { DriverLossAndViolationForm } from '../DriverLossAndViolationForm';
import { useStyles } from './AutoLossesAndViolationsPageForm.styles';

interface Props {
  onNext?: () => Promise<void>;
  nextPageInstructions?: string;
}

export const AutoLossesAndViolationsPageForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const drivers = useSelector(getDriverInfo);

  const [policyLossRef, setPolicyLossRef] = useState('');
  const [addingPolicyLoss, setAddingPolicyLoss] = useState(false);
  const [policyLossActionInfoMessage, setPolicyLossActionInfoMessage] = useState('');
  const [editPolicyLossRef, setEditPolicyLossRef] = useState('');
  const [policyLossBeforeEdit, setPolicyLossBeforeEdit] = useState<PolicyLoss | undefined>();
  const [openPolicyLossDialog, setOpenPolicyLossDialog] = useState(false);
  const [policyLossActionInfoType, setPolicyLossActionInfoType] = useState<
    'NONE' | 'ADD' | 'REMOVE'
  >('NONE');
  const [policyLossToBeRemoved, setPolicyLossToBeRemoved] = useState<PolicyLoss>();
  const currentPolicyLoss = usePolicyLoss(editPolicyLossRef || policyLossRef);
  const policyLossesList = usePolicyLosses('auto', 'accidentOrClaim');
  const policyLossFields = useGetPolicyLossFields(editPolicyLossRef || policyLossRef);
  const addPolicyLoss = useAddPolicyLoss('auto', 'accidentOrClaim');
  const removePolicyLoss = useRemovePolicyLoss();
  const handleSubmit = useCallback(async () => {
    if (onNext) await onNext();
  }, [onNext]);
  const {
    accidentOrClaim: { lossDate, lossDescription, lossAmountUserEntered, status, source },
  } = policyLossFields;

  const [noLossesText, setNoLossesText] = useState(policyLossesList.length === 0);

  /**
   * TODO: for now we can check indicator. Later indicator will be updated by reports that are ordered.
   *   If we need to update this with logic with a count of accidents/claims we could do it then, but
   *   nay not be needed, as the indicator would be updated.
   */
  const hasIndicatedLosses = useSelector((state: RootStore) =>
    getHasLossesInThePastFiveYears(state, AUTO_POLICY_HOUSEHOLD_LOSSES),
  );

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  const lossesAndViolationsInfoMessage = (
    <div>
      You indicated that no one in the household has had an Auto incident in the last 5 years. As a
      result, the Auto Claims and Losses Report wasn’t ordered, but you can still add any losses or
      violations for a more accurate indicative quote.
    </div>
  );

  const noPolicyLossesMessage = 'No policy losses.';
  const noPolicyLossesFoundInReportsMessage = 'No policy losses found in third-party reports.';

  const driversLossesAndViolations = drivers.map((driver) => {
    return <DriverLossAndViolationForm key={driver.ref} driver={driver} />;
  });

  const refsSortedByEndDate = policyLossesList
    .sort((a, b) => new Date(b.lossDate).valueOf() - new Date(a.lossDate).valueOf())
    .map((policyLoss) => policyLoss.ref);

  const policyLossByRef: { [key: string]: (typeof policyLossesList)[number] } =
    policyLossesList?.reduce(
      (acc: Record<string, (typeof policyLossesList)[number]>, policyLoss) => {
        acc[policyLoss.ref] = policyLoss;

        return acc;
      },
      {},
    );

  const onEditPolicyLoss = useCallback(
    (policyLossRef: string) => {
      const policyLoss = policyLossByRef[policyLossRef];
      setEditPolicyLossRef(policyLossRef);
      setPolicyLossBeforeEdit({ ...policyLoss });
    },
    [policyLossByRef],
  );

  const onRemovePolicyLoss = useCallback(
    (policyLossRef: string) => {
      const policyLoss = policyLossByRef[policyLossRef];
      setPolicyLossToBeRemoved(policyLoss);
      setOpenPolicyLossDialog(true);
    },
    [policyLossByRef],
  );

  const onRemoveDialogAction = useCallback(() => {
    setAddingPolicyLoss(false);
    setOpenPolicyLossDialog(false);
    setPolicyLossActionInfoMessage('Loss removed.');
    setPolicyLossActionInfoType('REMOVE');

    if (policyLossBeforeEdit) {
      // restore old values as user has clicked on discard
      lossDate.validateUpdateAndPatch(policyLossBeforeEdit.lossDate);
      lossAmountUserEntered.validateUpdateAndPatch(policyLossBeforeEdit.lossAmountUserEntered);
      lossDescription.validateUpdateAndPatch(policyLossBeforeEdit.lossDescription);
      status.validateUpdateAndPatch(policyLossBeforeEdit.status);
      source.validateUpdateAndPatch(policyLossBeforeEdit.source);
    } else {
      if (policyLossToBeRemoved) {
        removePolicyLoss(policyLossToBeRemoved);
      }
      lossDate.validateUpdateAndPatch('');
      lossAmountUserEntered.validateUpdateAndPatch('');
      lossDescription.validateUpdateAndPatch('');
      status.validateUpdateAndPatch('');
      source.validateUpdateAndPatch('');
    }
    setEditPolicyLossRef('');
    setPolicyLossBeforeEdit(undefined);
    setPolicyLossToBeRemoved(undefined);
  }, [
    policyLossBeforeEdit,
    lossDate,
    lossAmountUserEntered,
    lossDescription,
    status,
    source,
    policyLossToBeRemoved,
    removePolicyLoss,
  ]);

  const handleSavePolicyLoss = useCallback(() => {
    setAddingPolicyLoss(false);
    setPolicyLossRef('');
    setEditPolicyLossRef('');
  }, []);

  const addPolicyLevelLoss = useCallback(() => {
    setAddingPolicyLoss(true);
    setPolicyLossRef(addPolicyLoss());
  }, [addPolicyLoss]);

  const handleCancelPolicyLoss = useCallback(
    async (selectedPolicyLossRef: string) => {
      // if cancel selected while adding device and if there are any data entered then have to show remove dialog.
      if (selectedPolicyLossRef) {
        const policyLoss = policyLossByRef[selectedPolicyLossRef];
        setPolicyLossToBeRemoved(policyLoss);
      } else {
        // Dont show remove dialog if there are no data loss
        if (editPolicyLossRef && policyLossBeforeEdit) {
          lossDate.validateUpdateAndPatch(policyLossBeforeEdit.lossDate);
          lossAmountUserEntered.validateUpdateAndPatch(policyLossBeforeEdit.lossAmountUserEntered);
          if (policyLossBeforeEdit.lossDescription) {
            const lossDescriptionBeforeEdit = lossDescription.question.options?.find(
              (option) => option.label === policyLossBeforeEdit.lossDescription,
            )?.value;
            if (lossDescriptionBeforeEdit) {
              lossDescription.validateUpdateAndPatch(lossDescriptionBeforeEdit);
            }
          }
          status.validateUpdateAndPatch(policyLossBeforeEdit.status);
          source.validateUpdateAndPatch(policyLossBeforeEdit.source);
        } else {
          removePolicyLoss(currentPolicyLoss);
        }
        setPolicyLossRef('');
        setEditPolicyLossRef('');
        setAddingPolicyLoss(false);
        setOpenPolicyLossDialog(false);
        setPolicyLossBeforeEdit(undefined);
        setPolicyLossToBeRemoved(undefined);
      }
    },
    [
      policyLossByRef,
      currentPolicyLoss,
      editPolicyLossRef,
      lossAmountUserEntered,
      lossDate,
      lossDescription,
      removePolicyLoss,
      source,
      status,
      policyLossBeforeEdit,
    ],
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason === 'clickaway') {
        return;
      }
      setPolicyLossActionInfoMessage('');
      setPolicyLossActionInfoType('NONE');

      return;
    },
    [],
  );

  const snackbarDefault = policyLossActionInfoType === 'REMOVE' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!policyLossActionInfoMessage}
      autoHideDuration={3000}
      message={policyLossActionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = policyLossActionInfoType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={policyLossActionInfoMessage}
      hideActionButton
    />
  );

  const onCloseDialogAction = useCallback(() => {
    setOpenPolicyLossDialog(false);
  }, []);

  const removeDialog = (
    <Dialog
      actionButtonLabel='REMOVE LOSS'
      titleText='Remove loss?'
      textButtonLabel='Cancel'
      open={openPolicyLossDialog}
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
    >
      <div>Any associated content will be removed.</div>
    </Dialog>
  );

  // TODO: ADVECP-3922: Currently, status is blank. It has to be updated based on the final requirement.
  const renderPolicyLossRefItem = useCallback(
    (item: string) => {
      const policyLossItem = policyLossesList.find((policyLoss) => policyLoss.ref === item);

      return (
        <>
          <Resource.Item
            xs={2}
            title='Loss Date'
            value={
              policyLossItem && policyLossItem.lossDate
                ? `${dayjs(policyLossItem.lossDate).format('MM/DD/YYYY')}`
                : ' '
            }
          />
          <Resource.Item xs={2} title='Loss Description' value={policyLossItem?.lossDescription} />
          <Resource.Item
            xs={2}
            title='Amount'
            value={parseDollar(policyLossItem?.lossAmountUserEntered)}
          />
          <Resource.Item xs={2} title='Status' value={policyLossItem?.status || ''} />
          <Resource.Item xs={2} title='Source' value={policyLossItem?.source || 'Insured'} />
        </>
      );
    },
    [policyLossesList],
  );

  /**
   * To display text secondary text under Policy Losses header
   */
  useEffect(() => {
    const hasNoLosses = policyLossesList.length === 0;
    if (noLossesText !== hasNoLosses) {
      setNoLossesText(hasNoLosses);
    }
  }, [policyLossesList, noLossesText]);

  const renderPolicyLossForm = useCallback(
    (formProps: ResourceFormProps) => (
      <PolicyLossQuestions
        {...formProps}
        policyLossRef={policyLossRef}
        setAddingPolicyLoss={setAddingPolicyLoss}
        setPolicyLossActionInfoMessage={setPolicyLossActionInfoMessage}
        setPolicyLossActionInfoType={setPolicyLossActionInfoType}
        editPolicyLossRef={editPolicyLossRef}
        setOpenPolicyLossDialog={setOpenPolicyLossDialog}
        policyLossList={policyLossesList}
      />
    ),
    [
      policyLossRef,
      setAddingPolicyLoss,
      setPolicyLossActionInfoMessage,
      setPolicyLossActionInfoType,
      editPolicyLossRef,
      setOpenPolicyLossDialog,
      policyLossesList,
    ],
  );

  return (
    <div className={classes.root}>
      <Form showBackdrop={false}>
        <Grid container>
          {!hasIndicatedLosses && (
            <Grid item xs={12}>
              <div className={classes.alertContainer}>
                <Alert withIcon type='info'>
                  {lossesAndViolationsInfoMessage}
                </Alert>
              </div>
            </Grid>
          )}

          <Grid xs={12} className={classes.policyLossesContainer}>
            <GridItem>
              <div className={classes.policyHeader}>Policy Losses</div>
            </GridItem>
            {noLossesText ? (
              <p className={classes.secondaryText}>
                {!hasIndicatedLosses ? noPolicyLossesMessage : noPolicyLossesFoundInReportsMessage}
              </p>
            ) : (
              <br />
            )}
          </Grid>

          <>
            <Resource.List
              items={refsSortedByEndDate}
              editItemRef={editPolicyLossRef || policyLossRef}
              onEdit={onEditPolicyLoss}
              pageErrors={[]}
              onDelete={onRemovePolicyLoss}
              onCancel={handleCancelPolicyLoss}
              onNext={handleSavePolicyLoss}
              form={renderPolicyLossForm}
              renderListItem={renderPolicyLossRefItem}
            />
            <div className={classes.actionButtons}>
              <Resource.AddButton
                onClick={addPolicyLevelLoss}
                disabled={addingPolicyLoss}
                data-testid='autoPolicyLossAdd'
                trackingName={GoogleAnalyticsLabels.CONTINUE}
                trackingLabel='losses_and_violations_page_add_policy_loss_button'
                analyticsElement='choice.lossesAndViolationsPage.addButton'
              >
                ADD POLICY LOSS
              </Resource.AddButton>
            </div>
          </>
          <Grid container>{driversLossesAndViolations}</Grid>

          <Grid item xs={12}>
            <NextPageInstructions divider />
          </Grid>

          <Grid className={classes.buttonsPanel} container item xs={12}>
            <div className={classes.addButton}>
              <Button
                className={classes.next}
                variant='primary'
                onClick={handleSubmit}
                data-testid='lossesAndViolationsContinue'
                // This stops the onBlur from firing only when this button is clicked
                // and the button retains its coordinates for the mouseup to eventually
                // register it as a click event. If you want the onBlur as well, you can
                // fire it yourself from the onClick handler. Touch should keep working.
                onMouseDown={handleMouseDown}
                // isProcessing={isPatchFormInProgress || isSubmitting}
                trackingName={GoogleAnalyticsLabels.CONTINUE}
                trackingLabel='losses_and_violations_page_continue'
                analyticsElement='choice.lossesAndViolationsPage.continueButton'
              >
                Continue
              </Button>
            </div>
          </Grid>
        </Grid>
      </Form>
      {removeDialog}
      {snackbarDefault}
      {snackbarSuccess}
    </div>
  );
};
