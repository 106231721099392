import { useMemo, useRef } from 'react';

import { emptyArray, unique } from '@ecp/utils/common';

import {
  getFieldsByKeys,
  getOfferProductsSelectedByType,
  getPrimaryInsuredStateCode,
  getShownFields,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, CoverageItem, CoveragesFields } from '@ecp/features/sales/shared/types';
import type { PropertyProduct } from '@ecp/features/shared/product';
import type { Field } from '@ecp/types';

import type { CoveragesSharedProps } from '../../../../common';
import { useCoveragesFormValidationFactory } from '../../../../common';
import { buildCoverageItems, useCoveragesAnalytics } from '../../../../common/utils/coverageUtil';
import { PropertyPolicyCoveragesMeta } from './metadata';
import type { PropertyCoveragesFormProps } from './types';

export const useGetPropertyCoverageFields = (selectedPropertyProduct?: string): CoveragesFields => {
  const dispatch = useDispatch();

  const propertyPolicyCoverageMetadata = selectedPropertyProduct
    ? PropertyPolicyCoveragesMeta[selectedPropertyProduct]
    : {};
  const PropertyPolicyCoverageMetadataKeys = Object.keys(propertyPolicyCoverageMetadata).reduce(
    (acc, curr) => {
      acc.push(curr);

      const subCoverages = propertyPolicyCoverageMetadata[curr]?.subCoverages;
      if (subCoverages) {
        acc.push(...Object.keys(subCoverages));
      }

      const stateOptions = propertyPolicyCoverageMetadata[curr]?.stateOptions;
      if (stateOptions) {
        Object.keys(stateOptions).forEach((state) => {
          const stateSubCoverages = stateOptions[state].subCoverages;
          if (stateSubCoverages) {
            acc.push(...Object.keys(stateSubCoverages));
          }
        });
      }

      return unique(acc);
    },
    [] as string[],
  );

  const policyCoverageFields = useSelector((state: RootStore) => {
    const fields = getFieldsByKeys(
      state,
      `${selectedPropertyProduct}.coverages`,
      [...PropertyPolicyCoverageMetadataKeys],
      dispatch,
    );

    return fields;
  }) as CoveragesFields;

  const policyCoverageFieldsFiltered = dispatch(getShownFields({ fields: policyCoverageFields }));

  return policyCoverageFieldsFiltered as CoveragesFields;
};

export const buildPropertyCoverages = (
  productKey: PropertyProduct,
  propertyCoverageFields: CoveragesFields,
  stateCode = '',
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): CoverageItem[] => {
  const metaData = PropertyPolicyCoveragesMeta[productKey];

  return buildCoverageItems(metaData, propertyCoverageFields, stateCode, handleCoverageItemChange);
};

export const usePropertyCoveragesForm = (
  fields: CoveragesSharedProps['fields'][PropertyProduct],
  props: Pick<PropertyCoveragesFormProps, 'onFormValidChange'>,
): {
  propertyCoverages: CoverageItem[];
  handleCoverageItemChange: (field: Field) => (value: AnswerValue) => Promise<void>;
} => {
  const { onFormValidChange } = props;
  const initValues = useRef({});
  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);
  const { handleCoverageItemChange } = useCoveragesFormValidationFactory(
    initValues,
    fields,
    onFormValidChange,
  );

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  /** Property coverages and deductibles */
  const propertyCoverages = useMemo(
    () =>
      propertyOfferProduct && fields?.policyCoverageFields
        ? buildPropertyCoverages(
            propertyOfferProduct,
            fields.policyCoverageFields,
            stateCode,
            handleCoverageItemChange,
          )
        : (emptyArray as unknown as CoverageItem[]),
    [fields, propertyOfferProduct, stateCode, handleCoverageItemChange],
  );

  // Analytics
  useCoveragesAnalytics(propertyCoverages, 'DefaultPropertyPolicyLevelCoverages');

  return {
    propertyCoverages,
    handleCoverageItemChange,
  };
};
