import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './PostBindForm.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        titleAgent: css({
          color: theme.palette.primary.main,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
        }),
        titleHeaderAgent: css({
          ...theme.typography.h1,
          color: theme.palette.primary.main,
        }),
        successIconContainer: css({
          backgroundColor: `${theme.palette.primary.main}40`,
        }),
        successIcon: css({
          ...theme.mixins.setColorSvg(theme.palette.primary.main),
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
