import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { Driver } from '@ecp/features/sales/shared/types';

import { DeltaDriverNonOperatorDescription } from '../DeltaDriverNonOperatorDescription';
import { DeltaOtherNonOperatorDescription } from '../DeltaOtherNonOperatorDescription';

interface Props extends QuestionProps {
  driver: Driver;
}

export const DeltaNonOperatorCard: React.FC<Props> = (props) => {
  const { driver } = props;

  return (
    <Grid container columnSpacing={1}>
      <GridItem topSpacing='xs' xs={12} md={6}>
        <DeltaDriverNonOperatorDescription driverRef={driver.ref} />
      </GridItem>
      <GridItem topSpacing='xs' xs={12} md={6}>
        <DeltaOtherNonOperatorDescription driverRef={driver.ref} />
      </GridItem>
    </Grid>
  );
};
