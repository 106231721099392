import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const RenovationDocumentProvidedIndQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('renovation.renovationDocumentProvidedInd', true);

  if (!field) return null;

  const {
    trackingName = 'renovation_document_provided_selection',
    label = field?.question?.title,
  } = props;

  return (
    <RadioGroupWithOptions
      {...field.props}
      id='RenovationDocumentProvided'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
