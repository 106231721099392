import { useMemo } from 'react';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  useAddAndUpdateFoundation,
  useFoundationPercentages,
  useRemoveFoundation,
  useUpdateFoundation,
} from '@ecp/features/sales/quotes/property/home';
import { PercentageGroupSelection } from '@ecp/features/sales/shared/components';
import { useField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { CardOption } from '@ecp/types';

import { HomeBasementFinishedPercentageQuestion } from '../HomeBasementFinishedPercentageQuestion';
import type { FoundationTypeQuestionProps } from './FoundationTypeQuestion';

export const FoundationTypeMultiSelectionQuestion: React.FC<FoundationTypeQuestionProps> = (
  props,
) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const foundationType = useField('static.foundationType');

  // Not using usePropertyField hook here. This field needs to be conditionally added to the form
  const percentageFinished = useHomePropertyField('foundation.percentageFinished');
  const type = useFieldWithPrefix('foundation.<id>', 'foundation.<id>')('foundationType');
  const percentage = useFieldWithPrefix('foundation.<id>', 'foundation.<id>')('percentage');

  const percentages = useFoundationPercentages();
  const foundationTypes = Object.keys(percentages).join(',');
  const addAndUpdateFoundation = useAddAndUpdateFoundation();
  const updateFoundation = useUpdateFoundation();
  const removeFoundation = useRemoveFoundation();

  useInitValues({ [foundationType.key]: foundationTypes });

  const foundationTypeIncludesBasement = useMemo(
    (): boolean =>
      (foundationType.value as string)?.includes(
        'FOUNDATION.FOUNDATION_TYPE.BASEMENT_UNDERGROUND_BELOW_GRADE',
      ) ||
      (foundationType.value as string)?.includes(
        'FOUNDATION.FOUNDATION_TYPE.BASEMENT_DAYLIGHT_WALKOUT',
      ),
    [foundationType.value],
  );

  useAddFields({ foundationType });

  useAddConditionValues({
    conditionalFields: [percentageFinished],
    isExcluded: () => !foundationTypeIncludesBasement,
    isRequiredOverride: () => true,
  });

  if (!type.exists) return null;

  const {
    trackingName = 'home_basement_finished_percentage_slider',
    groupLabel = 'What percentage of your basement is finished?',
    label = type.question.title || '',
  } = props;

  return (
    <>
      <GridItem>
        <PercentageGroupSelection
          hidePercentageSelected
          title={label}
          subtitle={type.question.subtitle || ''}
          options={type.question.options as CardOption[]}
          percentageOptions={percentage?.question?.options ?? []}
          percentages={percentages}
          uiField={foundationType}
          optionTrackingName='home_foundation_selection'
          percentTrackingName='home_foundation_percentages'
          moreLessTrackingName='home_foundation_more_choices'
          onAdd={addAndUpdateFoundation}
          onUpdate={updateFoundation}
          onRemove={removeFoundation}
          moreLessDataTestId='FoundationType-SeeMoreOrLessChoices'
          checkBoxGroupTestId='FoundationType'
          sortByKey
        />
      </GridItem>
      {foundationTypeIncludesBasement && (
        <GridItem topSpacing='lg' xs={12}>
          <HomeBasementFinishedPercentageQuestion
            label={groupLabel}
            displayType={props.displayTypeForHomeBasementPercentageFinished}
            trackingName={trackingName}
          />
        </GridItem>
      )}
    </>
  );
};
