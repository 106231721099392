import { Grid } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import { downloadAndSaveDocuments } from '@ecp/features/sales/checkout';
import { Button } from '@ecp/features/sales/shared/components';
import { getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromProduct } from '@ecp/features/shared/product';
import {
  IconMaterialDesignCommunicationMailOutline,
  IconMaterialDesignFileCloudDownload,
} from '@ecp/themes/base';

import { useStyles } from './DownloadSendQuoteButtons.styles';

interface Props {
  isProcessing?: boolean;
  recalculate?: boolean;
}

export const DownloadSendQuoteButtons: React.FC<Props> = (props) => {
  const { isProcessing, recalculate } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const selectedProducts = useSelector(getOfferProductsSelected);
  const DOWNLOAD_FORM_NAME = 'PrintQuote';

  const handleDownloadProposalClick = useEvent(async (): Promise<void> => {
    for (const product of selectedProducts) {
      await dispatch(
        downloadAndSaveDocuments({
          product: product,
          documentTypeName: DOWNLOAD_FORM_NAME,
          documentDisplayName: DOWNLOAD_FORM_NAME + getProductNameFromProduct(product),
        }),
      );
    }
  });

  const handleSendQuoteClick = useEvent(async (): Promise<void> => {
    // DO ACTION
  });

  return (
    <Grid item xs={12}>
      <Grid container className={classes.premiumBoxButtonContainer}>
        <Grid item xs={12}>
          <Button
            disabled={recalculate}
            classes={{
              root: classes.premuimActionButton,
            }}
            onClick={handleDownloadProposalClick}
            isProcessing={isProcessing}
            trackingName='download_quote_proposal'
            trackingLabel={DOWNLOAD_FORM_NAME}
            analyticsElement='choice.policyCoveragePage.DownloadProposal'
            data-testid='downloadProposal'
            variant='iconTextMedium'
            icon={<IconMaterialDesignFileCloudDownload className={classes.icon} />}
          >
            DOWNLOAD PROPOSAL
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={recalculate}
            classes={{
              root: classes.premuimActionButton,
            }}
            onClick={handleSendQuoteClick}
            isProcessing={isProcessing}
            analyticsElement='choice.policyCoveragePage.SendQuoteButton'
            data-testid='sendQuoteButton'
            variant='iconTextMedium'
            icon={<IconMaterialDesignCommunicationMailOutline className={classes.icon} />}
          >
            SEND QUOTE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
