import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoverageGroups' })((theme) => ({
  groupNameHeader: {
    ...theme.typography.body3Bold,
    marginBottom: 0,
    paddingLeft: 6,
    paddingTop: 35,
    '&:first-of-type': {
      marginTop: 0,
      paddingTop: 0,
    },
  },
  itemGroup: {
    border: `1px solid ${theme.palette.other.border}`,
    marginBottom: 20,
    padding: 20,
    borderRadius: 4,
  },
  item: {
    borderBottom: `1px solid ${theme.palette.other.border}`,
    marginTop: 20,
    '&:last-child': {
      borderBottom: 0,
    },
  },
  itemTitle: {
    ...theme.typography.body4,
    marginTop: 20,
    '&:last-child': {
      borderBottom: 0,
    },
  },
  accordionHeaderError: {
    color: theme.palette.error.main,
  },
  premiumAmount: {
    textAlign: 'right',
  },
  premiumHeader: {
    ...theme.typography.body4,
    textAlign: 'right',
    color: theme.palette.text.tertiary,
  },
}));
