import { Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { Recalc } from '@ecp/features/sales/checkout';
import { getUpdateOffersInProgress } from '@ecp/features/sales/shared/store';
import type { Premium, RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './FullPaymentView.styles';

interface Props {
  recalculate?: boolean;
  showProcess: boolean;
  effectiveDate: string;
  fullPremium: Premium;
}

export const FullPaymentView: React.FC<Props> = (props) => {
  const { recalculate, showProcess, effectiveDate, fullPremium } = props;
  const { classes } = useStyles();
  const updatesInProgress = useSelector((state: RootStore) => getUpdateOffersInProgress(state));
  const showLoading = updatesInProgress || showProcess;

  return (
    <>
      <Grid item xs={8} display='flex' alignItems='center'>
        <p className={classes.premiumDetailHeaders}>One-time payment of</p>
      </Grid>
      <Grid item xs={4} className={classes.totalPremium}>
        <Recalc
          amount={parseDollar(fullPremium?.totalPremium)}
          recalculate={recalculate}
          showProcess={showLoading}
        />
      </Grid>

      <Grid item xs={6}>
        <p className={classes.premiumDetailHeaders}>Effective date</p>
      </Grid>
      <Grid item xs={6} className={classes.effectiveDateItem}>
        <span className={classes.effectiveDate}>{formatDate(effectiveDate)}</span>
      </Grid>
    </>
  );
};
