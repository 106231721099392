import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import { GridItem } from '@ecp/components';
import { getCoveragePremiumAmount } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { CoverageItem } from '@ecp/features/sales/shared/types';

import { CoverageCardItems } from '..';
import type {
  AutoParentCoverageGroupKey,
  AutoPolicyCoverageGroupKey,
  AutoVehicleCoverageGroupKey,
} from '../../../lob/autoLine/forms/AutoCoveragesForm/types';
import type { PropertyPolicyCoverageGroupKey } from '../../../lob/propertyLine/utils';
import { useStyles } from './QuoteCoverages.styles';

interface QuoteCoverageProps {
  coverageItems: CoverageItem[];
  parentCoverageGroupingList:
    | Record<PropertyPolicyCoverageGroupKey, string>
    | Record<AutoParentCoverageGroupKey, string>
    | null;
  subsectionCoverageGroupingList?:
    | Record<AutoPolicyCoverageGroupKey, string>
    | Record<AutoVehicleCoverageGroupKey, string>
    | null;
}

// This is a react component, not just a function. That's why the name
// starts with a capital letter and also, can use react hook here.
const GetPremiumDisplay = (premiumAmountCoverageNode: string): React.ReactNode => {
  const { classes } = useStyles();
  const premium = useSelector((state: RootStore) => {
    const premiumValue = getCoveragePremiumAmount(state, premiumAmountCoverageNode);

    return premiumValue ? parseDollar(premiumValue) : ' ';
  });

  return premium ? (
    <GridItem xs={2}>
      <div className={classes.premiumAmount}>{premium}</div>
    </GridItem>
  ) : null;
};

export const QuoteCoverages: React.FC<QuoteCoverageProps> = (props) => {
  const { classes } = useStyles();
  const { coverageItems, parentCoverageGroupingList, subsectionCoverageGroupingList } = props;

  const getCoveragesForGroupDisplay = useCallback(
    (sectionGroup: string, groupType: 'parentGroup' | 'subGroup') => {
      return coverageItems
        .filter((covItem) => covItem[groupType] === sectionGroup)
        .map((item, key) =>
          item.subCoverages ? (
            <>
              <Grid container gap={1} justifyItems='space-between' className={classes.itemTitle}>
                <GridItem xs={9}>
                  <div>{item.title}</div>
                </GridItem>
                {GetPremiumDisplay(item.premiumAmountNode || '')}
              </Grid>
              <Grid item xs={12} lg={8} className={classes.item} key={key}>
                {Object.values(item.subCoverages).map((subCoverageItem, k) => (
                  <CoverageCardItems
                    key={k}
                    field={subCoverageItem.field}
                    primaryText={subCoverageItem.primaryText}
                    title={subCoverageItem.title}
                    isSubCoverage
                    displayInfo={subCoverageItem.displayInfo}
                  />
                ))}
              </Grid>
            </>
          ) : (
            <Grid item xs={12} lg={8} className={classes.item} key={key}>
              <CoverageCardItems
                field={item.field}
                primaryText={item.primaryText}
                secondaryText={item.secondaryText}
                title={item.title}
                displayInfo={item.displayInfo}
              />
            </Grid>
          ),
        );
    },
    [classes.item, classes.itemTitle, coverageItems],
  );

  if (!parentCoverageGroupingList || !coverageItems || coverageItems.length < 1) return null;

  const showError = coverageItems.some((item: CoverageItem): boolean =>
    Boolean(item.field?.errors.length),
  );

  return (
    <GridItem topSpacing='lg' xs={12}>
      <Grid container>
        <Grid item xs={12}>
          {Object.entries(parentCoverageGroupingList).map(([group, title]) => (
            <>
              {coverageItems.length &&
                coverageItems.some((covItem) => covItem.parentGroup === group) && (
                  <Grid item xs={12} className={classes.itemGroup}>
                    <h3 className={showError ? classes.accordionHeaderError : undefined}>
                      {title}
                    </h3>
                    {/* Only auto has subsections at this point, and only auto has the premium header floating to the right of the title */}
                    {subsectionCoverageGroupingList
                      ? Object.entries(subsectionCoverageGroupingList).map(([group, title]) => (
                          <GridItem topSpacing='lg' xs={12}>
                            <Grid container gap={1} justifyItems='space-between'>
                              <GridItem xs={9}>
                                <h4
                                  className={showError ? classes.accordionHeaderError : undefined}
                                >
                                  {title}
                                </h4>
                              </GridItem>
                              <GridItem xs={2}>
                                <div className={classes.premiumHeader}>Premium</div>
                              </GridItem>
                            </Grid>
                            {getCoveragesForGroupDisplay(group, 'subGroup')}
                          </GridItem>
                        ))
                      : getCoveragesForGroupDisplay(group, 'parentGroup')}
                  </Grid>
                )}
            </>
          ))}
        </Grid>
      </Grid>
    </GridItem>
  );
};
