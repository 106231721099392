import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { GridItem, NumberFormat } from '@ecp/components';
import { Resource, Select } from '@ecp/features/sales/shared/components';
import type { Affiliation } from '@ecp/features/sales/shared/store';
import {
  useCurrentAffiliationGroups,
  useGetAffiliationFields,
} from '@ecp/features/sales/shared/store';
import {
  setFormErrorsChangedByField,
  useFieldWithPrefix,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { useStyles } from './AffiliationQuestion.styles';
export interface AffiliationQuestionProps {
  itemRef: string;
  namedInsuredRef: string;
  affiliationRef: string;
  setAddingAffiliation: Dispatch<SetStateAction<boolean>>;
  setAffiliationActionInfoMessage: Dispatch<SetStateAction<string>>;
  setAffiliationActionInfoType: Dispatch<SetStateAction<'NONE' | 'ADD' | 'REMOVE'>>;
  editAffiliationRef: string;
  setOpenAffiliationDialog: Dispatch<SetStateAction<boolean>>;
  affiliationList: Array<Affiliation>;
  onNext: () => void;
  onCancel: (affiliationRef: string) => void;
}

export const AffiliationQuestion: React.FC<AffiliationQuestionProps> = (props) => {
  const {
    itemRef: affiliationRef,
    namedInsuredRef,
    setAddingAffiliation,
    setAffiliationActionInfoMessage,
    setAffiliationActionInfoType,
    editAffiliationRef,
    setOpenAffiliationDialog,
    affiliationList,
    onNext,
    onCancel,
  } = props;

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const initValues = useRef({});

  const [groupList, setGroupList] = useState(['']);

  const currentAffiliationRef = affiliationRef;
  const affiliationFields = useGetAffiliationFields(
    editAffiliationRef || currentAffiliationRef || '',
  );

  const getGroups = useCurrentAffiliationGroups(affiliationList);

  const usePersonField = useFieldWithPrefix(namedInsuredRef, 'person.<id>');
  const membershipNumber = usePersonField('additionalInformation.costcoMembershipNumber');
  const {
    affiliation: { affiliationType, group },
  } = affiliationFields;

  const { validateForm: validateAffiliationForm, patchFormValues: patchAffiliationFormValues } =
    useForm({
      fields: {
        ...affiliationFields.affiliation,
        membershipNumber,
      },
      initValues,
      conditions: [],
    });

  useEffect(() => {
    if (editAffiliationRef) {
      group.props.actionOnChange(group.value);
      affiliationType.props.actionOnChange(affiliationType.value);
    } else {
      setGroupList(getGroups());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeOptions = useMemo(
    () =>
      affiliationType.props.options?.filter((item) => {
        const nonAmfamTypes = ['MEMBER'];
        if (group.props.value?.includes('AMFAM')) {
          return !nonAmfamTypes.includes(item.value);
        }

        return item.value;
      }),
    [affiliationType, group],
  );

  const groupOptions = useMemo(() => {
    const groups = getGroups();
    let filteredGroup = group.props.options?.filter((item) => !groupList.includes(item.value));
    // To Avoid the duplication of affiliation group, filter the already used affiliation group from the group option.
    if (editAffiliationRef) {
      filteredGroup = group.props.options?.filter(
        (item) => item.value === group.value || !groups.includes(item.value),
      );
    }

    return filteredGroup;
  }, [editAffiliationRef, getGroups, group, groupList]);

  const onValidateAffiliationFields = useCallback(
    (affiliationType: Field, group: Field) => {
      let isValid = true;
      if (!affiliationType.value) {
        dispatch(
          setFormErrorsChangedByField({ key: affiliationType.key, errors: ['Required field'] }),
        );
        isValid = false;
      }
      if (!group.value) {
        dispatch(setFormErrorsChangedByField({ key: group.key, errors: ['Required field'] }));
        isValid = false;
      }

      return isValid;
    },
    [dispatch],
  );

  const handleAddAffiliation = useEvent(async () => {
    const affiliationFormValid = validateAffiliationForm().isValid;

    const affiliationFieldValid = onValidateAffiliationFields(affiliationType, group);
    if (affiliationFieldValid && affiliationFormValid) {
      await patchAffiliationFormValues();
      onNext();
      setAffiliationActionInfoMessage('Affiliation saved.');
      setAffiliationActionInfoType('ADD');
    }
  });

  const handleCancel = useCallback(() => {
    if (group.props.value || affiliationType.props.value) {
      if (group.props.value) {
        setOpenAffiliationDialog(true);
      }
    } else {
      setAddingAffiliation(false);
      setOpenAffiliationDialog(true);
    }
    onCancel(editAffiliationRef || currentAffiliationRef);
  }, [
    group.props.value,
    affiliationType.props.value,
    onCancel,
    editAffiliationRef,
    currentAffiliationRef,
    setOpenAffiliationDialog,
    setAddingAffiliation,
  ]);

  const handleAffiliationGroupChange = useCallback(
    (newValue: AnswerValue): void => {
      if (newValue === 'COSTCO' || newValue === 'UW_MADISON') {
        affiliationType.validateUpdateAndPatch('MEMBER');
      } else if (newValue === 'SANFORDMEDGRP') {
        affiliationType.validateUpdateAndPatch('CURRENT_EMPLOYEE');
      }
      group.props.actionOnChange(newValue);
    },
    [group.props, affiliationType],
  );

  const renderAffiliations = (): React.ReactElement => (
    <Grid container item xs={12} className={classes.affiliationQuestionContainer}>
      {group.exists && (
        <GridItem topSpacing='sm' xs={6} md={6}>
          <Select
            {...group.props}
            id='affiliationGroup'
            inputButtonAriaLabel='Affliiation Group'
            actionOnChange={handleAffiliationGroupChange}
            label='Affinity Group'
            options={groupOptions}
            trackingName='affiliation_group_selection'
          />
        </GridItem>
      )}
      {affiliationType.exists && (
        <Grid container xs={6} className={classes.affinityTypeContainer}>
          {group.value === 'AMFAM' && (
            <GridItem topSpacing='sm' xs={12}>
              <Select
                {...affiliationType.props}
                id='affiliationType'
                inputButtonAriaLabel='Affliiation Type'
                label='Affinity Type'
                data-testid='affinityType'
                options={typeOptions}
                trackingName='affiliation_group_selection'
              />
            </GridItem>
          )}
          {(group.value === 'COSTCO' || group.value === 'UW_MADISON') && (
            <>
              <GridItem topSpacing='sm' xs={12} className={classes.headerText}>
                Affinity Type
              </GridItem>
              <GridItem topSpacing='sm' xs={12} className={classes.itemText}>
                Member
              </GridItem>
            </>
          )}
          {group.value === 'COSTCO' && membershipNumber && (
            <GridItem topSpacing='sm' xs={12} md={12}>
              <NumberFormat
                {...membershipNumber.props}
                formatType='costcoMembershipNumber'
                label='Costco Member Number (optional)'
                id='costcoMembership'
                name='costcoMembership'
                ariaLabel='CostcoMembership'
              />
            </GridItem>
          )}
        </Grid>
      )}

      <GridItem xs={12} topSpacing='sm'>
        <div className={classes.actionButtons}>
          <Resource.CancelButton
            onClick={handleCancel}
            data-testid='affiliationCancel'
            trackingName='affiliation_cancel_button'
            trackingLabel='affiliation_cancel'
            analyticsElement='choice.affiliation.cancelButton'
          >
            Cancel
          </Resource.CancelButton>
          <Resource.SaveButton
            onClick={handleAddAffiliation}
            data-testid='affiliationSave'
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='affiliation_save'
            analyticsElement='choice.affiliation.saveButton'
          >
            SAVE AFFILIATION
          </Resource.SaveButton>
        </div>
      </GridItem>
    </Grid>
  );

  return <>{renderAffiliations()}</>;
};
