import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const NoOfOccupantsQuestion: React.FC<QuestionProps> = (props) => {
  const noOfOccupantsField = usePropertyField('noOfOccupants');
  if (!noOfOccupantsField) return null;

  const { label = noOfOccupantsField.question?.title, trackingName = 'no_of_occupants' } = props;

  return (
    <NumberFormat
      {...noOfOccupantsField.props}
      fullWidth={false}
      id='NoOfOccupants' // This is to fix accessibility test
      groupLabel={<Typography variant='body4'>{label}</Typography>}
      trackingName={trackingName}
    />
  );
};
