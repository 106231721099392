import { useCallback } from 'react';

import type { SelectProps } from '@ecp/components';
import { GridItem } from '@ecp/components';
import type { VehicleOptions } from '@ecp/features/sales/quotes/auto';
import { checkAndClearFields, useVehicleFields } from '@ecp/features/sales/quotes/auto';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useVehicleInfo } from '@ecp/sales/lob/auto';
import type { AnswerValue } from '@ecp/types';

import { useStyles } from '../VehicleInfoQuestions.styles';

type Props = QuestionProps &
  Pick<VehicleOptions, 'makeOptions'> & {
    maskRequiredFieldError: boolean;
    setMaskRequiredFieldError: (value: boolean) => void;
    vehicleRef: string;
  };
export const VehicleMakeQuestion: React.FC<Props> = (props) => {
  const { makeOptions, maskRequiredFieldError, setMaskRequiredFieldError, vehicleRef } = props;
  const { classes } = useStyles();
  const { make, model, series, vin, stubVin, vehicleDetailId, msrpPrice } =
    useVehicleFields(vehicleRef);
  const smartError = (error: string | undefined, value: AnswerValue): string | undefined => {
    if (!error || !maskRequiredFieldError) return error;

    return maskRequiredFieldError && !value ? undefined : error;
  };

  const { error: makeFieldError, ...makeProps } = make.props;
  const { setInfoError } = useVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
    msrpPrice,
  );
  const handleMakeChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      setMaskRequiredFieldError(true);
      setInfoError('');
      checkAndClearFields([model, series, vin, stubVin, vehicleDetailId]);
      make.props.actionOnChange(value);
    },
    [
      setMaskRequiredFieldError,
      setInfoError,
      model,
      series,
      vin,
      stubVin,
      vehicleDetailId,
      make.props,
    ],
  );

  if (!make.exists) return null;

  return (
    <GridItem topSpacing='sm' xs={12} className={classes.vehicleInfoSelect}>
      <Select
        error={smartError(makeFieldError, make.value)}
        {...makeProps}
        disabled={makeOptions.length === 0}
        id='VehicleMake'
        label='Vehicle make'
        actionOnChange={handleMakeChange}
        options={makeOptions}
        data-testid='make'
        trackingName='VehicleMake'
      />
    </GridItem>
  );
};
