import { useCallback, useState } from 'react';

import { Typography } from '@mui/material';

import { GridItem, RadioGroupWithOptions, Snackbar, SnackbarAlert } from '@ecp/components';
import { Dialog, Resource } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getAnswer } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, PageErrors } from '@ecp/features/sales/shared/types';

import { ProtectiveDeviceQuestion } from '../../../components';
import type { ProtectiveDevice } from '../../../utils';
import {
  useAddProtectiveDevice,
  useGetProtectiveDeviceFields,
  useProtectiveDevice,
  useProtectiveDevices,
  useRemoveAllProtectiveDevice,
  useRemoveProtectiveDevice,
} from '../../../utils';
import { useStyles } from './ProtectiveDeviceFormQuestion.styles';

interface Props extends QuestionProps {
  lob: string;
}

export const ProtectiveDeviceFormQuestion: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { lob } = props;

  const [protectiveDeviceRef, setProtectiveDeviceRef] = useState('');
  const [addingProtectiveDevice, setAddingProtectiveDevice] = useState(false);
  const [protectiveDeviceActionInfoMessage, setProtectiveDeviceActionInfoMessage] = useState('');
  const [editProtectiveDeviceRef, setEditProtectiveDeviceRef] = useState('');
  const [openProtectiveDeviceDialog, setOpenProtectiveDeviceDialog] = useState(false);
  const [pageErrors] = useState<PageErrors[]>([]);
  const [removeAllDeviceMsg, setRemoveAllDeviceMsg] = useState(false);
  const [protectiveDeviceToBeRemoved, setProtectiveDeviceToBeRemoved] =
    useState<ProtectiveDevice>();
  const [protectiveDeviceBeforeEdit, setProtectiveDeviceBeforeEdit] = useState<
    ProtectiveDevice | undefined
  >();
  const [protectiveDeviceActionInfoType, setProtectiveDeviceActionInfoType] = useState<
    'NONE' | 'ADD' | 'REMOVE'
  >('NONE');

  const currentProtectiveDevice = useProtectiveDevice(
    editProtectiveDeviceRef || protectiveDeviceRef,
  );

  const protectiveDeviceList = useProtectiveDevices();
  const ProtectiveDeviceFields = useGetProtectiveDeviceFields(
    editProtectiveDeviceRef || protectiveDeviceRef,
  );
  const removeProtectiveDevice = useRemoveProtectiveDevice();
  const removeAllProtectiveDevices = useRemoveAllProtectiveDevice();

  const addProtectiveDevice = useAddProtectiveDevice();

  const {
    protectiveDevice: { categoryType, deviceType, deviceName },
  } = ProtectiveDeviceFields;

  const protectiveDeviceRefsKey = 'property.protectiveDevice.ref';
  const protectiveDeviceRefsValue =
    (useSelector((state: RootStore) =>
      getAnswer(state, protectiveDeviceRefsKey),
    ) as Array<string>) || [];

  const [hasProtectiveDevice, setHasProtectiveDevice] = useState(
    protectiveDeviceRefsValue?.length > 0,
  );

  const ProtectiveDeviceByRef: { [key: string]: (typeof protectiveDeviceList)[number] } =
    protectiveDeviceList?.reduce(
      (acc: Record<string, (typeof protectiveDeviceList)[number]>, protectiveDevice) => {
        acc[protectiveDevice.ref] = protectiveDevice;

        return acc;
      },
      {},
    );

  const handleAddProtectiveDevice = useCallback(() => {
    setProtectiveDeviceRef(addProtectiveDevice());
    setAddingProtectiveDevice(true);
  }, [addProtectiveDevice]);

  const onRemoveProtectiveDevice = useCallback(
    (protectiveDeviceRef: string) => {
      const protectiveDevice = ProtectiveDeviceByRef[protectiveDeviceRef];
      setProtectiveDeviceToBeRemoved(protectiveDevice);
      setOpenProtectiveDeviceDialog(true);
      setRemoveAllDeviceMsg(false);
    },
    [ProtectiveDeviceByRef],
  );

  const onEditProtectiveDevice = useCallback(
    (protectiveDeviceRef: string) => {
      const protectiveDevice = ProtectiveDeviceByRef[protectiveDeviceRef];
      setEditProtectiveDeviceRef(protectiveDeviceRef);
      setProtectiveDeviceBeforeEdit({ ...protectiveDevice });
    },
    [ProtectiveDeviceByRef],
  );

  const onCloseDialogAction = useCallback(() => {
    setOpenProtectiveDeviceDialog(false);
  }, []);

  const onRemoveDialogAction = useCallback(() => {
    setAddingProtectiveDevice(false);
    setOpenProtectiveDeviceDialog(false);
    setProtectiveDeviceActionInfoMessage('Protective device removed.');
    setProtectiveDeviceActionInfoType('REMOVE');

    if (protectiveDeviceBeforeEdit) {
      categoryType.validateUpdateAndPatch(protectiveDeviceBeforeEdit.categoryType);
      deviceType.validateUpdateAndPatch(deviceType.value);
      deviceName.validateUpdateAndPatch(protectiveDeviceBeforeEdit.deviceName);
    } else {
      if (protectiveDeviceToBeRemoved) {
        removeProtectiveDevice(protectiveDeviceToBeRemoved);
        if (protectiveDeviceRefsValue.length === 1) {
          setHasProtectiveDevice(false);
        }
      } else {
        setHasProtectiveDevice(false);
        removeAllProtectiveDevices();
      }
      categoryType.validateUpdateAndPatch('');
      deviceType.validateUpdateAndPatch('');
      deviceName.validateUpdateAndPatch('');
    }
    setEditProtectiveDeviceRef('');
    setProtectiveDeviceBeforeEdit(undefined);
    setProtectiveDeviceToBeRemoved(undefined);
  }, [
    protectiveDeviceBeforeEdit,
    categoryType,
    deviceType,
    deviceName,
    protectiveDeviceToBeRemoved,
    removeProtectiveDevice,
    protectiveDeviceRefsValue.length,
    removeAllProtectiveDevices,
  ]);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setProtectiveDeviceActionInfoMessage('');
        setProtectiveDeviceActionInfoType('NONE');
      }
    },
    [],
  );

  const snackbarDefault = protectiveDeviceActionInfoType === 'REMOVE' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!protectiveDeviceActionInfoMessage}
      autoHideDuration={3000}
      message={protectiveDeviceActionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = protectiveDeviceActionInfoType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={protectiveDeviceActionInfoMessage}
      hideActionButton
    />
  );

  const removeDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText={
        removeAllDeviceMsg
          ? 'Remove all protective devices?'
          : `Remove ${protectiveDeviceToBeRemoved?.deviceName || 'protective device'}?`
      }
      textButtonLabel='Cancel'
      open={openProtectiveDeviceDialog}
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
    >
      {removeAllDeviceMsg ? (
        <div>All protective devices will be removed.</div>
      ) : (
        <div>{`${
          protectiveDeviceToBeRemoved?.deviceName || 'protective device'
        } will be removed.`}</div>
      )}
    </Dialog>
  );

  const handleCancelProtectiveDevice = useCallback(
    async (selectedProtectiveDeivceRef: string) => {
      // if cancel selected while adding device and if there are any data entered then have to show remove dialog.
      if (selectedProtectiveDeivceRef) {
        const protectiveDevice = ProtectiveDeviceByRef[selectedProtectiveDeivceRef];
        setProtectiveDeviceToBeRemoved(protectiveDevice);
      } else {
        // Dont show remove dialog if there are no data loss
        // cancel click while edit - Discard the edit changes and return to previous value
        if (editProtectiveDeviceRef && protectiveDeviceBeforeEdit) {
          categoryType.validateUpdateAndPatch(protectiveDeviceBeforeEdit.categoryType);
          if (protectiveDeviceBeforeEdit.deviceType) {
            const deviceTypeBeforeEdit = deviceType.question.options?.find(
              (option) => option.label === protectiveDeviceBeforeEdit.deviceType,
            )?.value;
            if (deviceTypeBeforeEdit) {
              deviceType.validateUpdateAndPatch(deviceTypeBeforeEdit);
            }
          }
          deviceName.validateUpdateAndPatch(protectiveDeviceBeforeEdit.deviceName);
        } else {
          removeProtectiveDevice(currentProtectiveDevice);
          if (protectiveDeviceRefsValue?.length === 1) setHasProtectiveDevice(false);
        }
        setProtectiveDeviceRef('');
        setEditProtectiveDeviceRef('');
        setAddingProtectiveDevice(false);
        setOpenProtectiveDeviceDialog(false);
        setProtectiveDeviceBeforeEdit(undefined);
        setProtectiveDeviceToBeRemoved(undefined);
      }
    },
    [
      ProtectiveDeviceByRef,
      categoryType,
      currentProtectiveDevice,
      deviceName,
      deviceType,
      editProtectiveDeviceRef,
      protectiveDeviceBeforeEdit,
      protectiveDeviceRefsValue?.length,
      removeProtectiveDevice,
    ],
  );

  const handleSaveProtectiveDevice = useCallback(() => {
    setAddingProtectiveDevice(false);
    setProtectiveDeviceRef('');
    setEditProtectiveDeviceRef('');
  }, []);

  const handleProtectiveDeviceChange = useCallback(
    async (value: AnswerValue) => {
      if (!value && hasProtectiveDevice) {
        // user changed from YES to NO, so show dialog to remove any protective device
        setOpenProtectiveDeviceDialog(true);
        protectiveDeviceRefsValue.length > 1
          ? setRemoveAllDeviceMsg(true)
          : setRemoveAllDeviceMsg(false);
      } else if (value && protectiveDeviceRefsValue.length < 1 && !hasProtectiveDevice) {
        // User clicked from NO to YES, so add an initial device
        setAddingProtectiveDevice(true);
        setProtectiveDeviceRef(addProtectiveDevice());
        setHasProtectiveDevice(!!value);
      }
    },
    [addProtectiveDevice, protectiveDeviceRefsValue.length, hasProtectiveDevice],
  );
  const renderProtectiveDeviceItem = useCallback(
    (item: string) => (
      <>
        <Resource.Item
          xs={4}
          title='Device Type'
          value={
            ProtectiveDeviceByRef[item]?.deviceType ? ProtectiveDeviceByRef[item].deviceType : ''
          }
        />
        <Resource.Item
          xs={7}
          title='Device Name'
          value={
            ProtectiveDeviceByRef[item]?.deviceName ? ProtectiveDeviceByRef[item].deviceName : ''
          }
        />
      </>
    ),
    [ProtectiveDeviceByRef],
  );

  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <h3>Safe, Secure, Smart Home Discount</h3>
        <Typography className={classes.heading}>
          Add any protective devices to qualify for a discount.
        </Typography>
      </GridItem>
      <GridItem bottomSpacing='sm' xs={12} className={classes.helperText}>
        <RadioGroupWithOptions
          actionOnComplete={handleProtectiveDeviceChange}
          value={hasProtectiveDevice}
          label={
            lob === 'renters'
              ? 'Does the rental unit have any protective devices?'
              : 'Does the home have any protective devices?'
          }
          id='haveProtectiveDevices'
          variant='yesNoButton'
          trackingName='protectiveDevices_selection'
        />
      </GridItem>

      {hasProtectiveDevice && (
        <>
          <Resource.List
            items={protectiveDeviceRefsValue}
            editItemRef={editProtectiveDeviceRef || protectiveDeviceRef}
            onEdit={onEditProtectiveDevice}
            onDelete={onRemoveProtectiveDevice}
            onCancel={handleCancelProtectiveDevice}
            onNext={handleSaveProtectiveDevice}
            pageErrors={pageErrors}
            // eslint-disable-next-line react/jsx-no-bind
            form={(formProps) => (
              <ProtectiveDeviceQuestion
                {...formProps}
                protectiveDeviceRef={protectiveDeviceRef}
                setAddingProtectiveDevice={setAddingProtectiveDevice}
                setProtectiveDeviceActionInfoMessage={setProtectiveDeviceActionInfoMessage}
                setProtectiveDeviceActionInfoType={setProtectiveDeviceActionInfoType}
                editProtectiveDeviceRef={editProtectiveDeviceRef}
                setOpenProtectiveDeviceDialog={setOpenProtectiveDeviceDialog}
                protectiveDeviceList={protectiveDeviceList}
              />
            )}
            renderListItem={renderProtectiveDeviceItem}
          />

          <Resource.AddButton
            onClick={handleAddProtectiveDevice}
            disabled={addingProtectiveDevice}
            data-testid='protectiveDeviceRefAdd'
          >
            add Another protective device
          </Resource.AddButton>
        </>
      )}
      {removeDialog}
      {snackbarDefault}
      {snackbarSuccess}
    </>
  );
};
