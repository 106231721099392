import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useRef } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isEmpty } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { GridItem, TextField } from '@ecp/components';
import { Resource, Select } from '@ecp/features/sales/shared/components';
import { setFormErrorsChangedByField, useForm } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { type ProtectiveDevice, useGetProtectiveDeviceFields } from '../../utils';
import { useStyles } from './ProtectiveDeviceQuestion.styles';
export interface ProtectiveDeviceQuestionProps {
  itemRef: string;
  protectiveDeviceRef: string;
  setAddingProtectiveDevice: Dispatch<SetStateAction<boolean>>;
  setProtectiveDeviceActionInfoMessage: Dispatch<SetStateAction<string>>;
  setProtectiveDeviceActionInfoType: Dispatch<SetStateAction<'NONE' | 'ADD' | 'REMOVE'>>;
  editProtectiveDeviceRef: string;
  setOpenProtectiveDeviceDialog: Dispatch<SetStateAction<boolean>>;
  protectiveDeviceList: Array<ProtectiveDevice>;
  onNext: () => void;
  onCancel: (protectiveDeviceRef: string) => void;
}

export const ProtectiveDeviceQuestion: React.FC<ProtectiveDeviceQuestionProps> = (props) => {
  const {
    itemRef: protectiveDeviceRef,
    setAddingProtectiveDevice,
    setProtectiveDeviceActionInfoMessage,
    setProtectiveDeviceActionInfoType,
    editProtectiveDeviceRef,
    setOpenProtectiveDeviceDialog,
    protectiveDeviceList,
    onNext,
    onCancel,
  } = props;

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const initValues = useRef({});

  const currentProtectiveDeviceRef = protectiveDeviceRef;
  const ProtectiveDeviceFields = useGetProtectiveDeviceFields(
    editProtectiveDeviceRef || currentProtectiveDeviceRef || '',
  );

  const {
    protectiveDevice: { categoryType, deviceType, deviceName },
  } = ProtectiveDeviceFields;

  const {
    validateForm: validateProtectiveDeviceForm,
    patchFormValues: patchProtectiveDeviceFormValues,
  } = useForm({
    fields: ProtectiveDeviceFields.protectiveDevice,
    initValues,
    conditions: [],
  });

  // protective device names without currect/open protective device form,
  // used to validate the current/open form device name is same as other saved form device names.
  // device name check should not consider case sensitive
  const protectiveDeviceNames = protectiveDeviceList
    .filter((device) => device.ref !== currentProtectiveDeviceRef)
    .map((device) => device.deviceName.toLowerCase().trim());

  const onValidateProtectiveDeviceFields = useCallback(
    (categoryType: Field, deviceName: Field) => {
      let isValid = true;
      if (!categoryType.value) {
        dispatch(
          setFormErrorsChangedByField({ key: categoryType.key, errors: ['Required field'] }),
        );
        isValid = false;
      }
      if (!deviceType.value) {
        dispatch(setFormErrorsChangedByField({ key: deviceType?.key, errors: ['Required field'] }));
        isValid = false;
      }
      if (!deviceName.value) {
        dispatch(
          setFormErrorsChangedByField({
            key: deviceName.key,
            errors: ['Device Name is required.'],
          }),
        );
        isValid = false;
      }

      return isValid;
    },
    [deviceType, dispatch],
  );

  const handleSaveDevice = useEvent(async () => {
    let validForm = validateProtectiveDeviceForm().isValid;

    validForm = onValidateProtectiveDeviceFields(categoryType, deviceName) && validateDeviceName();
    if (validForm) {
      await patchProtectiveDeviceFormValues();
      onNext();
      setProtectiveDeviceActionInfoMessage('Protective device saved.');
      setProtectiveDeviceActionInfoType('ADD');
    }
  });

  const handleCancel = useCallback(() => {
    if (categoryType.props.value || deviceType.props.value || deviceName.props.value) {
      if (editProtectiveDeviceRef) {
        setOpenProtectiveDeviceDialog(false);
        onCancel('');
      } else if (categoryType.props.value) {
        setOpenProtectiveDeviceDialog(true);
        onCancel(editProtectiveDeviceRef || currentProtectiveDeviceRef);
      }
    } else {
      setAddingProtectiveDevice(false);
      setOpenProtectiveDeviceDialog(false);
      onCancel('');
    }
  }, [
    categoryType.props.value,
    deviceType.props.value,
    deviceName.props.value,
    onCancel,
    editProtectiveDeviceRef,
    currentProtectiveDeviceRef,
    setOpenProtectiveDeviceDialog,
    setAddingProtectiveDevice,
  ]);

  const handleCategoryChange = useCallback(
    (categoryTypeValue: AnswerValue): void => {
      categoryType.props.actionOnChange(categoryTypeValue);
    },
    [categoryType.props],
  );

  // device name check should not consider case sensitive
  const validateDeviceName = useCallback(() => {
    if (deviceName.props.value.length > (deviceName.question.maxLength || 300)) {
      dispatch(
        setFormErrorsChangedByField({
          key: deviceName.key,
          errors: ['Enter a shorter Device Name.'],
        }),
      );

      return false;
    } else if (protectiveDeviceNames.indexOf(deviceName.props.value.toLowerCase().trim()) > -1) {
      dispatch(
        setFormErrorsChangedByField({
          key: deviceName.key,
          errors: ['Device Name must be unique.'],
        }),
      );

      return false;
    } else return true;
  }, [
    deviceName.key,
    deviceName.props.value,
    deviceName.question.maxLength,
    dispatch,
    protectiveDeviceNames,
  ]);

  const handleDeviceNameComplete = useCallback(
    async (value: string) => {
      await deviceName.props.actionOnComplete(value);
      if (isEmpty(value)) {
        dispatch(
          setFormErrorsChangedByField({
            key: deviceName.key,
            errors: ['Device Name is required.'],
          }),
        );
      } else if (isEmpty(deviceName.errors) && !isEmpty(value)) {
        validateDeviceName();
      }
    },
    [deviceName.errors, deviceName.key, deviceName.props, dispatch, validateDeviceName],
  );

  const renderProtectiveDevice = (): React.ReactElement => (
    <Grid container item xs={12} className={classes.protectiveDeviceQuestionContainer}>
      {categoryType.exists && (
        <GridItem topSpacing='sm' xs={12} md={6}>
          <Select
            {...categoryType.props}
            id='categoryType'
            inputButtonAriaLabel='Device Category'
            actionOnChange={handleCategoryChange}
            label='Device Category'
            trackingName='device_Category_selection'
          />
        </GridItem>
      )}

      {categoryType.value && deviceType.exists && (
        <GridItem topSpacing='sm' xs={12} md={12}>
          <Select
            {...deviceType.props}
            id='deviceType'
            inputButtonAriaLabel='Device Type'
            label='Device Type'
            data-testid='deviceType'
            trackingName='device_type_selection'
          />
        </GridItem>
      )}

      {categoryType.value && deviceName.exists && (
        <GridItem topSpacing='sm' xs={6} md={6}>
          <TextField
            {...deviceName.props}
            label='Device Name'
            name='deviceName'
            ariaLabel='deviceName'
            actionOnComplete={handleDeviceNameComplete}
          />
        </GridItem>
      )}

      <GridItem xs={12} topSpacing='sm' className={classes.actionButtons}>
        <Resource.CancelButton
          onClick={handleCancel}
          data-testid='protectiveDeviceCancel'
          trackingName='protectiveDevice_cancel_button'
          trackingLabel='protectiveDevice_cancel'
          analyticsElement='choice.protectiveDevice.cancelButton'
        >
          Cancel
        </Resource.CancelButton>
        <Resource.SaveButton
          onClick={handleSaveDevice}
          data-testid='protectiveDeviceSave'
          trackingName={GoogleAnalyticsLabels.CONTINUE}
          trackingLabel='protectiveDevice_save'
          analyticsElement='choice.protectiveDevice.saveButton'
        >
          SAVE DEVICE
        </Resource.SaveButton>
      </GridItem>
    </Grid>
  );

  return <>{renderProtectiveDevice()}</>;
};
