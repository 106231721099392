import { useEffect } from 'react';

import { Divider, Grid } from '@mui/material';

import { scrollToTop } from '@ecp/utils/web';

import type { CheckoutCardProps } from '@ecp/features/sales/checkout';
import { Form } from '@ecp/features/sales/shared/components';
import { getOfferProductsSelectedByType } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { AutoCoveragesForm } from '../../../lob/autoLine';
import { PropertyCoveragesForm } from '../../../lob/propertyLine';
import { PremiumBoxCard } from '../../components';
import { useCoveragesFormCoordinatedValidation, useIsCustomizeCoveragesEnabled } from '../../utils';
import { PolicyEffDateAndTermForm } from '../PolicyEffDateAndTermForm';
import { useStyles } from './CoveragesForm.styles';

export type CoveragesFormProps = { checkoutCardProps: CheckoutCardProps };

export const CoveragesForm: React.FC<CoveragesFormProps> = (props) => {
  const { checkoutCardProps } = props;
  const { classes } = useStyles();

  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const isCustomizeCoveragesEnabled = useIsCustomizeCoveragesEnabled?.();

  // TODO: look into this function i think its for bundle which maybe could be handled somewhere else
  const { handleAutoCoveragesFormValidChange, handlePropertyCoveragesFormValidChange, showError } =
    useCoveragesFormCoordinatedValidation();

  const updatedCheckoutCardProps = {
    ...checkoutCardProps,
    showError,
  };

  useEffect(() => {
    if (!updatedCheckoutCardProps.recalculate) scrollToTop();
  }, [updatedCheckoutCardProps.recalculate]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={updatedCheckoutCardProps.isProcessing} className={classes.formContainer}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.addSpacing}>
            <PolicyEffDateAndTermForm />
          </Grid>
          {autoOfferProduct && (
            <AutoCoveragesForm onFormValidChange={handleAutoCoveragesFormValidChange} />
          )}
          {propertyOfferProduct && (
            <PropertyCoveragesForm onFormValidChange={handlePropertyCoveragesFormValidChange} />
          )}
        </Grid>
        {isCustomizeCoveragesEnabled && (
          <>
            <Divider orientation='horizontal' className={classes.divider} />
            <p className={classes.disclaimer}>
              All coverages are subject to the terms, conditions, special limits, and exclusions of
              your policy and all applicable endorsements. In the event of any conflict between the
              policy and this coverage summary, the provisions of the policy shall prevail.
            </p>
          </>
        )}
      </Form>
      <PremiumBoxCard {...updatedCheckoutCardProps} />
    </div>
  );
};
