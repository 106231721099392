import { useCallback } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';

import { ThirdPartyReportsPageForm } from '../../forms';

export const ThirdPartyReportsPage: React.FC = () => {
  const navigateToNextPage = useNavigateToNextPage();

  const navigateToNext = useCallback(async () => {
    await navigateToNextPage();
  }, [navigateToNextPage]);

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.THIRD_PARTY_REPORTS} />,
        }}
        title='Third-Party Reports'
        analyticsElement='choice.thirdPartyReports.page'
      >
        <ThirdPartyReportsPageForm onNext={navigateToNext} />
      </Page>
    </div>
  );
};
