import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { castAnswerType } from '@ecp/utils/common';

import { GridItem } from '@ecp/components';
import {
  useAddConditionValues,
  useAddFields,
  useGetConditionValues,
  useInitValues,
} from '@ecp/features/sales/form';
import {
  Form,
  RadioGroupWithOptions,
  Resource,
  Select,
  TextField,
} from '@ecp/features/sales/shared/components';
import { getShownFields, useField, useForm } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { BusinessOnPremises } from '@ecp/features/sales/shared/types';
import type { Fields } from '@ecp/types';

import type { BusinessOnPremisesData } from './BusinessOnPremisesQuestion';
import { useStyles } from './BusinessOnPremisesQuestion.styles';

interface Props {
  itemRef: string;
  onCancel: (businessRef: string, businessFields: Fields) => void;
  onNext: () => void;
  setBusinessBeforeEdit: Dispatch<SetStateAction<BusinessOnPremisesData | undefined>>;
  businesses: BusinessOnPremises[];
}

export const BusinessOnPremisesForm: React.FC<Props> = (props) => {
  const {
    onCancel,
    onNext: onSave,
    itemRef: editBusinessTypeRef,
    setBusinessBeforeEdit,
    businesses,
  } = props;
  const { classes } = useStyles();
  const getCondition = useGetConditionValues();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const businessType = useField(`${editBusinessTypeRef}.businessType`);
  const businessDescription = useField(`${editBusinessTypeRef}.businessDescription`);
  const commercialCoverageInd = useField(`${editBusinessTypeRef}.commercialCoverageInd`);
  const employeeInd = useField(`${editBusinessTypeRef}.employeeInd`);
  const fields = {
    [businessType.key]: businessType,
    [businessDescription.key]: businessDescription,
    [commercialCoverageInd.key]: commercialCoverageInd,
    [employeeInd.key]: employeeInd,
  };

  const businessTypeInitValue = castAnswerType(
    businessType.question.defaultValue,
    businessType.question.answerType,
  );
  const commercialCoverageIndInitValue = castAnswerType(
    commercialCoverageInd.question.defaultValue,
    commercialCoverageInd.question.answerType,
  );
  const employeeIndInitValue = castAnswerType(
    employeeInd.question.defaultValue,
    employeeInd.question.answerType,
  );

  useInitValues({
    [businessType.key]: businessTypeInitValue,
    [commercialCoverageInd.key]: commercialCoverageIndInitValue,
    [employeeInd.key]: employeeIndInitValue,
  });

  useAddFields(fields);

  // Needed to use the hide attribute
  const dispatch = useDispatch();
  const filteredFields = dispatch(getShownFields({ fields })) as Fields;

  // Can only add a business type once
  const businessTypes = businesses
    .filter((business) => business.ref !== editBusinessTypeRef)
    .map((b) => b.businessType);
  const businessTypeOptions = businessType.question?.options?.filter(
    (option) => !businessTypes.includes(option.value),
  );

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    fields: {
      businessOnPremises: {
        businessType: businessType,
        businessDescription: businessDescription,
        commercialCoverageInd: commercialCoverageInd,
        employeeInd: employeeInd,
      },
    },
    conditions: getCondition(),
  });

  // There seems to be an edge case in the form validation when user selects business type of other,
  // then tries to submit without providing a description, then selects a different business type
  // and tries to submit again. The validation still seems to be hung up on the description field at that point.
  useAddConditionValues({
    conditionalFields: [businessDescription],
    isExcluded: () => !(businessType.value === 'OTHER'),
    isRequiredOverride: () => true,
  });

  const handleCancelBusinessOnPremises = useCallback((): void => {
    onCancel(editBusinessTypeRef, {
      businessType,
      businessDescription,
      commercialCoverageInd,
      employeeInd,
    });
  }, [
    onCancel,
    editBusinessTypeRef,
    businessType,
    businessDescription,
    commercialCoverageInd,
    employeeInd,
  ]);

  const handleSaveBusinessOnPremises = useCallback(async () => {
    const { isValid } = validateForm();
    if (isValid) {
      setIsSubmitting(true);
      await patchFormValues();
      onSave();
      setIsSubmitting(false);
    }
    trackClick({
      action: 'BusinessOnPremiseseModalSaveButton',
      label: `${businessType.value}`,
    });
  }, [businessType, onSave, patchFormValues, validateForm]);

  useEffect(() => {
    // Storing fields before user edits.
    setBusinessBeforeEdit({
      businessType: businessType.value,
      businessDescription: businessDescription.value,
      commercialCoverageInd: commercialCoverageInd.value,
      employeeInd: employeeInd.value,
    });
    // this executes when the form first opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessForm = (
    <Grid container item xs={12} spacing={4}>
      <GridItem xs={12}>
        <Typography variant='body4'>
          Tell us about the business, office, school, or studio.
        </Typography>
      </GridItem>
      <GridItem xs={12}>
        <Select
          {...businessType.props}
          id='businessType'
          groupLabel={<Typography variant='body1'>{businessType.question?.title}</Typography>}
          disabled={isPatchFormInProgress}
          trackingName='business_type_selection'
          options={businessTypeOptions}
          fullWidth={false}
        />
      </GridItem>
      {filteredFields && filteredFields[businessDescription.key] && (
        <GridItem xs={12}>
          <TextField
            {...businessDescription.props}
            id='businessDescription'
            label={<Typography variant='body1'>{businessDescription.question?.title}</Typography>}
            disabled={isPatchFormInProgress}
          />
        </GridItem>
      )}
      <GridItem xs={12}>
        <RadioGroupWithOptions
          {...commercialCoverageInd.props}
          id='commercialCoverage'
          label={<Typography variant='body1'>{commercialCoverageInd.question?.title}</Typography>}
          variant='yesNoButton'
          disabled={isPatchFormInProgress}
          trackingName='commercial_coverage_selection'
        />
      </GridItem>
      <GridItem xs={12}>
        <RadioGroupWithOptions
          {...employeeInd.props}
          id='employeeCoverage'
          label={<Typography variant='body1'>{employeeInd.question?.title}</Typography>}
          variant='yesNoButton'
          disabled={isPatchFormInProgress}
          trackingName='has_employee_selection'
        />
      </GridItem>
    </Grid>
  );

  return (
    <Form>
      {businessForm}
      <Grid container justifyContent='flex-end' className={classes.marginTop}>
        <GridItem>
          <Resource.CancelButton
            onClick={handleCancelBusinessOnPremises}
            trackingName='remove_business_type'
            trackingLabel='remove_business_type'
          >
            CANCEL
          </Resource.CancelButton>
          <Resource.SaveButton
            onClick={handleSaveBusinessOnPremises}
            isProcessing={isPatchFormInProgress || isSubmitting}
            trackingName='save_business_type'
            trackingLabel='save_business_type'
          >
            SAVE BUSINESS
          </Resource.SaveButton>
        </GridItem>
      </Grid>
    </Form>
  );
};
