import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getDriverInfo,
  getFullPniPersonInfo,
  getPersonInfo,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { DriverBasicInfo, OptionProps } from '@ecp/features/sales/shared/types';

interface Props extends QuestionProps {
  personRef: string;
}

export const RelationshipQuestion: React.FC<Props> = (props) => {
  const {
    personRef,
    label,
    id = 'RelationshipQuestion',
    dataTestId = 'relationshipQuestion',
    trackingName = 'relationshipQuestion',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    groupLabel,
  } = props;
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const relationshipStatus = usePersonField('relationshipToApplicant');
  const { maritalStatus: pniMaritalStatus } = useSelector(getFullPniPersonInfo); // pni marital status
  const driversInfo: DriverBasicInfo[] = useSelector(getDriverInfo);
  const { maritalStatus: driverMaritalStatus } = useSelector((state: RootStore) =>
    getPersonInfo(state, personRef),
  );

  const validSpousalMaritalOptions = ['Married', 'Separated'];
  useAddFields({ relationshipStatus });

  if (!relationshipStatus.exists) return null;

  let relationshipStatusOptions = relationshipStatus.props.options;
  const otherSpouseDrivers = driversInfo?.filter(
    (driver) =>
      driver.personRef !== personRef &&
      driver.relationshipToApplicant === 'RELATIONSHIP_TO_APPLICANT.SPOUSE',
  );

  if (
    !validSpousalMaritalOptions?.includes(pniMaritalStatus) ||
    !validSpousalMaritalOptions?.includes(driverMaritalStatus) ||
    otherSpouseDrivers.length > 0
  ) {
    relationshipStatusOptions = relationshipStatus.props.options?.filter(
      (status) => status.value !== 'RELATIONSHIP_TO_APPLICANT.SPOUSE',
    );
    // If PNI is changed from married to unmarried, the relationship option for spouse disappears,
    // but we also need to clear that selection from the store or else it won't trigger the required flag
    if (relationshipStatus.value === 'RELATIONSHIP_TO_APPLICANT.SPOUSE') {
      relationshipStatus.props.actionOnComplete(null);
    }
  }

  return (
    <Select
      {...(relationshipStatus.props as OptionProps)}
      fullWidth={false}
      groupLabel={groupLabel}
      label={label}
      id={id}
      inputButtonAriaLabel='Relationship'
      data-testid={dataTestId}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      options={relationshipStatusOptions}
    />
  );
};
