import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';

import { AutoAddTPIForm } from '../../../lob/autoLine';

export const AddThirdPartyInterestPage: React.FC = () => {
  return (
    <div>
      <Page
        title='Third-Party Interest (TPI)'
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.THIRD_PARTY_INTEREST} />,
        }}
      >
        <AutoAddTPIForm />
      </Page>
    </div>
  );
};
