import { useCallback, useEffect, useState } from 'react';

import { GridItem } from '@ecp/components';
import { DatePicker } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../../utils';

interface ConstructionDetailsProps extends QuestionProps {
  resetValues: boolean;
}
export const ConstructionStartDate: React.FC<ConstructionDetailsProps> = (props) => {
  const { resetValues } = props;

  const startDateField = usePropertyField('construction.constructionStartDate', true);

  const [isResetDone, setIsResetDone] = useState(false);

  const resetConstructionValues = useCallback((): void => {
    startDateField?.validateUpdateAndPatch(null);
    setIsResetDone(true);
  }, [startDateField]);

  useEffect(() => {
    if (resetValues && !isResetDone) {
      resetConstructionValues();
    }
  }, [resetValues, resetConstructionValues, isResetDone]);

  if (!startDateField) return null;

  const {
    label = startDateField.question?.title,
    trackingName = 'construction_start_date_question',
  } = props;

  return (
    <GridItem xs={12}>
      <DatePicker
        {...startDateField?.props}
        groupLabel={label}
        fullWidth={false}
        format='MM/DD/YYYY'
        placeholder='MM/DD/YYYY'
        autoComplete='off'
        id='ConstructionStartDate'
        trackingName={trackingName}
        data-testid={trackingName}
      />
    </GridItem>
  );
};
