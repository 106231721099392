import { Link } from '@ecp/features/sales/shared/components';
import { getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './AgreementContent.styles';
import metadata from './metadata';

export interface AgreementContentProps {
  className?: string;
  onNavigateToPrivacyPolicy?: () => void;
}

export const AgreementContent: React.FC<AgreementContentProps> = ({
  className,
  onNavigateToPrivacyPolicy,
}) => {
  const { classes } = useStyles();
  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const agreementContent = metadata.stateOptions?.[stateCode]?.agreementContent;

  const defaultAgreementContent = (
    <>
      To provide you with an accurate premium, information from consumer reporting agencies will be
      used. Please review your rights under the FCRA and our{' '}
      <Link component='button' onClick={onNavigateToPrivacyPolicy}>
        privacy policy
      </Link>
      .
    </>
  );

  return (
    <div className={className}>
      <p className={classes.agreementContent}>
        <strong>By clicking "Continue", I agree to the following:</strong>
        <br />
        {agreementContent || defaultAgreementContent}
      </p>
    </div>
  );
};
