import { useCallback } from 'react';

import { FormControlLabel, Grid } from '@mui/material';

import { castAnswerType } from '@ecp/utils/common';

import { GridItem, TooltipWithIcon } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Checkbox, Select } from '@ecp/features/sales/shared/components';
import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import { useStyles } from './JewelryCoverage.styles';

export const JewelryCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const { carrierName, product } = props;
  const coveragePrefix = `${carrierName}.${product}.coverages`;
  const limitField = useFieldWithPrefix(coveragePrefix)('jewelry.limit');
  const initValue = castAnswerType(
    limitField.question?.defaultValue,
    limitField.question.answerType,
  );
  useInitValues({ [limitField.key]: initValue });

  useAddFields({ [limitField.key]: limitField });

  // Jewelry Limit field options to displayed
  const limitFieldDisplayedOptions = limitField?.question?.options?.filter(
    (limitOption) => limitOption.value !== 'NoCoverage',
  );

  // No validation needed because the values are read only and user can't modify it
  const handleChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      if (!newChecked) {
        limitField?.update('NoCoverage');
        limitField?.patch('NoCoverage');
      } else {
        limitField?.update(initValue);
        limitField?.patch(initValue);
      }
    },
    [limitField, initValue],
  );

  if (!limitField.exists) return null;

  // The main coverage checkbox will be checked if the limit has the selected values
  const isChecked = !!limitFieldDisplayedOptions?.find(
    (limitOption) => limitOption.value === limitField.value,
  );

  const { title: limitTitle = '', helpText: limitHelpText } = limitField.question;

  const labelWithTooltip = (
    <div className={classes.label}>
      {limitTitle}
      {limitHelpText && <TooltipWithIcon className={classes.toolTip} title={limitHelpText} />}
    </div>
  );

  // TODO Probably with a new story - premium amount
  return (
    <div>
      <Grid container justifyItems='space-between'>
        <GridItem
          xs={12}
          className={isChecked ? classes.selectedMainCoverage : classes.mainCoverage}
        >
          <FormControlLabel
            control={
              <Checkbox
                className={classes.formControlCheckBox}
                id='jewelryCoverage'
                checked={isChecked}
                onChange={handleChange}
                trackingName='jewelryCoverage'
                trackingLabel={isChecked.toString()}
              />
            }
            label={
              <div className={classes.coverageName}>Jewelry, Gemstones, Watches, and Furs</div>
            }
            className={classes.formControlLabel}
          />
        </GridItem>
        {isChecked && (
          <Grid container item className={classes.subCoverageRoot}>
            <GridItem xs={12} className={classes.subCoverage}>
              <Select
                {...limitField.props}
                options={limitFieldDisplayedOptions}
                groupLabel={labelWithTooltip}
                className={classes.coveragesDropdown}
                labelId={`${limitField.props.name}-label`}
                id={limitField.props.name}
                value={limitField.props.value}
                actionOnChange={limitField.props.actionOnComplete}
                fullWidth
                trackingName={limitField.props.name}
                trackingLabel={limitField.props.value}
              />
            </GridItem>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
