import { useCallback, useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isEmpty } from '@ecp/utils/common';
import { scrollToTop } from '@ecp/utils/web';

import { Alert, GridItem, Snackbar, SnackbarAlert, TooltipWithIcon } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import {
  DRIVER_INTL,
  DRIVER_UNLICENSED,
  DriverType,
  getInvalidDlRefs,
  useAddDriver,
  useDeepRemoveDriver,
  useUpdateVehiclesAndDrivers,
  useVehiclesForSidebar,
} from '@ecp/features/sales/quotes/auto';
import { Button, Dialog, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { Resource } from '@ecp/features/sales/shared/components';
import {
  AUTO_POLICY_HOUSEHOLD_LOSSES,
  NavStatus,
  PrefillFlow,
} from '@ecp/features/sales/shared/constants';
import {
  fetchInquiry,
  getCurrentFlows,
  getDriverRefs,
  getDrivers,
  getDriversInfoByRef,
  getNonPniDrivers,
  getPersonInfo,
  getPreviousPage,
  getPrimaryInsuredPersonRef,
  getVehicleRefs,
  isAnyApiInProgress,
  updateAnswers,
  updatePageStatus,
  useFieldWithPrefix,
  useForm,
  usePniRef,
  useSniRef,
  validateQuestion,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, PageErrors, Person } from '@ecp/features/sales/shared/types';
import { useLoading } from '@ecp/features/sales/shared/utils/web';
import { SecondaryNamedInsuredDropdownQuestion } from '@ecp/features/sales/shell';
import type { Field, Fields } from '@ecp/types';

import { HouseholdPriorLossQuestion } from '../../../../common';
import { useGetDriverFieldsForValidations } from '../../utils';
import { useGetDriverAssignmentFields, useGetLineItems, useResetAllItems } from '../../utils';
import { AddEditDriverForm } from '../AddEditDriverForm';
import { useStyles } from './DriversPageForm.styles';

interface Props {
  onNext: () => Promise<void>;
  nextPageInstructions?: string;
}

export interface DriverFormSnapshot {
  firstName?: AnswerValue;
  lastName: AnswerValue;
  suffix: AnswerValue;
  middleName: AnswerValue;
  dateOfBirth: AnswerValue;
  maritalStatus: AnswerValue;
  gender: AnswerValue;
  relationshipToApplicant: AnswerValue;
  driverStatus: AnswerValue;
  licenseNumber: AnswerValue;
  licenseState: AnswerValue;
  sr22Filing: AnswerValue;
}

export const DriversPageForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const deepRemoveDriver = useDeepRemoveDriver();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const shouldWait = useSelector(isAnyApiInProgress);
  const flow = useSelector(getCurrentFlows);
  const flowAuto: PrefillFlow = flow.auto || PrefillFlow.LONG;
  const [fetchingInquiry, setFetchingInquiry] = useState(true);
  const drivers = useSelector(getDrivers);
  const noDriverStatusChoiceToMake = drivers.length < 3;
  const driverInfoByRef = useSelector(getDriversInfoByRef);
  const nonPNIdrivers = useSelector(getNonPniDrivers);
  const driverQuestions = useGetDriverFieldsForValidations(drivers, flowAuto);
  const invalidDlRefs = useSelector(getInvalidDlRefs);
  const [pageErrors, setPageErrors] = useState<PageErrors[]>([]);
  const [snackBarPageErrors, setSnackBarPageErrors] = useState<PageErrors[]>([]);
  const [driverId, setDriverId] = useState<string | undefined>(undefined);
  const { isLoading, withLoading } = useLoading(shouldWait);
  const addDriver = useAddDriver();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const getConditions = useGetConditionValues();
  const conditions = getConditions();
  const driverRefs = useSelector(getDriverRefs);
  const vehicleRefs = useSelector(getVehicleRefs);
  const pniPersonRef = useSelector(getPrimaryInsuredPersonRef);
  const pniRef = usePniRef();
  const driverFormFields = getFields();
  const { isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: driverFormFields,
    conditions,
  });
  const [isSnackbarAlertOpen, setIsSnackbarAlertOpen] = useState(false);
  const previousPage = useSelector(getPreviousPage);
  const [removeDriverRef, setRemoveDriverRef] = useState('');
  const [snackbarDefaultMessage, setSnackbarDefaultMessage] = useState('');
  const [showAutoAssignStatusDialog, setshowAutoAssignStatusDialog] = useState(false);
  const useRemoveDriverField = useFieldWithPrefix(removeDriverRef, 'driver.<id>');
  const sniFieldRef = 'secondaryInsured.person.ref';
  const secondaryNamedInsuredRef = useSniRef();

  // Get nonPNIPersons from nonPNIdrivers.
  const nonPNIPersons = useSelector((state: RootStore) => {
    return nonPNIdrivers.map((driver) => {
      const { firstName, lastName } = getPersonInfo(state, driver.personRef);

      return { firstName, lastName, ref: driver.personRef } as Person;
    });
  });

  const [driverDataBeforeEdit, setDriverDataBeforeEdit] = useState<
    DriverFormSnapshot | undefined
  >();

  useEffect(() => {
    dispatch(fetchInquiry({})).then(() => {
      setFetchingInquiry(false);
    });
  }, [dispatch]);

  const { updateVehiclesAndDrivers } = useUpdateVehiclesAndDrivers();

  const alertErrors = pageErrors.map((error) => {
    return (
      error.message && (
        <Alert className={classes.error} type='error' key={error.id}>
          <p>{error.message}</p>
        </Alert>
      )
    );
  });

  const [unevenMarried, setUnevenMarried] = useState(false);
  const [unevenDPCU, setUnevenDPCU] = useState(false);

  const vehicles = useVehiclesForSidebar();
  const driverAssignmentFields = useGetDriverAssignmentFields(vehicles, DriverType.PRIMARY);
  const occasionalDriverAssignmentFields = useGetDriverAssignmentFields(
    vehicles,
    DriverType.OCCASIONAL,
  );
  const lineItems = useGetLineItems(
    driverAssignmentFields,
    occasionalDriverAssignmentFields,
    drivers,
    vehicles,
  );
  const resetAssignments = useResetAllItems(lineItems);

  const checkDriversValidity = useCallback(() => {
    setPageErrors([]);
    // For advance UX we will be updating all of these banners to the snackbaralert that is sticky at the bottom of the page as scroll happens
    const legacyAlertErrors: PageErrors[] = [];

    drivers.forEach((driver) => {
      const { ref = '', personRef } = driver;

      const filteredQuestions = Object.keys(driverQuestions || {}).reduce((obj, key) => {
        if ((key.includes(ref) || key.includes(personRef)) && !key.includes('ref')) {
          obj[key] = driverQuestions[key];
        }

        return obj;
      }, {} as typeof driverQuestions);

      const filteredQuestion = filteredQuestions[ref] as Fields;

      Object.keys(filteredQuestion).forEach((filtered) => {
        const { question, value, key } = filteredQuestion[filtered] as Field;
        // add dl number validation result here as part of the field completion validator
        // to ensure that incomplete driver will be located properly
        let isDlValid = true;
        if (question.description === 'License number') {
          isDlValid = !invalidDlRefs.includes(ref);
        }
        const isValid = key.includes('.lapse')
          ? true
          : dispatch(validateQuestion({ question, value, questionKey: key }));
        if (!isValid || !isDlValid) {
          legacyAlertErrors.push({
            message: undefined,
            id: ref,
          });
        }
      });
    });

    setPageErrors(legacyAlertErrors);
    const snackBarAlertError = [];
    // At least one driver must be an operator
    if (drivers.every((driver) => driver.driverStatus !== 'OPERATOR')) {
      snackBarAlertError.push({
        message: 'At least one person must be listed as an Operator.',
        id: '',
      });
      setSnackBarPageErrors(snackBarAlertError);
      setIsSnackbarAlertOpen(true);
    }

    return legacyAlertErrors.length > 0 || snackBarAlertError.length > 0;
  }, [dispatch, driverQuestions, drivers, invalidDlRefs]);

  driverRefs.forEach((ref) => {
    // This undefined check for `driverQuestions[ref]` is needed
    // to avoid any issues while back navigation from driver page.
    if (driverQuestions[ref]) {
      const driverQuestionClone = JSON.parse(JSON.stringify(driverQuestions[ref]));

      driverQuestionClone['license.ageFirstLicensed'].question.required = false;
      if (driverQuestionClone['license.status'].value === DRIVER_UNLICENSED) {
        driverQuestionClone['license.ageFirstLicensed'].question.required = false;
        driverQuestionClone['license.number'].question.required = false;
        driverQuestionClone['license.state'].question.required = false;
      } else if (driverQuestionClone['license.status'].value === DRIVER_INTL) {
        driverQuestionClone['license.state'].question.required = false;
      }

      driverQuestions[ref] = driverQuestionClone;
    }
  });

  const subText =
    'If anyone on the policy is legally married and there are two or more drivers, their significant other must also be listed on the policy.';
  const subTextError =
    'Your quote must have an even number of married drivers if there are two or more drivers. Change the married status of a driver to continue.';

  const showError = unevenMarried || unevenDPCU;
  const subTextContent = showError ? subTextError : subText;

  const SubContent = (): React.ReactElement => {
    const tooltipText = subText;

    return (
      <span
        className={
          showError
            ? cx(classes.errorSubTextWrapper, classes.maritalWarning)
            : classes.subTextWrapper
        }
      >
        {showError && <strong>Edit marital status: </strong>}
        {subTextContent}
        {showError && <TooltipWithIcon title={tooltipText} />}
      </span>
    );
  };

  const toggleEditDriver = useCallback(
    (driverRef: string) => {
      // Don't allow edit click of another driver while form is up or loading
      if (isProcessing || isLoading || driverId) return;
      setIsProcessing(true);
      setIsEditMode(true);
      setDriverId(driverRef);

      if (driverInfoByRef[driverRef]?.driverStatus !== driverDataBeforeEdit?.driverStatus) {
        resetAssignments();
      }
      setIsProcessing(false);
    },
    [
      driverDataBeforeEdit?.driverStatus,
      driverId,
      driverInfoByRef,
      isLoading,
      isProcessing,
      resetAssignments,
    ],
  );

  const handleAddDriver = useCallback(async () => {
    // This avoids showing previous driver info in dialog
    setIsProcessing(true);
    const newDriver = addDriver();
    const { driverRef } = newDriver;
    await newDriver.done;
    setDriverId(driverRef);
    setIsProcessing(false);
    setIsEditMode(false); // adding a new driver so it's not an edit
  }, [addDriver]);

  const handleSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setIsSnackbarAlertOpen(false);
      }
    },
    [],
  );

  const snackBarAlertErrors = snackBarPageErrors.map((error) => {
    return (
      error.message && (
        <SnackbarAlert
          open={isSnackbarAlertOpen}
          vertical='bottom'
          horizontal='center'
          onClose={handleSnackbarClose}
          severity='error'
          message={error.message}
        />
      )
    );
  });

  const handleSaveDriver = useCallback(() => {
    let numMarriedTotal = 0;
    let numDPCUTotal = 0;
    drivers.forEach((driver) => {
      const { maritalStatus } = driver;

      if (maritalStatus === 'MARITAL_STATUS.MARRIED') {
        numMarriedTotal += 1;
        if (numMarriedTotal === 1 && drivers.length === 1) {
          setUnevenMarried(false);
        } else if (numMarriedTotal % 2 !== 0) {
          setUnevenMarried(true);
        } else {
          setUnevenMarried(false);
        }
      }

      if (maritalStatus === 'MARITAL_STATUS.DOMESTIC_PARTNER_CIVIL_UNION') {
        numDPCUTotal += 1;
        if (numDPCUTotal % 2 !== 0) {
          setUnevenDPCU(true);
        } else {
          setUnevenDPCU(false);
        }
      }

      if (numMarriedTotal === 0) {
        setUnevenMarried(false);
      }

      if (numDPCUTotal === 0) {
        setUnevenDPCU(false);
      }
    });

    const driverNamesList = drivers.map(
      (driver) =>
        `${driver.suffix || ''}${driver.firstName}${driver.middleName || ''}${driver.lastName}`,
    );

    // Check for drivers with same first name, last name, middle name and suffix
    const checkForDriversWithSameName = driverNamesList.some(function (item, idx) {
      const itemIndex = driverNamesList.indexOf(item);

      return itemIndex !== idx;
    });

    // Render alert based on the checkForDriversWithSameName.
    if (checkForDriversWithSameName) {
      const errors: PageErrors[] = [];
      errors.push({
        message:
          'Each driver’s full name must be unique. Please enter a middle initial or select a suffix.',
        id: '',
      });
      setSnackBarPageErrors(errors);
      setIsSnackbarAlertOpen(true);

      return;
    }

    if (
      noDriverStatusChoiceToMake &&
      drivers.every((driver) => driver.driverStatus !== 'OPERATOR')
    ) {
      setshowAutoAssignStatusDialog(true);

      return;
    }

    // Reset driver assignments whenever an operator is added.
    const driverInfo = drivers.find((driver) => driver.ref === driverId);
    if (driverInfo?.driverStatus === 'OPERATOR') {
      resetAssignments();
    }
    setPageErrors([]);
    setSnackBarPageErrors([]);
    setDriverId(undefined);
  }, [driverId, drivers, noDriverStatusChoiceToMake, resetAssignments]);

  const saveDrivers = useCallback(async () => {
    if (checkDriversValidity()) {
      scrollToTop();

      return;
    }
    setIsSubmitting(true);
    dispatch(updatePageStatus(NavStatus.VALID));

    setIsSubmitting(false);

    setIsProcessing(true);
    if (driverRefs.length > 0) {
      await updateVehiclesAndDrivers(vehicleRefs, driverRefs, [], []);

      dispatch(updatePageStatus(NavStatus.VALID));

      if (onNext) await onNext();
    } else {
      dispatch(updatePageStatus(NavStatus.INVALID));

      return;
    }
    setIsProcessing(false);
  }, [checkDriversValidity, dispatch, driverRefs, onNext, updateVehiclesAndDrivers, vehicleRefs]);

  const handleDeleteDriver = withLoading(async (driverRef: string) => {
    setRemoveDriverRef(driverRef);

    // Reset driver assignments whenever an operator is deleted.
    if (driverInfoByRef[driverRef]?.driverStatus === 'Operator') {
      resetAssignments();
    }
  });

  const handleCancelDriver = useCallback(
    async (driverRef: string, driverFields?: Fields) => {
      const {
        firstName,
        lastName,
        suffix,
        middleName,
        dateOfBirth,
        maritalStatus,
        gender,
        relationshipStatus,
        driverStatus,
        dlNumber,
        dlState,
        needFinancialForm,
      } = driverFields as Fields;
      if (!isEditMode) {
        // Don't show confirmation modal, on cancel, if any non-defaulted fields have values
        const showConfirmation = !!(
          firstName?.value ||
          lastName?.value ||
          suffix?.value ||
          middleName?.value ||
          dateOfBirth?.value ||
          maritalStatus?.value ||
          gender?.value ||
          relationshipStatus?.value ||
          dlNumber?.value
        );
        if (showConfirmation) {
          handleDeleteDriver(driverRef);
        } else {
          await deepRemoveDriver(driverRef);
          setRemoveDriverRef('');
          // close form
          setDriverId(undefined);
        }
      } else {
        setDriverId(undefined);
        // cancel functionality for each driver
        // reseting the driver values when user click on cancel even after edited the driver values.
        if (!isEmpty(driverFields)) {
          const firstNameField = firstName as Field;
          firstNameField.validateUpdateAndPatch(driverDataBeforeEdit?.firstName);

          const lastNameField = lastName as Field;
          lastNameField.validateUpdateAndPatch(driverDataBeforeEdit?.lastName);

          const middleNameField = middleName as Field;
          middleNameField.validateUpdateAndPatch(driverDataBeforeEdit?.middleName);

          const suffixField = suffix as Field;
          suffixField.validateUpdateAndPatch(driverDataBeforeEdit?.suffix);

          const dateOfBirthField = dateOfBirth as Field;
          dateOfBirthField.validateUpdateAndPatch(driverDataBeforeEdit?.dateOfBirth);

          const maritalStatusField = maritalStatus as Field;
          maritalStatusField.validateUpdateAndPatch(driverDataBeforeEdit?.maritalStatus);

          const genderField = gender as Field;
          genderField.validateUpdateAndPatch(driverDataBeforeEdit?.gender);

          const relationshipToApplicantField = relationshipStatus as Field;
          relationshipToApplicantField?.validateUpdateAndPatch(
            driverDataBeforeEdit?.relationshipToApplicant,
          );

          const driverStatusField = driverStatus as Field;
          driverStatusField.validateUpdateAndPatch(driverDataBeforeEdit?.driverStatus);

          const licenseNumberField = dlNumber as Field;
          licenseNumberField.validateUpdateAndPatch(driverDataBeforeEdit?.licenseNumber);

          const licenseStateField = dlState as Field;
          if (licenseStateField)
            licenseStateField.validateUpdateAndPatch(driverDataBeforeEdit?.licenseState);

          const sr22FilingField = needFinancialForm as Field;
          sr22FilingField.validateUpdateAndPatch(driverDataBeforeEdit?.sr22Filing);
        }
      }
    },
    [
      deepRemoveDriver,
      driverDataBeforeEdit?.dateOfBirth,
      driverDataBeforeEdit?.driverStatus,
      driverDataBeforeEdit?.firstName,
      driverDataBeforeEdit?.gender,
      driverDataBeforeEdit?.lastName,
      driverDataBeforeEdit?.licenseNumber,
      driverDataBeforeEdit?.licenseState,
      driverDataBeforeEdit?.maritalStatus,
      driverDataBeforeEdit?.middleName,
      driverDataBeforeEdit?.relationshipToApplicant,
      driverDataBeforeEdit?.sr22Filing,
      driverDataBeforeEdit?.suffix,
      handleDeleteDriver,
      isEditMode,
    ],
  );

  const shouldHideDeleteButton = useCallback(
    (id: string) => driverInfoByRef[id]?.personRef === pniRef,
    [driverInfoByRef, pniRef],
  );

  const renderDriverItem = useCallback(
    (item: string) => (
      <>
        <Resource.Item
          title='Driver Name'
          value={`${driverInfoByRef[item]?.firstName ?? ''} ${
            driverInfoByRef[item]?.lastName ?? ''
          }`}
        />
        <Resource.Item
          title='Date of Birth'
          value={
            driverInfoByRef[item]?.dateOfBirth &&
            dayjs(driverInfoByRef[item]?.dateOfBirth).format('MM/DD/YYYY')
          }
        />
        <Resource.Item
          title='License Number'
          value={driverInfoByRef[item]?.driversLicense.number}
        />
        <Resource.Item title='Gender' value={driverInfoByRef[item]?.gender} />
        <Resource.Item title='Operator Type' value={driverInfoByRef[item]?.driverStatus} />
      </>
    ),
    [driverInfoByRef],
  );

  useEffect(() => {
    // Check if the previous page was the address (PNI) page
    if (previousPage?.includes('/quote/person')) {
      // Set the driver ID to the PNI driver
      const pniDriverRef = driverRefs.find((ref) => {
        return (ref = pniPersonRef);
      });
      setDriverId(pniDriverRef);
      setIsEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseDialogAction = useCallback(() => {
    setRemoveDriverRef('');
    setshowAutoAssignStatusDialog(false);
  }, []);

  const removedDriversFirstName =
    drivers.find((driver) => driver.ref === removeDriverRef)?.firstName || 'Operator';
  // After removing driver, do we have an operator?
  const operatorExists = drivers
    .filter((driver) => driver.ref !== removeDriverRef)
    .some((driver) => driver.driverStatus === 'OPERATOR');
  const pniFirstName = drivers.find((driver) => driver.personRef === pniRef)?.firstName;

  const removedDriverPersonRef = useRemoveDriverField('person.ref');

  const getSnackBarMessage = useCallback(
    (isSniRemoved: boolean): string => {
      let message = `${removedDriversFirstName} removed.`;
      // Append below additional message to the snackbar if the driver being removed is also the SNI.
      if (isSniRemoved) {
        message = `${removedDriversFirstName} removed as a driver and secondary named insured.`;
      }

      return message;
    },
    [removedDriversFirstName],
  );

  const onRemoveDialogAction = useCallback(async () => {
    const isSniRemoved = secondaryNamedInsuredRef === removedDriverPersonRef.props.value;
    let snackBarMessage = getSnackBarMessage(isSniRemoved);
    // on remove dialog update pni driver to operator if there is no other operator
    const hasNoOtherOperators = drivers
      .filter((driver) => driver.ref !== removeDriverRef)
      .every((driver) => driver.driverStatus !== 'OPERATOR');

    // Only auto assign when there is no choice to make
    if (hasNoOtherOperators && noDriverStatusChoiceToMake) {
      const pniDriverRef = drivers.find((driver) => driver.personRef === pniRef)?.ref;
      await dispatch(updateAnswers({ answers: { [`${pniDriverRef}.driverStatus`]: 'OPERATOR' } }));
      snackBarMessage += ` ${pniFirstName} set to Operator.`;
    }

    setRemoveDriverRef('');
    await deepRemoveDriver(removeDriverRef);

    // close form
    setDriverId(undefined);
    setSnackbarDefaultMessage(snackBarMessage);
  }, [
    secondaryNamedInsuredRef,
    deepRemoveDriver,
    dispatch,
    drivers,
    getSnackBarMessage,
    noDriverStatusChoiceToMake,
    pniFirstName,
    pniRef,
    removeDriverRef,
    removedDriverPersonRef.props.value,
  ]);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setSnackbarDefaultMessage('');
      }
    },
    [],
  );

  const getMessage = (): string => {
    let baseMessage = `Removing ${removedDriversFirstName} will discard all associated content.`;
    // Append below additional message to the dialog if the driver being removed is also the SNI.
    if (secondaryNamedInsuredRef === removedDriverPersonRef.props.value) {
      baseMessage += ` ${removedDriversFirstName} will also be removed as the secondary named insured (SNI) for the Auto policy.`;
    }

    return baseMessage;
  };

  const removeDriverDialog = removeDriverRef && (
    <Dialog
      actionButtonLabel='REMOVE DRIVER'
      titleText={`Remove ${removedDriversFirstName}?`}
      textButtonLabel='Cancel'
      open
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
    >
      <p>{getMessage()}</p>
      {!operatorExists && noDriverStatusChoiceToMake && (
        <>
          <br />
          <p>{`As the only person on the policy, ${pniFirstName} will be listed as an Operator.`}</p>
        </>
      )}
    </Dialog>
  );

  const onSaveDriverAndAutoAssignStatus = useCallback(async () => {
    const otherDriverRef = drivers.find((driver) => driver.ref !== driverId)?.ref;
    await dispatch(updateAnswers({ answers: { [`${otherDriverRef}.driverStatus`]: 'OPERATOR' } }));
    setshowAutoAssignStatusDialog(false);
    setPageErrors([]);
    setSnackBarPageErrors([]);
    setDriverId(undefined);
  }, [dispatch, driverId, drivers]);

  const useDriverField = useFieldWithPrefix(driverId || '', 'driver.<id>');
  const driverStatus = useDriverField('driverStatus');
  const currentDriver = drivers.find((driver) => driver.ref === driverId);
  const otherDriver = drivers.find((driver) => driver.ref !== driverId);
  const roleLabel = driverStatus?.props?.options?.find(
    (opt) => opt.value === currentDriver?.driverStatus,
  )?.label;
  const aOrAn = roleLabel === 'Non-Operator' ? 'a' : 'an';
  const autoAssignStatusDialog = showAutoAssignStatusDialog && (
    <Dialog
      actionButtonLabel='SAVE DRIVER'
      titleText={`Make ${pniFirstName} an Operator?`}
      textButtonLabel='Cancel'
      open
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onSaveDriverAndAutoAssignStatus}
    >
      <div>{`By making ${currentDriver?.firstName} ${aOrAn} ${roleLabel}, ${otherDriver?.firstName} will be listed as an Operator.`}</div>
    </Dialog>
  );

  const snackbarDefault = (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!snackbarDefaultMessage}
      autoHideDuration={3000}
      message={snackbarDefaultMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  // TODO: Added snackBarAlertErrors. Eventualy we will be using snack bar alert over regular alerts.
  return (
    <div className={classes.root}>
      {autoAssignStatusDialog}
      {removeDriverDialog}
      {snackbarDefault}
      <Form showBackdrop={isSubmitting || isProcessing || isPatchFormInProgress}>
        <Grid container>
          <SubContent />
          {alertErrors}
          {snackBarAlertErrors}
          <Resource.List
            items={driverRefs}
            pageErrors={pageErrors}
            editItemRef={driverId}
            hideDelete={shouldHideDeleteButton}
            onEdit={toggleEditDriver}
            onDelete={handleDeleteDriver}
            onCancel={handleCancelDriver}
            onNext={handleSaveDriver}
            // eslint-disable-next-line react/jsx-no-bind
            form={(formProps) => (
              <AddEditDriverForm {...formProps} setDriverDataBeforeEdit={setDriverDataBeforeEdit} />
            )}
            renderListItem={renderDriverItem}
          />
          <Resource.AddButton
            onClick={handleAddDriver}
            data-testid='autoDriverAdd'
            isProcessing={isProcessing}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='driver_details_page_continue'
            analyticsElement='choice.driverDetailsPage.continueButton'
            disabled={!!driverId || isLoading}
          >
            Add Another Driver
          </Resource.AddButton>
          <>
            <GridItem topSpacing='lg' xs={12}>
              <SecondaryNamedInsuredDropdownQuestion
                sniQuestionLabel='Does the Auto policy need a secondary named insured (SNI)?'
                secondaryText='The SNI must be listed as a driver on the policy.'
                sniFieldRef={sniFieldRef}
                nonPNIPersons={nonPNIPersons}
              />
            </GridItem>
            <GridItem topSpacing='lg' xs={12}>
              <HouseholdPriorLossQuestion
                priorLossKey={AUTO_POLICY_HOUSEHOLD_LOSSES}
                priorLossHelpLabel='Has any member of the household had an auto incident in the past 5 years?'
                secondaryText='Incidents include accidents, tickets, or claims.'
              />
            </GridItem>
            <Grid item xs={12}>
              <NextPageInstructions divider />
            </Grid>
            <Grid className={classes.buttonsPanel} container item xs={12}>
              <div className={classes.addButton}>
                <Button
                  className={classes.next}
                  variant='primary'
                  onClick={saveDrivers}
                  disabled={
                    !!driverId ||
                    fetchingInquiry ||
                    unevenMarried ||
                    unevenDPCU ||
                    pageErrors.length > 0
                  }
                  data-testid='driverFormContinue'
                  isProcessing={isPatchFormInProgress || isSubmitting}
                  trackingName={GoogleAnalyticsLabels.CONTINUE}
                  trackingLabel='driver_details_continue'
                  analyticsElement='choice.driverDetailsPage.continueButton'
                >
                  Continue
                </Button>
              </div>
            </Grid>
          </>
        </Grid>
      </Form>
    </div>
  );
};
