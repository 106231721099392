import { useCallback, useEffect, useMemo, useState } from 'react';

import { CircularProgress, Grid } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { isEmpty, isMasked } from '@ecp/utils/common';
import { useBackAndForwardNav } from '@ecp/utils/routing';
import { scrollToTop } from '@ecp/utils/web';

import { Alert, Snackbar } from '@ecp/components';
import {
  checkDuplicateVIN,
  DriverType,
  useAddVehicle,
  useRemoveUnusedRefsForVehicle,
  useUpdateVehiclesAndDrivers,
  useVehicleRef,
  useVehiclesFields,
  useVehiclesForSidebar,
} from '@ecp/features/sales/quotes/auto';
import {
  Button,
  Dialog,
  Form,
  NextPageInstructions,
  Resource,
} from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import {
  fetchInquiry,
  getDriverRefs,
  getDrivers,
  getVehicleRefs,
  getVehiclesInfoByRef,
  isAnyApiInProgress,
  updatePageStatus,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { PageErrors } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { goToFirstError, useLoading } from '@ecp/features/sales/shared/utils/web';
import type { AnswerValue, Field, Fields } from '@ecp/types';

import { useGetDriverAssignmentFields, useGetLineItems, useResetAllItems } from '../../utils';
import { AddEditVehicleForm } from '../AddEditVehicleForm';
import { useStyles } from './VehiclesPageForm.styles';

export interface AutoProfileFields extends Fields {
  vehicleRefs: Field;
  driverRefs: Field;
}

interface Props {
  onNext: () => Promise<void>;
  nextPageInstructions?: string;
}

export interface VehicleFormSnapshot {
  vin: AnswerValue;
  year: AnswerValue;
  make: AnswerValue;
  model: AnswerValue;
  series: AnswerValue;
  primaryUse: AnswerValue;
  vehicleComprehensive: AnswerValue;
  coOwnsVehicle: AnswerValue;
  kitCarReplica: AnswerValue;
  keptAtRiskAddress: AnswerValue;
  antiTheftActive: AnswerValue;
  antiTheftPassive: AnswerValue;
  annualMiles: AnswerValue;
  safetyFeatures: AnswerValue;
  airbagPassenger: AnswerValue;
  airbagDriver: AnswerValue;
  airbagSide: AnswerValue;
  antiLockBrakes: AnswerValue;
  dayTimeRunningLights: AnswerValue;
  electronicStabilityControl: AnswerValue;
}

export const VehiclesPageForm: React.FC<Props> = (props) => {
  const { onNext, nextPageInstructions } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [fetchingInquiry, setFetchingInquiry] = useState(true);
  const addVehicle = useAddVehicle();
  const [maskRequiredFieldError, setMaskRequiredFieldError] = useState(true);
  const [vehicleId, setVehicleId] = useState<string | undefined>(undefined);
  const [savedVehicle, setSavedVehicle] = useState<boolean>(false);
  const currentVehicleRef = useVehicleRef(vehicleId) || '';
  const keptAtRiskAddress = useField(`${currentVehicleRef}.keptAtRiskAddress`);
  const vehicleItems = useSelector(getVehiclesInfoByRef);
  const vehicleRefs = useSelector(getVehicleRefs);
  const getVehicles = useVehiclesFields(vehicleRefs);
  const drivers = useSelector(getDrivers);
  const driverRefs = useSelector(getDriverRefs);
  const { updateVehiclesAndDrivers } = useUpdateVehiclesAndDrivers();
  const removeUnusedVehicleRef = useRemoveUnusedRefsForVehicle();
  const [pageErrors, setPageErrors] = useState<PageErrors[]>([]);
  const [removeVehicleRef, setRemoveVehicleRef] = useState('');
  const [snackbarDefaultMessage, setSnackbarDefaultMessage] = useState('');
  const [vehicleDataBeforeEdit, setVehicleDataBeforeEdit] = useState<
    VehicleFormSnapshot | undefined
  >();

  // Make sure not to run any action while previous one is still in flight, like multiple clicks on delete
  const shouldWait = useSelector(isAnyApiInProgress);
  const { isLoading, withLoading } = useLoading(shouldWait);

  const vehiclesValues = useMemo((): string[] => {
    return vehicleRefs.reduce<string[]>((preValues, ref) => {
      return [...preValues, ref];
    }, []);
  }, [vehicleRefs]);

  const vehicles = useVehiclesForSidebar();
  const driverAssignmentFields = useGetDriverAssignmentFields(vehicles, DriverType.PRIMARY);
  const occasionalDriverAssignmentFields = useGetDriverAssignmentFields(
    vehicles,
    DriverType.OCCASIONAL,
  );
  const lineItems = useGetLineItems(
    driverAssignmentFields,
    occasionalDriverAssignmentFields,
    drivers,
    vehicles,
  );
  const resetAssignments = useResetAllItems(lineItems);

  const checkIncompleteVehicles = useCallback(() => {
    setPageErrors([]);
    const errors: PageErrors[] = [];
    const vehicles = getVehicles;

    Object.values(vehicleItems).forEach((vehicle) => {
      const { ref = '', description } = vehicle;

      const hasDuplicateVin = checkDuplicateVIN(vehicles, vehicle.vin, ref);
      if (hasDuplicateVin) {
        errors.push({
          message: `${description} has a duplicate VIN.`,
          id: ref,
        });
      }

      // We need to stop a user from continuing forward if the vin is masked but isnt equal to 17 chars
      if (isMasked(vehicle.vin) && vehicle.vin?.length !== 17) {
        errors.push({
          message: `${description}: VIN is incomplete.`,
          id: ref,
        });
      }
    });
    setPageErrors(errors);

    return errors.length > 0;
    // we don't want to re-render for vehicleQuestions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleItems, classes, dispatch]);

  // TODO: I moved the logic from old page but not sure if we'll need it
  // Old page had incomplete status on card option
  // Keeping for now until further UX discussions
  const alertErrors = pageErrors.map((error) => {
    return (
      error.message && (
        <Alert className={classes.error} type='error' key={error.id}>
          <p>{error.message}</p>
        </Alert>
      )
    );
  });

  const toggleEditVehicle = useCallback(
    (vehicleRef: string) => {
      // Don't allow edit click of another vehicle while form is up or loading
      if (isProcessing || isLoading || vehicleId) return;
      setIsProcessing(true);
      setIsEditMode(true);
      setVehicleId(vehicleRef);
      setIsProcessing(false);
    },
    [isLoading, isProcessing, vehicleId],
  );

  const handleSaveVehicle = useCallback(() => {
    setSavedVehicle(true);

    // Reset Assignment when a vehicle is added but not in case of any edit and save
    if (vehicleId && !isEditMode) {
      resetAssignments();
    }

    setVehicleId(undefined);
  }, [isEditMode, resetAssignments, vehicleId]);

  // getNonDefaultValues for vehicle page form
  const getNonDefaultValues = (vehicleRef: string, fields: Fields): AnswerValue[] => {
    const values = [
      fields.vin?.value,
      fields.year?.value,
      fields.make?.value,
      fields.model?.value,
      fields.series?.value,
      fields.primaryUse?.value,
      fields?.[`${vehicleRef}.features.safety.airbag.driver`]?.value,
      fields?.[`${vehicleRef}.features.safety.airbag.passenger`]?.value,
      fields?.[`${vehicleRef}.features.safety.airbag.side`]?.value,
      fields?.[`${vehicleRef}.features.safety.antiLockBrakes`]?.value,
      fields?.[`${vehicleRef}.features.safety.dayTimeRunningLights`]?.value,
      fields?.[`${vehicleRef}.features.safety.electronicStabilityControl`]?.value,
    ];

    return values.filter((value): value is AnswerValue => value != null);
  };

  const handleDeleteVehicle = withLoading<string>(
    async (vehicleRef: string, vehicleFields?: Fields) => {
      let showConfirmation = true;

      if (vehicleFields) {
        const nonDefaultValues = getNonDefaultValues(vehicleRef, vehicleFields as Fields);

        // Show confirmation modal if any non-defaulted fields have values
        showConfirmation = nonDefaultValues.length > 0;
      }

      trackClick({ action: 'delete_vehicle_button', label: vehicleRef });

      trackSapiAnalyticsEvent({
        element: 'choice.reviewVehicleAndDriverPage.DeleteVehicleButton',
        event: 'click',
        eventDetail: vehicleRef,
      });

      if (showConfirmation) {
        setRemoveVehicleRef(vehicleRef);
      } else {
        await removeUnusedVehicleRef(vehicleRef);
        // Reset vehicle assignments when a vehicle is deleted.
        resetAssignments();
        // Close form
        setVehicleId(undefined);
      }
    },
  );

  // TODO: answers are patched on change of the fields, there is not backing out changes on an edit; discuss with IAN
  const handleCancelVehicle = useCallback(
    async (vehicleRef: string, vehicleFields?: Fields) => {
      // Helper function to validate, update, and patch fields
      const validateUpdateAndPatchField = (field: Field | undefined, value: AnswerValue): void => {
        if (field) {
          field.validateUpdateAndPatch(value);
        }
      };
      const {
        vin,
        year,
        make,
        model,
        series,
        primaryUse,
        vehicleComprehensive,
        coOwnsVehicle,
        kitCarReplica,
        keptAtRiskAddress,
        annualMiles,
        antiTheftActive,
        antiTheftPassive,
        safety,
      } = vehicleFields as Fields;
      if (!isEditMode) {
        // Get non-default values
        const nonDefaultValues = getNonDefaultValues(vehicleRef, vehicleFields as Fields);

        // show confirmation modal, on cancel, if any non-defaulted fields have values
        const showConfirmation = nonDefaultValues.length > 0;

        if (showConfirmation) {
          handleDeleteVehicle(vehicleRef);
        } else {
          await removeUnusedVehicleRef(vehicleRef);
          setRemoveVehicleRef('');
          // Close form
          setVehicleId(undefined);
        }
      } else {
        // Close form if in edit mode
        setVehicleId(undefined);

        // cancel functionality for each vehicle
        // reseting the vehicle values when user click on cancel even after edited the vehicle values.
        if (!isEmpty(vehicleFields)) {
          validateUpdateAndPatchField(vin as Field, vehicleDataBeforeEdit?.vin);
          validateUpdateAndPatchField(year as Field, vehicleDataBeforeEdit?.year);
          validateUpdateAndPatchField(make as Field, vehicleDataBeforeEdit?.make);
          validateUpdateAndPatchField(model as Field, vehicleDataBeforeEdit?.model);
          validateUpdateAndPatchField(series as Field, vehicleDataBeforeEdit?.series);
          validateUpdateAndPatchField(primaryUse as Field, vehicleDataBeforeEdit?.primaryUse);
          validateUpdateAndPatchField(
            vehicleComprehensive as Field,
            vehicleDataBeforeEdit?.vehicleComprehensive,
          );
          validateUpdateAndPatchField(coOwnsVehicle as Field, vehicleDataBeforeEdit?.coOwnsVehicle);
          validateUpdateAndPatchField(kitCarReplica as Field, vehicleDataBeforeEdit?.kitCarReplica);
          validateUpdateAndPatchField(annualMiles as Field, vehicleDataBeforeEdit?.annualMiles);
          validateUpdateAndPatchField(
            antiTheftActive as Field,
            vehicleDataBeforeEdit?.antiTheftActive,
          );
          validateUpdateAndPatchField(
            antiTheftPassive as Field,
            vehicleDataBeforeEdit?.antiTheftPassive,
          );
          validateUpdateAndPatchField(
            keptAtRiskAddress as Field,
            vehicleDataBeforeEdit?.keptAtRiskAddress,
          );
          validateUpdateAndPatchField(
            vehicleFields?.[`${vehicleRef}.features.safety.airbag.driver`] as Field,
            vehicleDataBeforeEdit?.airbagDriver,
          );
          validateUpdateAndPatchField(
            vehicleFields?.[`${vehicleRef}.features.safety.airbag.passenger`] as Field,
            vehicleDataBeforeEdit?.airbagPassenger,
          );
          validateUpdateAndPatchField(
            vehicleFields?.[`${vehicleRef}.features.safety.airbag.side`] as Field,
            vehicleDataBeforeEdit?.airbagSide,
          );
          validateUpdateAndPatchField(
            vehicleFields?.[`${vehicleRef}.features.safety.antiLockBrakes`] as Field,
            vehicleDataBeforeEdit?.antiLockBrakes,
          );
          validateUpdateAndPatchField(
            vehicleFields?.[`${vehicleRef}.features.safety.dayTimeRunningLights`] as Field,
            vehicleDataBeforeEdit?.dayTimeRunningLights,
          );
          validateUpdateAndPatchField(
            vehicleFields?.[`${vehicleRef}.features.safety.electronicStabilityControl`] as Field,
            vehicleDataBeforeEdit?.electronicStabilityControl,
          );
          validateUpdateAndPatchField(safety as Field, vehicleDataBeforeEdit?.safetyFeatures);
        }
      }
    },
    [handleDeleteVehicle, isEditMode, removeUnusedVehicleRef, vehicleDataBeforeEdit],
  );

  const saveVehicles = useCallback(async () => {
    setIsSubmitting(true);
    if (checkIncompleteVehicles()) {
      scrollToTop();

      return;
    }
    if (vehiclesValues.length > 0) {
      await updateVehiclesAndDrivers(vehiclesValues, driverRefs, [], []);
      dispatch(updatePageStatus(NavStatus.VALID));
      await onNext();
    } else {
      dispatch(updatePageStatus(NavStatus.INVALID));
      goToFirstError();
    }
    setIsSubmitting(false);
    // we don't want to re-render for driverQuestions
  }, [
    checkIncompleteVehicles,
    vehiclesValues,
    updateVehiclesAndDrivers,
    driverRefs,
    dispatch,
    onNext,
  ]);

  const handleAddVehicle = useCallback(async () => {
    setIsProcessing(true);
    trackSapiAnalyticsEvent({
      element: 'choice.reviewVehicleAndDriverPage.AddAnotherVehicleButton',
      event: 'click',
      eventDetail: 'true',
    });

    // This avoids showing previous vehicle info in dialog
    const newVehicle = addVehicle();
    const { vehicleRef } = newVehicle;
    await newVehicle.done;

    keptAtRiskAddress.reset();
    trackClick({ action: 'add_vehicle_link', label: vehicleRef });
    setVehicleId(vehicleRef);
    setIsProcessing(false);
    setIsEditMode(false); // adding a new vehicle so it's not an edit
  }, [addVehicle, keptAtRiskAddress]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  // On back navigation remove ref for vehicle and delete all answers related to the new ref.
  // Since for every new vehicle we initialize the page with new vehicle ref and patch it as answers.
  // We will lose all data for that particular vehicle (as per the requirements)
  useBackAndForwardNav(async () => {
    if (!isEditMode && vehicleId) await removeUnusedVehicleRef(vehicleId);
  });

  // Controls the whether the edit vehicle form is open
  useEffect(() => {
    if (!vehicleId && !savedVehicle) {
      if (!vehicleRefs.length) {
        setVehicleId(vehiclesValues[0]); // preexisting check
      } else {
        // if the vehicle refs exists, and no vehicle has been saved
        if (!vehicleItems[vehicleRefs[vehicleRefs.length - 1]]?.year) {
          // check if there is a value for year of the latest vehcicle and if no year, open the form
          setVehicleId(vehicleRefs[vehicleRefs.length - 1]);
        }
      }
    }
  }, [vehicleId, vehicleRefs, vehiclesValues, savedVehicle, vehicleItems]);

  useEffect(() => {
    dispatch(fetchInquiry({})).then(() => {
      setFetchingInquiry(false);
    });
  }, [dispatch]);

  const renderVehicleItem = useCallback(
    (item: string) => (
      <>
        <Resource.Item
          xs={4}
          title='Year, Make, Model, and Series'
          value={`${vehicleItems[item]?.year ?? ''} ${vehicleItems[item]?.make ?? ''} ${
            vehicleItems[item]?.model ?? ''
          }`}
        />
        <Resource.Item xs={3} title='VIN' value={vehicleItems[item]?.vin} />
        <Resource.Item
          xs={2}
          title='Annual Miles'
          value={
            vehicleItems[item]?.annualMiles
              ? (+vehicleItems[item].annualMiles).toLocaleString()
              : ''
          }
        />
      </>
    ),
    [vehicleItems],
  );

  const onCloseDialogAction = useCallback(() => {
    setRemoveVehicleRef('');
  }, []);

  const removedVehicleInfo = vehicleItems[removeVehicleRef];
  const getRemovedVehicleName = (removedVehicleInfo?: {
    year?: string;
    make?: string;
    model?: string;
  }): string => {
    if (removedVehicleInfo?.year && removedVehicleInfo?.make && removedVehicleInfo?.model) {
      return `${removedVehicleInfo.year} ${removedVehicleInfo.make} ${removedVehicleInfo.model}`;
    }

    return 'vehicle';
  };

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const removedVehicleName = getRemovedVehicleName(removedVehicleInfo);
  const onRemoveDialogAction = useCallback(async () => {
    const snackBarMessage = `${removedVehicleName} removed.`;

    setRemoveVehicleRef('');
    await removeUnusedVehicleRef(removeVehicleRef);

    resetAssignments();

    // close form
    setVehicleId(undefined);
    setSnackbarDefaultMessage(capitalizeFirstLetter(snackBarMessage));
  }, [removeUnusedVehicleRef, removeVehicleRef, removedVehicleName, resetAssignments]);

  const removeVehicleDialog = removeVehicleRef && (
    <Dialog
      actionButtonLabel='REMOVE VEHICLE'
      titleText={`Remove ${removedVehicleName}?`}
      textButtonLabel='Cancel'
      open
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
    >
      <p>{`Removing the ${removedVehicleName} will discard all associated content.`}</p>
    </Dialog>
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setSnackbarDefaultMessage('');
      }
    },
    [],
  );

  const snackbarDefault = (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!snackbarDefaultMessage}
      autoHideDuration={3000}
      message={snackbarDefaultMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  return (
    <div className={classes.root}>
      {removeVehicleDialog}
      {snackbarDefault}
      {isLoading || (isProcessing && <CircularProgress />)}
      <Form showBackdrop={isSubmitting || isProcessing}>
        {alertErrors}
        <Resource.List
          items={vehicleRefs}
          pageErrors={pageErrors}
          editItemRef={vehicleId}
          onEdit={toggleEditVehicle}
          onDelete={handleDeleteVehicle}
          onCancel={handleCancelVehicle}
          onNext={handleSaveVehicle}
          // eslint-disable-next-line react/jsx-no-bind
          form={(formProps) => (
            <AddEditVehicleForm
              {...formProps}
              setVehicleDataBeforeEdit={setVehicleDataBeforeEdit}
              isEdit={isEditMode}
              maskRequiredFieldError={maskRequiredFieldError}
              setMaskRequiredFieldError={setMaskRequiredFieldError}
            />
          )}
          renderListItem={renderVehicleItem}
        />
        <Resource.AddButton
          onClick={handleAddVehicle}
          data-testid='autoVehicleAdd'
          isProcessing={isProcessing}
          trackingName={GoogleAnalyticsLabels.CONTINUE}
          trackingLabel='vehicle_details_page_continue'
          analyticsElement='choice.vehicleDetailsPage.continueButton'
          disabled={!!vehicleId || isLoading}
        >
          Add Another Vehicle
        </Resource.AddButton>
        <Grid container>
          <>
            <Grid item xs={12}>
              {!fetchingInquiry && (
                <NextPageInstructions divider>{nextPageInstructions}</NextPageInstructions>
              )}
            </Grid>
            <Grid className={classes.buttonsPanel} container item xs={12}>
              <div className={classes.addButton}>
                <Button
                  className={classes.next}
                  variant='primary'
                  onClick={saveVehicles}
                  data-testid='autoVehicleContinue'
                  // This stops the onBlur from firing only when this button is clicked
                  // and the button retains its coordinates for the mouseup to eventually
                  // register it as a click event. If you want the onBlur as well, you can
                  // fire it yourself from the onClick handler. Touch should keep working.
                  onMouseDown={handleMouseDown}
                  isProcessing={isSubmitting}
                  trackingName={GoogleAnalyticsLabels.CONTINUE}
                  trackingLabel='vehicle_details_page_continue'
                  analyticsElement='choice.vehicleDetailsPage.continueButton'
                  disabled={!!vehicleId || fetchingInquiry} // disable continue while form group is up
                >
                  Continue
                </Button>
              </div>
            </Grid>
          </>
        </Grid>
      </Form>
    </div>
  );
};
