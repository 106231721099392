import { Typography } from '@mui/material';

import { Select, Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import { usePropertyField } from '../../utils/usePropertyField';

export const HomeBasementFinishedPercentageQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('foundation.percentageFinished');

  if (!field) return null;

  const {
    label = field.question?.title,
    trackingName = 'home_basement_finished_percentage',
    displayType = 'Slider',
  } = props;

  switch (displayType) {
    case 'Select':
      return (
        <Select
          {...field.props}
          id='HomeBasementFinishedPercentage'
          groupLabel={<Typography variant='body4'>{label}</Typography>}
          trackingName={trackingName}
          inputButtonAriaLabel='homeBasementFinishedPercentage'
          fullWidth={false}
        />
      );
    case 'Slider':
      return (
        <Slider
          {...(field.props as OptionProps)}
          id='HomeBasementFinishedPercentage'
          groupLabel={label}
          trackingName={trackingName}
        />
      );
    default:
      return null;
  }
};
