import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { GridItem, Snackbar } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Dialog, RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  updateAnswers,
  useCheckboxNoPatchGroupSelectionsCleanUp,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Answers } from '@ecp/features/sales/shared/types';
import type { AnswerValue } from '@ecp/types';

import { safetyDiscountOptions } from './metadata';
import { SafetyFeaturesAdditionalQuestion } from './SafetyFeaturesAdditionalQuestion';
import { useStyles } from './SafetyFeaturesAdditionalQuestion.styles';

interface Props extends QuestionProps {
  vehicleRef: string;
  isContinueClicked: boolean;
}

export const VehicleSafetyQuestion: React.FC<Props> = (props) => {
  const {
    vehicleRef,
    label = 'Does this vehicle have any of the following safety features?',
    helperText = 'Such as anti-lock brakes, daytime running lights, electronic stability controls and airbags',
    trackingName = 'safety_features_question',
    isContinueClicked,
  } = props;
  const dispatch = useDispatch();
  const safety = useField(`${vehicleRef}.features.safety`);
  useAddFields({ safety });
  useInitValues({ [safety.key]: true });
  const initialCheckBoxGroupValue: Answers = {};
  const [checkboxGroupValuetoBePatched, setCheckboxGroupValuetoBePatched] =
    useState(initialCheckBoxGroupValue);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isRemovedSnackbarOpen, setIsRemovedSnackbarOpen] = useState(false);
  const { classes } = useStyles();

  const mouseMovingOutAndPatch = useCallback(
    () => dispatch(updateAnswers({ answers: checkboxGroupValuetoBePatched })),
    [checkboxGroupValuetoBePatched, dispatch],
  );

  const handleChange = useCallback((value: Answers) => setCheckboxGroupValuetoBePatched(value), []);

  const safetyAntiLockBrakes = useField(`${vehicleRef}.features.safety.antiLockBrakes`);
  const driverSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.driver`);
  const passengerSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.passenger`);
  const sideSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.side`);
  const safetyDayTimeRunningLights = useField(`${vehicleRef}.features.safety.dayTimeRunningLights`);
  const safetyElectronicStabilityControl = useField(
    `${vehicleRef}.features.safety.electronicStabilityControl`,
  );

  const safetyFeatures = useMemo(
    () => [
      driverSafetyAirbag,
      passengerSafetyAirbag,
      sideSafetyAirbag,
      safetyAntiLockBrakes,
      safetyDayTimeRunningLights,
      safetyElectronicStabilityControl,
    ],
    [
      driverSafetyAirbag,
      passengerSafetyAirbag,
      safetyAntiLockBrakes,
      safetyDayTimeRunningLights,
      safetyElectronicStabilityControl,
      sideSafetyAirbag,
    ],
  );

  let safetyOptions = safetyDiscountOptions;

  safetyFeatures.forEach((feature) => {
    if (!feature.exists) {
      safetyOptions = safetyOptions.filter(
        (option) => option.fieldName !== feature.key.substr(feature.key.lastIndexOf('.') + 1),
      );
    }
  });

  const safetyFeaturesFiltered = safetyFeatures.filter((feature) => feature.exists);

  useCheckboxNoPatchGroupSelectionsCleanUp({
    theQuestionValueNeedsToBeCleanedUp: safety.value,
    theQuestionValueGroup: safetyFeaturesFiltered,
  });

  const handleHasSafety = useCallback(
    (value: AnswerValue): void => {
      // If no is selected and safety features exist, show removal confirmation
      if (!value && safetyFeatures.some((feature) => feature.value)) {
        setIsRemoveDialogOpen(!value);
      } else {
        // Go ahead and change value when YES, or NO is selected with no safety featurs checked yet
        safety.props.actionOnComplete(value);
      }
    },
    [safety.props, safetyFeatures],
  );

  const removeDialog = (
    <Dialog
      className={classes.root}
      actionButtonLabel='REMOVE'
      textButtonLabel='Cancel'
      titleText='Remove safety features?'
      open={isRemoveDialogOpen}
      // eslint-disable-next-line react/jsx-no-bind
      onClose={() => {
        // user clicks the X button
        setIsRemoveDialogOpen(false);
      }}
      // eslint-disable-next-line react/jsx-no-bind
      actionButtonOnClick={() => {
        safety.props.actionOnComplete(false);
        setIsRemoveDialogOpen(false);
        setIsRemovedSnackbarOpen(true);
      }}
      // eslint-disable-next-line react/jsx-no-bind
      textButtonOnClick={() => {
        // user clicks cancel on cancel pop-up
        setIsRemoveDialogOpen(false);
      }}
      buttonPlacement='right'
    >
      <p>The safety features will be discarded.</p>
    </Dialog>
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setIsRemovedSnackbarOpen(false);
      }
    },
    [],
  );

  const snackbarDefault = (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={isRemovedSnackbarOpen}
      autoHideDuration={3000}
      message='Safety features removed.'
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  return (
    <Grid container>
      {removeDialog}
      {snackbarDefault}
      {safety.exists && (
        <RadioGroupWithOptions
          {...safety.props}
          label={label}
          helperText={helperText}
          id='SafetyFeatures'
          variant='yesNoButton'
          trackingName={trackingName}
          dataTestId='SafetyFeaturesButton'
          actionOnComplete={handleHasSafety}
        />
      )}
      <GridItem topSpacing='lg' onMouseLeave={mouseMovingOutAndPatch}>
        <SafetyFeaturesAdditionalQuestion
          isContinueClicked={isContinueClicked}
          vehicleRef={vehicleRef}
          setCheckboxGroupValuetoBePatched={handleChange}
          originalCheckboxGroupValue={checkboxGroupValuetoBePatched}
        />
      </GridItem>
    </Grid>
  );
};
