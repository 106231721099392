import { yearOptions } from '@ecp/features/sales/quotes/auto';

export interface Metadata {
  yearOptions: {
    label: string;
    value: string;
  }[];
}
/**
 * We need this workaround for now because SAPI doesn't have the capability to fix this on their side.
 * They can't remove the “driver.<id>.insurance.prior.*” questions because they are needed for the PNI.
 */

const metadata: Metadata = {
  yearOptions,
};

export default metadata;
