import { useCallback, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';

import { GridItem, ReadOnlyField, Snackbar, SnackbarAlert, TooltipWithIcon } from '@ecp/components';
import {
  useIsSniValue,
  useMilitaryDeployments,
  useRemoveUnUsedRefForMilitaryDeployments,
  useUpdateSecondaryPolicyHolderRef,
} from '@ecp/features/sales/quotes/auto';
import {
  Button,
  Dialog,
  NextPageInstructions,
  RadioGroupWithOptions,
  Resource,
  type ResourceFormProps,
} from '@ecp/features/sales/shared/components';
import { SECONDARY_INSURED_PERSON_REF } from '@ecp/features/sales/shared/constants';
import {
  deleteAnswers,
  getAnswer,
  getPolicyStartDates,
  setFormErrorsChangedByField,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, PageErrors } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import {
  useAddPriorInsurance,
  useGetPriorInsuranceInfo,
  useRemoveAllPriorInsurance,
  useRemoveUnusedPriorRefs,
} from '../../utils';
import { DriverLevelLapseQuestion } from '../DriverLevelLapseQuestion';
import { MilitaryDeploymentsQuestion } from '../MilitaryDeploymentsQuestion';
import { useStyles } from './InsuranceQuestions.styles';
import { PriorInsuranceQuestions } from './PriorInsuranceQuestions';

export interface InsuranceQuestionsProps {
  driverRef: string;
  personRef: string;
  isPni: boolean;
  allowAddSNI: boolean;
  unambiguousName?: string;
  onNext: () => Promise<void>;
}

export interface CurrentInsuranceFields {
  state: Field;
  carrier: Field;
  carrierNameText: Field;
  years: Field;
  policyInceptionDate: Field;
  endDate: Field;
  lapse: Field;
  limit: Field;
  vehicleComprehensive: Field;
  vehicleCollision: Field;
}

export interface PriorInsuranceSnapshot {
  state: AnswerValue;
  carrier: AnswerValue;
  carrierNameText: AnswerValue;
  policyEndDate: AnswerValue;
  policyInceptionDate: AnswerValue;
  'coverages.policy.bodilyInjury': AnswerValue;
  'coverages.vehicle.comprehensive': AnswerValue;
  'coverages.vehicle.collision': AnswerValue;
}

const useGetFields = (driverRef: string, priorInsuranceRef: string): CurrentInsuranceFields => {
  const userPriorInsuranceField = useFieldWithPrefix(priorInsuranceRef, 'priorInsurance.<id>');

  return {
    state: userPriorInsuranceField('state'),
    carrier: userPriorInsuranceField('carrierName'),
    carrierNameText: userPriorInsuranceField('carrierNameText'),
    years: userPriorInsuranceField('years'),
    policyInceptionDate: userPriorInsuranceField('policyInceptionDate'),
    endDate: userPriorInsuranceField('policyEndDate'),
    lapse: userPriorInsuranceField('lapse'),
    limit: userPriorInsuranceField('coverages.policy.bodilyInjury'),
    vehicleComprehensive: userPriorInsuranceField('coverages.vehicle.comprehensive'),
    vehicleCollision: userPriorInsuranceField('coverages.vehicle.collision'),
  };
};

export const InsuranceQuestions: React.FC<InsuranceQuestionsProps> = (props) => {
  const { driverRef, personRef, isPni, allowAddSNI, unambiguousName, onNext } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const sniPageEnabled = flagValues[FeatureFlags.SECONDARY_NAMED_INSURED_PAGE];

  const priorInsuranceRefsKey = `${driverRef}.priorInsurance.ref`;
  const priorInsuranceRefsValue =
    (useSelector((state: RootStore) => getAnswer(state, priorInsuranceRefsKey)) as Array<string>) ||
    [];
  const [priorInsuranceRef, setPriorInsuranceRef] = useState(''); // Form is open when there is ref here; default to not open
  const [isEditMode, setIsEditMode] = useState(false); // this signifies that you clicked the edit button to edit an existing ref
  const [priorInsuranceActionInfoType, setPriorPolicyActionInfoType] = useState<
    'NONE' | 'ADD' | 'REMOVE'
  >('NONE');

  const [isAddingOrRemovingRef, setIsAddingOrRemovingRef] = useState(false);
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const insuranceFields = useGetFields(driverRef, priorInsuranceRef);
  const [hasPriorInsurance, setHasPriorInsurance] = useState(priorInsuranceRefsValue?.length > 0); // set the default question visibility to false unless any of the prior carrier fields have a value
  const [priorInsuranceSnapshot, setPriorInsuranceSnapshot] = useState<
    PriorInsuranceSnapshot | undefined
  >(undefined);
  const addPriorInsurance = useAddPriorInsurance(driverRef);
  const removePriorRef = useRemoveUnusedPriorRefs(driverRef);
  const removeAllPriorRefs = useRemoveAllPriorInsurance(driverRef);
  const priorInsuranceInfo = useGetPriorInsuranceInfo(driverRef);
  const [removeModalProperties, setRemoveModalProperties] = useState<{
    open: boolean;
    ref: string;
  }>({
    open: false,
    ref: '',
  });
  const [removeMilitaryModal, setMilitaryRemoveModal] = useState<boolean>(false);
  const [pageErrors, setPageErrors] = useState<PageErrors[]>([]);

  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const priorLapse = useDriverField('lapse');

  const {
    state,
    carrier,
    carrierNameText,
    years,
    policyInceptionDate,
    endDate,
    lapse,
    limit,
    vehicleCollision,
    vehicleComprehensive,
  } = insuranceFields;

  const isSni = useField('static.isSni');
  const isSniValue = useIsSniValue(personRef);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const removeMilitaryDeployments = useRemoveUnUsedRefForMilitaryDeployments(driverRef);
  const militaryDeploymentsList = useMilitaryDeployments(driverRef);
  const [hasMilitaryDeployment, setHasMilitaryDeployment] = useState(
    !!militaryDeploymentsList?.length,
  );

  const initializePriorInsurance = useEvent(async (value: AnswerValue) => {
    setHasPriorInsurance(!!value);
    setIsAddingOrRemovingRef(true);
    const newPriorInsurance = addPriorInsurance();
    const { priorInsuranceRef } = newPriorInsurance;
    await newPriorInsurance.done;
    setPriorInsuranceRef(priorInsuranceRef);
    setIsAddingOrRemovingRef(false);
  });

  const removeAllSavedMilitaryDeploymentsFromDriver = useEvent(() => {
    militaryDeploymentsList.forEach((militarDeployments) =>
      removeMilitaryDeployments(militarDeployments),
    );
    initializePriorInsurance(true);
    setMilitaryRemoveModal(false);
    setOpenSnackBar(true);
  });

  const {
    props: { value: name = '' },
  } = usePersonField('firstName');
  const nameOrYou = unambiguousName || 'you';
  const hasYouInLabel = name === '';

  useEffect(() => {
    trackClick({ action: 'current_bi_limit_selection', label: limit.props.value });
  }, [limit.props.value]);

  useEffect(() => {
    if (endDate.props.value !== 'Invalid date')
      trackClick({ action: 'current_policy_end_date', label: endDate.props.value });
  }, [endDate.props.value]);

  const updateSNI = useUpdateSecondaryPolicyHolderRef();

  const handleUpdateSecondaryPolicyHolder = useEvent(async (value: AnswerValue) => {
    isSni.props.actionOnChange(value);
    if (allowAddSNI) {
      if (value) await updateSNI(driverRef);
      else {
        updateSNI('');
        const insuranceKeys = Object.values(insuranceFields).map((field) => field.key);
        await dispatch(deleteAnswers({ keys: insuranceKeys }));
        await dispatch(deleteAnswers({ ref: SECONDARY_INSURED_PERSON_REF }));
        state.props.actionOnChange('');
        carrier.props.actionOnChange('');
        carrierNameText.props.actionOnChange('');
        endDate.props.actionOnChange('');
        lapse.props.actionOnChange('');
        limit.props.actionOnChange('');
        vehicleCollision.props.actionOnChange('');
        vehicleComprehensive.props.actionOnChange('');
        years.props.actionOnChange('');
        policyInceptionDate.props.actionOnChange('');
      }
    }
  });

  const handleDialogClose = useEvent((ref: string | undefined) => {
    if (ref) {
      removePriorRef(ref);
    } else {
      removeAllPriorRefs();
      setHasPriorInsurance(false);
    }
    setRemoveModalProperties({ open: false, ref: '' });
    setPriorPolicyActionInfoType('REMOVE');
    setPriorInsuranceRef('');
    setIsAddingOrRemovingRef(false);
  });

  const closeSnackBar = useEvent(() => {
    setOpenSnackBar(false);
  });

  const removeDialog = useEvent(() => {
    const { open, ref } = removeModalProperties;

    return (
      <Dialog
        className={classes.root}
        actionButtonLabel={ref ? 'REMOVE POLICY' : 'REMOVE POLICIES'}
        textButtonLabel='Cancel'
        titleText={ref ? 'Remove prior insurance' : 'Remove all prior insurance'}
        open={open}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={() => {
          // user clicks the X button
          setRemoveModalProperties({ open: false, ref: '' });
          setIsAddingOrRemovingRef(false);
        }}
        // eslint-disable-next-line react/jsx-no-bind
        actionButtonOnClick={() => {
          handleDialogClose(ref);
        }}
        // eslint-disable-next-line react/jsx-no-bind
        textButtonOnClick={() => {
          // user clicks cancel on cancel pop-up
          setRemoveModalProperties({ open: false, ref: '' });
          setIsAddingOrRemovingRef(false);
        }}
        buttonPlacement='right'
      >
        <p>Any associated content will be removed.</p>
      </Dialog>
    );
  });

  const militaryDeploymentDialog = useEvent(() => {
    const ptitleText =
      militaryDeploymentsList.length === 1
        ? 'Remove military deployment?'
        : 'Remove all military deployments?';

    return (
      <Dialog
        className={classes.root}
        titleText={ptitleText}
        actionButtonLabel='Remove'
        textButtonLabel='Cancel'
        actionButtonOnClick={removeAllSavedMilitaryDeploymentsFromDriver}
        open={removeMilitaryModal}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={() => {
          setMilitaryRemoveModal(false);
        }}
        buttonPlacement='right'
      >
        <p>Any associated content will be removed.</p>
      </Dialog>
    );
  });

  // default to No when no prior insurance exists.
  useEffect(() => {
    if (priorInsuranceRefsValue.length === 0) {
      setHasPriorInsurance(false);
    }
  }, [priorInsuranceRefsValue.length]);

  const showConfirmation = useCallback((): boolean => {
    return !!(
      state?.value ||
      carrier?.value ||
      carrierNameText?.value ||
      years?.value ||
      policyInceptionDate?.value ||
      endDate?.value ||
      lapse?.value ||
      limit?.value ||
      vehicleCollision?.value ||
      vehicleComprehensive?.value
    );
  }, [
    carrier?.value,
    carrierNameText?.value,
    endDate?.value,
    lapse?.value,
    limit?.value,
    policyInceptionDate?.value,
    state?.value,
    vehicleCollision?.value,
    vehicleComprehensive?.value,
    years?.value,
  ]);

  const handlehasPriorInsuranceChange = useCallback(
    async (value: AnswerValue) => {
      if (!value && hasPriorInsurance) {
        // user changed from YES to NO, so do not show dialog if prior insurance doen't exists.
        if (priorInsuranceRefsValue.length === 0) {
          setRemoveModalProperties({
            open: false,
            ref: '',
          });
          setHasPriorInsurance(false);
          // user changed from YES to NO and there is only one prior insurance
          // verify any non-defaulted fields are undefined and skip displaying confirmation dialog
          // priorInsuranceRef is true when edit fields are displayed, isEditMode is true when editing, but not when newly added
        } else if (
          priorInsuranceRef !== '' &&
          !isEditMode &&
          priorInsuranceRefsValue.length === 1 &&
          !showConfirmation()
        ) {
          setIsAddingOrRemovingRef(true);
          removeAllPriorRefs();
          setHasPriorInsurance(false);
          setRemoveModalProperties({ open: false, ref: '' });
          setPriorInsuranceRef('');
          setIsAddingOrRemovingRef(false);
        } else {
          // user changed from YES to NO, so show dialog to remove any prior insurance if exists.
          setRemoveModalProperties({
            open: true,
            ref: '',
          });
        }
      } else if (value && priorInsuranceRefsValue.length < 1 && !hasPriorInsurance) {
        // If the user has added military deployment then click yes to having prior provide a message
        if (militaryDeploymentsList.length > 0) {
          //  verify both military deployment dates fields are undefined and skip displaying confirmation dialog
          if (
            militaryDeploymentsList.length === 1 &&
            militaryDeploymentsList[0].activeMilitaryDeployDate === undefined &&
            militaryDeploymentsList[0].militaryReturnDate === undefined
          ) {
            militaryDeploymentsList.forEach((militaryDeployment) =>
              removeMilitaryDeployments(militaryDeployment),
            );
            initializePriorInsurance(true);
          } else {
            setMilitaryRemoveModal(true);
          }
        } else {
          setMilitaryRemoveModal(true);
        }
      } else {
        initializePriorInsurance(!!value);
      }
    },
    [
      hasPriorInsurance,
      priorInsuranceRefsValue.length,
      priorInsuranceRef,
      isEditMode,
      showConfirmation,
      removeAllPriorRefs,
      militaryDeploymentsList,
      initializePriorInsurance,
      removeMilitaryDeployments,
    ],
  );

  const handleAddPriorInsurance = useEvent(async () => {
    setIsAddingOrRemovingRef(true);
    const newPriorInsurance = addPriorInsurance();
    const { priorInsuranceRef } = newPriorInsurance;
    await newPriorInsurance.done;
    setPriorInsuranceRef(priorInsuranceRef);
    setIsAddingOrRemovingRef(false);
    setIsEditMode(false);
  });

  const handleRemovePolicy = useEvent(async (selectedPriorRef: string) => {
    setIsAddingOrRemovingRef(true);
    setRemoveModalProperties({
      open: true,
      ref: selectedPriorRef,
    });
  });

  // Need to feed refs by themselves to the Resource component, so we need to sort the order of the refs here
  const refsSortedByEndDate = priorInsuranceInfo
    .sort((a, b) => new Date(b.policyEndDate).valueOf() - new Date(a.policyEndDate).valueOf())
    .map((priorInsurance) => priorInsurance.ref);
  const latestEndDate = priorInsuranceInfo.find(
    (priorInsurance) => priorInsurance.ref === refsSortedByEndDate[0],
  )?.policyEndDate;
  const autoPolicyEffectiveDate = useSelector(getPolicyStartDates).auto;
  const hasGapInInsurance =
    !hasPriorInsurance || dayjs(latestEndDate).isBefore(dayjs(autoPolicyEffectiveDate)); // if the endDate is at least one day before the effective date, it's a lapse
  const hasPriorInsurancePrefill = useEvent(
    (ref: string) =>
      priorInsuranceInfo.find((priorInsurance) => priorInsurance.ref === ref)?.source === 'Prefill',
  );
  const hasAnyPrefill = refsSortedByEndDate.find((ref: string) => hasPriorInsurancePrefill(ref));
  const renderPriorRefItem = useEvent((item: string) => {
    const priorInsuranceItem = priorInsuranceInfo.find(
      (priorInsurance) => priorInsurance.ref === item,
    );

    return (
      <>
        <Resource.Item
          xs={2}
          title='Policy Effective'
          value={
            priorInsuranceItem &&
            priorInsuranceItem.policyInceptionDate &&
            priorInsuranceItem.policyEndDate
              ? `${dayjs(priorInsuranceItem.policyInceptionDate).format('MM/DD/YYYY')} - ${dayjs(
                  priorInsuranceItem.policyEndDate,
                ).format('MM/DD/YYYY')}`
              : ' '
          }
        />
        <Resource.Item
          xs={2}
          title='Insurer'
          value={
            priorInsuranceItem?.carrierName !== 'Other - Not Listed'
              ? priorInsuranceItem?.carrierName
              : priorInsuranceItem?.carrierNameText
          }
        />
        <Resource.Item
          xs={2}
          title='BI Limits'
          value={priorInsuranceItem ? priorInsuranceItem['coverages.policy.bodilyInjury'] : ' '}
        />
        <Resource.Item
          xs={2}
          title='Other Coverage'
          value={
            priorInsuranceItem &&
            (() => {
              let coverageContent = '';
              if (priorInsuranceItem['coverages.vehicle.comprehensive']) {
                coverageContent = 'Comprehensive';
              }
              if (priorInsuranceItem['coverages.vehicle.collision']) {
                coverageContent += ' Collision';
              }

              return coverageContent;
            })()
          }
        />
        <Resource.Item
          xs={2}
          title='Source'
          value={priorInsuranceItem?.source === 'Prefill' ? 'Pre-Fill Report' : 'Insured'}
        />
      </>
    );
  });

  const toggleEditPriorInsurance = useEvent((selectedPriorRef: string) => {
    // Don't allow edit / click of another form while current form is up or loading
    if (isAddingOrRemovingRef || priorInsuranceRef) return;
    setIsEditMode(true);
    setIsAddingOrRemovingRef(true);
    setPriorInsuranceRef(selectedPriorRef);
    setIsAddingOrRemovingRef(false);
  });

  useEffect(() => {
    if (priorInsuranceRef) {
      setPriorInsuranceSnapshot({
        state: state.value,
        carrier: carrier.value,
        carrierNameText: carrierNameText.value,
        policyEndDate: endDate.value,
        policyInceptionDate: policyInceptionDate.value,
        'coverages.policy.bodilyInjury': limit.value,
        'coverages.vehicle.comprehensive': vehicleComprehensive.value,
        'coverages.vehicle.collision': vehicleCollision.value,
      });
    } else {
      setPriorInsuranceSnapshot(undefined);
    }
    // Only execute once prior ref state has updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorInsuranceRef]);

  const handleCancelAdd = useEvent(async (selectedPriorRef: string) => {
    if (!isEditMode) {
      // Don't show confirmation modal, on cancel, if any non-defaulted fields have values
      if (showConfirmation()) {
        handleRemovePolicy(selectedPriorRef);
      } else {
        setPriorInsuranceRef('');
        await removePriorRef(selectedPriorRef);
      }
    } else if (priorInsuranceSnapshot) {
      await Promise.all([
        state.validateUpdateAndPatch(priorInsuranceSnapshot.state || ''),
        carrier.validateUpdateAndPatch(priorInsuranceSnapshot.carrier || ''),
        carrierNameText.validateUpdateAndPatch(priorInsuranceSnapshot.carrierNameText || null),
        endDate.validateUpdateAndPatch(priorInsuranceSnapshot.policyEndDate || ''),
        limit.validateUpdateAndPatch(priorInsuranceSnapshot['coverages.policy.bodilyInjury'] || ''),
        vehicleCollision.validateUpdateAndPatch(
          !!priorInsuranceSnapshot['coverages.vehicle.collision'],
        ),
        vehicleComprehensive.validateUpdateAndPatch(
          !!priorInsuranceSnapshot['coverages.vehicle.comprehensive'],
        ),
        policyInceptionDate.validateUpdateAndPatch(
          priorInsuranceSnapshot.policyInceptionDate || '',
        ),
      ]);
      setPriorInsuranceRef('');
      setIsEditMode(false);
    }
  });

  const handleSavePriorRef = useEvent(() => {
    // Fields are already saved, so just close form
    setPriorInsuranceRef('');
    setPriorPolicyActionInfoType('ADD'); // snackbar
    setIsEditMode(false);
  });

  const handleInfoSnackbarClose = useEvent(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setPriorPolicyActionInfoType('NONE');
      }
    },
  );

  const snackbarDefault = priorInsuranceActionInfoType === 'REMOVE' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open
      autoHideDuration={3000}
      message='Insurance removed.'
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = priorInsuranceActionInfoType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message='Insurance saved.'
      hideActionButton
    />
  );
  const priorCarrierLabel = `${
    hasYouInLabel ? 'Do' : 'Does'
  } ${nameOrYou} have prior auto insurance?`;

  const renderForm = useEvent((formProps: ResourceFormProps) => {
    return (
      <PriorInsuranceQuestions
        {...formProps}
        isPni
        driverRef={driverRef}
        priorInsuranceRef={priorInsuranceRef}
        pageErrors={pageErrors}
      />
    );
  });

  const renderInsuranceQuestions = (): React.ReactElement => (
    <>
      {removeDialog()}
      {militaryDeploymentDialog()}
      <GridItem topSpacing='sm' bottomSpacing='lg' xs={12}>
        {hasAnyPrefill && (
          <ReadOnlyField label={priorCarrierLabel} value='Yes' id='hasPriorInsurance' />
        )}
        {!hasAnyPrefill && (
          <RadioGroupWithOptions
            value={hasPriorInsurance}
            actionOnComplete={handlehasPriorInsuranceChange}
            label={priorCarrierLabel}
            id='HasPriorInsurance'
            variant='yesNoButton'
            data-testid='hasPriorInsurance'
            trackingName='PriorInsurance'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        )}
      </GridItem>
      {isAddingOrRemovingRef && <CircularProgress />}
      {/* show the prior insurance questions if selected as true. */}
      {hasPriorInsurance && (
        <GridItem xs={12} bottomSpacing='sm'>
          <Resource.List
            items={refsSortedByEndDate}
            pageErrors={pageErrors}
            editItemRef={priorInsuranceRef}
            onEdit={toggleEditPriorInsurance}
            onDelete={handleRemovePolicy}
            onCancel={handleCancelAdd}
            hideDelete={hasPriorInsurancePrefill}
            hideEdit={hasPriorInsurancePrefill}
            onNext={handleSavePriorRef}
            form={renderForm}
            renderListItem={renderPriorRefItem}
          />
          <Resource.AddButton
            onClick={handleAddPriorInsurance}
            data-testid='autoPriorRefAdd'
            isProcessing={isAddingOrRemovingRef}
            disabled={!!priorInsuranceRef || isAddingOrRemovingRef}
          >
            ADD ANOTHER POLICY
          </Resource.AddButton>
        </GridItem>
      )}
      {snackbarDefault}
      {snackbarSuccess}
      {/* TODO: Remove this logic once we have the hide expression working that SAPI sends */}

      {!hasPriorInsurance && (
        <MilitaryDeploymentsQuestion
          driverRef={driverRef}
          personRef={personRef}
          hasMilitaryDeployment={hasMilitaryDeployment}
          setHasMilitaryDeployment={setHasMilitaryDeployment}
        />
      )}
      {hasGapInInsurance && !hasMilitaryDeployment && (
        <GridItem topSpacing='lg' xs={8}>
          <DriverLevelLapseQuestion driverRef={driverRef} name={nameOrYou} />
        </GridItem>
      )}
    </>
  );

  const handleSubmit = useCallback(async () => {
    setPageErrors([]);
    const errors: PageErrors[] = [];
    priorInsuranceInfo.forEach((priorInsurance) => {
      if (
        !priorInsurance.carrierName ||
        !priorInsurance.policyInceptionDate ||
        !priorInsurance.policyEndDate ||
        !priorInsurance['coverages.policy.bodilyInjury']
      ) {
        errors.push({ id: priorInsurance.ref, message: '' });
      }
      if (
        dayjs(priorInsurance.policyEndDate).isBefore(priorInsurance.policyInceptionDate) ||
        dayjs(priorInsurance.policyEndDate).isSame(priorInsurance.policyInceptionDate)
      ) {
        errors.push({ id: priorInsurance.ref, message: '' });
      }
    });
    if (hasGapInInsurance && !hasMilitaryDeployment && priorLapse.exists) {
      const priorLapseErrors = priorLapse.validate(priorLapse.value);
      if (priorLapseErrors.length) {
        dispatch(setFormErrorsChangedByField({ key: priorLapse.key, errors: priorLapseErrors }));
        errors.push({ id: priorLapse.key, message: '' });
      }
    }

    setPageErrors(errors);
    if (errors.length < 1) {
      await onNext();
    }
  }, [dispatch, hasGapInInsurance, hasMilitaryDeployment, onNext, priorInsuranceInfo, priorLapse]);

  const handleMouseDown = useEvent((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  });

  return (
    <>
      {isPni && renderInsuranceQuestions()}
      {isSni.exists && !isPni && allowAddSNI && !sniPageEnabled && (
        <GridItem topSpacing='lg' xs={12}>
          <RadioGroupWithOptions
            id='secondaryDriver'
            {...isSni.props}
            value={isSniValue}
            label={
              <>
                {`Will ${nameOrYou} be a secondary policy holder? `}
                <span className={classes.textTertiary}>(optional)</span>
                <TooltipWithIcon title='The Secondary Named Insured has the same rights and duties as the Primary Named Insured and must also have an ownership interest in the vehicle being insured.' />
              </>
            }
            variant='yesNoButton'
            data-testid='isSni'
            actionOnComplete={handleUpdateSecondaryPolicyHolder}
            trackingName='SecondaryPolicyHolderButton'
          />
        </GridItem>
      )}
      {!isPni && isSniValue && renderInsuranceQuestions()}
      <GridItem topSpacing='lg' xs={12}>
        <NextPageInstructions divider />
      </GridItem>
      {/* className={classes.buttonsPanel} */}
      <GridItem xs={12}>
        <div>
          {/* className={classes.addButton}> */}
          <Button
            className={classes.next}
            variant='primary'
            onClick={handleSubmit}
            data-testid='autoPriorCarrierContinue'
            // This stops the onBlur from firing only when this button is clicked
            // and the button retains its coordinates for the mouseup to eventually
            // register it as a click event. If you want the onBlur as well, you can
            // fire it yourself from the onClick handler. Touch should keep working.
            onMouseDown={handleMouseDown}
            isProcessing={isAddingOrRemovingRef}
            disabled={!!priorInsuranceRef}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='auto_prior_carrier_page_continue'
            analyticsElement='choice.autoPriorCarrierPage.continueButton'
          >
            Continue
          </Button>
        </div>
        {openSnackBar && (
          <Snackbar
            classes={{ root: `${classes.snackBarWidth}` }}
            message='Deployment removed.'
            vertical='bottom'
            horizontal='center'
            autoHideDuration={3000}
            open={openSnackBar}
            onClose={closeSnackBar}
          />
        )}
      </GridItem>
    </>
  );
};
