import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const NumberOfDaysRentedQuestion: React.FC<QuestionProps> = (props) => {
  const numberOfDaysRented = usePropertyField('noOfDaysRented', true);

  if (!numberOfDaysRented) return null;

  const {
    trackingName = 'number_of_days_rented_drop_down',
    label = numberOfDaysRented?.question?.title,
  } = props;

  return (
    <Select
      {...numberOfDaysRented.props}
      fullWidth={false}
      groupLabel={label}
      id='numberOfDaysRented'
      trackingName={trackingName}
      inputButtonAriaLabel='numberOfDaysRented'
    />
  );
};
