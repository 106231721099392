import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicySummaryPage' })((theme) => ({
  root: {
    ...theme.mixins.pageRoot,
    '& #content': {
      maxWidth: 'initial',
    },
    '& [role=main]': {
      [theme.breakpoints.only('md')]: {
        maxWidth: 'initial',
      },
    },
  },
  loading: {
    display: 'flex',
    height: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    color: theme.palette.primary.main,
  },
  paymentCard: {
    backgroundColor: theme.palette.other.background,
    padding: 15,
    marginTop: 30,
  },
}));
