import { TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const RoofMaterialDescriptionQuestion: React.FC<QuestionProps> = (props) => {
  const roofMaterialDescription = usePropertyField('roof.materialDescription', true);
  const {
    trackingName = 'roof_material_description',
    label = roofMaterialDescription?.question?.title,
  } = props;

  if (!roofMaterialDescription) return null;

  return (
    <TextField
      {...roofMaterialDescription?.props}
      ariaLabel='Roof Material Description'
      groupLabel={label}
      trackingLabel='roofMaterialDescription'
      trackingName={trackingName}
      required
    />
  );
};
