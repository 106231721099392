export enum PropertyPolicyCoverageGroup {
  COVERAGESFORDWELLING = 'COVERAGESFORDWELLING',
  DEDUCTIBLES = 'DEDUCTIBLES',
  EXTENDEDPROTECTION = 'EXTENDEDPROTECTION',
  INCLUDED = 'INCLUDED',
  COMMONOPTIONAL = 'COMMONOPTIONAL',
  LESSCOMMONOPTIONAL = 'LESSCOMMONOPTIONAL',
  WATERPROTECTION = 'WATERPROTECTION',
  OTHER = 'OTHER',
}

export type PropertyPolicyCoverageGroupKey = keyof typeof PropertyPolicyCoverageGroup;
