import { useCallback, useState } from 'react';

import { Snackbar } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { useDiscountsUtil } from '../../utils';
import { CustomCheckbox } from '../CustomCheckbox';
import { RemoveDiscountModal } from '../RemoveDiscountModal';
import { useStyles } from './GoodStudentQuestion.styles';

interface Props extends QuestionProps {
  driverRef: string;
  driverAge: number | undefined;
  driverName: string;
}

const ageLimit = 25;

export const GoodStudentQuestion: React.FC<Props> = (props) => {
  const { driverAge, driverRef, driverName } = props;
  const { classes } = useStyles();
  const [removedSnackbarMessage, setRemovedSnackbarMessage] = useState('');

  const goodStudent = useField(`${driverRef}.discount.goodStudent`);
  const awayAtSchool = useField(`${driverRef}.discount.awayAtSchool`);
  const awayAtSchoolCountry = useField(`${driverRef}.discount.awayAtSchoolCountry`);
  const awayAtSchoolZipCode = useField(`${driverRef}.discount.awayAtSchoolZip`);
  useAddFields({
    [`${awayAtSchool.key}`]: awayAtSchool,
    [`${goodStudent.key}`]: goodStudent,
    [`${awayAtSchoolCountry.key}`]: awayAtSchoolCountry,
    [`${awayAtSchoolZipCode.key}`]: awayAtSchoolZipCode,
  });
  const { showRemoveDialog, dismissRemove, handleDriverDiscountChange, handleConflicts } =
    useDiscountsUtil(goodStudent, [
      { field: awayAtSchool, newValue: false },
      { field: awayAtSchoolZipCode, newValue: null },
    ]);

  const onRemoveConflicts = useCallback(
    (discountSubMessage?: string): void => {
      handleConflicts();
      if (discountSubMessage) {
        setRemovedSnackbarMessage(discountSubMessage);
      }
    },
    [handleConflicts],
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setRemovedSnackbarMessage('');
      }
    },
    [],
  );

  if (!goodStudent.exists || !driverAge || driverAge > ageLimit) return null;

  return (
    <>
      {!!removedSnackbarMessage && (
        <Snackbar
          classes={{ root: classes.snackBarWidth }}
          open
          autoHideDuration={3000}
          message={`${removedSnackbarMessage} removed.`}
          vertical='bottom'
          horizontal='center'
          onClose={handleInfoSnackbarClose}
        />
      )}
      <CustomCheckbox
        actionOnChange={handleDriverDiscountChange}
        tooltipText='Full time college or high school student under age of 25. May need to provide a recent grade report maintaining a "B" average.'
        field={goodStudent}
        title='Good Student'
        key={`${driverRef}-${goodStudent.key}`}
      />
      <RemoveDiscountModal
        showDialog={showRemoveDialog}
        closeDialog={dismissRemove}
        onRemove={onRemoveConflicts}
        conflictingDiscountNames={['Away at School']}
        selectedDiscountName='Good Student'
        driverName={driverName}
      />
    </>
  );
};
