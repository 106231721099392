import { useEffect } from 'react';

import { Grid } from '@mui/material';

import { useSearchParams } from '@ecp/utils/routing';

import { GridItem } from '@ecp/components';
import type { ZipCodeQuestionProps } from '@ecp/features/sales/shared/questions';
import { ZipCodeQuestion } from '@ecp/features/sales/shared/questions';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';

import { ProductQuestion } from '../../../components';
import { useStyles } from '../LandingPageForm.styles';
export interface ProductQuestionsProps
  extends Pick<ZipCodeQuestionProps, 'validateZipCodeAndGetStateCity'> {
  disabled?: boolean;
}

export const ProductQuestions: React.FC<ProductQuestionsProps> = (props) => {
  const { classes } = useStyles();
  const { validateZipCodeAndGetStateCity, disabled } = props;

  const searchParams = useSearchParams();
  const handleSearchParamsImplemented = useNavigateToPage(PagePath.LANDING, {
    replace: true,
    removeQuery: true,
    replaceProfileAdd: false,
  });

  useEffect(() => {
    if (Object.keys(searchParams).length) {
      handleSearchParamsImplemented();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h2 className={classes.subtitle}>Product type and ZIP code</h2>
      <Grid container>
        <GridItem topSpacing='sm' xs={12}>
          <ProductQuestion disabled={disabled} />
        </GridItem>

        <GridItem topSpacing='md' xs={12} md={4} className={classes.columnLeft}>
          <ZipCodeQuestion
            validateZipCodeAndGetStateCity={validateZipCodeAndGetStateCity}
            disabled={disabled}
          />
        </GridItem>
      </Grid>
    </div>
  );
};
