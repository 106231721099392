import { Grid } from '@mui/material';

import { getVehicles } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './PolicySummaryVehicleCard.styles';

export const PolicySummaryVehicleCard: React.FC = () => {
  const { classes } = useStyles();
  const vehicles = useSelector(getVehicles);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <ul className={classes.vehicleDriverList}>
          {vehicles.map(({ year, make, model }, i) => (
            <li key={`${year}${model}${i}`} className={classes.vehicleDriverContent}>
              {year} {make} {model}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};
