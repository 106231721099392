import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MultiProductDiscountQuestion' })((theme) => ({
  heading: {
    ...theme.typography.subtitle,
  },
  horizontalCardCuztomizedLabel: {
    flexDirection: 'column',
  },
  sizeHorizontal: {
    maxWidth: 320,
    maxHeight: 'none',
    borderRadius: 8,
    marginBottom: 12,
    [theme.breakpoints.only('sm')]: {
      marginBottom: 12,
    },
  },
  horizontalLabelRoot: {
    flexDirection: 'row',
    padding: '0 8px 0 20px',
    height: 'calc(100% - 2px)',
    width: 'calc(100% - 2px)',
    zIndex: 2,
    borderRadius: 3,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  horizontalControl: {
    margin: 0,
    padding: 12,
    height: 42,
    width: 42,
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  help: {
    cursor: 'pointer',
    width: 20,
    height: 16,
    border: 'none',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    paddingBottom: 8,
    [theme.breakpoints.up('md')]: {
      marginTop: 4,
      marginRight: 4,
      top: 1,
    },
  },
  helpIcon: theme.mixins.setColorSvg(theme.palette.grey[700]),
  svgHorizontal: {
    ...theme.mixins.svg(),
    margin: '0px 10px 0px auto',
    '& svg, &': {
      height: 32,
      width: 32,
    },
  },
  horizontalLabel: {
    alignItems: 'center',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'auto',
  },
  subLabel: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginRight: 'auto',
    margin: '7px auto 7px 0px',
  },
  horizontalLabelWrapper: {
    flexDirection: 'row',
    display: 'flex',
    margin: '12px 0 12px',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
    flexGrow: 1,
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
}));
