import { useRef, useState } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { useEvent } from '@ecp/utils/react';

import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import type {
  DriversLicenseNumberAndState,
  ValidateVinResult,
} from '@ecp/features/sales/quotes/auto';
import {
  useValidateDriverLicenseNumberForMutipleDrivers,
  useValidateVin,
} from '@ecp/features/sales/quotes/auto';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import {
  applyDelta,
  getDeltaField,
  getField,
  getOfferSetId,
  useForm,
  useValidateCostcoMembershipField,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Driver, Vehicle } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { AutoProduct } from '@ecp/features/shared/product';

import { useStyles } from './AutoDeltaForm.styles';
import { AutoDeltaFormQuestions } from './AutoDeltaFormQuestions';

export interface Props {
  drivers: Driver[];
  onNext: () => Promise<void>;
  autoOfferProduct: AutoProduct;
  allVehicles: Vehicle[];
  disableVehicleServiceCallFlag?: boolean;
  disclaimerTextElement?: JSX.Element;
  actionButtonLabel?: string;
  isBundle?: boolean;
}

export const AutoDeltaForm: React.FC<Props> = (props) => {
  const {
    drivers,
    onNext,
    actionButtonLabel = 'Save & Continue',
    autoOfferProduct,
    allVehicles,
    disableVehicleServiceCallFlag,
    isBundle,
  } = props;

  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();
  const { classes } = useStyles();
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const validateDriverLicenseNumbers = useValidateDriverLicenseNumberForMutipleDrivers();
  const validateVinNum = useValidateVin();
  const valdiateCostcoMembershipField = useValidateCostcoMembershipField();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });
  const offerSetId = useSelector(getOfferSetId);

  const dispatch = useDispatch();

  const getVehicleDeltaVin = useSelector((state: RootStore) => (vehicleRef: string) => {
    const key = getDeltaField(state, vehicleRef, 'vin');

    return getField(state, { key, dispatch });
  });

  // We need to loop through vehicles once again on the delta page to make sure we have valid vins
  // for all of them before letting a user continue onwards because of scenarios where a user does
  // not blur out of a vin field and clicks continue resulting in the user being able to proceed
  // further because vin validation does not occur.
  const vinValidation = useEvent(async () => {
    const results: ReturnType<typeof validateVinNum>[] = [];
    allVehicles.forEach(async (singleVehicle: Vehicle) => {
      const vin = getVehicleDeltaVin(singleVehicle.ref);
      const value = vin.value;

      // only validate vehicles that aren't masked
      if (value && !isMasked(value)) {
        results.push(validateVinNum(`${value}`, singleVehicle));
      }
    });
    const response = await Promise.all(results);
    // if any value in the array isnt an empty string will mean there is a validation error
    // and we must stop user from continuing.
    const hasVinError = response.some((res: ValidateVinResult) => res.vinErr !== '');

    return hasVinError;
  });

  const handleSubmit = useEvent(async () => {
    if (validateForm().isValid) {
      setIsSubmitInProgress(true);

      const isDriverLicenseNumberValid = await validateDriverLicenseNumbers(
        drivers as unknown as DriversLicenseNumberAndState,
      );
      if (!isDriverLicenseNumberValid) {
        setIsSubmitInProgress(false);

        return;
      }

      if (valdiateCostcoMembershipField()) {
        setIsSubmitInProgress(false);

        return;
      }

      if (!disableVehicleServiceCallFlag) {
        const hasVinValidationErrors = await vinValidation();
        if (hasVinValidationErrors) {
          setIsSubmitInProgress(false);

          return;
        }
      }
      await patchFormValues();
      if (isBundle) {
        if (!offerSetId) {
          datadogLog({
            logType: 'error',
            message: 'offerSetId missing',
            context: {
              logOrigin:
                'apps/sales/edsp-asp/src/lob/autoLine/forms/AutoDeltaForm/AutoDeltaForm.tsx',
              functionOrigin: 'handleSubmit',
            },
          });
          throw new Error('offerSetId missing');
        }
        await dispatch(applyDelta({ policyTypes: ['auto'] }));
      }

      trackSapiAnalyticsEvent({
        element: 'choice.autoDeltaPage.continueButton',
        event: 'click',
        eventDetail: 'true',
      });

      await onNext();
      setIsSubmitInProgress(false);
    }
  });

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress || isSubmitInProgress}>
        <AutoDeltaFormQuestions
          drivers={drivers}
          vehicles={allVehicles}
          product={autoOfferProduct}
        />
        <NextPageInstructions divider>
          'Thanks. Next you’ll see your updated quote.'
        </NextPageInstructions>
        <div className={classes.carrierButtons}>
          <Button
            variant='primary'
            onClick={handleSubmit}
            isProcessing={isPatchFormInProgress}
            disabled={isPatchFormInProgress}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='auto_delta_continue'
            data-testid='continue'
            analyticsElement='choice.autoDeltaPage.continueButton'
          >
            {actionButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};
