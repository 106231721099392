import { useRef } from 'react';

import { Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, TooltipWithIcon } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { useStyles } from './HouseholdPriorLossQuestion.styles';
interface Props extends QuestionProps {
  priorLossKey: string;
  priorLossHelpLabel: string;
  secondaryText: string;
}
export const HouseholdPriorLossQuestion: React.FC<Props> = (props) => {
  const {
    priorLossKey,
    trackingName = 'householdPriorLoss',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    priorLossHelpLabel,
    secondaryText,
  } = props;
  const { classes } = useStyles();
  const householdPriorLoss = useField(priorLossKey);
  /**
   * TODO: ADVECP-3033 Update the locking down of the prior losses question after the report has been ordered
   *  once user reaches the Prior Insurance screen in the flow, lock down the prior loss question as read-only.
   *
   * For now, this will let you change the YES/NO, if the component mounts with NO. If it mounts with YES,
   *   it will be read only.
   */
  const readOnly = useRef(householdPriorLoss.props.value);
  useInitValues({ [householdPriorLoss.key]: false });
  useAddFields({ householdPriorLoss });
  if (!householdPriorLoss.exists) return null;
  const priorLossQuestion = (
    <GridItem>
      {priorLossHelpLabel}
      <TooltipWithIcon title={priorLossHelpLabel} />
    </GridItem>
  );
  const readOnlyPriorLossQuestion = (
    <>
      <GridItem className={classes.primaryText}>
        {priorLossHelpLabel}
        <TooltipWithIcon title={priorLossHelpLabel} />
        <p className={classes.secondaryText}>{secondaryText}</p>
      </GridItem>
      <GridItem>
        <Typography variant='body1'>Yes</Typography>
      </GridItem>
    </>
  );

  return (
    <>
      {readOnly.current ? (
        readOnlyPriorLossQuestion
      ) : (
        <RadioGroupWithOptions
          id='householdPriorLoss'
          {...householdPriorLoss.props}
          label={priorLossQuestion}
          helperText={secondaryText}
          variant='yesNoButton'
          trackingName={trackingName}
          trackingLabel={trackingLabel}
        />
      )}
    </>
  );
};
