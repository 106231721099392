import { castToBoolean } from '@ecp/utils/common';

import { OfferStatusCode } from '@ecp/features/sales/shared/constants';
import {
  getAnswer,
  getAnswers,
  getIsBundleForOfferProductsSelected,
  getLineOfBusiness,
  getOffer,
  getOfferProductsSelectedByType,
  getPrimaryInsuredStateCode,
  getQuestion,
  getUserSelection,
  isOfferValid,
  questionExists,
} from '@ecp/features/sales/shared/store';
import type { OfferInfo, Premium, RootStore } from '@ecp/features/sales/shared/store/types';
import type { Product, ProductName } from '@ecp/features/shared/product';
import {
  getProductNameFromProduct,
  isLineOfBusinessBundle,
  isProductAvailable,
} from '@ecp/features/shared/product';
import type { OptionMetadata, Question } from '@ecp/types';

import { CoveragePolicyLevelDiscountsMetadata } from '../utils/metadata';

const multiProductDiscountKeys = ['multiProductDiscount', 'MultiPolicy'];
// Top of the funnel selection needs to be bundle and bottom of the funnel - monoline for multi product discount to be excluded
const shouldApplyMultiProductDiscount = (state: RootStore): ((key: string) => boolean) => {
  const isLobBundle = isLineOfBusinessBundle(getLineOfBusiness(state));
  const isBundleForOfferProductsSelected = getIsBundleForOfferProductsSelected(state);

  return (key: string): boolean =>
    !multiProductDiscountKeys.includes(key) || !isLobBundle || isBundleForOfferProductsSelected;
};

const makePolicyLevelDiscounts = (
  state: RootStore,
  offerProduct: Product,
  stateCode: string,
): OptionMetadata[] => {
  const metadata = CoveragePolicyLevelDiscountsMetadata[offerProduct];

  // Policy level discount answer keys look like discount.auto.paperless
  const product = getProductNameFromProduct(offerProduct);

  const discounts = Object.keys(metadata)
    .filter((key) => castToBoolean(getAnswer(state, `discount.${product}.${key}`)))

    /**
     * TODO: SAPI v4 needs have the ability to send discounts for monoline and bundle separately.
     * SAPI v3 sends all discounts to the quote summary page and erroniously associates multipolicy discounts with the monoline tabs.
     * The multipolicy discounts should only be included with bundle offers.
     * */
    .filter(shouldApplyMultiProductDiscount(state))
    .map((key: keyof typeof metadata) => {
      const stateOption = metadata[key].stateOptions?.[stateCode];
      if (stateOption) {
        Object.entries(stateOption).forEach(([k, v]) => {
          // Inferred type of v is a union of all possible OptionMetadata values
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          metadata[key][k] = v as any;
        });
      }

      return metadata[key];
    });

  return discounts;
};

export const getPolicyLevelDiscounts = (
  state: RootStore,
): Partial<Record<ProductName, OptionMetadata[]>> => {
  const { property, auto } = getOfferProductsSelectedByType(state);
  const stateCode = getPrimaryInsuredStateCode(state);
  const propertyProductName = property && getProductNameFromProduct(property);
  const policyLevelDiscounts = {
    ...(auto && { auto: makePolicyLevelDiscounts(state, auto, stateCode) }),
    ...(propertyProductName && {
      [propertyProductName]: makePolicyLevelDiscounts(state, property, stateCode),
    }),
  } as const;

  return policyLevelDiscounts;
};

export const getAvailableObjectForDriverVehicleDiscount = <T extends { ref: string }>(
  state: RootStore,
  discountKey: string,
  list: T[],
  entityKey?: string,
): { question: Question; ref: string }[] => {
  let carrierQuestionExists = false;

  return list
    .filter((obj) => {
      carrierQuestionExists = questionExists(`${obj.ref}.discount.${discountKey}`)(state);
      if (carrierQuestionExists) return true;
      // For SAPI v4, we are removing carrier specific discount questions,
      // instead we will use the template questions on each entity

      return entityKey && questionExists(`${obj.ref.split('.')[0]}.<id>.${entityKey}`)(state);
    })
    .map((obj) => {
      const carrierQuestion = getQuestion(`${obj.ref}.discount.${discountKey}`)(state);
      const entityQuestion = getQuestion(`${obj.ref.split('.')[0]}.<id>.${entityKey}`)(state);
      const question = carrierQuestionExists ? carrierQuestion : entityQuestion;
      const value = carrierQuestionExists
        ? carrierQuestion.value
        : String(getAnswers(state)[`${obj.ref}.${entityKey}`]);

      return {
        question: {
          value,
          ...question,
        },
        ref: obj.ref,
      };
    });
};

export const getOfferDetailsForProduct = (
  state: RootStore,
  product?: Product,
): OfferInfo | null => {
  if (!product || !isProductAvailable(product)) {
    return null;
  }

  const offer = getOffer(state, product);
  if (!offer || !isOfferValid(offer, !!getUserSelection(state))) {
    return null;
  }

  const retrieveLinks = offer.links ? offer.links.filter((link) => link.rel === 'retrieve') : [];

  return {
    offerId: offer.offerId,
    fullPremium: (offer.premiums.find((p) => p.paymentPlan === 'Full') || null) as Premium,
    monthlyPremium: offer.premiums.find((p) => p.paymentPlan !== 'Full') || null,
    policyDuration: offer.details.offerKeys?.policyDurationMonths ?? '',
    // If not bindable or MVR/CBR is not available, the offer has been estimated
    isEstimated: !offer.eligibility.bindable,
    isQuoteNoBind: offer.eligibility.statusCode === OfferStatusCode.QNB,
    isPurchased: offer.eligibility.statusCode === OfferStatusCode.PURCHASED,
    isLocked: offer.eligibility.statusCode === OfferStatusCode.LOCKED,
    product: offer.product,
    retrieveLink: retrieveLinks.length > 0 ? retrieveLinks[0].href : '',
    details: offer.details,
    // These attr are Not used by every experience
    isMVRActive: false,
    isEnrolledTelematics: false,
    isRVP: false,
    coverages: {},
    checkoutPolicyKeys: {},
    coveragePolicyKeys: {},
  };
};
