import { useCallback } from 'react';

import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

import { usePropertyField } from '../../utils';

export const RentedToOthersQuestion: React.FC<QuestionProps> = (props) => {
  const rentedToOthers = usePropertyField('rentedToOthersInd');
  const numberOfDaysRented = useFieldWithPrefix('property', 'property')('noOfDaysRented');

  const handleRentedToOthersComplete = useCallback(
    async (value: AnswerValue) => {
      rentedToOthers?.validateUpdateAndPatch(value);
      if (!value) {
        numberOfDaysRented.validateUpdateAndPatch(null);
      }
    },
    [numberOfDaysRented, rentedToOthers],
  );

  if (!rentedToOthers) return null;

  const { label = rentedToOthers.question?.title, trackingName = 'rentedToOthersButton' } = props;

  return (
    <RadioGroupWithOptions
      {...rentedToOthers.props}
      variant='yesNoButton'
      id='RentedToOthers'
      trackingName={trackingName}
      trackingLabel={rentedToOthers.props.value}
      label={label}
      actionOnComplete={handleRentedToOthersComplete}
    />
  );
};
