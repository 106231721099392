import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DeltaOdometerQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
}));
