import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicyEffDateAndTermForm' })((theme) => ({
  dateInputField: {
    width: 'auto',
  },
  policyToolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
  },
  effectiveDateBlk: {
    paddingRight: 20,
    margin: 0,
    width: 'auto',
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: '4px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 20,
    },
  },
  datePickerContainer: {
    marginBottom: 20,
  },
  title: {
    paddingLeft: 16,
    paddingTop: 16,
  },
}));
