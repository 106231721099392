import { useCallback } from 'react';

import { useShowMoreOrLess } from '@ecp/components';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/types';

import { usePropertyField } from '../../utils';

export const ConstructionTypeQuestion: React.FC<QuestionProps> = (props) => {
  const constructionTypeField = usePropertyField('constructionType');
  const constructionTypeDescriptionField = usePropertyField('constructionTypeDescription');
  const singleFoundationRef = useSelector((state) => {
    return state.inquiry.answers['property.exteriorWall.ref'];
  })?.toString();

  const exteriorWallConstructionMaterialField = useFieldWithPrefix(
    singleFoundationRef ?? 'exteriorWall.<id>',
    'exteriorWall.<id>',
  )('constructionMaterial');

  const exteriorWallConstructionDescriptionField = useFieldWithPrefix(
    singleFoundationRef ?? 'exteriorWall.<id>',
    'exteriorWall.<id>',
  )('description');

  const {
    label = constructionTypeField?.question?.title,
    trackingName = 'construction_type_selection',
  } = props;

  const { showMore, displayOptions, handleClick } = useShowMoreOrLess(
    constructionTypeField?.question?.options,
    4,
    'construction_type_more_choices',
    undefined,
    true,
  );

  const handleConstructionTypeComplete = useCallback(
    async (value: AnswerValue) => {
      constructionTypeField?.validateUpdateAndPatch(value);

      // clear out constructionTypeDescriptionField value if constructionType is not OTHER
      if (constructionTypeDescriptionField?.props.value && !['OTHER'].includes(value)) {
        constructionTypeDescriptionField?.validateUpdateAndPatch(null);
      }

      // TODO These logic should be extracted out and passed as props.
      // clear out exterior wall once construction type changes.
      if (exteriorWallConstructionMaterialField?.value) {
        exteriorWallConstructionMaterialField?.update(null);
        exteriorWallConstructionMaterialField?.patch(null);
      }

      if (exteriorWallConstructionDescriptionField?.value) {
        exteriorWallConstructionDescriptionField.update(null);
        exteriorWallConstructionDescriptionField.patch(null);
      }
    },
    [
      constructionTypeDescriptionField,
      exteriorWallConstructionMaterialField,
      exteriorWallConstructionDescriptionField,
      constructionTypeField,
    ],
  );

  if (!constructionTypeField) return null;

  return (
    <RadioGroupWithOptions
      {...constructionTypeField.props}
      id='ConstructionType'
      options={displayOptions}
      label={label}
      showMoreOrLessProps={{
        showMore,
        onClick: handleClick,
        groupTestId: 'ConstructionType-SeeMoreOrLessChoices',
      }}
      trackingName={trackingName}
      cardSize='small'
      actionOnComplete={handleConstructionTypeComplete}
    />
  );
};
