import { useCallback } from 'react';

import { Typography } from '@mui/material';

import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

import { usePropertyField } from '../../utils/usePropertyField';

export const OccupancyTypeQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('occupancyType');
  const occupyByMoreThanOneResidentIndfield = useFieldWithPrefix(
    'property',
    'property',
  )('occupyByMoreThanOneResidentInd');

  const handleOccupancyTypeComplete = useCallback(
    async (value: AnswerValue) => {
      field?.validateUpdateAndPatch(value);
      if (value !== 'PROPERTY.OCCUPANCY_TYPE.PRIMARY_HOME_OWNER_OCCUPIED') {
        occupyByMoreThanOneResidentIndfield.validateUpdateAndPatch(null);
      }
    },
    [field, occupyByMoreThanOneResidentIndfield],
  );

  if (!field) return null;

  const { label = field.question?.title, trackingName = 'home_usage_selection' } = props;

  return (
    <Select
      {...field.props}
      id='OccupancyType'
      groupLabel={<Typography variant='body4'>{label}</Typography>}
      trackingName={trackingName}
      inputButtonAriaLabel='occupancyType'
      fullWidth={false}
      actionOnComplete={handleOccupancyTypeComplete}
    />
  );
};
