import type { OptionsMetadata } from '@ecp/types';

import {
  AutoParentCoverageGroup,
  AutoPolicyCoverageGroup,
  AutoVehicleCoverageGroup,
} from '../types';

// metadata for auto policy coverage card on coverage page
export const AutoPolicyCoverageMetadata: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.auto': {
    'bodilyInjury.limit': {
      title: 'Bodily Injury Liability Coverage',
      primaryText:
        'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident.',
      secondaryText: 'Per Person / Per Occurrence',
      limitText: 'Per Person Limit / Per Occurrence Limit',
      hasLimits: true,
      parentGroup: AutoParentCoverageGroup.POLICY,
      subGroup: AutoPolicyCoverageGroup.MANDATORY,
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.bodilyInjury.premiumAmount',
      subCoverages: {
        'bodilyInjury.limit': {
          title: 'Per Person Limit / Per Occurrence Limit',
          primaryText:
            'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'propertyDamage.limit': {
      title: 'Property Damage Liability',
      primaryText:
        'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
      secondaryText: 'Per Occurrence',
      limitText: 'Limit',
      parentGroup: AutoParentCoverageGroup.POLICY,
      subGroup: AutoPolicyCoverageGroup.MANDATORY,
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.propertyDamage.premiumAmount',
      subCoverages: {
        'propertyDamage.limit': {
          title: 'Per Occurrence Limit',
          primaryText:
            'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'medical.limit': {
      title: 'Medical Expense',
      primaryText:
        'Coverage may pay for expenses for all passengers in the insured vehicle for medical or funeral expenses resulting from a covered vehicle related accident.',
      secondaryText: 'Each Person',
      parentGroup: AutoParentCoverageGroup.POLICY,
      subGroup: AutoPolicyCoverageGroup.MANDATORY,
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.medical.premiumAmount',
      subCoverages: {
        'medical.limit': {
          title: 'Each Person Limit',
          primaryText:
            'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'medical.deductible': {
          title: 'Deductible',
          primaryText: 'Deductible Amount paid for medical coverage',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    /**
     * SAPI has pipBasic, pipExcess as common keys for all 7 states but PLPC has different keys for each
     *   state appended with state codes Ex: PAPipCovKS, PAPipCovMN.
     * SAPI sends pipBasic, so to identify state-specific PLPC key from SAPI we're appending state code
     *   from coverageOption.
     */
    'pipBasicKs.option': {
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
      parentGroup: AutoParentCoverageGroup.POLICY,
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'pipBasicMn.option': {
      title: 'Personal Injury Protection',
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      subCoverages: {
        'pipBasicMn.option': {
          title: 'Limit',
          primaryText:
            'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'pipBasicMn.stacking': {
          title: 'PIP Stacking',
          primaryText: 'PIP Stacking',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'pipBasicMn.workLoss': {
          title: 'Exclude Work Loss',
          primaryText: 'Exclude Work Loss',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'pipBasicOr.option': {
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'pipBasicNd.option': {
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'pipBasicUt.option': {
      title: 'Personal Injury Protection',
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      subCoverages: {
        'pipBasicUt.option': {
          title: 'Limit',
          primaryText:
            'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'pipBasicUt.excludeWorkLoss': {
          title: 'Exclude Work Loss',
          primaryText: 'PIP Exclude Work Loss',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'pipBasicWa.option': {
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'totalDisability.text': {
      title: 'Total Disability Coverage',
      subGroup: AutoPolicyCoverageGroup.MANDATORY,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'TextField',
      },
    },
    'underInsuredMotoristBi.limit': {
      title: 'Underinsured Motorist Bodily Injury Liability',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who does not have sufficient liability insurance limits to cover the full amount of a covered loss.',
      limitText: 'Per Person Limit / Per Occurrence Limit',
      secondaryText: 'Per Person / Per Occurrence',
      hasLimits: true,
      subGroup: AutoPolicyCoverageGroup.MANDATORY,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.underInsuredMotoristBi.premiumAmount',
    },
    'uninsuredMotoristBi.limit': {
      title: 'Uninsured Motorist Bodily Injury Liability',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
      limitText: 'Per Person Limit / Per Occurrence Limit',
      secondaryText: 'Per Person / Per Occurrence',
      hasLimits: true,
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.uninsuredMotoristBi.premiumAmount',
    },
    'uninsuredUnderInsuredCombined.limit': {
      title: 'Uninsured/Under Motorist Bodily Injury Combined',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
      secondaryText: 'Per Person / Per Occurrence',
      limitText: 'Per Person Limit / Per Occurrence Limit',
      hasLimits: true,
      subGroup: AutoPolicyCoverageGroup.MANDATORY,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode:
        'amfam-adv.auto.coverages.policy.uninsuredUnderInsuredCombined.premiumAmount',
    },
    'accidentalDeath.limit': {
      title: 'Accidental Death & Dismemberment',
      primaryText:
        'This coverage may provide benefits if an insured person is killed or injured while occupying or when struck by a land motor vehicle during a covered loss.',
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'accidentForgiveness.selected': {
      title: 'Accident Forgiveness (Purchased) Coverage',
      primaryText: 'Accident Forgiveness (Purchased) Coverage Text.',
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'diminishing.amount': {
      title: 'Diminishing Deductible',
      primaryText: 'Diminishing Deductible Coverage Text.',
      subGroup: AutoPolicyCoverageGroup.OPTIONAL,
      parentGroup: AutoParentCoverageGroup.POLICY,
      displayInfo: {
        displayElement: 'TextField',
      },
    },
  },
};

export const VehicleCoverageMetadata: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.auto': {
    'comprehensive.deductible': {
      title: 'Comprehensive',
      primaryText:
        'May cover damages to your covered auto not caused by a collision with another vehicle. Covered incidents such as theft, vandalism, fire, windshield replacement, hail or hitting an animal.',
      subGroup: AutoVehicleCoverageGroup.DEDUCTIBLES,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      limitText: 'Deductible',
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.comprehensive.premiumAmount',
    },
    'collision.deductible': {
      refFieldKey: 'comprehensive',
      title: 'Collision',
      primaryText:
        'May cover damages to your vehicle if it collides with another vehicle or object in a covered loss. Insurance will not pay for repairs or a replacement unless you have Collision Coverage.',
      subGroup: AutoVehicleCoverageGroup.DEDUCTIBLES,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      limitText: 'Deductible',
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.collision.premiumAmount',
    },
    'fullSafetyGlass.selected': {
      title: 'Full Glass Coverage',
      primaryText: 'Full Glass Coverage',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'newCarReplacement.selected': {
      title: 'New Car Replacement',
      primaryText:
        'New Car Replacement Coverage is an optional endorsement that modifies the base policy to provide coverage for a new car in the event of a total loss.',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'travelAssistance.selected': {
      title: 'Travel Assistance',
      primaryText: 'Includes: Emergency Roadside Service and Road Trip Accident Accommodations.',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'roadsideService.selected': {
      title: 'Emergency Roadside Assistance',
      primaryText:
        'May provide help if you have a flat tire, dead battery, get locked out of your car, or run out of gas. Coverage also includes towing costs for the insured vehicle when it is disabled.',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'rental.dailyLimitMaximum': {
      title: 'Rental Reimbursement',
      primaryText:
        'May cover the reimbursement of rental fees for renting an auto, for a specified limit, as a temporary substitute for the insured auto while it is being repaired due to a covered loss.',
      limitText: 'Limit Per Day / Maximum Limit',
      hasLimits: true,
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'roadTripAccident.selected': {
      title: 'Road Trip Accident Accommodations',
      primaryText:
        'May pay for alternate transportation, lodging, and meals incurred due to a covered Comprehensive or Collision loss that happens more than 100 miles from your home.',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'loanOrLease.selected': {
      title: 'Loan or Lease Assistance',
      primaryText:
        'In the event of a covered total loss, this coverage may pay the difference between the unpaid amount on your lease or installment loan and the actual cash value of your insured car. When you select this coverage, you will be asked to provide the lienholder information after you purchase your policy.',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'oem.selected': {
      title: 'Original Equipment Manufacturer (OEM) Parts',
      primaryText:
        'OEM coverage requires claims to first look for parts made by the original manufacturer before they look for used, refurbished, or non-OEM parts.',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'sav.selected': {
      title: 'Semiautonomous vehicle',
      primaryText: 'SAV coverage',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'useOfOtherVehicle.selected': {
      title: 'Use Of Other Vehicle',
      primaryText: 'Use Of Other Vehicle',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'travelPeaceOfMind.selected': {
      title: 'Travel Peace Of Mind',
      primaryText:
        'Includes: Accidental Death and Dismemberment, Emergency Roadside Service, Rental Reimbursement, and Road Trip Accident Accommodations.',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'customizedVehicle.limit': {
      title: 'Customized Vehicle Limit',
      primaryText: 'Customized Vehicle Limit',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'TextField',
      },
    },
    'customizedVehicle.description': {
      title: 'Customized Vehicle Description',
      primaryText: 'Customized Vehicle Description',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      limitText: 'Description',
      displayInfo: {
        displayElement: 'TextField',
      },
    },
    'milesMyWay.selected': {
      title: 'Miles My Way',
      primaryText: 'Miles My Way',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    UninsuredMotoristPropertyDamageLimit: {
      title: 'Uninsured Motorist Property Damage',
      primaryText: 'Uninsured Motorist - Property Damage',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    UninsuredMotoristPropertyDamage: {
      title: 'Uninsured Motorist Property Damage Deductible',
      primaryText: 'Uninsured Motorist Property Damage Deductible',
      subGroup: AutoVehicleCoverageGroup.COVERAGESANDBENEFITS,
      parentGroup: AutoParentCoverageGroup.VEHICLE,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
  },
};
