import {
  IconCardFrontAirBag as AirBagIcon,
  IconCardAntiLockBreaks,
  IconCardAntiTheft,
  IconCardDaytimeRunningLights,
  IconCardKeptInGarage,
} from '@ecp/themes/base';
import type { OptionsMetadata } from '@ecp/types';

/**
 * The key will be used to patch the answer if a user
 * makes a selection.
 *
 * The type will be used to differentiate driver and vehicle
 * discounts since they're going to be merged into a single array
 */
export const DriverDiscountMetadata: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.auto': {
    goodStudent: { title: 'Good Student', type: 'driver' },
    awayAtSchool: { title: 'Away At School', type: 'driver' },
  },
};

export const VehicleDiscountMetadata: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.auto': {
    AntiTheftDevice: {
      title: 'Anti-theft',
      type: 'vehicle',
      primaryImage: <IconCardAntiTheft />,
      subHeaderText: 'Which anti-theft devices?',
      secondaryText: `You may qualify for a discount if your vehicle is equipped with at least one or more active or passive anti-theft devices. An active device will prevent your vehicle from being driven by a thief. A passive device will set off an alarm or aid in the recovery of a stolen vehicle.`,
      subDiscount: {
        Active: {
          title: 'Active',
          entityKey: 'features.antitheft.active',
        },
        Passive: {
          title: 'Passive',
          entityKey: 'features.antitheft.passive',
        },
        Alarm: {
          title: 'Alarm',
          entityKey: 'features.antitheft.alarm',
        },
        VehicleRecoverySystem: {
          title: 'Vehicle Recovery System',
          entityKey: 'features.antitheft.vehicleRecoverySystem',
        },
        WindowGlassEtching: {
          title: 'Window Etching',
          entityKey: 'features.antitheft.windowGlassEtching',
        },
        CategoryI: {
          title: 'Category I',
          entityKey: 'features.antitheft.category1',
        },
        CategoryII: {
          title: 'Category II',
          entityKey: 'features.antitheft.category2',
        },
        CategoryIII: {
          title: 'Category III',
          entityKey: 'features.antitheft.category3',
        },
        CategoryIV: {
          title: 'Category IV',
          entityKey: 'features.antitheft.category4',
        },
      },
      stateOptions: {
        KY: {
          secondaryText: `You may qualify for a discount if your vehicle(s) have one or more anti-theft devices. Discount applicability is subject to state rules and may vary by state.`,
          subDiscount: {
            CategoryI: {
              title: 'Category I',
              entityKey: 'features.antitheft.category1',
            },
            CategoryII: {
              title: 'Category II',
              entityKey: 'features.antitheft.category2',
            },
            CategoryIII: {
              title: 'Category III',
              entityKey: 'features.antitheft.category3',
            },
          },
        },
        NJ: {
          secondaryText: `You may qualify for a discount if your vehicle(s) have one or more anti-theft devices. Discount applicability is subject to state rules and may vary by state.`,
          subDiscount: {
            CategoryI: {
              title: 'Category I',
              entityKey: 'features.antitheft.category1',
            },
            CategoryII: {
              title: 'Category II',
              entityKey: 'features.antitheft.category2',
            },
            CategoryIII: {
              title: 'Category III',
              entityKey: 'features.antitheft.category3',
            },
            CategoryIV: {
              title: 'Category IV',
              entityKey: 'features.antitheft.category4',
            },
          },
        },
      },
      entityKey: 'features.antitheft',
    },
    GarageParked: {
      title: 'Vehicle kept in garage',
      type: 'vehicle',
      primaryImage: <IconCardKeptInGarage />,
      entityKey: 'discount.keptInGarage',
    },
    Airbags: {
      title: 'Airbags',
      type: 'vehicle',
      primaryImage: <AirBagIcon />,
      subHeaderText: 'Which airbags?',
      fieldType: 'radiogroup',
      entityKey: 'features.safety.airbag',
    },
    AntiLockBrakes: {
      title: 'Anti-lock brakes',
      type: 'vehicle',
      primaryImage: <IconCardAntiLockBreaks />,
      entityKey: 'features.safety.antiLockBrakes',
    },
    DaytimeRunningLights: {
      title: 'Daytime running lights',
      type: 'vehicle',
      primaryImage: <IconCardDaytimeRunningLights />,
      entityKey: 'features.safety.dayTimeRunningLights',
    },
  },
};
