import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DefensiveDriverQuestion' })((theme) => ({
  root: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 'auto',
  },
  questionItem: {
    paddingBottom: 16,
    paddingRight: 16,
  },
}));
