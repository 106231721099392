import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HomeUnderConstructionQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  details: {
    border: `1px solid ${theme.palette.other.border}`,
    padding: 20,
    marginTop: 20,
  },
}));
