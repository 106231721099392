import { Alert as MuiAlert, Snackbar as MuiSnackbar } from '@mui/material';

import { useStyles } from './SnackbarAlert.styles';

type VerticalPosition = 'top' | 'bottom';
type HorizontalPosition = 'left' | 'center' | 'right';
type AlertType = 'error' | 'info' | 'success' | 'warning';

interface Props {
  message?: React.ReactNode | string;
  autoHideDuration?: number | null; // Explicitly pass in null to avoid autoHideDuration default of 3000
  vertical: VerticalPosition;
  horizontal: HorizontalPosition;
  open: boolean;
  onClose?: () => void;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  severity: AlertType;
  hideActionButton?: boolean;
}

/**
 * https://mui.com/ material-ui/react-snackbar/#use-with-alerts
 *Use an Alert inside a Snackbar for messages that communicate a certain severity.
 *EDSP-ASP Page Level Error design pattern calls for the snackbar-with-alert component.
 *It could be used by other partners in the future.
 *
 */
export const SnackbarAlert: React.FC<Props> = (props) => {
  const {
    message,
    vertical,
    horizontal,
    open,
    onClose,
    severity,
    autoHideDuration,
    hideActionButton = false,
  } = props;
  const { classes } = useStyles(undefined, { props });

  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      onClose={onClose}
      key={vertical + horizontal}
      autoHideDuration={autoHideDuration}
    >
      <MuiAlert
        onClose={onClose}
        severity={severity}
        variant='filled'
        sx={{ width: '100%' }}
        className={classes.alertContent}
        action={hideActionButton ? '' : undefined}
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};
