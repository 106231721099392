import { TooltipWithIcon } from '@ecp/components';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils/usePropertyField';

export const HasPoolQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('hasPool');

  if (!field) return null;

  const helpText = field?.question?.helpText;
  const { trackingName = 'has_pool_drop_down', label = field.question?.title } = props;

  return (
    <Select
      {...field.props}
      fullWidth={false}
      groupLabel={
        <>
          {label}
          {helpText && <TooltipWithIcon title={helpText} />}
        </>
      }
      id='hasPool'
      trackingName={trackingName}
      inputButtonAriaLabel='hasPool'
    />
  );
};
