import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const OtherStructuresBlanketCoverageQuestion: React.FC<QuestionProps> = (props) => {
  const otherStructuresBlanketCoverage = usePropertyField('otherStructuresBlanketCoverageInd');

  if (!otherStructuresBlanketCoverage) return null;

  const {
    label = otherStructuresBlanketCoverage.question?.title,
    trackingName = 'other_structures_blanket_coverage_question',
  } = props;

  return (
    <RadioGroupWithOptions
      {...otherStructuresBlanketCoverage.props}
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
      value={otherStructuresBlanketCoverage.value}
    />
  );
};
