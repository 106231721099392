import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePniFirstName, usePropertyField } from '../../utils';

export const OccupyMoreThanOneResidenceQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('occupyByMoreThanOneResidentInd', true);
  const label = usePniFirstName(field?.question?.title || '');
  const { trackingName = 'OccupyMoreThanOneResidence' } = props;

  if (!field) return null;

  return (
    <RadioGroupWithOptions
      {...field.props}
      variant='yesNoButton'
      id='OccupyMoreThan'
      trackingName={trackingName}
      trackingLabel={field.props.value}
      label={label}
    />
  );
};
