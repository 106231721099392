import { useCallback, useState } from 'react';

import { Snackbar } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { useDiscountsUtil } from '../../utils';
import { CustomCheckbox } from '../CustomCheckbox';
import { RemoveDiscountModal } from '../RemoveDiscountModal';
import { useStyles } from './YoungVolunteerQuestion.styles';

interface Props extends QuestionProps {
  driverRef: string;
  driverAge: number | undefined;
  driverName: string;
}

const ageLimit = 25;

export const YoungVolunteerQuestion: React.FC<Props> = (props) => {
  const { driverAge, driverRef, driverName } = props;
  const { classes } = useStyles();
  const [removedSnackbarMessage, setRemovedSnackbarMessage] = useState('');

  const youngVolunteer = useField(`${driverRef}.discount.youngVolunteer`);
  const awayAtSchool = useField(`${driverRef}.discount.awayAtSchool`);
  const awayAtSchoolCountry = useField(`${driverRef}.discount.awayAtSchoolCountry`);
  const awayAtSchoolZipCode = useField(`${driverRef}.discount.awayAtSchoolZip`);
  useAddFields({
    [`${awayAtSchool.key}`]: awayAtSchool,
    [`${youngVolunteer.key}`]: youngVolunteer,
    [`${awayAtSchoolCountry.key}`]: awayAtSchoolCountry,
    [`${awayAtSchoolZipCode.key}`]: awayAtSchoolZipCode,
  });

  const { showRemoveDialog, dismissRemove, handleDriverDiscountChange, handleConflicts } =
    useDiscountsUtil(youngVolunteer, [
      { field: awayAtSchool, newValue: false },
      { field: awayAtSchoolZipCode, newValue: null },
    ]);

  const onRemoveConflicts = useCallback(
    (discountSubMessage?: string): void => {
      handleConflicts();
      if (discountSubMessage) {
        setRemovedSnackbarMessage(discountSubMessage);
      }
    },
    [handleConflicts],
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setRemovedSnackbarMessage('');
      }
    },
    [],
  );

  if (!youngVolunteer.exists || !driverAge || driverAge > ageLimit) return null;

  return (
    <>
      {!!removedSnackbarMessage && (
        <Snackbar
          classes={{ root: classes.snackBarWidth }}
          open
          autoHideDuration={3000}
          message={`${removedSnackbarMessage} removed.`}
          vertical='bottom'
          horizontal='center'
          onClose={handleInfoSnackbarClose}
        />
      )}
      <CustomCheckbox
        actionOnChange={handleDriverDiscountChange}
        tooltipText='A driver 24 years or younger who volunteered a minimum of 40 hours of unpaid work during the past 12 months for a non-profit organization.'
        field={youngVolunteer}
        title='Young Volunteer'
        key={`${driverRef}-${youngVolunteer.key}`}
      />
      <RemoveDiscountModal
        showDialog={showRemoveDialog}
        closeDialog={dismissRemove}
        onRemove={onRemoveConflicts}
        conflictingDiscountNames={['Away at School']}
        selectedDiscountName='Young Volunteer'
        driverName={driverName}
      />
    </>
  );
};
