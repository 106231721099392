import { Divider, Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import {
  getLineOfBusiness,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import {
  DeltaAffinityCard,
  DeltaAutopayCard,
  DeltaPaperlessCard,
  MailingAddressQuestion,
} from '../../../../../common';
import { useStyles } from './PropertyDeltaFormQuestion.styles';

export const PropertyDeltaFormQuestion: React.FC = () => {
  const { classes } = useStyles();
  const { property } = useSelector(getOfferProductsSelectedByType);
  const selectedLob = useSelector(getLineOfBusiness);

  const lob =
    selectedLob === LineOfBusiness.BUNDLE_AUTO_RENTERS || selectedLob === LineOfBusiness.RENTERS
      ? 'renters'
      : 'home';

  return (
    <>
      {property && (
        <>
          <GridItem topSpacing='lg' xs={12}>
            <h2>Contact Information</h2>
            <Divider className={classes.divider} />
          </GridItem>
          <Grid container className={classes.body} columnSpacing={1.5}>
            <GridItem topSpacing='lg' xs={10}>
              <MailingAddressQuestion />
            </GridItem>
          </Grid>
          <DeltaAffinityCard />
          <DeltaPaperlessCard product={property} lob={lob} />
          <DeltaAutopayCard product={property} lob={lob} />
        </>
      )}
    </>
  );
};
