import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HouseholdProiorLossQuestion' })((theme) => ({
  secondaryText: {
    ...theme.typography.body1,
    fontSize: '14px',
    fontWeight: 400,
    color: '#4C4C4C',
  },
  primaryText: {
    ...theme.typography.body4,
    fontSize: '18px',
    fontWeight: 400,
    paddingBottom: '8px',
  },
}));
