import { useEffect } from 'react';

import { Page } from '@ecp/features/sales/shared/components';
import { useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { getInquiryLoaded } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { LandingPageForm } from '../../forms';
import { useStyles } from './LandingPage.styles';

export const LandingPage: React.FC = () => {
  const { classes } = useStyles();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  useEffect(() => {
    if (inquiryLoaded) {
      trackSapiAnalyticsEvent({
        element: 'choice.landingPage.page',
        event: 'render',
        eventDetail: 'true',
      });
    }
  }, [inquiryLoaded]);

  const handleStartNewQuote = useNavigateToNextPage();

  return (
    <div className={classes.root}>
      <Page title='Welcome to the Exclusive Carrier Platform'>
        <LandingPageForm onStartNewQuote={handleStartNewQuote} />
      </Page>
    </div>
  );
};
