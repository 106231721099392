import type { StateOption } from '@ecp/types';

const stateOptions = {
  CO: {
    ageMinimum: 55,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must be 55 years of age or older and completed a defensive driver course within the last 3 years.',
  },
  ID: {
    ageMinimum: 55,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must be 55 years of age or older and completed a defensive driver course within the last 3 years.',
  },
  IL: {
    ageMinimum: 55,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must be 55 years of age or older and completed a defensive driver course within the last 3 years.',
  },
  KS: {
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must have completed a defensive driver course within the last 3 years.',
  },
  MN: {
    ageMinimum: 55,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must be 55 years of age or older and completed a defensive driver course within the last 3 years.',
  },
  ND: {
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must have completed a defensive driver course within the last 3 years.',
  },
  NV: {
    ageMinimum: 55,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must be 55 years of age or older and completed a defensive driver course within the last 3 years.',
  },
  OH: {
    ageMinimum: 60,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must be 60 years of age or older and completed a defensive driver course within the last 3 years.',
  },
  OR: {
    ageMinimum: 55,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, a driver who is 55 to 69 years of age or older must have completed a defensive driver course within the last 3 years and a driver who is 70 years of age or older must have completed a defensive driver course within the last 3 years. If an additional driver who is less than 25 years old is assigned to the same vehicle, the discount will not be applied.',
  },
  UT: {
    ageMinimum: 55,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must be 55 years of age or older and completed a defensive driver course within the last 3 years.',
  },
  WA: {
    ageMinimum: 55,
    requiresCourse: true,
    helpText:
      'To qualify for this discount, the driver must be 55 years of age or older and completed a defensive driver course within the last 3 years.',
  },
};

export interface BaseMetadata {
  ageMinimum?: number;
  requiresCourse?: boolean;
  helpText?: string;
}

export interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}
export const metadata: StateMetadata = {
  stateOptions: stateOptions,
};

export default metadata;
