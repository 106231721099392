import type { LineOfBusinessUnbundled } from '@ecp/features/shared/product';
import { LineOfBusiness } from '@ecp/features/shared/product';
import {
  IconAfiProductAuto,
  IconAfiProductHome,
  IconAfiProductRenter,
} from '@ecp/themes/partners/amfam-adv';
import type { CardOption } from '@ecp/types';

export const options: Record<LineOfBusinessUnbundled, CardOption> = {
  [LineOfBusiness.AUTO]: {
    icon: <IconAfiProductAuto />,
    label: 'Auto',
    value: LineOfBusiness.AUTO,
  },
  [LineOfBusiness.HOME]: {
    icon: <IconAfiProductHome />,
    label: 'Home',
    value: LineOfBusiness.HOME,
  },
  [LineOfBusiness.RENTERS]: {
    icon: <IconAfiProductRenter />,
    value: LineOfBusiness.RENTERS,
    label: 'Renters',
  },
};

export const supportedLobOptions = {
  options: [
    options[LineOfBusiness.AUTO],
    options[LineOfBusiness.HOME],
    options[LineOfBusiness.RENTERS],
  ] as CardOption[],
};

export { productMetadata } from '@ecp/features/sales/shared/metadata';
