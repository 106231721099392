import { TextField } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';
import { useStyles } from './ConstructionTypeDescriptionQuestion.styles';

export const ConstructionTypeDescriptionQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const constructionTypeDescriptionField = usePropertyField('constructionTypeDescription', true);

  const {
    label = constructionTypeDescriptionField?.question?.title,
    trackingName = 'constructionTypeDescription',
  } = props;

  if (!constructionTypeDescriptionField) return null;

  return (
    <TextField
      className={classes.numberInput}
      {...constructionTypeDescriptionField.props}
      ariaLabel='Construction Type Description'
      groupLabel={label}
      trackingLabel='constructionTypeDescription'
      trackingName={trackingName}
      placeholder='Other Description'
    />
  );
};
