import type { FieldCardOption } from '@ecp/features/sales/shared/types';

// TODO: remove meta when updating this question per new UX
export const safetyDiscountOptions: FieldCardOption[] = [
  {
    fieldName: 'dayTimeRunningLights',
    value: 'features.safety.dayTimeRunningLights',
    label: 'Daytime running lights',
    helpText:
      'Lights on the front of a motor vehicle, automatically switched on when the vehicle is in drive.',
  },
  {
    fieldName: 'antiLockBrakes',
    value: 'features.safety.antiLockBrakes',
    label: 'Anti-lock brakes',
    helpText:
      'ABS operates by preventing the wheels from locking up during braking, thereby maintaining traction with the road surface.',
  },
  {
    fieldName: 'electronicStabilityControl',
    value: 'features.safety.electronicStabilityControl',
    label: 'Electronic stability controls',
    helpText:
      "A computerized technology that improves a vehicle's stability by detecting and reducing loss of traction.",
  },
  {
    fieldName: 'driver',
    value: 'features.safety.airbag.driver',
    label: 'Driver airbag',
    helpText:
      'Airbags designed to inflate to prevent a driver’s head and chest from contacting hard structures in the vehicle.',
  },
  {
    fieldName: 'passenger',
    value: 'features.safety.airbag.passenger',
    label: 'Passenger airbags',
    helpText:
      'Airbags designed to inflate to prevent a passenger’s head and chest from contacting hard structures in the vehicle.',
  },
  {
    fieldName: 'side',
    value: 'features.safety.airbag.side',
    label: 'Side airbags',
    helpText:
      'Side-impact airbags are usually located in the seat or door panel, and inflate between the seat occupant and the door.',
  },
];
