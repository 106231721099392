import { Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { getNumOfInstallments, Recalc } from '@ecp/features/sales/checkout';
import { getUpdateOffersInProgress } from '@ecp/features/sales/shared/store';
import type { Premium, RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';

import { useStyles } from './MonthlyPaymentView.styles';

interface Props {
  offerProduct: Product;
  recalculate?: boolean;
  showProcess: boolean;
  effectiveDate: string;
  monthlyPremium: Premium | null;
}

export const MonthlyPaymentView: React.FC<Props> = (props) => {
  const { offerProduct, recalculate, showProcess, effectiveDate, monthlyPremium } = props;
  const { classes } = useStyles();

  const updatesInProgress = useSelector((state: RootStore) => getUpdateOffersInProgress(state));
  const recalculatingOrUpdating = recalculate || updatesInProgress;

  return (
    <>
      <Grid item xs={8} display='flex' alignItems='center'>
        <p className={classes.premiumDetailHeaders}>
          {getNumOfInstallments(
            monthlyPremium?.paymentPlan,
            monthlyPremium?.downPayment,
            offerProduct,
          )}{' '}
          monthly payments of
        </p>
      </Grid>
      <Grid item xs={4} className={classes.monthlyPremiumValue}>
        <Recalc
          amount={parseDollar(monthlyPremium?.installmentPayment)}
          recalculate={recalculatingOrUpdating}
          showProcess={showProcess}
        />
      </Grid>
      <Grid item xs={6}>
        <p className={classes.premiumDetailHeaders}>Policy total</p>
      </Grid>
      <Grid item xs={6} className={classes.monthlyPremiumValue}>
        <Recalc
          amount={parseDollar(monthlyPremium?.totalPremium)}
          recalculate={recalculatingOrUpdating}
          showProcess={showProcess}
        />
      </Grid>
      <Grid item xs={6}>
        <p className={classes.premiumDetailHeaders}>Effective date</p>
      </Grid>
      <Grid item xs={6} className={classes.effectiveDateItem}>
        <span className={classes.effectiveDate}>{formatDate(effectiveDate)}</span>
      </Grid>
    </>
  );
};
