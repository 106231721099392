// Question: What happens if the user changes the construction type value after updating exterior wall? Answer: Remove exterior wall user selection
// Question: What happens if there is no construction type value, which options to display? Answer: All options
// EIFS is STUCCO_ON_FRAME_EIFS
// Different Stucco, Stucco on Frame and Stucco on Masonry

const brickVeneer = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICK_VENEER';
const earth = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.EARTH';
const stuccoOnFrameEIFS = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_FRAME_EIFS';
const vinylSiding = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.VINYL_SIDING';
const aluminiumSteelSiding = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.ALUMINUM_STEEL_SIDING';
const cementFiberSiding = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CEMENT_FIBER_SIDING';
const hardboardSiding = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.HARDBOARD_SIDING';
const logSiding = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.LOG_SIDING';
const woodSiding = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.WOOD_SIDING';
const stoneVeneer = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONE_VENEER';
const stuccoOnFrame = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_FRAME';
const other = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.OTHER';
const brickSolid = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICK_SOLID';
const stoneSolid = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONE_SOLID';
const stuccoOnMasonry = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_MASONRY';
const logSolid = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.LOG_SOLID';
const concreteBlock = 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CONCRETE_BLOCK';

export const optionsFiltering = (contingentValue: string | undefined): string[] => {
  switch (contingentValue) {
    case 'FRAME':
      return [
        brickVeneer,
        earth,
        stuccoOnFrameEIFS,
        vinylSiding,
        aluminiumSteelSiding,
        cementFiberSiding,
        hardboardSiding,
        logSiding,
        woodSiding,
        stoneVeneer,
        stuccoOnFrame,
        other,
      ];
    case 'MASONRY':
      return [
        earth,
        stuccoOnFrameEIFS,
        vinylSiding,
        aluminiumSteelSiding,
        cementFiberSiding,
        hardboardSiding,
        woodSiding,
        brickSolid,
        stoneSolid,
        stuccoOnMasonry,
        other,
      ];
    case 'LOG':
      return [logSolid, other];
    case 'CONCRETE':
    case 'STEEL':
    case 'OTHER':
      return [
        brickVeneer,
        concreteBlock,
        earth,
        stuccoOnFrameEIFS,
        vinylSiding,
        aluminiumSteelSiding,
        cementFiberSiding,
        hardboardSiding,
        logSiding,
        woodSiding,
        stoneVeneer,
        stuccoOnMasonry,
        stuccoOnFrame,
        other,
      ];
    case 'ADOBE':
      return [stuccoOnFrameEIFS, stuccoOnMasonry, stuccoOnFrame, other];
    default:
      // Return all options if construction type is not available.
      return [
        brickVeneer,
        earth,
        stuccoOnFrameEIFS,
        vinylSiding,
        aluminiumSteelSiding,
        cementFiberSiding,
        hardboardSiding,
        logSiding,
        woodSiding,
        stoneVeneer,
        stuccoOnFrame,
        other,
        brickSolid,
        stoneSolid,
        stuccoOnMasonry,
        logSolid,
        concreteBlock,
      ];
  }
};
