import { FormControl, FormControlLabel, FormHelperText, Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import { GridItem, NumberFormat, ReadOnlyField, TooltipWithIcon } from '@ecp/components';
import { Checkbox, Select, TextField } from '@ecp/features/sales/shared/components';
import type { CoverageItem } from '@ecp/features/sales/shared/types';
import type { DisplayCoverageType } from '@ecp/types';

import { useStyles } from './CoverageCardItems.styles';

type CoverageCardItemsProps = Pick<
  CoverageItem,
  'field' | 'title' | 'primaryText' | 'secondaryText'
> & {
  isSubCoverage?: boolean;
  displayInfo?: DisplayCoverageType;
};

export const CoverageCardItems: React.FC<CoverageCardItemsProps> = (props) => {
  const { field, isSubCoverage, primaryText, secondaryText, title, displayInfo } = props;
  const { classes } = useStyles();

  if (!field?.props) return null;

  const displayType = displayInfo?.displayElement;
  const displayAsDollarAmount = displayInfo?.displayAsDollarAmount;

  if (!displayType) return null;

  const labelWithTooltip = (
    <div className={isSubCoverage ? classes.label : classes.coverageName}>
      {title}
      {primaryText && <TooltipWithIcon className={classes.toolTip} title={primaryText} />}
    </div>
  );

  switch (displayInfo.displayElement) {
    case 'Checkbox':
      return (
        <div className={classes.root}>
          <Grid container gap={1} justifyItems='space-between'>
            <Grid item xs={12} className={classes.checkboxContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.formControlCheckBox}
                    id={field.props.name}
                    checked={field.props.value === 'true'}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(_, checked) => {
                      field.props.actionOnComplete(checked ? 'true' : 'false');
                    }}
                    trackingName={field.props.name}
                    trackingLabel={field.props.value}
                  />
                }
                label={labelWithTooltip}
                className={classes.formControlLabel}
              />
            </Grid>
            <FormControl
              className={classes.formControl}
              error={!!field.props.error}
              fullWidth
              variant='outlined'
              role='group'
            >
              {field.props.error && (
                <FormHelperText role='alert' data-testid='hidden-helper'>
                  {field.props.error}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </div>
      );
    case 'DropDown':
      return (
        <div className={classes.root}>
          <Grid container justifyItems='space-between'>
            <GridItem xs={12}>
              <Select
                {...field.props}
                label={labelWithTooltip}
                className={classes.coveragesDropdown}
                labelId={`${field.props.name}-label`}
                id={field.props.name}
                value={field.props.value}
                actionOnChange={field.props.actionOnComplete}
                fullWidth
                trackingName={field.props.name}
                trackingLabel={field.props.value}
              />
              <p>{secondaryText}</p>
            </GridItem>
          </Grid>
        </div>
      );
    case 'ReadOnly':
      return (
        <div className={classes.root}>
          <Grid container justifyItems='space-between'>
            <GridItem xs={12}>
              <ReadOnlyField
                id={field.props.name}
                label={title}
                helperText={primaryText} // This takes care of the tooltip
                value={
                  field.props.options?.length === 1
                    ? field.props.options[0].label.toString()
                    : displayAsDollarAmount
                    ? parseDollar(field.value?.toString())
                    : field.value
                }
                classes={classes} // This is needed to send label styles
              />
            </GridItem>
          </Grid>
          <p>{secondaryText}</p>
        </div>
      );
    case 'TextField': {
      return (
        <div className={classes.root}>
          <Grid container justifyItems='space-between'>
            <GridItem xs={12}>
              {displayAsDollarAmount ? (
                <NumberFormat
                  {...field.props}
                  label={labelWithTooltip}
                  id={field.props.name}
                  prefix='$'
                  thousandSeparator
                  decimalScale={0}
                  trackingName={field.props.name}
                  trackingLabel={field.props.value}
                />
              ) : (
                <TextField
                  {...field.props}
                  label={labelWithTooltip}
                  id={field.props.name}
                  trackingName={field.props.name}
                  trackingLabel={field.props.value}
                />
              )}
            </GridItem>
          </Grid>
        </div>
      );
    }
    default:
      return (
        <div className={classes.root}>
          <Grid container justifyItems='space-between'>
            <GridItem xs={12}>{displayType}</GridItem>
          </Grid>
        </div>
      );
  }
};
