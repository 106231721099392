import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  vehicleRef: string;
}

export const KitCarReplicaQuestion: React.FC<Props> = (props) => {
  const {
    label = 'Is Vehicle a kit car or replica?',
    trackingName = 'KitCarReplicaButton',
    vehicleRef,
  } = props;

  const kitCarReplica = useField(`${vehicleRef}.kitCarReplicaInd`);
  useInitValues({ [kitCarReplica.key]: false });
  useAddFields({ kitCarReplica });

  if (!kitCarReplica.exists) return null;

  return (
    <div>
      <RadioGroupWithOptions
        {...kitCarReplica.props}
        label={label}
        id='KitCarReplicaInd'
        variant='yesNoButton'
        trackingName={trackingName}
      />
    </div>
  );
};
