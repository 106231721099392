import {
  getLineOfBusiness,
  getOfferProductsSelectedByType,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import type { PolicyFields } from '../types';

export const usePolicyFields = (policyFieldName: string): PolicyFields => {
  const useStartDateField = useFieldWithPrefix('', '');
  const products = useSelector(getOfferProductsSelectedByType);
  const propertyPoductName = products.property && getProductNameFromProduct(products.property);

  return {
    auto: useStartDateField(`policy.auto.${policyFieldName}`),
    property: useStartDateField(`policy.${propertyPoductName}.${policyFieldName}`),
  };
};
export const useProductFromLineOfBusiness = (): string => {
  const lob = useSelector(getLineOfBusiness);
  const product = lob.substring(lob.lastIndexOf('.') + 1, lob.length).toLowerCase();

  return product;
};
