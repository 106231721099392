import { useCallback, useRef } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { HOME_POLICY_HOUSEHOLD_LOSSES } from '@ecp/features/sales/shared/constants';
import { useForm } from '@ecp/features/sales/shared/store';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import {
  BusinessOnPremisesQuestion,
  DaycareOnPremisesQuestion,
  HighRiskDogQuestion,
  NoOfOccupantsQuestion,
  NumberOfDaysRentedQuestion,
  OccupancyTypeQuestion,
  OccupantTypeQuestion,
  OccupyMoreThanOneResidenceQuestion,
  RentedToOthersQuestion,
  ResidenceTypeQuestion,
  SolidFuelQuestion,
} from '@ecp/sales/lob/property';

import { HouseholdPriorLossQuestion } from '../../../../common';
import { useStyles } from './HomeEligibilityPageForm.styles';

export interface HomeEligibilityPageFormProps {
  onNext: () => Promise<void>;
}

export const HomeEligibilityPageForm: React.FC<HomeEligibilityPageFormProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });

  const handleSubmit = useCallback(async () => {
    if (validateForm().isValid) {
      await patchFormValues();
      trackSapiAnalyticsEvent({
        element: 'choice.homeEligibilityPage.saveAndContinueButton',
        event: 'click',
        eventDetail: 'true',
      });
      await onNext();
    }
  }, [validateForm, patchFormValues, onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <GridItem topSpacing='lg' xs={12} md={6}>
            <NoOfOccupantsQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <HouseholdPriorLossQuestion
              priorLossKey={HOME_POLICY_HOUSEHOLD_LOSSES}
              priorLossHelpLabel='Has a member of the household had a property loss in the past 5 years?'
              secondaryText=''
            />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <ResidenceTypeQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <OccupancyTypeQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <OccupyMoreThanOneResidenceQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <OccupantTypeQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <Divider orientation='horizontal' />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <SolidFuelQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <HighRiskDogQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RentedToOthersQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <NumberOfDaysRentedQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <DaycareOnPremisesQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <BusinessOnPremisesQuestion />
          </GridItem>

          <Grid item xs={12}>
            <NextPageInstructions divider />
          </Grid>
          <Grid container item xs={12}>
            <Button
              data-testid='HomeEligibilitySubmit'
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress}
              className={classes.next}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='home_eligibility_continue'
            >
              Save & continue
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
