import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { TextField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useDeltaField } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  driverRef: string;
}

export const DeltaOtherNonOperatorDescription: React.FC<Props> = (props) => {
  const { driverRef } = props;
  const otherNonOperatorDescription = useDeltaField(driverRef, 'otherNonOperatorDescription');
  const nonOperatorDescription = useDeltaField(driverRef, 'nonOperatorDescription');

  useAddFields({ [`${otherNonOperatorDescription.key}`]: otherNonOperatorDescription });
  if (nonOperatorDescription.value !== 'OTH' || !otherNonOperatorDescription.exists) return null;

  return (
    <TextField
      {...otherNonOperatorDescription.props}
      label='Description Of Other'
      trackingName='delta_driver_other_non_operator_description_question'
      trackingLabel={GoogleAnalyticsLabels.REDACTED}
      actionOnChange={otherNonOperatorDescription.validateUpdateAndPatch}
    />
  );
};
