import { useCallback, useState } from 'react';

import { GridItem, RadioGroupWithOptions, SnackbarAlert } from '@ecp/components';
import { Snackbar } from '@ecp/components';
import type { ResourceFormProps } from '@ecp/features/sales/shared/components';
import { Dialog, Resource } from '@ecp/features/sales/shared/components';
import {
  createRef,
  deleteInquiryRef,
  getAnswer,
  updateAddedRef,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, PageErrors } from '@ecp/features/sales/shared/types';

import type { Garage } from '../../utils/garageUtils';
import { useGarageRefValues, useGarages, useRemoveAllGarages } from '../../utils/garageUtils';
import { GaragesFormQuestion } from './GaragesFormQuestion';
import { useStyles } from './GaragesQuestion.styles';
export interface GaragesProps {
  isAdvanced: boolean;
}

export const GaragesQuestion: React.FC<GaragesProps> = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { isAdvanced } = props;

  const trackingName = 'garages_selection';

  const garagesRefsKey = 'property.garage.ref';
  const garagesField = useFieldWithPrefix('property', 'property')('garage.ref');
  const staticMultipleGarageField = useFieldWithPrefix(
    'static',
    'static',
  )('dal.supportMultipleGarages');

  const garagesRefsValue = useGarageRefValues(garagesRefsKey);

  const [garagesState, setGaragesState] = useState(garagesRefsValue?.length > 0);
  const [garageRef, setGarageRef] = useState('');
  const [pageErrors] = useState<PageErrors[]>([]);
  const [actionType, setActionType] = useState<'NONE' | 'ADD' | 'REMOVE'>('NONE');
  const [removeAllMsg, setRemoveAllMsg] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [actionInfoMessage, setActionInfoMessage] = useState('');
  const [editItemRef, setEditItemRef] = useState('');
  const [garageItemToBeRemoved, setGarageItemToBeRemoved] = useState<Garage>();
  const [itemDataBeforeEdit, setItemDataBeforeEdit] = useState<Garage | undefined>();
  const removeAllGarages = useRemoveAllGarages(garagesRefsKey);
  const [addingStat, setAddingStat] = useState(false);

  const garageList = useGarages();
  const garagesItemField = useFieldWithPrefix(editItemRef, 'garage.<id>');
  const garageType = garagesItemField('type');
  const garageSize = garagesItemField('size');

  const GaragesByRef: { [key: string]: (typeof garageList)[number] } = garageList?.reduce(
    (acc: Record<string, (typeof garageList)[number]>, garage) => {
      acc[garage.ref] = garage;

      return acc;
    },
    {},
  );
  const handleAddGarages = useCallback(async () => {
    setAddingStat(true);
    const customRef = await dispatch(createRef('garage'));
    setGarageRef(customRef);

    await dispatch(
      updateAddedRef({
        type: garagesRefsKey,
        newRef: customRef,
      }),
    );
  }, [dispatch]);

  const handleHasGarageChange = useCallback(
    async (value: AnswerValue) => {
      if (!value && garagesState) {
        if (garageList.length === 1 && addingStat) {
          const garageItem = garageList[0];

          if (!garageItem.type && !garageItem.size) {
            const refId = garageItem.ref.split('.')[1];
            await dispatch(
              deleteInquiryRef({
                refType: 'garage',
                refId,
              }),
            );
            setGaragesState(false);
          } else if (garageItem.type || garageItem.size) {
            setOpenDiscardDialog(true);
            setGarageItemToBeRemoved(garageItem);
          }
        } else {
          setRemoveAllMsg(true);
          setOpenRemoveDialog(true);
        }
      } else if (value && garagesRefsValue.length < 1 && !garagesState) {
        setGaragesState(!!value);
        if (garagesRefsValue.length === 0) {
          handleAddGarages();
        }
      }
      staticMultipleGarageField.validateUpdateAndPatch(true);
    },
    [
      garagesState,
      garagesRefsValue.length,
      staticMultipleGarageField,
      garageList,
      addingStat,
      dispatch,
      handleAddGarages,
    ],
  );

  const onCloseDialogAction = useCallback(() => {
    setOpenRemoveDialog(false);
  }, []);

  const garagesItems = useSelector((state: RootStore) =>
    getAnswer(state, garagesRefsKey),
  ) as Array<string>;

  const onRemoveDialogAction = useCallback(async () => {
    setOpenRemoveDialog(false);
    if (!removeAllMsg && itemDataBeforeEdit) {
      if (itemDataBeforeEdit.type) {
        const groupValue = garageType.question.options?.find(
          (option) => option.label === itemDataBeforeEdit.type,
        )?.value;
        if (groupValue) {
          garageType.validateUpdateAndPatch(groupValue);
        }
      }
      if (itemDataBeforeEdit.size) {
        const groupValue = garageSize.question.options?.find(
          (option) => option.label === itemDataBeforeEdit.size,
        )?.value;
        if (groupValue) {
          garageSize.validateUpdateAndPatch(groupValue);
        }
      }
    } else {
      if (removeAllMsg) {
        await removeAllGarages();
        setActionInfoMessage('All garages or carports removed.');
        setGaragesState(false);
      } else if (garageItemToBeRemoved) {
        const refId = garageItemToBeRemoved.ref.split('.')[1];
        setActionInfoMessage('Garage or carport removed.');
        await dispatch(
          deleteInquiryRef({
            refType: 'garage',
            refId,
          }),
        );
        if (garagesItems.includes(garageItemToBeRemoved.ref) && garagesItems.length === 1) {
          handleAddGarages();
        }
      }
      setActionType('REMOVE');
    }
    setGarageRef('');
    setEditItemRef('');
    setItemDataBeforeEdit(undefined);
    setGarageItemToBeRemoved(undefined);
  }, [
    removeAllMsg,
    itemDataBeforeEdit,
    garageType,
    garageSize,
    garageItemToBeRemoved,
    removeAllGarages,
    dispatch,
    garagesItems,
    handleAddGarages,
  ]);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setActionInfoMessage('');
        setActionType('NONE');
      }
    },
    [],
  );

  const deleteItem = useCallback(
    (item: string) => {
      setGarageItemToBeRemoved(GaragesByRef[item]);
      setOpenRemoveDialog(true);
      setRemoveAllMsg(false);
    },
    [GaragesByRef],
  );

  const cancelItems = useCallback(
    async (item: string) => {
      if (item) {
        const garageItem = GaragesByRef[item];
        const itemExists = garageList.some(
          (item1) => item1.ref === item && !item1.type && !item1.size,
        );

        if (addingStat && itemExists) {
          const refId = garageItem.ref.split('.')[1];

          await dispatch(
            deleteInquiryRef({
              refType: 'garage',
              refId,
            }),
          );
          if (garageList.length === 1) setGaragesState(false);
        } else {
          setOpenDiscardDialog(true);
          setGarageItemToBeRemoved(garageItem);
        }

        setAddingStat(false);
      }
    },
    [GaragesByRef, addingStat, dispatch, garageList],
  );

  const saveItems = useCallback(() => {
    setGarageRef('');
    setEditItemRef('');
    setAddingStat(false);
  }, []);

  const handleEditItemRef = useCallback(
    (itemRef: string) => {
      setEditItemRef(itemRef);
      setItemDataBeforeEdit({ ...GaragesByRef[itemRef] });
    },
    [GaragesByRef],
  );

  const snackbarSuccess = actionType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={actionInfoMessage}
      hideActionButton
    />
  );
  const snackbarDefault = actionType === 'REMOVE' && (
    <Snackbar
      open
      autoHideDuration={3000}
      message={actionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const removeDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText={removeAllMsg ? 'Remove all garages or carports?' : 'Remove garage or carport?'}
      textButtonLabel='Cancel'
      open={openRemoveDialog}
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
    >
      Any associated content will be removed.
    </Dialog>
  );

  const onDiscardCloseDialogAction = useCallback(() => {
    setOpenDiscardDialog(false);
  }, []);
  const onDiscardDialogAction = useCallback(async () => {
    setActionType('REMOVE');
    setActionInfoMessage('Changes discarded');
    setOpenDiscardDialog(false);
    if (garageItemToBeRemoved) {
      const refId = garageItemToBeRemoved.ref.split('.')[1];
      await dispatch(
        deleteInquiryRef({
          refType: 'garage',
          refId: refId,
        }),
      );
      if (garageList.length === 1) setGaragesState(false);
    }
  }, [dispatch, garageItemToBeRemoved, garageList.length]);

  const discardDialog = (
    <Dialog
      actionButtonLabel='Discard'
      titleText='Discard changes?'
      textButtonLabel='Cancel'
      open={openDiscardDialog}
      onClose={onDiscardCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onDiscardDialogAction}
    >
      Any unsaved changes will be discarded.
    </Dialog>
  );

  const renderItems = useCallback(
    (item: string) => (
      <>
        <Resource.Item xs={4} title='Type' value={GaragesByRef[item]?.type} />
        <Resource.Item xs={7} title='Size' value={GaragesByRef[item]?.size} />
      </>
    ),
    [GaragesByRef],
  );

  const renderGarageForm = useCallback(
    (formProps: ResourceFormProps) => (
      <GaragesFormQuestion
        {...formProps}
        garageRef={garageRef}
        editItemRef={editItemRef}
        setOpenRemoveDialog={setOpenRemoveDialog}
        setActionInfoMessage={setActionInfoMessage}
        setActionType={setActionType}
        garagesList={garageList}
      />
    ),
    [editItemRef, garageList, garageRef],
  );

  return (
    isAdvanced && (
      <>
        {removeDialog}
        <GridItem topSpacing='sm' bottomSpacing='sm' xs={12}>
          <RadioGroupWithOptions
            id='GaragesCarports'
            value={garagesState}
            label={garagesField?.question.title}
            variant='yesNoButton'
            trackingName={trackingName}
            actionOnComplete={handleHasGarageChange}
            data-testid='garage-type-options'
          />
        </GridItem>
        {garagesState && (
          <>
            <Resource.List
              items={garagesRefsValue}
              editItemRef={editItemRef || garageRef}
              onEdit={handleEditItemRef}
              onDelete={deleteItem}
              pageErrors={pageErrors}
              renderListItem={renderItems}
              onCancel={cancelItems}
              onNext={saveItems}
              form={renderGarageForm}
            />
            <Resource.AddButton
              onClick={handleAddGarages}
              data-testid='add_garage_button'
              disabled={addingStat}
              className={classes.actionButtons}
            >
              ADD ANOTHER GARAGE OR CARPORT
            </Resource.AddButton>
          </>
        )}

        {snackbarDefault}
        {snackbarSuccess}
        {discardDialog}
      </>
    )
  );
};
