import { useCallback, useEffect, useState } from 'react';

import { Typography } from '@mui/material';

import { GridItem, NumberFormat } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../../utils';

interface ConstructionDetailsProps extends QuestionProps {
  resetValues: boolean;
}
export const EstimatedConstructionCost: React.FC<ConstructionDetailsProps> = (props) => {
  const { resetValues } = props;

  const constructionCostField = usePropertyField('construction.constructionCost', true);

  const [isResetDone, setIsResetDone] = useState(false);

  const resetConstructionValues = useCallback((): void => {
    constructionCostField?.validateUpdateAndPatch(null);
    setIsResetDone(true);
  }, [constructionCostField]);

  useEffect(() => {
    if (resetValues && !isResetDone) {
      resetConstructionValues();
    }
  }, [resetValues, resetConstructionValues, isResetDone]);

  if (!constructionCostField) return null;

  const {
    label = constructionCostField.question?.title,
    trackingName = 'estimated_cost_question',
  } = props;

  return (
    <GridItem topSpacing='lg' bottomSpacing='sm' xs={12}>
      <NumberFormat
        {...constructionCostField?.props}
        id='ConstructionCost'
        groupLabel={<Typography variant='body4'>{label}</Typography>}
        prefix='$'
        thousandSeparator
        decimalScale={0}
        placeholder='$0'
        trackingName={trackingName}
        data-testid={trackingName}
      />
    </GridItem>
  );
};
