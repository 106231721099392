import { Route } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';

import { PrivacyPolicyPage } from '../../common';

export const staticPaths = [PagePath.PRIVACY_POLICY];

export const staticRoutes = [
  // Static page used for I agree section on Insured page
  <Route path={PagePath.PRIVACY_POLICY} component={PrivacyPolicyPage} />,
];
