import { castAnswerType } from '@ecp/utils/common';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix, usePniRef } from '@ecp/features/sales/shared/store';

import { usePniFirstName } from '../../utils';

export const OccupantTypeQuestion: React.FC<QuestionProps> = (props) => {
  const pniRef = usePniRef();
  const field = useFieldWithPrefix(pniRef, 'person.<id>')('additionalInformation.occupantType');

  const { trackingName = '' } = props;

  const label = usePniFirstName(field?.question?.title || '');
  useAddFields({ occupantType: field });

  // useInitValues set the default value for the field.
  const initValue = castAnswerType(field.question?.defaultValue, field.question.answerType);
  useInitValues({ [field.key]: initValue });

  if (!field.exists) return null;

  return (
    <Select
      {...field.props}
      id='occupantType'
      groupLabel={label}
      trackingName={trackingName}
      inputButtonAriaLabel='occupantType'
      fullWidth={false}
    />
  );
};
