import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SewerSepticSumpCoverage' })((theme) => ({
  coveragesDropdown: {
    '& > *:first-child': {
      marginBottom: 0,
    },
    paddingTop: 8,
    paddingBottom: 8,
    overflow: 'hidden',
    maxWidth: 340,
  },
}));
