import { memo } from 'react';

import { Grid } from '@mui/material';

import { type Item, TooltipWithIcon } from '@ecp/components';
import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getOfferDetailsForProduct,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { PropertyProduct, PropertyProductName } from '@ecp/features/shared/product';
import {
  getProductDisplayNameFromProduct,
  getProductNameFromProduct,
  isProductHome,
  isProductRenters,
} from '@ecp/features/shared/product';
import { IconAfiProductHome, IconAfiProductRenter } from '@ecp/themes/partners/amfam-adv';
import type { OptionMetadata } from '@ecp/types';

import type { PolicySummaryCardProps } from '../../../../common';
import {
  DiscountsAccordion,
  PolicySummaryCard,
  useIsCustomizeCoveragesEnabled,
} from '../../../../common';
import { QuoteCoverages } from '../../../../common/components/QuoteCoverages';
import { PropertyPolicyCoverageGroup } from '../../utils';
import { getPropertyPolicyCoverageGroupNames } from '../../utils';
import { useStyles } from './PropertyCoveragesForm.styles';
import type { PropertyCoveragesFormProps } from './types';
import { useGetPropertyCoverageFields, usePropertyCoveragesForm } from './util';

export const PropertyCoveragesForm: React.FC<PropertyCoveragesFormProps> = memo((props) => {
  const { classes, cx } = useStyles();
  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);
  // TODO: These discounts need to be grabbed from the offer not the answers change this when the API updates
  const propertyDiscountItems: Partial<Record<PropertyProduct, Item[]>> = {};
  const offerPropertyInfo = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );
  const shouldDisplayISG =
    propertyOfferProduct &&
    offerPropertyInfo &&
    offerPropertyInfo.details &&
    offerPropertyInfo.details.appliedISG;
  // Pull the applied discounts from the offer, remove the word 'Discount', map them to the proper type to be used by the component later
  const appliedDiscountsFromOffer = offerPropertyInfo?.details?.appliedDiscounts?.map(
    (discount) => {
      return { title: `${discount.replace('Discount', '')}` };
    },
  ) as OptionMetadata[];
  const propertyCoverageFields = {
    policyCoverageFields: useGetPropertyCoverageFields(propertyOfferProduct),
  };
  const { propertyCoverages } = usePropertyCoveragesForm(propertyCoverageFields, props);

  const isCustomizeCoveragesEnabled = useIsCustomizeCoveragesEnabled?.();
  if (!(propertyOfferProduct && propertyCoverages.length)) return null;
  const propertyProductName = getProductNameFromProduct(propertyOfferProduct);
  propertyDiscountItems[propertyOfferProduct] = appliedDiscountsFromOffer; // use the discounts from offer if available
  const productDisplayName = getProductDisplayNameFromProduct(propertyOfferProduct);
  const propertyPolicyCoverageGroupNames = getPropertyPolicyCoverageGroupNames(
    propertyProductName as PropertyProductName,
  );

  if (!propertyPolicyCoverageGroupNames) return null;

  const renderPolicySummary = (): React.ReactElement => {
    const propertyCoverageItems = [
      {
        title: propertyPolicyCoverageGroupNames.DEDUCTIBLES,
        items: propertyCoverages.filter(
          (cov) => cov.group === PropertyPolicyCoverageGroup.DEDUCTIBLES,
        ),
      },
      {
        title: propertyPolicyCoverageGroupNames.COVERAGESFORDWELLING,
        items: propertyCoverages.filter(
          (cov) => cov.group === PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
        ),
      },
      {
        title: propertyPolicyCoverageGroupNames.EXTENDEDPROTECTION,
        items: propertyCoverages.filter(
          (cov) => cov.group === PropertyPolicyCoverageGroup.EXTENDEDPROTECTION,
        ),
      },
      {
        title: propertyPolicyCoverageGroupNames.INCLUDED,
        items: propertyCoverages.filter(
          (cov) => cov.group === PropertyPolicyCoverageGroup.INCLUDED,
        ),
      },
      {
        title: propertyPolicyCoverageGroupNames.COMMONOPTIONAL,
        items: propertyCoverages.filter(
          (cov) => cov.group === PropertyPolicyCoverageGroup.COMMONOPTIONAL,
        ),
      },
      {
        title: propertyPolicyCoverageGroupNames.LESSCOMMONOPTIONAL,
        items: propertyCoverages.filter(
          (cov) => cov.group === PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
        ),
      },
      {
        title: propertyPolicyCoverageGroupNames.WATERPROTECTION,
        items: propertyCoverages.filter(
          (cov) => cov.group === PropertyPolicyCoverageGroup.WATERPROTECTION,
        ),
      },
      {
        title: propertyPolicyCoverageGroupNames.OTHER,
        items: propertyCoverages.filter((cov) => cov.group === PropertyPolicyCoverageGroup.OTHER),
      },
    ] as NonNullable<PolicySummaryCardProps['coverageGroups']>;

    return (
      <>
        <Grid container item xs={12} className={cx(classes.addSpacing, classes.productHeading)}>
          {isProductHome(propertyOfferProduct) && (
            <IconAfiProductHome className={classes.productIcon} />
          )}
          {isProductRenters(propertyOfferProduct) && (
            <IconAfiProductRenter className={classes.productIcon} />
          )}
          <h2>{`${productDisplayName} Policy`}</h2>
        </Grid>
        <PolicySummaryCard
          discountItems={propertyDiscountItems?.[propertyOfferProduct]}
          path={`/quote/${getProductNameFromProduct(propertyOfferProduct)}/discounts`}
          product={propertyProductName}
          summaryType='discounts'
          title={`${productDisplayName} Discounts`}
          trackingName={`Edit${productDisplayName}Discounts`}
        />
        <PolicySummaryCard
          coverageGroups={propertyCoverageItems}
          path={PagePath.QUOTES}
          summaryType='coverages'
          title={`${productDisplayName} Coverages`}
          trackingName={`Edit${productDisplayName}Coverages`}
        />
      </>
    );
  };

  const renderCoverages = (): React.ReactElement => (
    <>
      <Grid container item xs={12} className={cx(classes.addSpacing, classes.productHeading)}>
        {isProductHome(propertyOfferProduct) ? (
          <IconAfiProductHome className={classes.productIcon} />
        ) : (
          <IconAfiProductRenter className={classes.productIcon} />
        )}
        <h2>{`${productDisplayName} Policy`}</h2>
      </Grid>
      {shouldDisplayISG && (
        <Grid container className={classes.isgScoreContainer}>
          <Grid item xs={7}>
            <p className={classes.isgScore}>
              Applied Insurance Score Group (ISG)
              <TooltipWithIcon
                className={classes.policyToolTip}
                title='Adjustments to start date could result in adjustments to price.'
              />
            </p>
          </Grid>
          <Grid item xs={5}>
            <p className={classes.isgScore}>{offerPropertyInfo?.details.appliedISG}</p>
          </Grid>
        </Grid>
      )}
      {propertyDiscountItems && propertyDiscountItems[propertyOfferProduct]?.length ? (
        <DiscountsAccordion
          title='Applied Discounts'
          discountItems={propertyDiscountItems?.[propertyOfferProduct]}
          trackingName={`${productDisplayName}SpecificDiscounts`}
        />
      ) : undefined}
      {propertyCoverages && propertyCoverages?.length ? (
        <QuoteCoverages
          coverageItems={propertyCoverages}
          parentCoverageGroupingList={propertyPolicyCoverageGroupNames}
        />
      ) : undefined}
    </>
  );

  return <>{isCustomizeCoveragesEnabled ? renderPolicySummary() : renderCoverages()}</>;
});
