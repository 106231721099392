import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { MultiExteriorWallConstructionQuestion } from './MultiExteriorWallConstructionQuestion';
import { SingleExteriorWallConstructionQuestion } from './SingleExteriorWallConstructionQuestion';

export type ExteriorWallConstructionQuestionProps = QuestionProps & {
  exteriorWallSelectingLimit?: number;
  optionsDependencyRules?: {
    contingentAnswerPrefix: string;
    contingentQuestionPrefix: string;
    contingentNameWithoutPrefix: string;
    dependencyRules: (contingentValue: string | undefined) => string[];
  };
};

export const ExteriorWallConstructionQuestion: React.FC<ExteriorWallConstructionQuestionProps> = (
  props,
) => {
  const {
    displayType = 'RadioGroupWithOptions',
    exteriorWallSelectingLimit = 2,
    optionsDependencyRules,
    ...rest
  } = props;

  switch (displayType) {
    case 'CheckBoxGroup':
      return (
        <MultiExteriorWallConstructionQuestion
          exteriorWallSelectingLimit={exteriorWallSelectingLimit}
          {...rest}
        />
      );
    case 'RadioGroupWithOptions':
      return (
        <SingleExteriorWallConstructionQuestion
          optionsDependencyRules={optionsDependencyRules}
          {...rest}
        />
      );
    default:
      return null;
  }
};
