import {
  ResourceAddButton,
  ResourceCancelButton,
  ResourceDeleteButton,
  ResourceSaveButton,
  ResourceSummaryItem,
  ResourceSummaryList,
} from './Resource';

export const Resource = {
  Item: ResourceSummaryItem,
  List: ResourceSummaryList,
  AddButton: ResourceAddButton,
  CancelButton: ResourceCancelButton,
  DeleteButton: ResourceDeleteButton,
  SaveButton: ResourceSaveButton,
};

export type { ResourceFormProps } from './Resource';
