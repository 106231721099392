import { propertyQuestionsUIMetadata } from '@ecp/features/sales/quotes/property/home';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const HomeBuiltOnSlopeQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('homeBuiltOnSlope');

  if (!field) return null;

  const { trackingName = 'home_slope_selection', label = field.question?.title } = props;
  const uiQuestionMetaOptions = propertyQuestionsUIMetadata[field.key].options;

  return (
    <RadioGroupWithOptions
      {...field.props}
      options={uiQuestionMetaOptions}
      id='HomeBuiltOnSlope'
      label={label}
      trackingName={trackingName}
      cardSize='small'
    />
  );
};
