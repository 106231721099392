import { ReadOnlyField } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

export type WildfireRiskScoreProps = QuestionProps & {
  isReadOnly?: boolean;
};

export const WildfireRiskScore: React.FC<WildfireRiskScoreProps> = ({
  isReadOnly = true,
  ...props
}) => {
  const {
    groupLabel = 'Wildfire Score',
    dataTestId = 'WildfireRiskScore - label',
    helperText,
  } = props;

  const wildfireRiskScoreValue = useSelector((state: RootStore) => {
    return state.inquiry.answers['property.wildfireRiskScore'];
  });

  if (!wildfireRiskScoreValue) return null;

  // Creating only a read only version of the component by default.
  // If needed, this can be expanded for other partners.
  if (isReadOnly) {
    return (
      <ReadOnlyField
        label={groupLabel}
        value={wildfireRiskScoreValue}
        id={dataTestId}
        dataTestId={dataTestId}
        helperText={helperText}
      />
    );
  }

  return null;
};
