import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DriverAssignmentForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px 0 15px',
    },
  },
  noMargin: {
    margin: 0,
  },
  description: {
    marginTop: 5,
  },
  card: {
    border: `1px solid ${theme.palette.other.border}`,
    padding: 20,
    marginTop: 15,
    borderRadius: 8,
  },
  cardHeader: {
    ...theme.typography.body4,
    marginBottom: 15,
  },
  cardHeaderSub: {
    ...theme.typography.body1,
    marginBottom: 15,
    paddingLeft: 15,
  },
  divider: {
    margin: '20px 0px',
  },
  button: {
    ...theme.mixins.formAll.next,
  },
  actionButton: {
    height: 'auto',
    marginTop: 4,
    paddingLeft: 10,
    '&:hover path': {
      fill: theme.palette.text.hover,
    },
  },
  resetIcon: {
    marginRight: 2,
    fontSize: 24,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
}));
