import { useCallback, useMemo } from 'react';

import { useShowMoreOrLess } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { useSingleExteriorWall } from '@ecp/features/sales/quotes/property/home';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue, CardOption } from '@ecp/types';

export type SingleExteriorWallConstructionQuestionProps = QuestionProps & {
  optionsDependencyRules?: {
    contingentAnswerPrefix: string;
    contingentQuestionPrefix: string;
    contingentNameWithoutPrefix: string;
    dependencyRules: (contingentValue: string | undefined) => string[];
  };
};

export const SingleExteriorWallConstructionQuestion: React.FC<
  SingleExteriorWallConstructionQuestionProps
> = (props) => {
  const { singleExteriorWallRef: exteriorWallRef, addUpdateExteriorWallDetails } =
    useSingleExteriorWall();
  const exteriorWallField = useFieldWithPrefix(
    exteriorWallRef,
    'exteriorWall.<id>',
  )('constructionMaterial');

  const exteriorWallConstructionDescriptionField = useFieldWithPrefix(
    exteriorWallRef,
    'exteriorWall.<id>',
  )('description');

  useAddFields({ [exteriorWallField.key]: exteriorWallField });

  const {
    label = exteriorWallField?.question?.title || '',
    trackingName = 'exterior_wall_more_choices',
    dataTestId = 'ExteriorWallMoreChoices',
    optionsDependencyRules,
  } = props;

  const filteringNodeValue = useFieldWithPrefix(
    optionsDependencyRules?.contingentAnswerPrefix ?? '',
    optionsDependencyRules?.contingentQuestionPrefix ?? '',
  )(optionsDependencyRules?.contingentNameWithoutPrefix ?? '').value?.toString();

  const evaluatedFieldOptions = useMemo((): CardOption<string, string>[] | undefined => {
    if (optionsDependencyRules && exteriorWallField.question?.options?.length) {
      const filteredOptions = optionsDependencyRules.dependencyRules(filteringNodeValue);

      return exteriorWallField.question.options.filter((option) =>
        filteredOptions.includes(option.value),
      );
    } else {
      return exteriorWallField.question?.options ?? undefined;
    }
  }, [optionsDependencyRules, exteriorWallField.question, filteringNodeValue]);

  const { notShow, showMore, displayOptions, handleClick } = useShowMoreOrLess(
    evaluatedFieldOptions,
    4,
    trackingName,
    undefined,
    true,
  );

  const handleExteriorWallMaterialComplete = useCallback(
    async (value: AnswerValue) => {
      exteriorWallField.validateAndUpdate(value);
      await addUpdateExteriorWallDetails(value);
      // No need to validate the description field, we are resetting it
      if (exteriorWallConstructionDescriptionField?.value) {
        exteriorWallConstructionDescriptionField.update(null);
        exteriorWallConstructionDescriptionField.patch(null);
      }
    },
    [exteriorWallField, exteriorWallConstructionDescriptionField, addUpdateExteriorWallDetails],
  );

  if (!exteriorWallField.exists) return null;

  return (
    <RadioGroupWithOptions
      {...exteriorWallField.props}
      id='ExteriorWall'
      options={displayOptions}
      label={label}
      showMoreOrLessProps={{
        notShow,
        showMore,
        onClick: handleClick,
        groupTestId: dataTestId,
      }}
      actionOnComplete={handleExteriorWallMaterialComplete}
      trackingName={trackingName}
      cardSize='small'
      sortByKey
    />
  );
};
