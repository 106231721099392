import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DriverAssignmentPage' })((theme) => ({
  root: {},
  subTitle: {
    ...theme.typography.subtitle,
    textTransform: 'none',
  },
  loading: {
    margin: '10px 0 0 30px',
  },
}));
