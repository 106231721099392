import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { getYearsOptions } from '@ecp/utils/date';

import { NumberFormat } from '@ecp/components';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

const fromYear = 1920;
const endYear = dayjs().year();
const roofYearsOptions = getYearsOptions(endYear, fromYear);

export const RoofInstallationDateQuestion: React.FC<QuestionProps> = (props) => {
  const roofInstallOrReplace = usePropertyField('roof.installOrReplace');
  const yearBuiltField = usePropertyField('yearBuilt');
  const {
    label = roofInstallOrReplace?.question?.title,
    trackingName = 'roof_age_dropdown',
    dataTestId = 'roofInstallOrReplace',
    displayType,
  } = props;

  if (!roofInstallOrReplace) return null;

  if (displayType === 'NumberFormat')
    return (
      <NumberFormat
        {...roofInstallOrReplace.props}
        groupLabel={<Typography variant='body4'>{label}</Typography>}
        format='####'
        id='roofInstallOrReplace'
        name='roofInstallOrReplace'
        placeholder='YYYY'
        data-testid={dataTestId}
        trackingName={trackingName}
        fullWidth={false}
        value={((roofInstallOrReplace.value || yearBuiltField?.value) as string) || ''}
      />
    );

  return (
    <Select
      {...roofInstallOrReplace.props}
      fullWidth={false}
      options={roofYearsOptions}
      groupLabel={label}
      id='roofInstallOrReplace'
      data-testid={dataTestId}
      trackingName={trackingName}
      inputButtonAriaLabel='roofInstallOrReplace'
      value={roofInstallOrReplace.value || yearBuiltField?.value || ''}
    />
  );
};
