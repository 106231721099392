import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { ensureStringArray } from '@ecp/utils/common';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import {
  createRef,
  getAddressInfo,
  getFieldValue,
  getPrimaryInsuredAddressInfo,
  getPrimaryInsuredMailingAddressInfo,
  updateAnswers,
  useField,
  useInitialPrimaryMailingAddressRefFromRelate,
  useMailingAddressRef,
  usePreviousPrimaryMailingAddressRef,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector, useStore } from '@ecp/features/sales/shared/store/utils';

import {
  buildAddressLabel,
  isAddressComplete,
  isAddressUnique,
  updatePrimaryMailingAddress,
  useAddressFields,
} from '../../utils';
import { AddressQuestions } from '../AddressQuestions';
import { useStyles } from './MailingAddressQuestion.styles';

export interface AddressOptions {
  label: string;
  value: string;
}

export const MailingAddressQuestion: React.FC = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const { classes } = useStyles();
  const [selectedAddressRef, setSelectedAddressRef] = useState('');

  const staticSelectedPrimaryInsuredMailingAddressRef = useField(
    `static.selected.primaryInsuredMailingAddress.ref`,
  );

  const primaryAddressRef = usePrimaryAddressRef();
  const primaryInsuredAddressInfo = useSelector(getPrimaryInsuredAddressInfo);

  const primaryAddressTypes = ensureStringArray(
    getFieldValue(store.getState(), `${primaryAddressRef}.addressTypes`),
  );

  const primaryMailingAddressRef = useMailingAddressRef();
  const primaryInsuredMailingAddressInfo = useSelector(getPrimaryInsuredMailingAddressInfo);

  const initialPrimaryInsuredMailingAddresseRefFromRelate =
    useInitialPrimaryMailingAddressRefFromRelate();

  const initialPrimaryInsuredMailingAddressesInfoFromRelate = useSelector((state) =>
    getAddressInfo(state, initialPrimaryInsuredMailingAddresseRefFromRelate),
  );

  const primaryMailingAddressTypesFromRelate = ensureStringArray(
    getFieldValue(
      store.getState(),
      `${initialPrimaryInsuredMailingAddresseRefFromRelate}.addressTypes`,
    ),
  );

  const previousPrimaryMailingAddressRef = usePreviousPrimaryMailingAddressRef();

  /*
   * isNewAddressSelected determines if Agent has selected an address which is not same as
   * Primary Insured Mailing Address & is not same as Current selected Primary Address
   * If it is New Address then Agent should have option to update the Address
   */
  const isNewAddressSelected =
    selectedAddressRef !== '' &&
    selectedAddressRef !== initialPrimaryInsuredMailingAddresseRefFromRelate &&
    selectedAddressRef !== primaryAddressRef;

  const isNewAddressComplete = isAddressComplete(primaryInsuredMailingAddressInfo);
  const isInitialPrimaryMailingAddressComplete = isAddressComplete(
    initialPrimaryInsuredMailingAddressesInfoFromRelate,
  );

  const {
    address: { line1, line2, city, state, zipcode },
  } = useAddressFields(primaryMailingAddressRef);

  useAddFields({
    [line1.key]: line1,
    [line2.key]: line2,
    [city.key]: city,
    [state.key]: state,
    [zipcode.key]: zipcode,
  });

  useAddConditionValues({
    conditionalFields: [line1, line2, city, state, zipcode],
    isExcluded: () => !!isNewAddressComplete,
  });

  const {
    address: {
      line1: initialPrimaryInsuredMailingAddressesInfoFromRelateLine1,
      line2: initialPrimaryInsuredMailingAddressesInfoFromRelateLine2,
      city: initialPrimaryInsuredMailingAddressesInfoFromRelateCity,
      state: initialPrimaryInsuredMailingAddressesInfoFromRelateState,
      zipcode: initialPrimaryInsuredMailingAddressesInfoFromRelateZipcode,
    },
  } = useAddressFields(initialPrimaryInsuredMailingAddresseRefFromRelate);

  useAddConditionValues({
    conditionalFields: [
      initialPrimaryInsuredMailingAddressesInfoFromRelateLine1,
      initialPrimaryInsuredMailingAddressesInfoFromRelateLine2,
      initialPrimaryInsuredMailingAddressesInfoFromRelateCity,
      initialPrimaryInsuredMailingAddressesInfoFromRelateState,
      initialPrimaryInsuredMailingAddressesInfoFromRelateZipcode,
    ],
    isExcluded: () =>
      primaryMailingAddressRef !== initialPrimaryInsuredMailingAddresseRefFromRelate,
  });

  const {
    address: {
      line1: previouSelectedNewAddressLine1,
      line2: previouSelectedNewAddressLine2,
      city: previouSelectedNewAddressCity,
      state: previouSelectedNewAddressState,
      zipcode: previouSelectedNewAddressZipcode,
    },
  } = useAddressFields(previousPrimaryMailingAddressRef);

  useAddConditionValues({
    conditionalFields: [
      previouSelectedNewAddressLine1,
      previouSelectedNewAddressLine2,
      previouSelectedNewAddressCity,
      previouSelectedNewAddressState,
      previouSelectedNewAddressZipcode,
    ],
    isExcluded: () => !isNewAddressSelected,
  });

  const uniqueRiskAddressOptions: AddressOptions[] = [];

  /*
   * Case : Add the Primary Address info as the option
   */

  if (primaryAddressRef) {
    uniqueRiskAddressOptions.push({
      label: buildAddressLabel(primaryInsuredAddressInfo),
      value: primaryAddressRef,
    });
  }

  /*
   * Case : Add Mailing Address as option if it is not same as Primary Address
   */

  const isMailingAddressUnique = isAddressUnique(
    initialPrimaryInsuredMailingAddressesInfoFromRelate,
    primaryInsuredAddressInfo,
  );

  if (initialPrimaryInsuredMailingAddresseRefFromRelate && isMailingAddressUnique) {
    uniqueRiskAddressOptions.push({
      label: buildAddressLabel(initialPrimaryInsuredMailingAddressesInfoFromRelate),
      value: initialPrimaryInsuredMailingAddresseRefFromRelate,
    });
  }

  /*
   * Add New Address option in the dropdown.
   * As per the requirement Agent can add only 1 new Address
   * If Initial Primary/Mailing address which has been passed from Relate is Incomplete then
   * Agent has an option to update Either of them so there should be no option For Add New Address
   *
   * If Agent has previously Added a new address then reselecting the new Address in the list
   * show them the Previous new Added Address ref insted of creating a new one
   */

  if (
    isNewAddressSelected ||
    !primaryMailingAddressRef ||
    (primaryMailingAddressRef && primaryMailingAddressRef === primaryAddressRef) ||
    (initialPrimaryInsuredMailingAddresseRefFromRelate && isInitialPrimaryMailingAddressComplete)
  ) {
    uniqueRiskAddressOptions.push({
      label: 'Add New Address',
      value: isNewAddressSelected ? primaryMailingAddressRef : 'Add New Address',
    });
  }

  const onMailingAddressChange = useCallback(
    async (value: string) => {
      if (previousPrimaryMailingAddressRef) {
        await dispatch(
          updateAnswers({
            answers: {
              [`${previousPrimaryMailingAddressRef}.addressTypes`]: 'UNASSIGNED',
            },
          }),
        );
      }

      let updatedAnswers = {};
      if (value === 'Add New Address') {
        const newAddressRef = !previousPrimaryMailingAddressRef
          ? await dispatch(createRef('address'))
          : previousPrimaryMailingAddressRef;

        updatedAnswers = updatePrimaryMailingAddress(
          primaryAddressRef,
          primaryMailingAddressRef,
          initialPrimaryInsuredMailingAddresseRefFromRelate,
          newAddressRef,
          primaryMailingAddressTypesFromRelate,
          primaryAddressTypes,
        );

        await dispatch(
          updateAnswers({
            answers: { ...updatedAnswers },
          }),
        );

        setSelectedAddressRef(newAddressRef);
      } else {
        updatedAnswers = updatePrimaryMailingAddress(
          primaryAddressRef,
          primaryMailingAddressRef,
          initialPrimaryInsuredMailingAddresseRefFromRelate,
          value,
          primaryMailingAddressTypesFromRelate,
          primaryAddressTypes,
        );
        await dispatch(
          updateAnswers({
            answers: { ...updatedAnswers },
          }),
        );

        setSelectedAddressRef(value);
      }
    },
    [
      previousPrimaryMailingAddressRef,
      dispatch,
      primaryAddressRef,
      primaryMailingAddressRef,
      initialPrimaryInsuredMailingAddresseRefFromRelate,
      primaryMailingAddressTypesFromRelate,
      primaryAddressTypes,
    ],
  );

  return (
    <GridItem>
      <Grid>
        <GridItem>
          <Select
            {...staticSelectedPrimaryInsuredMailingAddressRef.props}
            label='Mailing Address'
            id='Mailing Address'
            options={uniqueRiskAddressOptions}
            value={primaryMailingAddressRef ? primaryMailingAddressRef : primaryAddressRef}
            actionOnChange={onMailingAddressChange}
          />
        </GridItem>
      </Grid>
      <Grid>
        <Grid className={classes.addressBox}>
          <AddressQuestions
            addressRef={primaryMailingAddressRef ? primaryMailingAddressRef : primaryAddressRef}
            label='New Mailing Address'
            trackingName='mailing_address'
            addressType='MAILING'
            isValidAddress
            isAddressChanged={isNewAddressSelected}
          />
        </Grid>
      </Grid>
    </GridItem>
  );
};
