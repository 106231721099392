import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const NumberOfStoriesQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('numberOfStories');

  if (!field) return null;

  const { trackingName = 'home_stories_slider', groupLabel = field?.question?.title } = props;

  return (
    <>
      {' '}
      <Select
        {...field.props}
        id='NumberOfStories'
        groupLabel={groupLabel}
        trackingName={trackingName}
        inputButtonAriaLabel='NumberOfStories'
        fullWidth={false}
      />
    </>
  );
};
