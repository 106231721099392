import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'MonthlyPaymentsView',
})((theme) => ({
  // custom styles

  premiumDetailHeaders: {
    ...theme.typography.body2,
    color: theme.palette.grey[800],
  },

  monthlyPremiumValue: {
    ...theme.typography.h4,
    textAlign: 'right',
  },
  effectiveDateItem: {
    textAlign: 'right',
  },
  effectiveDate: {
    ...theme.typography.body2Bold,
    color: theme.palette.text.primary,
  },
}));
