import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { Events, trackEvent } from '@ecp/utils/flags';
import type { GeoAddress } from '@ecp/utils/geo';
import { validateAndCombineAddress } from '@ecp/utils/geo';
import { fetchSuggestions } from '@ecp/utils/geo';
import { useEvent } from '@ecp/utils/react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus, STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import {
  PagePath,
  useNavigateToNextPage,
  useNavigateToPage,
} from '@ecp/features/sales/shared/routing';
import {
  createAddressForUpdate,
  getLineOfBusiness,
  makeSurePrefillFlowInStore,
  updateAnswers,
  updatePageStatus,
  useField,
  useFieldWithPrefix,
  usePrimaryAddress,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Field, Fields } from '@ecp/types';

import { AgreementContent } from '../../components';
import { InsuredInformationPageForm } from '../../forms';
import { useStyles } from '../InsuredInformationPage/InsuredInformationPage.styles';

export interface AddressFields extends Fields {
  primaryAddress: {
    primaryLine1: Field;
    primaryLine2: Field;
    primaryCity: Field;
    primaryState: Field;
    primaryZipcode: Field;
  };
}

const useGetFields = (): AddressFields => {
  const primaryAddressRef = usePrimaryAddressRef();
  const usePrimaryAddressFields = useFieldWithPrefix(primaryAddressRef, 'address.<id>');

  return {
    primaryAddress: {
      primaryCity: usePrimaryAddressFields('city'),
      primaryState: usePrimaryAddressFields('state'),
      primaryLine1: usePrimaryAddressFields('line1'),
      primaryLine2: usePrimaryAddressFields('line2'),
      primaryZipcode: usePrimaryAddressFields('zipcode'),
    },
  };
};

export const InsuredInformationPage: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const primaryAddressRef = usePrimaryAddressRef();
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const [geoAddressSuggestions, setGeoAddressSuggestions] = useState<GeoAddress[]>([]);
  const navigateToNextPage = useNavigateToNextPage();
  const navigateToNextAddressPage = useNavigateToPage(PagePath.PERSON_ADDRESS);
  const fields = useGetFields();
  const address = useField('static.address');
  const primaryAddress = usePrimaryAddress();
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true);

  // TODO: We might need a better way to handle this. A/B test metric tracking function for personal info page view
  useEffect(() => {
    trackEvent(Events.PERSONAL_PAGE);
  }, []);

  // If a user doesn't select a suggested address and chooses to enter the address manually all fields need to be combined and validated when a user submits the form.
  const handleNext = useCallback(async () => {
    const {
      primaryAddress: { primaryCity, primaryState, primaryLine1, primaryLine2, primaryZipcode },
    } = fields;
    const primaryStateWithoutPrefix = primaryState.value as string;
    const primaryInputAddress = {
      street: `${primaryLine1.value} ${primaryLine2.value ? (primaryLine2.value as string) : ''}`,
      state: primaryStateWithoutPrefix
        ? primaryStateWithoutPrefix.replace(STATE_CODE_PREFIX, '')
        : '',
      city: primaryCity.value as string,
    };
    const parsedAddress = await validateAndCombineAddress(primaryInputAddress, primaryAddressRef);

    const streetAddress = `${primaryLine1.value ?? ''}${
      primaryLine2.value && primaryLine2.value ? ` ${primaryLine2.value}` : ''
    }`;

    const enteredZipcode = primaryZipcode ? (primaryZipcode?.value?.toString() as string) : '';

    const lookupQuery = {
      value: streetAddress,
      zipcode: enteredZipcode,
      state: '',
    };
    if (parsedAddress && parsedAddress.line1 === primaryLine1.value) {
      const addressToBeSaved = createAddressForUpdate(parsedAddress);
      setIsValidAddress(true);

      address.props.actionOnComplete(primaryAddress);

      await dispatch(updateAnswers({ answers: { ...addressToBeSaved } }));

      dispatch(updatePageStatus(NavStatus.VALID, PagePath.PERSON_ADDRESS));

      await dispatch(makeSurePrefillFlowInStore());

      await navigateToNextPage();

      return;
    } else {
      fetchSuggestions(lookupQuery).then(async (result) => {
        if (result && Array.isArray(result) && result.length > 0) {
          await navigateToNextAddressPage();
        } else {
          setIsValidAddress(false);
        }
      });
    }
  }, [
    fields,
    primaryAddressRef,
    navigateToNextAddressPage,
    dispatch,
    address.props,
    primaryAddress,
    navigateToNextPage,
  ]);

  const navigateToPrivacyPolicyPage = useNavigateToPage(PagePath.PRIVACY_POLICY);

  const navigateToPrivacyPolicy = useEvent(() => {
    trackClick({ action: 'BodyTextPrivacyPolicyLink', label: 'PrivacyPolicy' });
    navigateToPrivacyPolicyPage();
  });

  return (
    <Page
      title="Let's get to know you better"
      analyticsElement='choice.personPage.page'
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.PERSON} />,
      }}
    >
      <InsuredInformationPageForm
        onNext={handleNext}
        geoAddressSuggestions={geoAddressSuggestions}
        setGeoAddressSuggestions={setGeoAddressSuggestions}
        lineOfBusiness={lineOfBusiness}
        isValidAddress={isValidAddress}
      />
      <Grid item xs={12}>
        <AgreementContent
          className={classes.agreementContent}
          onNavigateToPrivacyPolicy={navigateToPrivacyPolicy}
        />
      </Grid>
    </Page>
  );
};
