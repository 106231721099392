import { useCallback, useEffect, useState } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import type { TextFieldProps } from '@ecp/components';
import { NumberFormat, Snackbar } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Dialog } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getOfferProductsSelectedByType,
  useDeltaField,
  useField,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Vehicle } from '@ecp/features/sales/shared/types';

import { useStyles } from './DeltaOdometerQuestion.styles';

export interface DeltaOdometerQuestionProps extends QuestionProps {
  vehicle: Vehicle;
}

interface OdometerReadings {
  beforeValue: AnswerValue;
  nextValue: AnswerValue;
}

const newCarReplacementOdometerLimit = 500;

export const DeltaOdometerQuestion: React.FC<DeltaOdometerQuestionProps> = (props) => {
  const {
    vehicle,
    trackingName = 'delta_vehicle_odometer_question',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
  } = props;

  const { classes } = useStyles();

  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);
  const newCarReplacementSelectedField = useField(
    `${autoOfferProduct}.coverages.${vehicle.ref}.newCarReplacement.selected`,
  );

  const [removeNewCarReplacementDialogOpen, setRemoveNewCarReplacementDialogOpen] = useState(false);
  const [isRemovingNewCarReplacement, setIsRemovingNewCarReplacement] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [odometerReadings, setOdometerReadings] = useState<OdometerReadings>({
    beforeValue: '',
    nextValue: '',
  });

  const odometerReading = useDeltaField(vehicle.ref, 'odometerReading');
  useAddFields({ [`${odometerReading.key}`]: odometerReading });

  const handleClose = useCallback(() => {
    // User clicks cancel or closes dialog
    odometerReading.props.actionOnComplete(odometerReadings.beforeValue);
    setRemoveNewCarReplacementDialogOpen(false);
  }, [odometerReading.props, odometerReadings.beforeValue]);

  const onPrimaryDialogAction = useCallback(async () => {
    // User confirms to change odometer and remove new car replacement
    setIsRemovingNewCarReplacement(true);
    odometerReading.props.actionOnComplete(odometerReadings.nextValue);
    await newCarReplacementSelectedField.validateUpdateAndPatch(false);
    setIsRemovingNewCarReplacement(false);
    setRemoveNewCarReplacementDialogOpen(false);
    setSnackBarMsg(
      `New Car Replacement Coverage removed from ${vehicle.year} ${vehicle.make} ${vehicle.model}.`,
    );
  }, [
    newCarReplacementSelectedField,
    odometerReading.props,
    odometerReadings.nextValue,
    vehicle.make,
    vehicle.model,
    vehicle.year,
  ]);

  const removeNewCarReplacementDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove New Car Replacement Coverage?'
      textButtonLabel='Cancel'
      open={removeNewCarReplacementDialogOpen}
      onClose={handleClose}
      buttonPlacement='right'
      actionButtonOnClick={onPrimaryDialogAction}
      isProcessing={isRemovingNewCarReplacement}
    >
      <p>{`To qualify for New Car Replacement Coverage, the Current Odometer Reading for the 
      ${vehicle.year} ${vehicle.make} ${vehicle.model} must be 500 miles or less.`}</p>
      <br />
      <p>To continue, the New Car Replacement Coverage will be removed from the vehicle.</p>
    </Dialog>
  );

  const handleOdometerFocus = useEvent<NonNullable<TextFieldProps['onFocus']>>(() => {
    setOdometerReadings({ ...odometerReadings, beforeValue: odometerReading.value });
  });

  const handleOdometerBlur = useCallback(
    (value: AnswerValue) => {
      const newCarReplacementSelected = newCarReplacementSelectedField?.value === 'true';
      if (
        !newCarReplacementSelected ||
        (newCarReplacementSelected && !!value && Number(value) <= newCarReplacementOdometerLimit) ||
        (newCarReplacementSelected &&
          Number(value) > newCarReplacementOdometerLimit &&
          Number(odometerReadings.beforeValue) > newCarReplacementOdometerLimit)
      ) {
        odometerReading.props.actionOnComplete(value);
      } else {
        setOdometerReadings({ ...odometerReadings, nextValue: value });
        setRemoveNewCarReplacementDialogOpen(true);
      }
    },
    [newCarReplacementSelectedField, odometerReading.props, odometerReadings],
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setSnackBarMsg('');
      }
    },
    [],
  );

  const newCarReplacementRemovedSnack = (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!snackBarMsg}
      autoHideDuration={3000}
      message={snackBarMsg}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  /**
   * This handles a back nav situation from the delta page where the user entered above the limit without
   *   new car replacement coverage select, then goes back to quote page and selects it. When passing through
   *   the delta screen again this will check and correct it.
   */
  useEffect(() => {
    if (
      newCarReplacementSelectedField?.value === 'true' &&
      Number(odometerReading.value) > newCarReplacementOdometerLimit
    ) {
      setOdometerReadings({ beforeValue: null, nextValue: odometerReading.value });
      setRemoveNewCarReplacementDialogOpen(true);
    }
    // only execute on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAddConditionValues({
    conditionalFields: [odometerReading],
    isExcluded: () => false,
    isRequiredOverride: () => newCarReplacementSelectedField?.value === 'true',
  });

  if (!odometerReading.exists) return null;

  return (
    <>
      {newCarReplacementRemovedSnack}
      {removeNewCarReplacementDialog}
      <NumberFormat
        {...odometerReading.props}
        label='Current Odometer Reading'
        fullWidth={false}
        trackingName={trackingName}
        trackingLabel={trackingLabel}
        actionOnComplete={handleOdometerBlur}
        onFocus={handleOdometerFocus}
      />
    </>
  );
};
