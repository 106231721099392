import { useEffect } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { TextField } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import { useStyles } from './AwayAtSchoolSubQuestions.styles';

interface Props extends QuestionProps {
  driverRef: string;
}

export const AwayAtSchoolSubQuestions: React.FC<Props> = (props) => {
  const { driverRef } = props;
  const { classes } = useStyles();

  const awayAtSchool = useField(`${driverRef}.discount.awayAtSchool`);
  const awayAtSchoolCountry = useField(`${driverRef}.discount.awayAtSchoolCountry`);
  const awayAtSchoolZipCode = useField(`${driverRef}.discount.awayAtSchoolZip`);
  const driverId = driverRef.split('.')[1];

  useAddFields({ awayAtSchoolCountry, awayAtSchoolZipCode });
  useInitValues({ [awayAtSchoolCountry.key]: 'US' });

  useEffect(() => {
    // handles issue where zip code persists when away at school is deselected or country is set to not us
    if ((!awayAtSchool.value || awayAtSchoolCountry.value !== 'US') && awayAtSchoolZipCode.value) {
      awayAtSchoolZipCode.props.actionOnComplete(null);
    }
    // Only execute when one of the values changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awayAtSchool.value, awayAtSchoolCountry.value, awayAtSchoolZipCode.value]);

  useEffect(() => {
    // handles issue where country persists when away at school is deselected or country is set to not us
    if (!awayAtSchool.value && awayAtSchoolCountry.value) {
      awayAtSchoolCountry.props.actionOnComplete(null);
    } else if (awayAtSchool.value && !awayAtSchoolCountry.value) {
      awayAtSchoolCountry.props.actionOnComplete('US');
    }
    // Only execute when one of the values changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awayAtSchool.value, awayAtSchoolCountry.value]);

  if (!awayAtSchool.value || !awayAtSchoolCountry.exists || !awayAtSchoolZipCode.exists)
    return null;

  const needsZip = awayAtSchoolCountry.value === 'US';
  const countrySelect = (
    <div className={classes.oneColumn}>
      <Select
        {...(awayAtSchoolCountry.props as OptionProps)}
        inputButtonAriaLabel='AwayAtSchoolCountry'
        id={`AwayAtSchoolCountry-${driverId}`}
        label='School Country'
        trackingName='AwayAtSchoolCountry'
        trackingLabel={GoogleAnalyticsLabels.REDACTED}
      />
    </div>
  );

  const countryAndZip = (
    <Grid container spacing={2} key={driverRef} className={classes.root}>
      <Grid item xs={6} className={classes.questionItem}>
        <Select
          {...(awayAtSchoolCountry.props as OptionProps)}
          inputButtonAriaLabel='AwayAtSchoolCountry'
          id={`AwayAtSchoolCountry-${driverId}`}
          label='School Country'
          trackingName='AwayAtSchoolCountry'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </Grid>

      <Grid item xs={6} className={classes.questionItem}>
        <TextField
          {...awayAtSchoolZipCode.props}
          id={`AwayAtSchoolZipCode-${driverId}`}
          label='School Zip Code'
          maxLength={5}
          trackingName='AwayAtSchoolZipCode'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </Grid>
    </Grid>
  );

  return needsZip ? countryAndZip : countrySelect;
};
