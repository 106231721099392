import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useDeltaField } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';

interface Props extends QuestionProps {
  product: Product;
}

export const DeltaAutopayPaymentOptions: React.FC<Props> = (props) => {
  const {
    product,
    trackingName = 'delta_auto_pay_payment_options_question',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
  } = props;

  const autoPayType = useDeltaField(`coverageOption.${product}`, 'autoPayType');
  useAddFields({ autoPayType });

  if (!autoPayType.exists) return null;

  return (
    <Select
      {...(autoPayType.props as OptionProps)}
      label='Payment Option'
      id='autoPayType'
      options={[
        { label: 'Select Payment Option', value: '' },
        ...(autoPayType.props?.options ?? []),
      ]}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};
