import { useCallback, useEffect, useState } from 'react';

import { GridItem } from '@ecp/components';
import { DatePicker } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../../utils';

interface ConstructionDetailsProps extends QuestionProps {
  resetValues: boolean;
}
export const ConstructionEstimatedCompletionDate: React.FC<ConstructionDetailsProps> = (props) => {
  const { resetValues } = props;

  const estimatedCompletionDateField = usePropertyField(
    'construction.constructionEstimatedCompletionDate',
    true,
  );

  const [isResetDone, setIsResetDone] = useState(false);

  const resetConstructionValues = useCallback((): void => {
    estimatedCompletionDateField?.validateUpdateAndPatch(null);
    setIsResetDone(true);
  }, [estimatedCompletionDateField]);

  useEffect(() => {
    if (resetValues && !isResetDone) {
      resetConstructionValues();
    }
  }, [resetValues, resetConstructionValues, isResetDone]);

  if (!estimatedCompletionDateField) return null;

  const {
    label = estimatedCompletionDateField.question?.title,
    trackingName = 'estimated_completion_date_question',
  } = props;

  return (
    <GridItem topSpacing='lg' bottomSpacing='sm' xs={12}>
      <DatePicker
        {...estimatedCompletionDateField?.props}
        groupLabel={label}
        fullWidth={false}
        format='MM/DD/YYYY'
        placeholder='MM/DD/YYYY'
        autoComplete='off'
        id='EstimatedCompletionDate'
        trackingName={trackingName}
        data-testid={trackingName}
      />
    </GridItem>
  );
};
