import { Grid } from '@mui/material';

import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { isLineOfBusinessProperty } from '@ecp/features/shared/product';
import { IconAfiProductRenter } from '@ecp/themes/partners/amfam-adv';

import { PropertyThirdPartyReportsPageForm } from '../PropertyThirdPartyReportsPageForm';
import { useStyles } from './RentersThirdPartyReportsPageForm.styles';

export const RentersThirdPartyReportsPageForm: React.FC = () => {
  const { classes } = useStyles();
  const lineOfBusiness = useSelector(getLineOfBusiness);

  if (!isLineOfBusinessProperty(lineOfBusiness)) {
    return null;
  }

  return (
    <Grid item container rowSpacing={3}>
      <Grid item container xs={12}>
        <Grid item xs='auto'>
          <IconAfiProductRenter className={classes.icon} />
        </Grid>
        <Grid item xs>
          <h2>Renters Policy Report Results</h2>
        </Grid>
      </Grid>
      <PropertyThirdPartyReportsPageForm />
    </Grid>
  );
};
