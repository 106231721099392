import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AddressSuggestionForm' })((theme) => ({
  ...theme.mixins.formAll,
  card: {
    alignItems: 'flex-start',
    background: theme.palette.grey[200],
    marginTop: 20,
    padding: 20,
    '&:first-child': {
      marginTop: 0,
    },
  },
  cardSelected: {
    ...theme.mixins.shadow1,
    background: theme.palette.grey[50],
    transition: '0.5s',
  },
  useAddress: theme.typography.body2Bold,
  radio: {
    padding: 0,
  },
  label: {
    ...theme.typography.body2Bold,
    marginLeft: 16,
  },
  labelAddress: {
    ...theme.typography.body1,
    marginTop: 5,
  },
  labelAddressHighlight: {
    ...theme.typography.body1Bold,
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 2,
  },
  editButton: {
    marginLeft: 10,
  },
}));
