import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoveragesForm' })((theme) => ({
  root: {
    ...theme.mixins.form,
    ...theme.mixins.withRightSidebar.contentContainer,
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 !important',
    },
  },
  formContainer: theme.mixins.withRightSidebar.formContainer,
  container: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    '@media all and (-ms-high-contrast:none)': {
      display: 'block',
    },
  },
  divider: {
    marginTop: 30,
    marginBottom: 40,
  },
  disclaimer: {
    ...theme.typography.body3Italics,
    marginBottom: 40,
  },
  addSpacing: theme.mixins.addSpacing,
}));
