import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DriverLossAndViolationForm' })((theme) => ({
  ...theme.mixins.formAll,
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 8,
    gap: 16,
  },
  driverLossViolationContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
  },
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  lossesAndViolation: {
    ...theme.typography.h4,
  },
  secondaryText: {
    ...theme.typography.body1,
    color: theme.palette.text.tertiary,
    margin: '8px 0px',
  },
}));
