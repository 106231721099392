import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { useSetupDriverForm } from '@ecp/features/sales/quotes/auto';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';

import { DriversPageForm } from '../../forms/DriversPageForm';

export const DriversPage: React.FC = () => {
  const handleNext = useNavigateToPage(PagePath.ADD_EDIT_VEHICLES);

  useSetupDriverForm();

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.ADD_EDIT_DRIVERS} />,
        }}
        title='Drivers'
        analyticsElement='choice.driverDetailsPage.page'
      >
        <DriversPageForm onNext={handleNext} />
      </Page>
    </div>
  );
};
