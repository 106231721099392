import { useCallback } from 'react';

import { TooltipWithIcon } from '@ecp/components';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

import { usePropertyField } from '../../utils';

export const HistoricHomeQuestion: React.FC<QuestionProps> = (props) => {
  const historicHomeField = usePropertyField('isHistoricHome', true);
  const historicHomeDesignationField = useFieldWithPrefix(
    'property',
    'property',
  )('historicHomeDesignation');

  const { label = historicHomeField?.question?.title, trackingName = 'HistoricHomeButton' } = props;

  const handleHistoricHomeComplete = useCallback(
    async (value: AnswerValue) => {
      historicHomeField?.validateUpdateAndPatch(value);
      if (value === false) {
        historicHomeDesignationField?.validateUpdateAndPatch(null);
      }
    },
    [historicHomeField, historicHomeDesignationField],
  );

  if (!historicHomeField) return null;
  const helpText = historicHomeField.question?.helpText;

  return (
    <RadioGroupWithOptions
      {...historicHomeField.props}
      variant='yesNoButton'
      id='HistoricHome'
      trackingName={trackingName}
      trackingLabel={historicHomeField.props.value}
      actionOnComplete={handleHistoricHomeComplete}
      label={
        <>
          {label}
          {helpText && <TooltipWithIcon title={helpText} />}
        </>
      }
    />
  );
};
