import {
  getLineOfBusiness,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { ProtectiveDeviceFormQuestion } from './ProtectiveDeviceFormQuestion';

export const ProtectiveDeviceForm: React.FC = () => {
  const { property } = useSelector(getOfferProductsSelectedByType);
  const selectedLob = useSelector(getLineOfBusiness);

  const lob =
    selectedLob === LineOfBusiness.BUNDLE_AUTO_RENTERS || selectedLob === LineOfBusiness.RENTERS
      ? 'renters'
      : 'home';

  return property && <ProtectiveDeviceFormQuestion lob={lob} />;
};
