import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { validateAndCombineAddress } from '@ecp/utils/geo';

import { Alert } from '@ecp/components';
import { GridItem, NumberFormat } from '@ecp/components';
import { AutoComplete, Select, TextField } from '@ecp/features/sales/shared/components';
import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  createAddressForUpdate,
  getPniPersonInfo,
  setFormErrorsChangedByField,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import { useAddressFields, useAddressSearch } from '../../utils';

interface Props extends QuestionProps {
  label: string;
  trackingName: string;
  addressRef: string;
  addressType: string;
  isValidAddress: boolean;
  isAddressChanged: boolean;
}

export const AddressQuestions: React.FC<Props> = (props) => {
  const { addressRef, label, trackingName, addressType, isValidAddress, isAddressChanged } = props;
  const dispatch = useDispatch();
  const { firstName } = useSelector(getPniPersonInfo);
  const {
    address: { line1, line2, city, state, zipcode },
  } = useAddressFields(addressRef);

  const {
    autoCompleteAddressSuggestions,
    addressSuggestions,
    handleSuggestionsClearRequested,
    handleAddressSuggestionsFetch,
    handleAddressSelection,
    handleAptSuggestionFetchRequested,
    setAddressSuggestions,
    gaTrackSuggestionClick,
  } = useAddressSearch();

  const handleAddressSuggestionFetch = useCallback(
    (value: string) => {
      handleAddressSuggestionsFetch(value, zipcode, state);
    },
    [zipcode, state, handleAddressSuggestionsFetch],
  );

  const handleAddressSuggestion = useCallback(
    async (value: string) => {
      if (
        addressType === 'PRIMARY' &&
        value.match(/\bP(ost|ostal)?([ .]*O(ffice)?)?([ .]*Box)?\b/i)
      ) {
        dispatch(
          setFormErrorsChangedByField({
            key: line1.key,
            errors: ['Address cannot be a PO Box number.'],
          }),
        );
      }

      const adressToBeValiadted = handleAddressSelection(value);
      if (adressToBeValiadted) {
        if (adressToBeValiadted.entries <= 1) {
          line1.props.actionOnComplete(adressToBeValiadted.street_line);
          const inputAddress = {
            street: adressToBeValiadted.street_line,
            city: adressToBeValiadted.city,
            street2: adressToBeValiadted.secondary !== '' ? adressToBeValiadted.secondary : '',
            state: adressToBeValiadted.state,
            zipcode: adressToBeValiadted.zipcode,
          };
          const validatedAddress = await validateAndCombineAddress(inputAddress, addressRef);
          if (validatedAddress) {
            line1.props.actionOnComplete(validatedAddress.line1);
            line2.props.actionOnComplete(validatedAddress.line2);
            city.props.actionOnComplete(validatedAddress.city);
            state.props.actionOnComplete(`${STATE_CODE_PREFIX}${validatedAddress.state}`);
            zipcode.props.actionOnComplete(validatedAddress.zipcode);
            const addressToBeSaved = createAddressForUpdate(validatedAddress);
            await dispatch(updateAnswers({ answers: { ...addressToBeSaved } }));
          }
        } else {
          line1.props.actionOnComplete(adressToBeValiadted.street_line);
          // need to pass selected address as well as the letter A to limit the results to number of valid entries recieved for address.
          // Will  return # of entries whose secondary value starts with A.
          const selectedValue = `${adressToBeValiadted.street_line} ${adressToBeValiadted.secondary} A (${adressToBeValiadted.entries}) ${adressToBeValiadted.city} ${adressToBeValiadted.state} ${adressToBeValiadted.zipcode}`;
          // If selected address is a series of apartments
          const fetchNewAptSuggestions = await handleAptSuggestionFetchRequested(
            adressToBeValiadted.street_line,
            selectedValue,
          );

          setAddressSuggestions(fetchNewAptSuggestions);
        }
      }
    },
    [
      addressRef,
      addressType,
      city.props,
      dispatch,
      handleAddressSelection,
      handleAptSuggestionFetchRequested,
      line1.key,
      line1.props,
      line2.props,
      setAddressSuggestions,
      state.props,
      zipcode.props,
    ],
  );

  const handlePoBoxValidation = useCallback(
    async (value: string) => {
      if (
        addressType === 'PRIMARY' &&
        value.match(/\bP(ost|ostal)?([ .]*O(ffice)?)?([ .]*Box)?\b/i)
      ) {
        dispatch(
          setFormErrorsChangedByField({
            key: line2.key,
            errors: ['Address cannot be a PO Box number.'],
          }),
        );
      }
    },
    [addressType, dispatch, line2.key],
  );

  return (
    <Grid container>
      <Grid container columnSpacing={4}>
        {line1.exists && (
          <GridItem xs={12} md={6}>
            <AutoComplete
              {...line1.props}
              label={label}
              suggestions={autoCompleteAddressSuggestions}
              geoAddressFormattedSuggestions={addressSuggestions}
              onSuggestionsClearRequested={handleSuggestionsClearRequested}
              onSuggestionsFetchRequested={handleAddressSuggestionFetch}
              onSuggestionSelected={handleAddressSuggestion}
              id='AddressAutoComplete'
              trackingName={trackingName + `_line_1`}
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              gaTrackSuggestionClick={gaTrackSuggestionClick}
            />
          </GridItem>
        )}
        {line2.exists && (
          <GridItem xs={12} md={6}>
            <TextField
              {...line2.props}
              id='Apt'
              label='Apt./Unit # (optional)'
              trackingName={trackingName + `_line_2`}
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              actionOnComplete={handlePoBoxValidation}
            />
          </GridItem>
        )}
        {city.exists && (
          <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
            <TextField
              {...city.props}
              id='city'
              label='City'
              trackingName={trackingName + `_city`}
              trackingLabel={city.props.value}
            />
          </GridItem>
        )}
        {state.exists && (
          <GridItem topSpacing='sm' xs={12} md={4}>
            <Select
              {...(state.props as OptionProps)}
              id='state'
              label='State'
              trackingName={trackingName + `_state`}
              trackingLabel={state.props.value}
            />
          </GridItem>
        )}
        {zipcode.exists && (
          <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
            <NumberFormat
              {...zipcode.props}
              id='Zip'
              formatType='zipcode'
              label='ZIP Code'
              trackingName={trackingName + `_zip_code`}
            />
          </GridItem>
        )}

        {!isValidAddress && !isAddressChanged && (
          <GridItem topSpacing='sm' xs={12}>
            <Alert withIcon type='error'>
              We could not find a match in the the United States Postal Service database for{' '}
              {firstName}’s address. Please verify that the entered address is correct.
            </Alert>
          </GridItem>
        )}
      </Grid>
    </Grid>
  );
};
