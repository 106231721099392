import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';
import type { GeoAddress } from '@ecp/utils/geo';
import { useGeoAddressOptions } from '@ecp/utils/geo';
import { datadogLog } from '@ecp/utils/logger';

import {
  PRIMARY_INSURED_ADDRESS_REF,
  PRIMARY_INSURED_MAILING_ADDRESS_REF,
  STATIC_PREVIOUS_PRIMARY_INSURED_ADDRESS_REF,
  STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF,
} from '@ecp/features/sales/shared/constants';
import {
  createRef,
  deleteInquiryRef,
  getAnswer,
  getInquiryLoaded,
  updateAddedRef,
  updateAnswers,
  useFieldWithPrefix,
  usePriorAddressRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Address, Answers, FieldsDef } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { Field, Fields } from '@ecp/types';

export interface AddressFields extends Fields {
  address: FieldsDef<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'isLocked'>>;
}

export const useAddressFields = (addressRef: string): AddressFields => {
  const useAddressField = useFieldWithPrefix(addressRef, 'address.<id>');

  return {
    address: {
      line1: useAddressField('line1'),
      line2: useAddressField('line2'),
      city: useAddressField(`city`),
      state: useAddressField(`state`),
      zipcode: useAddressField('zipcode'),
      isLocked: useAddressField('isLocked'),
    },
  };
};

export const useRemoveAllAddressRef = (ref: string): (() => void) => {
  const dispatch = useDispatch();
  const addressRefs = useAddressRef(ref);

  return useCallback(async () => {
    addressRefs.forEach(async (addressRef) => {
      await dispatch(deleteInquiryRef({ refType: 'address', refId: addressRef.split('.')[1] }));
    });
  }, [dispatch, addressRefs]);
};

export const useAddressRef = (ref: string): string[] =>
  (useSelector((state: RootStore) => getAnswer(state, ref)) as Array<string>) || [];

export const useAddAddress = (): ((ref: string) => string) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(
    (ref: string) => {
      if (!inquiryLoaded) {
        datadogLog({
          logType: 'error',
          message: 'inquiry not loaded',
          context: {
            logOrigin: 'apps/sales/edsp-asp/src/common/utils/AddressUtil.ts',
            functionOrigin: 'useAddAddress/useCallback',
          },
        });
        throw new Error('inquiry not loaded');
      }

      const addressRef = dispatch(createRef('address'));

      dispatch(
        updateAddedRef({
          type: ref,
          newRef: addressRef,
        }),
      );

      return addressRef;
    },
    [dispatch, inquiryLoaded],
  );
};
interface AddressAutoComplete {
  autoCompleteAddressSuggestions: string[];
  addressSuggestions: GeoAddress[];
  handleSuggestionsClearRequested: () => void;
  handleAddressSuggestionsFetch: (value: string, zip: Field, state: Field) => Promise<void>;
  handleAddressSelection: (value: string) => GeoAddress | undefined;
  gaTrackSuggestionClick: () => void;
  handleAptSuggestionFetchRequested(
    searchValue: string,
    selectedValue: string,
  ): Promise<GeoAddress[]>;
  setAddressSuggestions: Dispatch<SetStateAction<GeoAddress[]>>;
}

export const useAddressSearch = (): AddressAutoComplete => {
  const {
    handleSuggestionsFetchRequested,
    handleSuggestionsClearRequested,
    handleAptSuggestionFetchRequested,
  } = useGeoAddressOptions();
  const [addressSuggestions, setAddressSuggestions] = useState<GeoAddress[]>([]);

  const autoCompleteAddressSuggestions = useMemo(
    () =>
      addressSuggestions.map((s: GeoAddress, index, arr) => {
        if (arr.length - 1 === index) {
          return s.street_line;
        }

        return (
          s.street_line +
          (s.secondary !== ''
            ? ` ${s.secondary} (${s.entries} more entries) ${s.city}, ${s.state} ${s.zipcode}`
            : ` ${s.city}, ${s.state} ${s.zipcode}`)
        );
      }),
    [addressSuggestions],
  );

  const handleAddressSuggestionsFetch = useCallback(
    async (value: string, zip: Field, state: Field): Promise<void> => {
      if (!value.includes('Apt') || !value.includes('Ste')) {
        // Need to pass state value as well to grab suggestions instead of just filtering on zipcode because garage address
        // may be different from primary address filled in.
        const output = await handleSuggestionsFetchRequested(
          value,
          zip.value as string,
          (state.value as string) || '',
        );
        setAddressSuggestions(output);
      }
    },
    [handleSuggestionsFetchRequested],
  );

  const handleAddressSelection = useCallback(
    (value: string) => {
      const addressToBeValidated = addressSuggestions.find((address: GeoAddress) => {
        const fullAddress =
          address.street_line +
          (address.secondary !== ''
            ? ` ${address.secondary} (${address.entries} more entries) ${address.city}, ${address.state} ${address.zipcode}`
            : ` ${address.city}, ${address.state} ${address.zipcode}`);

        return fullAddress === value;
      });

      return addressToBeValidated;
    },
    [addressSuggestions],
  );
  const gaTrackSuggestionClick = (): void => {
    // TODO: Update tracking events as per address usage
    trackClick({
      action: 'SmartyStreetSuggestedAddress',
      label: 'ClickedSuggestion',
    });
    trackSapiAnalyticsEvent({
      element: 'choice.personForm.smartyStreetSuggestion',
      event: 'click',
      eventDetail: 'true',
    });
  };

  return {
    autoCompleteAddressSuggestions,
    addressSuggestions,
    handleSuggestionsClearRequested,
    handleAddressSuggestionsFetch,
    handleAddressSelection,
    gaTrackSuggestionClick,
    handleAptSuggestionFetchRequested,
    setAddressSuggestions,
  };
};

export const useUpdatePriorAddressType = (): (() => Promise<void>) => {
  const dispatch = useDispatch();

  const addressRef = usePriorAddressRef();

  const useAddressField = useFieldWithPrefix(addressRef, 'address.<id>');
  const addressType = useAddressField('addressTypes');

  return useCallback(async () => {
    if (addressRef) {
      await dispatch(
        updateAnswers({
          answers: { [addressType.key]: 'PRIOR_PRIMARY' },
        }),
      );
    }
  }, [addressRef, addressType.key, dispatch]);
};

export const buildAddressLabel = (
  addressInfo: Pick<Address, 'city' | 'line1' | 'line2' | 'state' | 'zipcode'>,
): string => {
  const addressLabel = `${addressInfo.line1} ${
    addressInfo.line2 ? ` ${addressInfo.line2},` : ','
  }${' '}${addressInfo.city} ${addressInfo.state} ${addressInfo.zipcode}`;

  return addressLabel;
};

export const isAddressComplete = (
  ref: Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'isLocked'>,
): boolean => {
  return !!(ref.line1 && ref.city && ref.state && ref.zipcode);
};

export const isAddressUnique = (
  Address1: Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'isLocked'>,
  Address2: Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'isLocked'>,
): boolean => {
  return JSON.stringify(Address1) !== JSON.stringify(Address2);
};

export const updatePrimaryMailingAddress = (
  primaryAddressRef: string,
  primaryMailingAddressRef: string,
  initialPrimaryInsuredMailingAddresseRefFromRelate: string,
  selectedRef: string,
  primaryMailingAddressTypesFromRelate: string[],
  primaryAddressTypes: string[],
): Answers => {
  const isNewAddressSelected =
    selectedRef !== primaryAddressRef &&
    selectedRef !== initialPrimaryInsuredMailingAddresseRefFromRelate;

  let answers: Answers = {};
  if (isNewAddressSelected) {
    if (primaryMailingAddressTypesFromRelate) {
      answers = {
        [PRIMARY_INSURED_MAILING_ADDRESS_REF]: [selectedRef],
        [STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF]: [selectedRef],
        [`${selectedRef}.addressTypes`]: 'MAILING',
        [`${primaryAddressRef}.addressTypes`]: primaryAddressTypes
          .filter((addresType) => addresType !== 'MAILING')
          .toString(),
        [`${primaryMailingAddressTypesFromRelate}.addressTypes`]:
          primaryMailingAddressTypesFromRelate.length > 1
            ? primaryMailingAddressTypesFromRelate
                .filter((addresType) => addresType !== 'MAILING')
                .toString()
            : 'UNASSIGNED',
      };
    } else {
      answers = {
        [PRIMARY_INSURED_MAILING_ADDRESS_REF]: [selectedRef],
        [STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF]: [selectedRef],
        [`${selectedRef}.addressTypes`]: 'MAILING',
        [`${primaryAddressRef}.addressTypes`]: primaryAddressTypes
          .filter((addresType) => addresType !== 'MAILING')
          .toString(),
      };
    }
  } else {
    if (
      initialPrimaryInsuredMailingAddresseRefFromRelate &&
      selectedRef !== initialPrimaryInsuredMailingAddresseRefFromRelate
    ) {
      primaryAddressTypes.push('MAILING');
      answers = {
        [PRIMARY_INSURED_MAILING_ADDRESS_REF]: [selectedRef],
        [`${selectedRef}.addressTypes`]: primaryAddressTypes,
        [`${primaryMailingAddressRef}.addressTypes`]:
          primaryMailingAddressTypesFromRelate.length > 1
            ? primaryMailingAddressTypesFromRelate
                .filter((addresType) => addresType !== 'MAILING')
                .toString()
            : 'UNASSIGNED',
      };
    } else {
      primaryMailingAddressTypesFromRelate.push('MAILING');
      answers = {
        [PRIMARY_INSURED_MAILING_ADDRESS_REF]: [selectedRef],
        [`${selectedRef}.addressTypes`]: primaryMailingAddressTypesFromRelate
          .filter((addresType) => addresType !== 'UNASSIGNED')
          .toString(),
        [`${primaryAddressRef}.addressTypes`]: primaryAddressTypes
          .filter((addresType) => addresType !== 'MAILING')
          .toString(),
      };
    }
  }

  return answers;
};

export const updatePrimaryInsuredAddress = (
  primaryAddressRef: string,
  primaryMailingAddressRef: string,
  initialPrimaryInsuredAddresseRefFromRelate: string,
  selectedRef: string,
  primaryInsuredAddressTypesFromRelate: string[],
  primaryMailingdAddressTypes: string[],
): Answers => {
  const isNewAddressSelected =
    selectedRef !== primaryMailingAddressRef &&
    selectedRef !== initialPrimaryInsuredAddresseRefFromRelate;

  let answers: Answers = {};
  const pniAddressType =
    primaryInsuredAddressTypesFromRelate.length > 1 &&
    primaryInsuredAddressTypesFromRelate
      .filter((addresType) => addresType !== 'PRIMARY' && addresType !== 'INSURED')
      .toString();

  if (isNewAddressSelected) {
    if (initialPrimaryInsuredAddresseRefFromRelate && primaryMailingAddressRef) {
      answers = {
        [PRIMARY_INSURED_ADDRESS_REF]: [selectedRef],
        [STATIC_PREVIOUS_PRIMARY_INSURED_ADDRESS_REF]: [selectedRef],
        [`${selectedRef}.addressTypes`]: 'PRIMARY',
        [`${initialPrimaryInsuredAddresseRefFromRelate}.addressTypes`]:
          pniAddressType || 'UNASSIGNED',
        [`${primaryMailingAddressRef}.addressTypes`]: primaryMailingdAddressTypes
          .filter((addresType) => addresType !== 'PRIMARY' && addresType !== 'INSURED')
          .toString(),
      };
    } else {
      answers = {
        [PRIMARY_INSURED_MAILING_ADDRESS_REF]: [selectedRef],
        [STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF]: [selectedRef],
        [`${selectedRef}.addressTypes`]: 'MAILING',
        [`${initialPrimaryInsuredAddresseRefFromRelate}.addressTypes`]:
          pniAddressType || 'UNASSIGNED',
      };
    }
  } else {
    if (
      initialPrimaryInsuredAddresseRefFromRelate &&
      initialPrimaryInsuredAddresseRefFromRelate !== selectedRef
    ) {
      primaryMailingdAddressTypes.push('PRIMARY');
      answers = {
        [PRIMARY_INSURED_ADDRESS_REF]: [selectedRef],
        [`${selectedRef}.addressTypes`]: primaryMailingdAddressTypes,
        [`${primaryAddressRef}.addressTypes`]: pniAddressType || 'UNASSIGNED',
      };
    } else {
      primaryInsuredAddressTypesFromRelate.push('PRIMARY');
      answers = {
        [PRIMARY_INSURED_ADDRESS_REF]: [selectedRef],
        [`${selectedRef}.addressTypes`]: primaryInsuredAddressTypesFromRelate
          .filter((addresType) => addresType !== 'UNASSIGNED')
          .toString(),
        [`${primaryMailingAddressRef}.addressTypes`]:
          selectedRef !== primaryMailingAddressRef
            ? primaryMailingdAddressTypes
                .filter((addresType) => addresType !== 'PRIMARY' && addresType !== 'INSURED')
                .toString()
            : primaryMailingdAddressTypes.toString(),
      };
    }
  }

  return answers;
};
