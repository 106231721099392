import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './PostBindFormBody.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        policyInformationHeader: css({
          ...theme.typography.h2,
          marginBottom: 10,
        }),
        allSetContainer: css({
          display: 'flex',
          justifyContent: 'center',
          marginTop: 0,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
