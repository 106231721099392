import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { updatePageStatus } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { AutoThirdPartyReportsPageForm } from '../../../lob/autoLine';
import { RentersThirdPartyReportsPageForm } from '../../../lob/propertyLine';
import { useStyles } from './ThirdPartyReportsPageForm.styles';

interface Props {
  onNext?: () => Promise<void>;
  nextPageInstructions?: string;
}

export const ThirdPartyReportsPageForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async () => {
    dispatch(updatePageStatus(NavStatus.VALID));
    if (onNext) await onNext();
  }, [dispatch, onNext]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return (
    <div className={classes.root}>
      <Form>
        <Grid container>
          <AutoThirdPartyReportsPageForm />
          <RentersThirdPartyReportsPageForm />

          <Grid item xs={12}>
            <NextPageInstructions divider />
          </Grid>

          <Grid className={classes.buttonsPanel} container item xs={12}>
            <div className={classes.addButton}>
              <Button
                className={classes.next}
                variant='primary'
                onClick={handleSubmit}
                data-testid='thirdPartyReportsContinue'
                onMouseDown={handleMouseDown}
                // isProcessing={isPatchFormInProgress || isSubmitting}
                trackingName={GoogleAnalyticsLabels.CONTINUE}
                trackingLabel='third_party_reports_continue'
                analyticsElement='choice.thirdPartyReportsPage.continueButton'
              >
                Continue
              </Button>
            </div>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
