import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const HistoricHomeDesignationQuestion: React.FC<QuestionProps> = (props) => {
  const historicHomeDesignationField = usePropertyField('historicHomeDesignation', true);

  if (!historicHomeDesignationField) return null;

  const {
    label = historicHomeDesignationField.question?.title,
    trackingName = 'HistoricHomeDesignation',
  } = props;

  return (
    <Select
      {...historicHomeDesignationField.props}
      id='historicHomeDesignation'
      groupLabel={label}
      trackingName={trackingName}
      inputButtonAriaLabel='historicHomeDesignation'
      fullWidth={false}
    />
  );
};
