import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { isLineOfBusinessProperty } from '@ecp/features/shared/product';

export const PropertyThirdPartyReportsPageForm: React.FC = () => {
  const lineOfBusiness = useSelector(getLineOfBusiness);

  if (!isLineOfBusinessProperty(lineOfBusiness)) {
    return null;
  }

  return (
    <GridItem xs={12}>
      <Grid container spacing={3}>
        <GridItem xs={12}>
          <h3>Property Claims Loss (PCL) Status</h3>
          Implement Property Claims Loss component here
        </GridItem>
        <GridItem xs={12}>
          <h3>Property Losses</h3>
          Implement Property Losses component here
        </GridItem>
        <GridItem xs={12}>
          <h3>Credit Bureau Reports</h3>
          Implement Credit Bureau Reports component here
        </GridItem>
        <GridItem xs={12}>
          <h3>Property Hazard Reports</h3>
          Implement Property Hazard Reports component here
        </GridItem>
        <GridItem xs={12}>
          <h3>Inland Flood Information</h3>
          Implement Inland Flood component here
        </GridItem>
        <GridItem xs={12}>
          <h3>Non-Pay Occurences</h3>
          Implement Non-Pay occurences component here
        </GridItem>
      </Grid>
    </GridItem>
  );
};
