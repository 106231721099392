import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { isEmpty } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { Snackbar, SnackbarAlert } from '@ecp/components';
import {
  useBusinessOnPremises,
  useCreateBusinessOnPremises,
  useRemoveAllBusinessOnPremises,
  useRemoveBusinessOnPremises,
} from '@ecp/features/sales/quotes/property/home';
import { Dialog, RadioGroupWithOptions, Resource } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getBusinessOnPremisesRefs,
  getQuestion,
  isAnyApiInProgress,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useLoading } from '@ecp/features/sales/shared/utils/web';
import type { AnswerValue, Field, Fields } from '@ecp/types';

import { usePropertyField } from '../../utils';
import { BusinessOnPremisesForm } from './BusinessOnPremisesForm';
import { useStyles } from './BusinessOnPremisesQuestion.styles';

export interface BusinessOnPremisesData {
  businessType: AnswerValue;
  businessDescription: AnswerValue;
  commercialCoverageInd: AnswerValue;
  employeeInd: AnswerValue;
}

export const BusinessOnPremisesQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const businessOnPremises = usePropertyField('businessOnPremises');
  const businessOnPremisesRefs = useSelector(getBusinessOnPremisesRefs);
  const numBusinesses = !businessOnPremisesRefs ? 0 : businessOnPremisesRefs.length;
  const addBusinessType = useCreateBusinessOnPremises();
  const removeBusinessType = useRemoveBusinessOnPremises();
  const removeAllBusinessOnPremises = useRemoveAllBusinessOnPremises();
  const businessOnPremisesValues = useBusinessOnPremises(businessOnPremisesRefs);
  const businessTypeQuestion = useSelector(getQuestion('businessOnPremises.<id>.businessType'));
  const [editBusinessTypeRef, setEditBusinessTypeRef] = useState('');
  const [removeBusinessTypeRef, setRemoveBusinessTypeRef] = useState('');
  // Used to determine if cancel should delete the ref or leave it be
  const [newRef, setNewRef] = useState('');
  const [snackbarInfoMessage, setSnackbarInfoMessage] = useState('');
  const [showSnackbarAlert, setShowSnackbarAlert] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removeAllDialogOpen, setRemoveAllDialogOpen] = useState(false);
  const shouldWait = useSelector(isAnyApiInProgress);
  const { isLoading, withLoading } = useLoading(shouldWait);
  const [businessDataBeforeEdit, setBusinessDataBeforeEdit] = useState<
    BusinessOnPremisesData | undefined
  >();
  const [businessFields, setBusinessFields] = useState<Fields | undefined>();

  const {
    label = businessOnPremises?.question?.title,
    trackingName = 'conduct_business_at_home_button',
  } = props;

  const handleAddBusinessOnPremises = useCallback(async () => {
    const newBusiness = addBusinessType();
    const { businessRef } = newBusiness;
    await newBusiness.done;
    setEditBusinessTypeRef(businessRef);
    setNewRef(businessRef);
    trackClick({ action: 'AddBusinessTypeButton', label: 'AddBusinessType' });
  }, [addBusinessType, setEditBusinessTypeRef, setNewRef]);

  const handleCancelBusinessOnPremises = withLoading(async () => {
    // Save old values
    if (!isEmpty(businessFields)) {
      const { businessType, businessDescription, commercialCoverageInd, employeeInd } =
        businessFields as Fields;
      if (businessType) {
        const businessTypeField = businessType as Field;
        businessTypeField.validateUpdateAndPatch(businessDataBeforeEdit?.businessType);
      }
      if (businessDescription) {
        const businessDescriptionField = businessDescription as Field;
        businessDescriptionField.validateUpdateAndPatch(
          businessDataBeforeEdit?.businessDescription,
        );
      }
      if (commercialCoverageInd) {
        const commercialCoverageIndField = commercialCoverageInd as Field;
        commercialCoverageIndField.validateUpdateAndPatch(
          businessDataBeforeEdit?.commercialCoverageInd,
        );
      }
      if (employeeInd) {
        const employeeIndField = employeeInd as Field;
        employeeIndField.validateUpdateAndPatch(businessDataBeforeEdit?.employeeInd);
      }
    }
    trackClick({ action: 'CancelBusinessTypeButton', label: 'CancelBusinessType' });
    setEditBusinessTypeRef('');
    setBusinessFields(undefined);
    setBusinessDataBeforeEdit(undefined);
    setCancelDialogOpen(false);
    setSnackbarInfoMessage('Changes discarded.');
  });

  const handleSaveBusinessOnPremises = useCallback(() => {
    setEditBusinessTypeRef('');
    setNewRef('');
    setShowSnackbarAlert(true);
  }, [setEditBusinessTypeRef, setNewRef, setShowSnackbarAlert]);

  const removeBusinessAction = withLoading(async (ref: string) => {
    await removeBusinessType(ref);
    // Only set the yes/no question to false if the edit form was open
    if (numBusinesses === 1 && editBusinessTypeRef !== '') {
      businessOnPremises?.props.actionOnComplete(false);
    }
  });

  const handleRemoveBusinessOnPremise = useCallback(async () => {
    await removeBusinessAction(removeBusinessTypeRef);
    trackClick({ action: 'RemoveBusinessTypeButton', label: 'RemoveBusinessType' });
    // If we just removed the last business, open a new edit form
    if (numBusinesses === 1 && editBusinessTypeRef === '') {
      handleAddBusinessOnPremises();
    } else {
      setEditBusinessTypeRef('');
      setNewRef('');
      setBusinessDataBeforeEdit(undefined);
    }
    setRemoveBusinessTypeRef('');
    setRemoveDialogOpen(false);
    setSnackbarInfoMessage('Business removed.');
  }, [
    editBusinessTypeRef,
    numBusinesses,
    removeBusinessAction,
    removeBusinessTypeRef,
    handleAddBusinessOnPremises,
    setBusinessDataBeforeEdit,
    setEditBusinessTypeRef,
    setNewRef,
    setRemoveBusinessTypeRef,
    setRemoveDialogOpen,
    setSnackbarInfoMessage,
  ]);

  const handleRemoveAllBusinesses = withLoading(async () => {
    removeAllBusinessOnPremises();
    businessOnPremises?.props.actionOnComplete(false);
    setEditBusinessTypeRef('');
    setNewRef('');
    setRemoveAllDialogOpen(false);
    setSnackbarInfoMessage('All businesses removed.');
  });

  const handleEditBusiness = useCallback(
    (businessRef: string) => {
      if (editBusinessTypeRef || isLoading) return;
      setEditBusinessTypeRef(businessRef);
    },
    [editBusinessTypeRef, isLoading, setEditBusinessTypeRef],
  );

  const handleBusinessOnPremisesComplete = useEvent(async (value: AnswerValue) => {
    if (!value) {
      if (numBusinesses === 1) {
        const business = getBusinessByRef(businessOnPremisesRefs[0]);
        // Only open remove dialog if data entered
        if (
          business?.businessType ||
          business?.businessDescription ||
          business?.commercialCoverageInd ||
          business?.employeeInd
        ) {
          handleRemoveDialogOpen(businessOnPremisesRefs[0]);
        } else {
          removeBusinessAction(businessOnPremisesRefs[0]);
          setNewRef('');
          setEditBusinessTypeRef('');
          setBusinessDataBeforeEdit(undefined);
          businessOnPremises?.props.actionOnComplete(value);
        }
      } else if (numBusinesses > 1) {
        setRemoveAllDialogOpen(true);
      } else {
        businessOnPremises?.props.actionOnComplete(value);
      }
    }
    if (value && numBusinesses === 0) {
      handleAddBusinessOnPremises();
      businessOnPremises?.props.actionOnComplete(value);
    }
  });

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setSnackbarInfoMessage('');
      }
    },
    [setSnackbarInfoMessage],
  );

  const handleAlertSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setShowSnackbarAlert(false);
      }
    },
    [setShowSnackbarAlert],
  );

  const handleRemoveDialogOpen = useCallback(
    (businessRef: string) => {
      setRemoveBusinessTypeRef(businessRef);
      setRemoveDialogOpen(true);
    },
    [setRemoveBusinessTypeRef, setRemoveDialogOpen],
  );

  const handleRemoveDialogClose = useCallback(() => {
    setRemoveDialogOpen(false);
    setRemoveBusinessTypeRef('');
  }, [setRemoveDialogOpen, setRemoveBusinessTypeRef]);

  const handleRemoveAllDialogClose = useCallback(() => {
    setRemoveAllDialogOpen(false);
  }, [setRemoveAllDialogOpen]);

  const handleCancelDialogOpen = useCallback(
    (businessRef: string, businessFields?: Fields) => {
      let isChanged = false;
      if (businessDataBeforeEdit && businessFields) {
        const fieldKeys = Object.keys(businessDataBeforeEdit);
        for (const key of fieldKeys) {
          const previousValue = businessDataBeforeEdit[key];
          const currentValue = businessFields[key]?.value;
          if ((previousValue || currentValue) && previousValue !== currentValue) {
            isChanged = true;
            break;
          }
        }
      }
      // Don't show remove dialog if newly created and no data entered
      if (newRef !== '' && !isChanged) {
        removeBusinessAction(businessRef);
        setNewRef('');
        setEditBusinessTypeRef('');
      }
      // Show remove dialog if newly created and data entered
      else if (newRef !== '' && isChanged) {
        handleRemoveDialogOpen(businessRef);
      }
      // Show cancel dialog if edit has changes
      else if (isChanged) {
        setBusinessFields(businessFields);
        setCancelDialogOpen(true);
      }
      // Don't show cancel dialog if edit has no changes
      else {
        setEditBusinessTypeRef('');
        setBusinessFields(undefined);
        setBusinessDataBeforeEdit(undefined);
      }
    },
    [
      businessDataBeforeEdit,
      handleRemoveDialogOpen,
      newRef,
      removeBusinessAction,
      setBusinessDataBeforeEdit,
      setBusinessFields,
      setCancelDialogOpen,
      setEditBusinessTypeRef,
      setNewRef,
    ],
  );

  const handleCancelDialogClose = useCallback(() => {
    setCancelDialogOpen(false);
    setRemoveBusinessTypeRef('');
    setBusinessFields(undefined);
  }, [setBusinessFields, setCancelDialogOpen, setRemoveBusinessTypeRef]);

  const getBusinessByRef = useCallback(
    (ref: string) => {
      return businessOnPremisesValues.find((business) => business.ref === ref);
    },
    [businessOnPremisesValues],
  );

  const renderBusinessItem = useCallback(
    (item: string) => {
      const business = getBusinessByRef(item);
      const businessTypeLabel = businessTypeQuestion.options?.find(
        (o) => o.value === business?.businessType,
      )?.label as string;

      return (
        <>
          <Resource.Item title='Type of Business' value={businessTypeLabel} xs={3} />
          <Resource.Item
            title='Commercial Insurance'
            value={`${business?.commercialCoverageInd ? 'Yes' : 'No'}`}
            xs={3}
          />
          <Resource.Item
            title='Other Employees'
            value={`${business?.employeeInd ? 'Yes' : 'No'}`}
            xs={3}
          />
        </>
      );
    },
    [getBusinessByRef, businessTypeQuestion],
  );

  const snackbarInfo = (
    <Snackbar
      classes={{ root: classes.snackBar }}
      open={!!snackbarInfoMessage}
      autoHideDuration={3000}
      message={snackbarInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarAlert = (
    <SnackbarAlert
      classes={{ root: classes.snackBar }}
      open={showSnackbarAlert}
      autoHideDuration={3000}
      message='Business saved.'
      vertical='bottom'
      horizontal='center'
      onClose={handleAlertSnackbarClose}
      severity='success'
    />
  );

  const removeBusinessDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove business?'
      textButtonLabel='CANCEL'
      open={removeDialogOpen}
      onClose={handleRemoveDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleRemoveBusinessOnPremise}
    >
      <p>Any associated content will be removed.</p>
    </Dialog>
  );

  const removeAllBusinessesDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove all businesses?'
      textButtonLabel='CANCEL'
      open={removeAllDialogOpen}
      onClose={handleRemoveAllDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleRemoveAllBusinesses}
    >
      <p>All businesses will be removed.</p>
    </Dialog>
  );

  const cancelDialog = (
    <Dialog
      actionButtonLabel='DISCARD'
      titleText='Discard changes?'
      textButtonLabel='CANCEL'
      open={cancelDialogOpen}
      onClose={handleCancelDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleCancelBusinessOnPremises}
    >
      <p>Any unsaved changes will be discarded.</p>
    </Dialog>
  );

  if (!businessOnPremises?.exists) return null;

  return (
    <>
      {removeBusinessDialog}
      {removeAllBusinessesDialog}
      {cancelDialog}
      {snackbarInfo}
      {snackbarAlert}
      <RadioGroupWithOptions
        {...businessOnPremises.props}
        id='BusinessOnPremises'
        label={label}
        variant='yesNoButton'
        trackingName={trackingName}
        actionOnComplete={handleBusinessOnPremisesComplete}
      />
      {businessOnPremises.value && (
        <Grid container className={classes.marginTop}>
          <Resource.List
            items={businessOnPremisesRefs}
            pageErrors={[]}
            editItemRef={editBusinessTypeRef}
            onEdit={handleEditBusiness}
            onDelete={handleRemoveDialogOpen}
            onCancel={handleCancelDialogOpen}
            onNext={handleSaveBusinessOnPremises}
            editIconText='Edit Business'
            deleteIconText='Remove Business'
            // eslint-disable-next-line react/jsx-no-bind
            form={(formProps) => (
              <BusinessOnPremisesForm
                {...formProps}
                setBusinessBeforeEdit={setBusinessDataBeforeEdit}
                businesses={businessOnPremisesValues}
              />
            )}
            renderListItem={renderBusinessItem}
          />
          {!editBusinessTypeRef && (
            <Resource.AddButton
              onClick={handleAddBusinessOnPremises}
              data-testid='businessOnPremisesAdd'
              isProcessing={false}
              trackingName='business_on_premises_add'
              trackingLabel='business_on_premises_add'
              disabled={!!editBusinessTypeRef || isLoading}
            >
              ADD ANOTHER BUSINESS
            </Resource.AddButton>
          )}
        </Grid>
      )}
    </>
  );
};
