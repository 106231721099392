import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { OfferType } from '@ecp/features/sales/shared/constants';
import {
  applyDelta,
  getIsBundleForOfferProductsSelected,
  getLineOfBusiness,
  updateOffers,
  updateOfferType,
  useForm,
  useValidateCostcoMembershipField,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { PropertyDeltaFormQuestion } from './PropertyDeltaFormQuestion';
import { useStyles } from './PropertyDeltaPageForm.styles';

interface SubmitParams {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
  isBundle: boolean;
  lob: LineOfBusiness;
  valdiateCostcoMembershipField: () => boolean;
}

interface Props {
  actionButtonLabel?: string;

  onNext: () => Promise<void>;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
    isBundle,
    lob,
    valdiateCostcoMembershipField,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();

      // Apply Delta when Bundle or Renters monoline
      // For other monoline flow this will be handled on TPI page
      if (isBundle || (!isBundle && lob === LineOfBusiness.RENTERS)) {
        await dispatch(applyDelta({ policyTypes: ['renters'] }));
      }

      // Put offers if Renters monoline
      // For other monoline flows and bundle flows this will be handled on TPI page
      if (!isBundle && lob === LineOfBusiness.RENTERS) {
        await dispatch(updateOfferType({ offerType: OfferType.OFFER_WITH_PREMIUM }));
        await dispatch(updateOffers({ force: true }));
      }

      if (valdiateCostcoMembershipField()) {
        setIsSubmitting(false);

        return;
      }

      await onNext();
      setIsSubmitting(false);
    }

    setIsSubmitting(false);
  };

export const PropertyDeltaPageForm: React.FC<Props> = (props) => {
  const { actionButtonLabel = 'Save & Continue', onNext } = props;
  const { classes } = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const lob: LineOfBusiness = useSelector(getLineOfBusiness);
  const getFields = useGetFields();
  const dispatch = useDispatch();
  const getConditions = useGetConditionValues();
  const getInitValues = useGetInitValues();
  const valdiateCostcoMembershipField = useValidateCostcoMembershipField();

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  const handleSubmit = useCallback(async () => {
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
        isBundle,
        lob,
        valdiateCostcoMembershipField,
      }),
    );
  }, [
    dispatch,
    onNext,
    patchFormValues,
    validateForm,
    isBundle,
    lob,
    valdiateCostcoMembershipField,
  ]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container spacing={1} className={classes.content}>
          <PropertyDeltaFormQuestion />
          <GridItem topSpacing='sm' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='continue'
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='property_delta_continue'
              analyticsElement='choice.propertyDeltaForm.saveAndContinueButton'
              type='submit'
            >
              {actionButtonLabel}
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
