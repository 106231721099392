import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicySummaryCard' })((theme) => ({
  root: {
    justifyContent: 'center',
    padding: 24,
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: '4px',
    marginTop: 25,
  },
  coveragesSummaryHeader: {
    boxShadow: 'none',
    paddingBottom: 10,
    minHeight: 30,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  editButton: {
    marginTop: 4,
  },
}));
