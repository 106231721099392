import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'IncidentQuestions' })((theme) => ({
  ...theme.mixins.formAll,
  tableContainer: {
    ...theme.mixins.tableContainer,
    margin: 0,
    overflowX: 'auto',
  },
  firstRow: theme.mixins.tableFirstRow,
  tableBody: theme.mixins.simpleTableBody,
  divider: {
    backgroundColor: theme.palette.other.divider,
    margin: '40px 0px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
  },
}));
