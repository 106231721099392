import { useRef } from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';

import { useLocation } from '@ecp/utils/routing';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { getVehicleDescription, useGetVehicleInfoByTPIRef } from '@ecp/features/sales/quotes/auto';
import { Form } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { getOfferProductsSelectedByType, useForm } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconCardAuto } from '@ecp/themes/base';

import { SearchTPIForm } from '../../../../common';
import { useStyles } from './AutoAddTPIForm.styles';

export const AutoAddTPIForm: React.FC = () => {
  const { classes } = useStyles();
  const {
    parameters: { id: tpiId, referencePage: reference },
  } = useLocation(PagePath.ADD_THIRD_PARTY_INTEREST);
  const { auto: autoProduct } = useSelector(getOfferProductsSelectedByType);

  const vehicleRef = useGetVehicleInfoByTPIRef(tpiId);
  const tpiRef = tpiId || '';

  const getInitValues = useGetInitValues();
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();

  const { isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  // TODO: Refactor to not use reference
  if (!tpiRef && !autoProduct && reference !== 'AddVehicleTPI') return null;

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container className={classes.content}>
          <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
            <Box>
              <div className={classes.productIcon}>
                <IconCardAuto />
              </div>
            </Box>
            <Typography className={classes.productTitle}>Auto Policy?</Typography>
          </GridItem>
          <Divider className={classes.divider} />
          <GridItem xs={12} className={classes.vehicleTitle}>
            <Typography className={classes.contentTitle}>
              {getVehicleDescription(vehicleRef)}
            </Typography>
            {vehicleRef.vin && (
              <Typography
                className={classes.contentSubTitle}
              >{`VIN: ${vehicleRef.vin}`}</Typography>
            )}
          </GridItem>
          <SearchTPIForm refId={tpiRef} product={autoProduct} />
        </Grid>
      </Form>
    </div>
  );
};
