import { useCallback, useMemo } from 'react';

import { ensureStringArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import {
  createRef,
  deleteInquiryRef,
  getAllValues,
  getAnswer,
  getInquiryLoaded,
  getQuestion,
  getValueForPrefix,
  updateAddedRef,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, FieldsDef } from '@ecp/features/sales/shared/types';
import type { Fields, Option } from '@ecp/types';
import type { Field } from '@ecp/types';

export const PROTECTIVE_DEVICE_REF = 'property.protectiveDevice.ref';

export interface ProtectiveDevice {
  categoryType: string;
  deviceName: string;
  ref: string;
  deviceType: string;
}

interface ProtectiveDeivceOptions {
  fireCarbonMonoxideDeviceTypeMap: Record<string, string>;
  securityDeviceTypeMap: Record<string, string>;
  waterDeviceTypeMap: Record<string, string>;
  energyHomeManagementDeviceTypeMap: Record<string, string>;
}

export interface ProtectiveDeviceFields extends Fields {
  protectiveDevice: FieldsDef<ProtectiveDevice>;
}

export interface ProtectiveDeviceTypeField {
  deviceType: Field;
}

const arrayToObject = (array: Option[]): Record<string, string> =>
  array.reduce((obj, item) => {
    // @ts-ignore type
    obj[item.value] = item.label;

    return obj;
  }, {} as Record<string, string>);

const useProtectiveDeviceOptions = (): ProtectiveDeivceOptions => {
  const fireCarbonMonoxideDeviceTypeQuestion = useSelector(
    getQuestion('protectiveDevice.<id>.fireCarbonMonoxideDeviceType'),
  );
  const fireCarbonMonoxideDeviceTypeMap = useMemo(() => {
    return arrayToObject(fireCarbonMonoxideDeviceTypeQuestion.options || []);
  }, [fireCarbonMonoxideDeviceTypeQuestion?.options]);

  const securityDeviceTypeQuestion = useSelector(
    getQuestion('protectiveDevice.<id>.securityDeviceType'),
  );
  const securityDeviceTypeMap = useMemo(() => {
    return arrayToObject(securityDeviceTypeQuestion.options || []);
  }, [securityDeviceTypeQuestion?.options]);

  const waterDeviceTypeQuestion = useSelector(getQuestion('protectiveDevice.<id>.waterDeviceType'));
  const waterDeviceTypeMap = useMemo(() => {
    return arrayToObject(waterDeviceTypeQuestion.options || []);
  }, [waterDeviceTypeQuestion?.options]);

  const energyHomeManagementDeviceTypeQuestion = useSelector(
    getQuestion('protectiveDevice.<id>.energyHomeManagementDeviceType'),
  );
  const energyHomeManagementDeviceTypeMap = useMemo(() => {
    return arrayToObject(energyHomeManagementDeviceTypeQuestion.options || []);
  }, [energyHomeManagementDeviceTypeQuestion?.options]);

  return {
    fireCarbonMonoxideDeviceTypeMap,
    securityDeviceTypeMap,
    waterDeviceTypeMap,
    energyHomeManagementDeviceTypeMap,
  };
};

export const useAddProtectiveDevice = (): (() => string) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(() => {
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'apps/sales/edsp-asp/src/lob/propertyLine/utils/ProtectiveDevicesUtil.ts',
          functionOrigin: 'useAddProtectiveDevice/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }

    const protectiveDeviceRef = dispatch(createRef('protectiveDevice'));

    dispatch(
      updateAddedRef({
        type: PROTECTIVE_DEVICE_REF,
        newRef: protectiveDeviceRef,
      }),
    );

    return protectiveDeviceRef;
  }, [dispatch, inquiryLoaded]);
};

const getProtectiveDivice = (
  ref: string,
  fireCarbonMonoxideDeviceTypeMap: Record<string, string>,
  securityDeviceTypeMap: Record<string, string>,
  waterDeviceTypeMap: Record<string, string>,
  energyHomeManagementDeviceTypeMap: Record<string, string>,
  allValues: Answers,
): ProtectiveDevice => {
  const getString = getValueForPrefix<string>(ref, allValues);
  const categoryType = getString('categoryType');
  const fireCarbonMonoxideDeviceType =
    fireCarbonMonoxideDeviceTypeMap?.[getString('fireCarbonMonoxideDeviceType')];
  const securityDeviceType = securityDeviceTypeMap?.[getString('securityDeviceType')];
  const waterDeviceType = waterDeviceTypeMap?.[getString('waterDeviceType')];
  const energyHomeManagementDeviceType =
    energyHomeManagementDeviceTypeMap?.[getString('energyHomeManagementDeviceType')];
  const deviceName = getString('deviceName');

  let deviceType;
  switch (categoryType) {
    case 'FIRE_CARBON_MONOXIDE':
      deviceType = fireCarbonMonoxideDeviceType;
      break;
    case 'SECURITY':
      deviceType = securityDeviceType;
      break;
    case 'WATER':
      deviceType = waterDeviceType;
      break;
    case 'ENERGY_HOME_MANAGEMENT_SYSTEM':
      deviceType = energyHomeManagementDeviceType;
      break;
    default:
      deviceType = '';
  }

  return {
    ref: ref,
    categoryType: categoryType,
    deviceType: deviceType,
    deviceName: deviceName,
  };
};

export const useProtectiveDevice = (protectiveDeviceRef: string): ProtectiveDevice => {
  const allValues = useSelector(getAllValues);
  const {
    fireCarbonMonoxideDeviceTypeMap,
    securityDeviceTypeMap,
    waterDeviceTypeMap,
    energyHomeManagementDeviceTypeMap,
  } = useProtectiveDeviceOptions();

  return getProtectiveDivice(
    protectiveDeviceRef,
    fireCarbonMonoxideDeviceTypeMap,
    securityDeviceTypeMap,
    waterDeviceTypeMap,
    energyHomeManagementDeviceTypeMap,
    allValues,
  );
};

export const useProtectiveDevices = (): ProtectiveDevice[] => {
  const allValues = useSelector(getAllValues);

  const refs: string[] = ensureStringArray(allValues[PROTECTIVE_DEVICE_REF]);
  const {
    fireCarbonMonoxideDeviceTypeMap,
    securityDeviceTypeMap,
    waterDeviceTypeMap,
    energyHomeManagementDeviceTypeMap,
  } = useProtectiveDeviceOptions();

  return refs.map((ref) =>
    getProtectiveDivice(
      ref,
      fireCarbonMonoxideDeviceTypeMap,
      securityDeviceTypeMap,
      waterDeviceTypeMap,
      energyHomeManagementDeviceTypeMap,
      allValues,
    ),
  );
};

export const useGetProtectiveDeviceFields = (
  protectiveDeviceRef: string,
): ProtectiveDeviceFields => {
  const userProtectiveDeviceField = useFieldWithPrefix(
    protectiveDeviceRef,
    'protectiveDevice.<id>',
  );

  const categoryType = userProtectiveDeviceField('categoryType').value;

  const deviceType = (): Field => {
    if (categoryType === 'FIRE_CARBON_MONOXIDE') {
      return userProtectiveDeviceField('fireCarbonMonoxideDeviceType');
    } else if (categoryType === 'SECURITY') {
      return userProtectiveDeviceField('securityDeviceType');
    } else if (categoryType === 'WATER') {
      return userProtectiveDeviceField('waterDeviceType');
    } else {
      return userProtectiveDeviceField('energyHomeManagementDeviceType');
    }
  };

  return {
    protectiveDevice: {
      ref: useField(protectiveDeviceRef),
      categoryType: userProtectiveDeviceField('categoryType'),
      deviceType: deviceType(),
      deviceName: userProtectiveDeviceField('deviceName'),
    },
  };
};

export const useRemoveProtectiveDevice = (): ((
  protectiveDevice: ProtectiveDevice,
) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (protectiveDevice: ProtectiveDevice) => {
      if (protectiveDevice) {
        await dispatch(
          deleteInquiryRef({
            refType: 'protectiveDevice',
            refId: protectiveDevice.ref.split('.')[1],
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useRemoveAllProtectiveDevice = (): (() => void) => {
  const dispatch = useDispatch();

  const protectiveDeviceRefsValue = useSelector((state: RootStore) =>
    getAnswer(state, PROTECTIVE_DEVICE_REF),
  ) as Array<string>;

  return useCallback(() => {
    if (PROTECTIVE_DEVICE_REF && protectiveDeviceRefsValue.length > 0) {
      protectiveDeviceRefsValue.forEach(async (protectiveDeviceRef) => {
        await dispatch(
          deleteInquiryRef({
            refType: 'protectiveDevice',
            refId: protectiveDeviceRef.split('.')[1],
          }),
        );
      });
    }
  }, [protectiveDeviceRefsValue, dispatch]);
};
