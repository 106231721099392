import { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from '@ecp/utils/routing';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { useVehiclesForSidebar } from '@ecp/features/sales/quotes/auto';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getOperatorsInDrivers,
  questionExists,
  submitProofDraft,
  updateAnswers,
  updatePageStatus,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { VehiclesPageForm } from '../../forms/VehiclesPageForm';

export const VehiclesPage: React.FC = () => {
  // TODO: are they the same or can we get rid of one of the vehicle arrays
  const vehicles = useVehiclesForSidebar();
  const navigateToDriverAssignmentPage = useNavigateToPage(PagePath.DRIVER_ASSIGNMENT);

  const operators = useSelector(getOperatorsInDrivers);
  const dispatch = useDispatch();
  const { referencePage } = useSearchParams();
  const [nextPageInstructions, setNextPageInstructions] = useState('');
  const navigateToPriorCarrier = useNavigateToPage(PagePath.AUTO_PRIOR_INSURANCE);

  // do the driver assignment
  const autoAssign = useCallback(async () => {
    await dispatch(
      updateAnswers({
        answers: {
          [`${vehicles[0].ref}.primary.driver.ref`]: operators[0].ref,
          [`${vehicles[0].ref}.secondary.driver.ref`]: null,
        },
      }),
    );
    dispatch(updatePageStatus(NavStatus.VALID, PagePath.DRIVER_ASSIGNMENT));
    // remove seconday driver if it exists as its no longer needed for 1 driver 1 vehicle scenario.
  }, [dispatch, operators, vehicles]);

  const driverAssignmentQuestionExists = useSelector((state: RootStore) =>
    questionExists('vehicle.<id>.primary.driver.ref')(state),
  );

  const navigateToNext = useCallback(async () => {
    if (driverAssignmentQuestionExists && operators.length === 1 && vehicles.length === 1) {
      await autoAssign();
      await dispatch(
        submitProofDraft({
          policyTypes: ['auto'],
        }),
      );

      return await navigateToPriorCarrier();
    }
    await navigateToDriverAssignmentPage();
  }, [
    driverAssignmentQuestionExists,
    operators.length,
    vehicles.length,
    navigateToDriverAssignmentPage,
    autoAssign,
    dispatch,
    navigateToPriorCarrier,
  ]);

  useEffect(() => {
    if (!(operators.length === 1 && vehicles.length === 1)) {
      if (driverAssignmentQuestionExists) {
        setNextPageInstructions(
          'Once we have the right vehicles and drivers, you can move on to driver assignment.',
        );
      }
    } else {
      setNextPageInstructions(
        'Once we have the right vehicles and drivers shown, you can move on to prior insurance.',
      );
    }
  }, [dispatch, operators.length, vehicles.length, driverAssignmentQuestionExists, referencePage]);

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.ADD_EDIT_VEHICLES} />,
        }}
        title='Vehicles'
        analyticsElement='choice.vehicleDetailsPage.page'
      >
        <VehiclesPageForm onNext={navigateToNext} nextPageInstructions={nextPageInstructions} />
      </Page>
    </div>
  );
};
