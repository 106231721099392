import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const HigherThanThreeStoriesQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('higherThanThreeStories');

  if (!field) return null;

  const { trackingName = 'higher_than_three_stories', label = field.question?.title } = props;

  return (
    <RadioGroupWithOptions
      {...field.props}
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
