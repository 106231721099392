import { usePniRef, useSniRef } from '@ecp/features/sales/shared/store';

import { AffiliationFormQuestion } from './AffiliationFormQuestion';

export const AffiliationForm: React.FC = () => {
  const namedInsuredRef = usePniRef();
  const secondaryNamedInsuredRef = useSniRef();

  if (!namedInsuredRef) return null;

  return (
    <>
      <AffiliationFormQuestion namedInsuredRef={namedInsuredRef} />
      {secondaryNamedInsuredRef && (
        <AffiliationFormQuestion namedInsuredRef={secondaryNamedInsuredRef} />
      )}
    </>
  );
};
