import { useCallback, useMemo } from 'react';

import type { SelectProps } from '@ecp/components';
import { GridItem } from '@ecp/components';
import type { VehicleOptions } from '@ecp/features/sales/quotes/auto';
import { useVehicleFields } from '@ecp/features/sales/quotes/auto';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { AnswerValue } from '@ecp/types';

import { useStyles } from '../VehicleInfoQuestions.styles';

type Props = QuestionProps &
  Pick<VehicleOptions, 'seriesOptions' | 'isSeriesRequestInProgress'> & {
    vehicleRef: string;
    maskRequiredFieldError: boolean;
  };

export const VehicleSeriesQuestion: React.FC<Props> = (props) => {
  const { seriesOptions, isSeriesRequestInProgress, maskRequiredFieldError, vehicleRef } = props;
  const { classes } = useStyles();
  const { series } = useVehicleFields(vehicleRef);

  const seriesFieldDisabled = useMemo((): boolean => {
    return isSeriesRequestInProgress || seriesOptions.length === 0;
  }, [isSeriesRequestInProgress, seriesOptions.length]);

  const smartError = (error: string | undefined, value: AnswerValue): string | undefined => {
    if (!error || !maskRequiredFieldError) return error;

    return maskRequiredFieldError && !value ? undefined : error;
  };

  const { error: seriesFieldError, ...seriesProps } = series.props;

  const handleSeriesChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      series.props.actionOnComplete(value);
    },
    [series],
  );

  if (!series.exists) return null;

  return (
    <GridItem topSpacing='sm' xs={12} className={classes.vehicleInfoSelect}>
      <Select
        error={smartError(seriesFieldError, series.value)}
        {...seriesProps}
        disabled={seriesFieldDisabled}
        id='VehicleTrim'
        label='Vehicle trim'
        options={seriesOptions}
        actionOnChange={handleSeriesChange}
        data-testid='series'
        trackingName='vehicle_trim_selection'
      />
    </GridItem>
  );
};
