import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicySummaryCoveragesCard' })((theme) => ({
  topLine: {
    ...theme.typography.body1,
    display: 'block',
    marginBottom: 4,
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  marginTop: {
    marginTop: 5,
  },
  limitItems: {
    ...theme.typography.body2,
    paddingLeft: 20,
  },
  premiumAndCovTitle: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
  columnRight: {
    display: 'flex',
    justifyContent: 'right',
  },
}));
