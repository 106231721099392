import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  useAddAndUpdateExteriorWall,
  useExteriorWallPercentages,
  useRemoveExteriorWall,
  useUpdateExteriorWall,
} from '@ecp/features/sales/quotes/property/home';
import { PercentageGroupSelection } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { CardOption } from '@ecp/types';

export type MultiExteriorWallConstructionQuestionProps = QuestionProps & {
  exteriorWallSelectingLimit: number;
};

export const MultiExteriorWallConstructionQuestion: React.FC<
  MultiExteriorWallConstructionQuestionProps
> = (props) => {
  const exteriorWall = useField('static.exteriorWall');

  const exteriorWallMaterial = useFieldWithPrefix(
    'exteriorWall.<id>',
    'exteriorWall.<id>',
  )('constructionMaterial');
  const exteriorlWallPercentage = useFieldWithPrefix(
    'exteriorWall.<id>',
    'exteriorWall.<id>',
  )('constructionMaterialPercentage');

  const updateExteriorWall = useUpdateExteriorWall();
  const addAndUpdateExteriorWall = useAddAndUpdateExteriorWall();
  const removeExteriorWall = useRemoveExteriorWall();
  const percentages = useExteriorWallPercentages();
  const exteriorWallMaterials = Object.keys(percentages).join(',');
  useInitValues({ [exteriorWall.key]: exteriorWallMaterials });
  useAddFields({ exteriorWall });

  if (!exteriorWallMaterial.exists) return null;

  const {
    label = exteriorWallMaterial.question.title || '',
    trackingName = 'exterior_wall_more_choices',
    dataTestId = 'ExteriorWallMoreChoices',
    exteriorWallSelectingLimit,
  } = props;

  return (
    <PercentageGroupSelection
      hidePercentageSelected
      title={label}
      subtitle={exteriorWallMaterial.question.subtitle || ''}
      options={exteriorWallMaterial.question.options as CardOption[]}
      percentageOptions={exteriorlWallPercentage?.question?.options ?? []}
      percentages={percentages}
      uiField={exteriorWall}
      moreLessTrackingName={trackingName}
      moreLessDataTestId={dataTestId || 'ExteriorWallMoreChoices'}
      optionTrackingName='exterior_wall_multiselect'
      percentTrackingName='exterior_wall_percentage'
      onAdd={addAndUpdateExteriorWall}
      onUpdate={updateExteriorWall}
      onRemove={removeExteriorWall}
      checkBoxGroupTestId='ExteriorWallConstruction'
      maxOptionToSelect={exteriorWallSelectingLimit}
      sortByKey
    />
  );
};
