import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const SolidFuelQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('hasSolidFuelInd');

  if (!field) return null;

  const { label = field.question?.title, trackingName = 'SolidFuelButton' } = props;

  return (
    <RadioGroupWithOptions
      {...field.props}
      id='SolidFuel'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
