import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'QuotesPage' })((theme) => ({
  root: {
    '& #content': {
      maxWidth: 'initial',
    },
    '& [role=main]': {
      [theme.breakpoints.only('md')]: {
        maxWidth: 'initial',
      },
    },
  },
  header: {
    margin: '0 auto',
    [theme.breakpoints.only('xl')]: {
      maxWidth: 1084,
      padding: 0,
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: theme.breakpoints.values.lg - 60,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  errorContainer: {
    ...theme.mixins.form,
    maxWidth: 1144,
    margin: '60px auto 0 auto',
    [theme.breakpoints.down('xl')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px 0px',
      padding: '0px 15px',
    },
  },
  alertIcon: {
    ...theme.mixins.setColorSvg(theme.palette.error.main),
  },
  alertContainer: {
    padding: '0 30px',
    marginTop: 24,
  },
}));
