import { useRef } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { isDateExpired } from '@ecp/utils/date';

import { TooltipWithIcon } from '@ecp/components';
import { setPaymentOptionsAndAcksRefetchRequired } from '@ecp/features/sales/checkout';
import { Select } from '@ecp/features/sales/shared/components';
import { DatePicker } from '@ecp/features/sales/shared/components';
import { AUTO_POLICY_POST_EFFECTIVE_DATE_LOSSES } from '@ecp/features/sales/shared/constants';
import {
  getOfferProductsSelectedByType,
  getPolicyStartDates,
  getPolicyTerm,
  setShouldRecalc,
  useField,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';
import {
  getProductDisplayNameFromProduct,
  getReducedProductNameFromProduct,
  isProductAuto,
} from '@ecp/features/shared/product';
import type { Field } from '@ecp/types';

import { calculateMaxPolicyStartDaysLimit } from '../../utils';
import { usePolicyFields } from '../../utils/policyDataUtil';
import { useStyles } from './PolicyEffDateAndTermForm.styles';

export const PolicyEffDateAndTermForm: React.FC = () => {
  const { classes } = useStyles();
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const policyEffectiveDateFields = usePolicyFields('effectiveDate');
  const postEffDatePriorLoss = useField(AUTO_POLICY_POST_EFFECTIVE_DATE_LOSSES);
  const policyPolicyTermFields = usePolicyFields('policyTerm');
  const policyStartDateAnswers = useSelector(getPolicyStartDates);
  const policyPolicyTermAnswers = useSelector(getPolicyTerm);

  const dispatch = useDispatch();
  const minDate = dayjs().startOf('d').add(1, 'd');

  const initValues = useRef({});
  const { validateForm } = useForm({
    fields: { policyEffectiveDateFields, policyPolicyTermFields },
    initValues,
  });

  const renderTermDropDown = (
    product: Product,
    policyTermDisabled: boolean,
  ): React.ReactElement => {
    const productName = getReducedProductNameFromProduct(product);
    const policyPolicyTermField = policyPolicyTermFields[productName] as Field;
    const productDisplayName = getProductDisplayNameFromProduct(product);

    const handlePolicyTermComplete = async (value: AnswerValue): Promise<void> => {
      if (
        validateForm().isValid &&
        value &&
        policyPolicyTermAnswers[productName] !== value.toString()
      ) {
        await policyPolicyTermField.props.actionOnComplete(value);
        dispatch(setShouldRecalc(true));
        dispatch(setPaymentOptionsAndAcksRefetchRequired(true));
      }
    };

    return (
      <Grid item xs={12} className={classes.dateInputField}>
        <Select
          {...(policyPolicyTermField.props as OptionProps)}
          id={`${productDisplayName}PolicyTerm`}
          label={`${productDisplayName} Policy Term`}
          // eslint-disable-next-line react/jsx-no-bind
          actionOnChange={handlePolicyTermComplete}
          disabled={policyTermDisabled}
        />
      </Grid>
    );
  };

  const renderDatePicker = (product: Product): React.ReactElement => {
    const productName = getReducedProductNameFromProduct(product);
    const policyStartDateField = policyEffectiveDateFields[productName] as Field;
    const productDisplayName = getProductDisplayNameFromProduct(product);
    const maxDays = calculateMaxPolicyStartDaysLimit({
      isAutoProduct: isProductAuto(product),
    });
    const maxDate = dayjs().startOf('d').add(maxDays, 'd');

    const handlePropertyStartDateComplete = async (value: AnswerValue): Promise<void> => {
      if (
        validateForm().isValid &&
        value &&
        policyStartDateAnswers[productName] !== value.toString()
      ) {
        await policyStartDateField.props.actionOnComplete(value);
        dispatch(setShouldRecalc(true));
        dispatch(setPaymentOptionsAndAcksRefetchRequired(true));

        const isEffectiveDateInPastOrToday = isDateExpired(value.toString());
        if (!isEffectiveDateInPastOrToday && postEffDatePriorLoss.value) {
          // If new effectiveDate is in the future, set answer to effective date loss indicator to false
          postEffDatePriorLoss.validateUpdateAndPatch(false);
        }
      }
    };

    return (
      <Grid item xs={12} className={classes.dateInputField}>
        <DatePicker
          {...policyStartDateField.props}
          id={`${productDisplayName}PolicyStartDate`}
          label={`${productDisplayName} Policy Effective Date`}
          trackingName={`${productDisplayName.toLowerCase()}_policy_start_date`}
          trackingLabel={policyStartDateField.props.value}
          analyticsElement='policyCoveragePage.policyDatePicker'
          // eslint-disable-next-line react/jsx-no-bind
          actionOnComplete={handlePropertyStartDateComplete}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={2} className={classes.effectiveDateBlk}>
      <Grid container item spacing={2} xs={12}>
        <h3 className={classes.title}>
          Effective Date
          <TooltipWithIcon
            className={classes.policyToolTip}
            title='Adjustments to start date could result in adjustments to price.'
          />
        </h3>
      </Grid>
      <Grid item xs={6} className={classes.datePickerContainer}>
        {autoOfferProduct && renderDatePicker(autoOfferProduct)}
        {propertyOfferProduct && renderDatePicker(propertyOfferProduct)}
      </Grid>
      <Grid item xs={6} className={classes.datePickerContainer}>
        {autoOfferProduct && renderTermDropDown(autoOfferProduct, false)}
        {propertyOfferProduct && renderTermDropDown(propertyOfferProduct, true)}
      </Grid>
    </Grid>
  );
};
