import { useCallback } from 'react';

import {
  createRef,
  getAnswers,
  getMappedKeyDynamic,
  getPropertiesOfTemplateWithRef,
  removeAndDeleteAllRefs,
  removeAndDeleteRef,
  setProperty,
  updateAddedRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AnswerValue,
  BusinessOnPremises,
  Nested,
  PropertyList,
  Template,
} from '@ecp/features/sales/shared/types';

import { BUSINESS_ON_PREMISES_REF } from '../../constants/questions';

export interface AddBusinessResult {
  // the api has received the update
  done: Promise<[string]>;
  // the ref used
  businessRef: string;
}

export const useCreateBusinessOnPremises = (): (() => AddBusinessResult) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    const businessRef = dispatch(createRef('businessOnPremises'));
    const updateBusinessWork = dispatch(
      updateAddedRef({ type: BUSINESS_ON_PREMISES_REF, newRef: businessRef }),
    );
    const done = Promise.all([updateBusinessWork]).then((): [string] => [businessRef]);

    return {
      done,
      businessRef,
    };
  }, [dispatch]);
};

export const useRemoveBusinessOnPremises = (): ((ref: string) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (ref: string) => {
      await dispatch(removeAndDeleteRef({ refType: BUSINESS_ON_PREMISES_REF, ref }));
    },
    [dispatch],
  );
};

export const useRemoveAllBusinessOnPremises = (): (() => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await dispatch(
      removeAndDeleteAllRefs({
        refType: BUSINESS_ON_PREMISES_REF,
        refPrefix: 'businessOnPremises',
      }),
    );
  }, [dispatch]);
};

const businessOnPremisesTemplate: Template<BusinessOnPremises> = {
  ref: '',
  businessType: '',
  businessDescription: '',
  commercialCoverageInd: '',
  employeeInd: '',
};
const businessOnPremisesMapping: Record<string, string> = {};

const getBusinessOnPremisesProperties = (
  ref: string,
  ...prefix: string[]
): PropertyList<BusinessOnPremises> =>
  getPropertiesOfTemplateWithRef(businessOnPremisesTemplate, [ref, ...prefix]);

const geBusinessOnPremisesValues = (state: RootStore, ref: string): BusinessOnPremises => {
  const answers = getAnswers(state);
  const properties = getBusinessOnPremisesProperties(ref);

  return properties.reduce(
    (result, property) => {
      const key = getMappedKeyDynamic(property, { [ref]: businessOnPremisesMapping });
      const value = answers[key];
      setProperty(result as unknown as Nested<AnswerValue>, property, value);

      return result;
    },
    { ref } as BusinessOnPremises,
  );
};

const getBusinessOnPremisesValues = (
  state: RootStore,
  refs: string[],
): Record<string, BusinessOnPremises> => {
  return refs.reduce((result, ref) => {
    result[ref] = geBusinessOnPremisesValues(state, ref);

    return result;
  }, {} as Record<string, BusinessOnPremises>);
};

const useBusinessOnPremisesValues = (refs: string[]): Record<string, BusinessOnPremises> => {
  return useSelector((state: RootStore) => getBusinessOnPremisesValues(state, refs));
};
export const useBusinessOnPremises = (refs: string[]): BusinessOnPremises[] => {
  return Object.values(useBusinessOnPremisesValues(refs));
};
