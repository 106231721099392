import { useCallback, useEffect, useState } from 'react';

import { Snackbar } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { useDiscountsUtil } from '../../utils';
import { CustomCheckbox } from '../CustomCheckbox';
import { RemoveDiscountModal } from '../RemoveDiscountModal';
import { useStyles } from './AwayAtSchoolQuestion.styles';

interface Props extends QuestionProps {
  driverRef: string;
  driverAge: number | undefined;
  driverName: string;
}

const ageLimit = 25;

export const AwayAtSchoolQuestion: React.FC<Props> = (props) => {
  const { driverAge, driverRef, driverName } = props;
  const [removedSnackbarMessage, setRemovedSnackbarMessage] = useState('');
  const { classes } = useStyles();

  const awayAtSchool = useField(`${driverRef}.discount.awayAtSchool`);
  const goodStudent = useField(`${driverRef}.discount.goodStudent`);
  const youngVolunteer = useField(`${driverRef}.discount.youngVolunteer`);
  const ubiProgram = useField('discount.auto.ubiProgram.type');
  useAddFields({
    [`${awayAtSchool.key}`]: awayAtSchool,
    [`${goodStudent.key}`]: goodStudent,
    [`${youngVolunteer.key}`]: youngVolunteer,
    [`${ubiProgram.key}`]: ubiProgram,
  });
  useInitValues({ [awayAtSchool.key]: false });

  const { showRemoveDialog, dismissRemove, handleDriverDiscountChange, handleConflicts } =
    useDiscountsUtil(awayAtSchool, [
      { field: goodStudent, newValue: false },
      { field: youngVolunteer, newValue: false },
      { field: ubiProgram, newValue: 'NONE', conflictingValues: ['MILES_MY_WAY'] },
    ]);

  const onRemoveConflicts = useCallback(
    (discountSubMessage?: string): void => {
      handleConflicts();
      if (discountSubMessage) {
        setRemovedSnackbarMessage(discountSubMessage);
      }
    },
    [handleConflicts],
  );

  useEffect(() => {
    /**
     * If there is a driver that qualifies for G.S. and Y.V. but not away at school;
     *   we must send awayAtSchool as false if the value is null.
     *   It was coming with default as false but now it is null maybe due to a sapi change;
     *   however this will ensure that the value gets defaulted to false
     */
    if (awayAtSchool.exists && !awayAtSchool.value) {
      awayAtSchool.validateAndPatch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conflictingDiscountNames = (() => {
    const conflictions = [];
    if (goodStudent.value) conflictions.push('Good Student');
    if (youngVolunteer.value) conflictions.push('Young Volunteer');
    if (ubiProgram.value === 'MILES_MY_WAY') conflictions.push('MilesMyWay');

    return conflictions;
  })();

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setRemovedSnackbarMessage('');
      }
    },
    [],
  );

  if (!awayAtSchool.exists || !driverAge || driverAge > ageLimit) return null;

  return (
    <>
      {!!removedSnackbarMessage && (
        <Snackbar
          classes={{ root: classes.snackBarWidth }}
          open
          autoHideDuration={3000}
          message={`${removedSnackbarMessage} removed.`}
          vertical='bottom'
          horizontal='center'
          onClose={handleInfoSnackbarClose}
        />
      )}
      <CustomCheckbox
        actionOnChange={handleDriverDiscountChange}
        tooltipText="You may qualify for a discount if you have a bachelor's degree or higher from a college or university."
        field={awayAtSchool}
        title='Away At School'
        key={`${driverRef}-${awayAtSchool.key}`}
      />
      <RemoveDiscountModal
        showDialog={showRemoveDialog}
        closeDialog={dismissRemove}
        onRemove={onRemoveConflicts}
        selectedDiscountName='Away at School'
        conflictingDiscountNames={conflictingDiscountNames}
        driverName={driverName}
      />
    </>
  );
};
