import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ResourceSummaryList' })((theme) => ({
  ...theme.mixins.formAll,
  container: {
    border: `1px solid ${theme.palette.actions.disabledBg}`,
    borderRadius: '4px',
    marginBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  summaryError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  headerText: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginBottom: 8,
  },
  itemText: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    '&::after': {
      content: '"‎"',
    },
  },
  actionButton: {
    padding: 10,
  },
  outlineButton: {
    height: 'auto',
    padding: '4px 10px',
    textTransform: 'uppercase',
    border: `1px solid ${theme.palette.primary.border}`,
  },
}));
