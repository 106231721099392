import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { parseDollar } from '@ecp/utils/common';
import { scrollToTop } from '@ecp/utils/web';

import { SnackbarAlert } from '@ecp/components';
import { Snackbar } from '@ecp/components';
import {
  INCIDENT_TYPE_CLAIM_OR_ACCIDENT,
  INCIDENT_TYPE_VIOLATION,
  useAddIncident,
  useAttachIncidentToDriver,
  useGetIncidentFields,
  useIncident,
  useIncidents,
  useRemoveIncident,
} from '@ecp/features/sales/quotes/auto';
import { Dialog, Resource, type ResourceFormProps } from '@ecp/features/sales/shared/components';
import { AUTO_POLICY_HOUSEHOLD_LOSSES } from '@ecp/features/sales/shared/constants';
import { getHasLossesInThePastFiveYears } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { DriverBasicInfo, Incident } from '@ecp/features/sales/shared/types';

import { IncidentQuestions } from '../../components';
import { useStyles } from './DriverLossAndViolationForm.styles';

interface Props {
  driver: DriverBasicInfo;
}

export const DriverLossAndViolationForm: React.FC<Props> = (props) => {
  const { driver } = props;
  const { classes } = useStyles();
  const [addingLossViolation, setAddingLossViolation] = useState(false);
  const [incidentTypeValue, setIncidentTypeValue] = useState('');
  const [lossActionInfoMessage, setLossActionInfoMessage] = useState('');
  const [editIncidentRef, setEditIncidentRef] = useState('');
  const [openLossViolationDialog, setOpenLossViolationDialog] = useState('');
  const [incidentToBeRemoved, setIncidentToBeRemoved] = useState<Incident>();
  const currentIncident = useIncident(editIncidentRef || '');
  const removeIncident = useRemoveIncident(driver.ref || '');
  const removeCurrentIncident = useRemoveIncident('');
  const addLossViolation = useAddIncident(driver.ref);
  const addIncidentToDriver = useAttachIncidentToDriver(driver.ref || '');
  const incidentFields = useGetIncidentFields(editIncidentRef);
  const incidentList = useIncidents(driver.ref || '');
  const [incidentDataBeforeEdit, setIncidentDataBeforeEdit] = useState<Incident | undefined>();
  const [noLossesOrViolationsText, setLossesOrViolationsText] = useState(incidentList.length === 0);
  const [lossAndViolationActionInfoType, setLossAndViolationActionInfoType] = useState<
    'NONE' | 'ADD' | 'REMOVE'
  >('NONE');

  const {
    incident: {
      type: incidentType,
      day: incidentDay,
      year: incidentYear,
      month: incidentMonth,
      date: incidentDate,
      violationDescription,
      claimDescription,
      lossAmountUserEntered,
    },
  } = incidentFields;

  const incidentByRef: { [key: string]: (typeof incidentList)[number] } = incidentList?.reduce(
    (acc: Record<string, (typeof incidentList)[number]>, incident) => {
      acc[incident.ref] = incident;

      return acc;
    },
    {},
  );

  const handleCancelIncident = useCallback(
    async (incidentRef: string) => {
      // if cancel selected while adding device and if there are any data entered then have to show remove dialog.
      if (incidentRef) {
        const incident = incidentByRef[incidentRef];
        setOpenLossViolationDialog(incident.type);
        setIncidentToBeRemoved(incident);
      } else {
        // Dont show remove dialog if there are no data loss
        // cancel click while edit - Discard the edit changes and return to previous value
        if (incidentDataBeforeEdit) {
          // restore old values as user has clicked on discard
          incidentMonth.validateUpdateAndPatch(incidentDataBeforeEdit.month);
          incidentYear.validateUpdateAndPatch(incidentDataBeforeEdit.year);
          incidentDay.validateUpdateAndPatch(incidentDataBeforeEdit.day);
          incidentDate.validateUpdateAndPatch(incidentDataBeforeEdit.date);
          if (incidentDataBeforeEdit.lossAmountUserEntered)
            lossAmountUserEntered.validateUpdateAndPatch(
              incidentDataBeforeEdit.lossAmountUserEntered,
            );
          if (incidentDataBeforeEdit.claimDescription) {
            const claimDesc = claimDescription.question.options?.find(
              (option) => option.label === incidentDataBeforeEdit.claimDescription,
            )?.value;
            if (claimDesc) {
              claimDescription.validateUpdateAndPatch(claimDesc);
            }
          }
          if (incidentDataBeforeEdit.violationDescription) {
            const violationDesc = violationDescription.question.options?.find(
              (option) => option.label === incidentDataBeforeEdit.violationDescription,
            )?.value;
            if (violationDesc) {
              violationDescription.validateUpdateAndPatch(violationDesc);
            }
          }
        } else {
          if (incidentToBeRemoved) {
            removeIncident(incidentToBeRemoved);
          }
          if (currentIncident) {
            removeCurrentIncident(currentIncident);
          }
          claimDescription.validateUpdateAndPatch('');
          lossAmountUserEntered.validateUpdateAndPatch('');
          violationDescription.validateUpdateAndPatch('');
          incidentType.validateUpdateAndPatch('');
          incidentDay.validateUpdateAndPatch('');
          incidentMonth.validateUpdateAndPatch('');
          incidentYear.validateUpdateAndPatch('');
        }
        setEditIncidentRef('');
        setIncidentDataBeforeEdit(undefined);
        setIncidentToBeRemoved(undefined);
      }
    },
    [
      claimDescription,
      currentIncident,
      incidentByRef,
      incidentDataBeforeEdit,
      incidentDate,
      incidentDay,
      incidentMonth,
      incidentToBeRemoved,
      incidentType,
      incidentYear,
      lossAmountUserEntered,
      removeCurrentIncident,
      removeIncident,
      violationDescription,
    ],
  );

  const onRemoveIncident = useCallback(
    (incidentRef: string) => {
      const incident = incidentByRef[incidentRef];
      setIncidentToBeRemoved(incident);
      setOpenLossViolationDialog(incident.type);
    },
    [incidentByRef],
  );

  const onEditIncident = useCallback(
    (incidentRef: string) => {
      const incident = incidentByRef[incidentRef];
      setAddingLossViolation(true);
      setIncidentTypeValue(incident.type);
      setEditIncidentRef(incident.ref);
      setIncidentDataBeforeEdit({ ...incident });
    },
    [incidentByRef],
  );

  const addIncident = useCallback(
    (incidentTypeValue: string) => {
      setAddingLossViolation(true);
      setIncidentTypeValue(incidentTypeValue);
      const incident = addLossViolation();
      addIncidentToDriver(incident.incidentRef);
      setEditIncidentRef(incident.incidentRef);
    },
    [addIncidentToDriver, addLossViolation],
  );

  const handleSaveIncident = useCallback(() => {
    setAddingLossViolation(false);
    setIncidentTypeValue('');
    setEditIncidentRef('');
  }, []);

  const addLoss = useCallback(() => {
    addIncident(INCIDENT_TYPE_CLAIM_OR_ACCIDENT);
  }, [addIncident]);

  const addViolation = useCallback(() => {
    addIncident(INCIDENT_TYPE_VIOLATION);
  }, [addIncident]);

  const onCloseDialogAction = useCallback(() => {
    setOpenLossViolationDialog('');
    setIncidentTypeValue('');
  }, []);

  const onRemoveDialogAction = useCallback(() => {
    setAddingLossViolation(false);
    setOpenLossViolationDialog('');
    setLossActionInfoMessage(
      openLossViolationDialog === 'Violation' ? 'Violation removed.' : 'Loss removed.',
    );
    setLossAndViolationActionInfoType('REMOVE');
    scrollToTop();

    if (incidentDataBeforeEdit) {
      // restore old values as user has clicked on discard
      incidentMonth.validateUpdateAndPatch(incidentDataBeforeEdit.month);
      incidentYear.validateUpdateAndPatch(incidentDataBeforeEdit.year);
      incidentDay.validateUpdateAndPatch(incidentDataBeforeEdit.day);
      incidentDate.validateUpdateAndPatch(incidentDataBeforeEdit.date);
      if (incidentDataBeforeEdit.lossAmountUserEntered)
        lossAmountUserEntered.validateUpdateAndPatch(incidentDataBeforeEdit.lossAmountUserEntered);
      if (incidentDataBeforeEdit.claimDescription) {
        const claimDesc = claimDescription.question.options?.find(
          (option) => option.label === incidentDataBeforeEdit.claimDescription,
        )?.value;
        if (claimDesc) {
          claimDescription.validateUpdateAndPatch(claimDesc);
        }
      }
      if (incidentDataBeforeEdit.violationDescription) {
        const violationDesc = violationDescription.question.options?.find(
          (option) => option.label === incidentDataBeforeEdit.violationDescription,
        )?.value;
        if (violationDesc) {
          violationDescription.validateUpdateAndPatch(violationDesc);
        }
      }
    } else {
      if (incidentToBeRemoved) {
        removeIncident(incidentToBeRemoved);
      }
      if (currentIncident) {
        removeCurrentIncident(currentIncident);
      }
      claimDescription.validateUpdateAndPatch('');
      lossAmountUserEntered.validateUpdateAndPatch('');
      violationDescription.validateUpdateAndPatch('');
      incidentType.validateUpdateAndPatch('');
      incidentDay.validateUpdateAndPatch('');
      incidentMonth.validateUpdateAndPatch('');
      incidentYear.validateUpdateAndPatch('');
    }
    setEditIncidentRef('');
    setIncidentDataBeforeEdit(undefined);
    setIncidentToBeRemoved(undefined);
  }, [
    claimDescription,
    currentIncident,
    incidentDataBeforeEdit,
    incidentDate,
    incidentDay,
    incidentMonth,
    incidentToBeRemoved,
    incidentType,
    incidentYear,
    lossAmountUserEntered,
    openLossViolationDialog,
    removeCurrentIncident,
    removeIncident,
    violationDescription,
  ]);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setLossActionInfoMessage('');
        setLossAndViolationActionInfoType('NONE');
      }
    },
    [],
  );

  const snackbarDefault = lossAndViolationActionInfoType === 'REMOVE' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!lossActionInfoMessage}
      autoHideDuration={3000}
      message={lossActionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = lossAndViolationActionInfoType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={lossActionInfoMessage}
      hideActionButton
    />
  );

  const removeDialog = openLossViolationDialog && (
    <Dialog
      actionButtonLabel={
        openLossViolationDialog === 'Violation' ? 'REMOVE VIOLATION' : 'REMOVE LOSS'
      }
      titleText={openLossViolationDialog === 'Violation' ? 'Remove violation?' : 'Remove loss?'}
      textButtonLabel='Cancel'
      open={!!openLossViolationDialog}
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
    >
      <div>Any associated content will be removed.</div>
    </Dialog>
  );

  const hasIndicatedLosses = useSelector((state: RootStore) =>
    getHasLossesInThePastFiveYears(state, AUTO_POLICY_HOUSEHOLD_LOSSES),
  );

  /**
   * To display text secondary text under driver level losses and violations header
   */
  useEffect(() => {
    const hasNoLossesOrViolations = incidentList.length === 0;
    if (noLossesOrViolationsText !== hasNoLossesOrViolations) {
      setLossesOrViolationsText(hasNoLossesOrViolations);
    }
  }, [incidentList, noLossesOrViolationsText]);

  const renderIncidentQuestionsForm = useCallback(
    (formProps: ResourceFormProps) => (
      <>
        {addingLossViolation && (
          <IncidentQuestions
            {...formProps}
            driverRef={driver.ref}
            personRef={driver.personRef}
            setAddingLossViolation={setAddingLossViolation}
            incidentTypeValue={incidentTypeValue}
            setLossActionInfoMessage={setLossActionInfoMessage}
            setLossAndViolationActionInfoType={setLossAndViolationActionInfoType}
            editIncidentRef={editIncidentRef}
            setOpenLossViolationDialog={setOpenLossViolationDialog}
            setEditIncidentRef={setEditIncidentRef}
            setIncidentTypeValue={setIncidentTypeValue}
            setIncidentDataBeforeEdit={setIncidentDataBeforeEdit}
          />
        )}
      </>
    ),
    [addingLossViolation, driver.ref, driver.personRef, incidentTypeValue, editIncidentRef],
  );

  const renderIncidentItem = useCallback(
    (item: string) => {
      const incident = incidentList.find((incident) => incident.ref === item);
      const isLoss = incident?.type === 'Claim';
      const incidentLossDate = `${incident?.month}-${incident?.day}-${incident?.year}`;

      return (
        <>
          <Resource.Item
            xs={2}
            title={isLoss ? 'Loss Date' : 'Violation Date'}
            value={incidentLossDate ? dayjs(incidentLossDate).format('MM/DD/YYYY') : ' '}
          />
          <Resource.Item
            xs={2}
            title={isLoss ? 'Loss Description' : 'Violation Description'}
            value={isLoss ? incident.claimDescription : incident?.violationDescription}
          />
          <Resource.Item
            xs={2}
            title={isLoss ? 'Amount' : ''}
            value={isLoss ? parseDollar(incident?.lossAmountUserEntered) : ''}
          />
          <Resource.Item xs={2} title='Status' value='Active' />
          <Resource.Item xs={2} title='Source' value='Insured' />
        </>
      );
    },
    [incidentList],
  );

  const noLossesOrViolationsMessage = 'No losses or violations.';
  const noLossesFoundInReportsMessage = 'No losses found in third-party reports.';

  const refsSortedByEndDate = incidentList
    .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
    .map((incident) => incident.ref);

  return (
    <Grid container className={classes.driverLossViolationContainer}>
      {removeDialog}
      <div
        className={classes.lossesAndViolation}
      >{`${driver.firstName} ${driver.lastName}'s Losses and Violations`}</div>
      {noLossesOrViolationsText ? (
        <p className={classes.secondaryText}>
          {!hasIndicatedLosses ? noLossesOrViolationsMessage : noLossesFoundInReportsMessage}
        </p>
      ) : (
        <br />
      )}
      <>
        <Resource.List
          items={refsSortedByEndDate}
          editItemRef={editIncidentRef}
          onEdit={onEditIncident}
          pageErrors={[]}
          onDelete={onRemoveIncident}
          onCancel={handleCancelIncident}
          onNext={handleSaveIncident}
          form={renderIncidentQuestionsForm}
          renderListItem={renderIncidentItem}
        />
        <div className={classes.actionButtons}>
          <Resource.AddButton
            onClick={addLoss}
            data-testid='autoDriverLossAdd'
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='losses_and_violations_page_add_loss_button'
            analyticsElement='choice.lossesAndViolationsPage.addLossButton'
          >
            ADD LOSS
          </Resource.AddButton>
          <Resource.AddButton
            onClick={addViolation}
            data-testid='autoDriverViolationAdd'
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='losses_and_violations_page_add_violation_button'
            analyticsElement='choice.lossesAndViolationsPage.addViolationButton'
          >
            ADD VIOLATION
          </Resource.AddButton>
        </div>
      </>
      {snackbarDefault}
      {snackbarSuccess}
    </Grid>
  );
};
