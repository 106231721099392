import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoverageCardItems' })((theme) => ({
  root: {
    padding: '20px 6px 20px 0px',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  coverageName: {
    ...theme.typography.body4,
  },
  label: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
  },
  checkboxContainer: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: '4px',
  },
  toolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
  },
  coveragesDropdown: {
    '& > *:first-child': {
      marginBottom: 0,
    },
    paddingTop: 8,
    paddingBottom: 8,
    overflow: 'hidden',
  },
  formControlLabel: {
    padding: 20,
  },
  formControlCheckBox: {
    marginRight: 30,
  },
  premiumAmount: {
    ...theme.typography.body4,
    textAlign: 'right',
  },
  formControl: {
    textAlign: 'left',
  },
}));
