import { RadioGroupWithOptions } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  driverRef: string;
  name: string;
}

export const DriverLevelLapseQuestion: React.FC<Props> = (props) => {
  const { driverRef, name } = props;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const priorLapse = useDriverField('lapse');
  useAddFields({ priorLapse });

  if (!priorLapse.exists) return null;

  return (
    <RadioGroupWithOptions
      {...priorLapse.props}
      label={`Why does ${name} not have active auto insurance?`}
      options={priorLapse.props.options}
      trackingName='no_insurance_reason_selection'
      variant='radioCard'
    />
  );
};
