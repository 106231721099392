import { useMemo } from 'react';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { GridItem, TooltipWithIcon } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { useGetVehicleItemsWithImage } from '@ecp/features/sales/quotes/auto';
import { CheckboxGroup } from '@ecp/features/sales/shared/components';
import {
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { VehicleDiscounts } from '@ecp/features/sales/shared/types';
import type { CardOption, Field } from '@ecp/types';

export interface VehicleDiscountOptionsProps {
  discountKey: string;
  discountName: string;
  vehicleDiscounts: VehicleDiscounts;
  discountLabel: string;
  helpText: string;
  parentDiscount?: Field;
  trackingName?: string;
  isRequired?: boolean;
}

export const VehicleDiscountOptions: React.FC<VehicleDiscountOptionsProps> = (props) => {
  const {
    discountKey,
    discountName,
    discountLabel,
    helpText,
    vehicleDiscounts,
    parentDiscount,
    trackingName,
    isRequired,
  } = props;
  const dispatch = useDispatch();

  const vehicleDiscountFields = useMemo(
    () =>
      Object.values(vehicleDiscounts).reduce((acc, questions) => {
        Object.values(questions).forEach((question) => {
          acc[question.key] = question;
        });

        return acc;
      }, {} as Record<string, Field>),
    [vehicleDiscounts],
  );

  useAddFields({ ...vehicleDiscountFields });

  const vehicleDiscountKeys = useMemo(() => {
    return Object.keys(vehicleDiscounts);
  }, [vehicleDiscounts]);

  const vehicleDiscountValues = useMemo(() => {
    return vehicleDiscountKeys.reduce((values, key) => {
      const field = vehicleDiscounts[key][discountKey];
      const { value } = field;
      if (castToBoolean(value)) {
        return [...values, key];
      }

      return values;
    }, [] as Array<keyof typeof vehicleDiscounts & string>);
  }, [discountKey, vehicleDiscountKeys, vehicleDiscounts]);

  const selectAtLeastError =
    isRequired && parentDiscount && parentDiscount?.value && vehicleDiscountValues.length === 0
      ? 'Select at least one vehicle for MilesMyWay discount'
      : undefined;

  const vehicleItemList = useGetVehicleItemsWithImage();
  const vehiclesOptions: CardOption[] = useMemo(() => {
    return vehicleItemList.map((v) => {
      const { vehicle } = v;
      const { ref, description } = vehicle;
      const parsedRef = ref || '';

      return {
        value: parsedRef,
        label: description,
        noIcon: true,
      };
    });
  }, [vehicleItemList]);

  const handleDiscountChange = useEvent(async (checkedRefs: string[]) => {
    checkedRefs.forEach((vehicleRef) => {
      const field = vehicleDiscounts[vehicleRef][discountKey];
      if (!castToBoolean(field.value)) {
        field.props.actionOnComplete(true);
      }
    });
    vehicleDiscountKeys.forEach((key) => {
      if (!checkedRefs.includes(key)) {
        const field = vehicleDiscounts[key][discountKey];
        if (castToBoolean(field.value)) {
          field.props.actionOnComplete(false);
        }
      }
    });

    const updateError =
      isRequired && parentDiscount && parentDiscount?.value && checkedRefs.length === 0
        ? 'Select at least one vehicle for MilesMyWay discount'
        : undefined;

    if (updateError) {
      const errorKey = vehicleDiscounts[vehicleDiscountKeys[0]]['ubiProgram.optIn'].key;
      dispatch(
        setFormErrorsChangedByField({
          key: errorKey,
          errors: [updateError],
        }),
      );
    } else {
      const errorKey = vehicleDiscounts[vehicleDiscountKeys[0]]['ubiProgram.optIn'].key;
      dispatch(setFormErrorsResetByField({ key: errorKey }));
    }
  });

  return (
    <GridItem xs={6} topSpacing='lg'>
      <CheckboxGroup
        name={discountName}
        cardSize='medium'
        options={vehiclesOptions}
        label={
          <>
            {discountLabel}
            <TooltipWithIcon title={helpText} />
          </>
        }
        variant='paddedHorizontalCard'
        actionOnComplete={handleDiscountChange}
        values={vehicleDiscountValues}
        trackingName={trackingName}
        error={selectAtLeastError}
        dataTestIdPrefix={discountName}
      />
    </GridItem>
  );
};
