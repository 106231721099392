import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormLabel, Grid } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { castToBoolean } from '@ecp/utils/common';
import type { GeoAddress } from '@ecp/utils/geo';
import { useGeoAddressOptions, validateAndCombineAddress } from '@ecp/utils/geo';

import { GridItem, NumberFormat, Snackbar, SnackbarAlert, TooltipWithIcon } from '@ecp/components';
import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  useAddressRefForVehicle,
  useDefaultVehicleAddressRef,
  useGarageField,
  useKeptAtRiskAddressValue,
  useUpdateVehicleAddressRef,
} from '@ecp/features/sales/quotes/auto';
import {
  AutoComplete,
  Dialog,
  RadioGroupWithOptions,
  Select,
  TextField,
} from '@ecp/features/sales/shared/components';
import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import {
  createAddressForUpdate,
  getPrimaryInsuredAddressInfo,
  updateAnswers,
  useAddressFieldValue,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { useStyles } from './VehicleKeptAtQuestion.styles';
const KEPT_AT_RISK_HELP_TEXT =
  'Enter the address where you regularly park this vehicle when not at work.';

const gaTrackSuggestionClick = (): void => {
  trackClick({
    action: 'SmartyStreetSuggestedAddress',
    label: 'ClickedSuggestion',
  });
  trackSapiAnalyticsEvent({
    element: 'choice.vehicleForm.smartyStreetSuggestion',
    event: 'click',
    eventDetail: 'true',
  });
};

interface Props {
  vehicleRef: string;
}

export const VehicleKeptAtQuestion: React.FC<Props> = (props) => {
  const { vehicleRef } = props;
  const dispatch = useDispatch();
  const garageAddressRef = useAddressRefForVehicle(vehicleRef);
  const garageAddress = useGarageField(vehicleRef, 'line1');
  const addressInformation = useSelector(getPrimaryInsuredAddressInfo);
  const { classes } = useStyles();
  const garageUnitOrApt = useGarageField(vehicleRef, 'line2');
  const garageCity = useGarageField(vehicleRef, 'city');
  const garageState = useGarageField(vehicleRef, 'state');
  const garageAtZip = useGarageField(vehicleRef, 'zipcode');
  const keptAtRiskAddress = useField(`${vehicleRef}.keptAtRiskAddress`);
  const [showOutOfStateError, setShowOutOfStateError] = useState(false);
  const [showRemoveAddressDialog, setShowRemoveAddressDialog] = useState(false);
  const [isRemovedSnackbarOpen, setIsRemovedSnackbarOpen] = useState(false);

  const [vehicleKeptAddressSuggestions, setVehicleKeptAddressSuggestions] = useState<GeoAddress[]>(
    [],
  );

  useInitValues({
    [keptAtRiskAddress.key]: true,
  });
  useAddFields({ keptAtRiskAddress });

  const updateVehicleAddressRef = useUpdateVehicleAddressRef(vehicleRef);

  // TODO: useInitValues issue here is it is not saving the default value to true
  useEffect(() => {
    if (keptAtRiskAddress.value == null) {
      keptAtRiskAddress.props.actionOnComplete('true');
      updateVehicleAddressRef(true);
    }
  }, [keptAtRiskAddress.props, keptAtRiskAddress.value, updateVehicleAddressRef]);

  const keptAtRiskAddressValue = Boolean(
    useKeptAtRiskAddressValue(keptAtRiskAddress, garageAddress),
  );

  useAddFields({
    garage: {
      line1: garageAddress,
      line2: garageUnitOrApt,
      city: garageCity,
      state: garageState,
      zipcode: garageAtZip,
    },
  });
  useAddConditionValues({
    conditionalFields: [garageAddress, garageUnitOrApt, garageCity, garageState, garageAtZip],
    isExcluded: () => keptAtRiskAddressValue,
  });

  const {
    handleSuggestionsFetchRequested: handleVKASuggestionsFetchRequested,
    handleSuggestionsClearRequested,
    handleAptSuggestionFetchRequested,
  } = useGeoAddressOptions();

  const defaultVehicleAddress = useDefaultVehicleAddressRef();
  const defaultVehicleAddressState = useAddressFieldValue(defaultVehicleAddress, 'state');

  const handleStateChange = useCallback(
    (value: AnswerValue) => {
      garageState.props.actionOnComplete(value);
      if (value !== defaultVehicleAddressState) {
        setShowOutOfStateError(true);
      }
    },
    [defaultVehicleAddressState, garageState.props],
  );

  const handleKeptAtRiskAddressChange = useCallback(
    async (value: AnswerValue) => {
      // because this change action is triggered on every click,
      // we should only run the actionOnComplete if the value has changed
      if (keptAtRiskAddress.value === value) return;

      /**
       * If switching from NO to YES and any address field was entered, show removal dialog
       * and close of Dialog will handle (or not if canceled) update of field value
       */
      const hasEnteredAddressValues =
        garageAddress.value ||
        garageUnitOrApt.value ||
        garageCity.value ||
        garageState.value ||
        garageAtZip.value;
      if (value && hasEnteredAddressValues) setShowRemoveAddressDialog(true);
      else {
        await updateVehicleAddressRef(castToBoolean(value));
        keptAtRiskAddress.props.actionOnComplete(value);
      }
    },
    [
      garageAddress.value,
      garageAtZip.value,
      garageCity.value,
      garageState.value,
      garageUnitOrApt.value,
      keptAtRiskAddress.props,
      keptAtRiskAddress.value,
      updateVehicleAddressRef,
    ],
  );

  const autoCompleteVKASuggestions = useMemo(
    () =>
      vehicleKeptAddressSuggestions.map((s: GeoAddress, index, arr) => {
        if (arr.length - 1 === index) {
          return s.street_line;
        }

        return (
          s.street_line +
          (s.secondary !== ''
            ? ` ${s.secondary} (${s.entries} more entries) ${s.city}, ${s.state} ${s.zipcode}`
            : ` ${s.city}, ${s.state} ${s.zipcode}`)
        );
      }),
    [vehicleKeptAddressSuggestions],
  );

  const handleVKASuggestionsFetch = useCallback(
    async (value: string): Promise<void> => {
      if (!value.includes('Apt') || !value.includes('Ste')) {
        // Need to pass state value as well to grab suggestions instead of just filtering on zipcode because garage address
        // may be different from primary address filled in.
        const output = await handleVKASuggestionsFetchRequested(
          value,
          garageAtZip.value as string,
          (garageState.value as string) || '',
        );
        setVehicleKeptAddressSuggestions(output);
      }
    },
    [garageAtZip.value, garageState.value, handleVKASuggestionsFetchRequested],
  );

  const handleVehickeKeptAddressSelection = useCallback(
    async (value: string) => {
      const vehicleKeptAddressToBeValidated = vehicleKeptAddressSuggestions.find(
        (vehicleKeptaddressVal: GeoAddress) => {
          const fullAddress =
            vehicleKeptaddressVal.street_line +
            (vehicleKeptaddressVal.secondary !== ''
              ? ` ${vehicleKeptaddressVal.secondary} (${vehicleKeptaddressVal.entries} more entries) ${vehicleKeptaddressVal.city}, ${vehicleKeptaddressVal.state} ${vehicleKeptaddressVal.zipcode}`
              : ` ${vehicleKeptaddressVal.city}, ${vehicleKeptaddressVal.state} ${vehicleKeptaddressVal.zipcode}`);

          return fullAddress === value;
        },
      );
      // If selected address is just a house
      if (vehicleKeptAddressToBeValidated) {
        if (vehicleKeptAddressToBeValidated.entries <= 1) {
          garageAddress.props.actionOnComplete(vehicleKeptAddressToBeValidated.street_line);
          const inputAddress = {
            street: vehicleKeptAddressToBeValidated.street_line,
            city: vehicleKeptAddressToBeValidated.city,
            street2:
              vehicleKeptAddressToBeValidated.secondary !== ''
                ? vehicleKeptAddressToBeValidated.secondary
                : '',
            state: vehicleKeptAddressToBeValidated.state,
            zipcode: vehicleKeptAddressToBeValidated.zipcode,
          };
          const newVehicleKeptAddress = await validateAndCombineAddress(
            inputAddress,
            garageAddressRef,
          );
          if (newVehicleKeptAddress) {
            // we should manually update these field values to account for any prior existing error messages,
            // because updating the answers in redux will not handle that for us
            garageAddress.props.actionOnComplete(newVehicleKeptAddress.line1);
            garageUnitOrApt.props.actionOnComplete(newVehicleKeptAddress.line2);
            garageCity.props.actionOnComplete(newVehicleKeptAddress.city);
            garageState.props.actionOnComplete(
              `${STATE_CODE_PREFIX}${newVehicleKeptAddress.state}`,
            );
            if (
              `${STATE_CODE_PREFIX}${newVehicleKeptAddress.state}` !== defaultVehicleAddressState
            ) {
              setShowOutOfStateError(true);
            }
            garageAtZip.props.actionOnComplete(newVehicleKeptAddress.zipcode);
            const vehicleKeptAddressToBeSaved = createAddressForUpdate(newVehicleKeptAddress);
            await dispatch(updateAnswers({ answers: { ...vehicleKeptAddressToBeSaved } }));
          }
        } else {
          garageAddress.props.actionOnComplete(vehicleKeptAddressToBeValidated.street_line);
          // need to pass selected address as well as the letter A to limit the results to number of valid entries recieved for address.
          // Will  return # of entries whose secondary value starts with A.
          const selectedValue = `${vehicleKeptAddressToBeValidated.street_line} ${vehicleKeptAddressToBeValidated.secondary} A (${vehicleKeptAddressToBeValidated.entries}) ${vehicleKeptAddressToBeValidated.city} ${vehicleKeptAddressToBeValidated.state} ${vehicleKeptAddressToBeValidated.zipcode}`;
          // If selected address is a series of apartments
          const fetchNewAptSuggestions = await handleAptSuggestionFetchRequested(
            vehicleKeptAddressToBeValidated.street_line,
            selectedValue,
          );

          setVehicleKeptAddressSuggestions(fetchNewAptSuggestions);
        }
      }
    },
    [
      vehicleKeptAddressSuggestions,
      garageAddress.props,
      garageAddressRef,
      garageUnitOrApt.props,
      garageCity.props,
      garageState.props,
      defaultVehicleAddressState,
      garageAtZip.props,
      dispatch,
      handleAptSuggestionFetchRequested,
    ],
  );

  const onCloseDialogAction = useCallback(() => {
    setShowRemoveAddressDialog(false);
  }, []);

  const onRemoveDialogAction = useCallback(() => {
    setShowOutOfStateError(false);
    setShowRemoveAddressDialog(false);
    // on remove change garage address back to risk state address
    keptAtRiskAddress.props.actionOnComplete(true);
    updateVehicleAddressRef(castToBoolean(true));
    setIsRemovedSnackbarOpen(true);
  }, [keptAtRiskAddress.props, updateVehicleAddressRef]);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        // Utilising the same close function for both remove snackbar and out of state error.
        //  Because both can't logically be open at the same time, this is fine.
        setIsRemovedSnackbarOpen(false);
        setShowOutOfStateError(false);
      }
    },
    [],
  );

  const removeAddressDialog = showRemoveAddressDialog && (
    <Dialog
      actionButtonLabel='REMOVE ADDRESS'
      titleText='Remove garaging address?'
      textButtonLabel='Cancel'
      open
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
    >
      <div>The garaging address will be removed.</div>
    </Dialog>
  );

  const snackbarDefault = (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={isRemovedSnackbarOpen}
      autoHideDuration={3000}
      message='Garaging address removed.'
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  return (
    <>
      {removeAddressDialog}
      {snackbarDefault}
      {keptAtRiskAddress.exists && (
        <Grid item xs={12}>
          <RadioGroupWithOptions
            {...keptAtRiskAddress.props}
            actionOnComplete={handleKeptAtRiskAddressChange}
            label={
              <>
                {`Is the vehicle kept at ${addressInformation.line1}?`}
                <TooltipWithIcon title={KEPT_AT_RISK_HELP_TEXT} />
              </>
            }
            variant='yesNoButton'
            trackingName='garaging_address_question'
            trackingLabel='garaging_address_label'
          />
        </Grid>
      )}

      {/* Address if vehicle is not kept at the risk address */}
      {!keptAtRiskAddress.value && (
        <>
          <GridItem topSpacing='lg' xs={12}>
            <FormLabel>Where is this vehicle garaged?</FormLabel>
          </GridItem>

          <Grid container columnSpacing={4}>
            {garageAddress.exists && (
              <GridItem topSpacing='sm' xs={12} md={6}>
                {' '}
                <AutoComplete
                  {...garageAddress.props}
                  label='Street Address'
                  suggestions={autoCompleteVKASuggestions}
                  geoAddressFormattedSuggestions={vehicleKeptAddressSuggestions}
                  onSuggestionsClearRequested={handleSuggestionsClearRequested}
                  onSuggestionsFetchRequested={handleVKASuggestionsFetch}
                  onSuggestionSelected={handleVehickeKeptAddressSelection}
                  id='GaragingAddressAutoComplete'
                  trackingName='garaging_address'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  gaTrackSuggestionClick={gaTrackSuggestionClick}
                />
              </GridItem>
            )}
            {garageUnitOrApt.exists && (
              <GridItem topSpacing='sm' xs={12} md={6}>
                <TextField
                  {...garageUnitOrApt.props}
                  id='GaragingApt'
                  label='Apt/Unit # (optional)'
                  trackingName='garaging_address_line_2'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </GridItem>
            )}
            {garageCity.exists && (
              <GridItem topSpacing='sm' xs={12} md={garageState.exists ? 4 : 6}>
                <TextField
                  {...garageCity.props}
                  id='GaragingCity'
                  label='City'
                  trackingName='garaging_city'
                  trackingLabel={garageCity.props.value}
                />
              </GridItem>
            )}
            {garageState.exists && (
              <GridItem topSpacing='sm' xs={12} md={4}>
                {' '}
                <Select
                  {...(garageState.props as OptionProps)}
                  id='State'
                  label='State'
                  trackingName='garaging_state'
                  trackingLabel={garageState.props.value}
                  actionOnComplete={handleStateChange}
                />
              </GridItem>
            )}
            {garageAtZip.exists && (
              <GridItem topSpacing='sm' xs={12} md={garageState.exists ? 4 : 6}>
                <NumberFormat
                  {...garageAtZip.props}
                  id='GaragingZip'
                  formatType='zipcode'
                  label='ZIP code'
                  trackingName='garaging_zip_code'
                />
              </GridItem>
            )}
          </Grid>
        </>
      )}
      {showOutOfStateError && (
        <SnackbarAlert
          message='We are unable to provide coverage for a vehicle kept outside of your residence state on
          this quote. To proceed with your in-state vehicles, please remove this vehicle.'
          vertical='bottom'
          horizontal='center'
          open
          onClose={handleInfoSnackbarClose}
          severity='error'
        />
      )}
    </>
  );
};
