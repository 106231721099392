import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  vehicleRef: string;
}

export const VehicleAntiTheftQuestion: React.FC<Props> = (props) => {
  const { vehicleRef } = props;

  const antiTheftActive = useField(`${vehicleRef}.features.antitheft.active`);
  useInitValues({ [antiTheftActive.key]: false });

  const antiTheftPassive = useField(`${vehicleRef}.features.antitheft.passive`);
  useInitValues({ [antiTheftPassive.key]: false });
  useAddFields({ antiTheftPassive, antiTheftActive });

  return (
    <Grid>
      {antiTheftActive.exists && (
        <GridItem>
          <RadioGroupWithOptions
            {...antiTheftActive.props}
            label='Active Anti-theft Device'
            id='AntiTheftActive'
            variant='yesNoButton'
            trackingName='AntiTheftActiveButton'
            dataTestId='ActiveAntiTheftButton'
          />
        </GridItem>
      )}
      {antiTheftPassive.exists && (
        <GridItem topSpacing='sm'>
          <RadioGroupWithOptions
            {...antiTheftPassive.props}
            label='Passive Anti-theft Device'
            id='AntiTheftPassive'
            variant='yesNoButton'
            trackingName='AntiTheftPassiveButton'
            dataTestId='PassiveAntiTheftButton'
          />
        </GridItem>
      )}
    </Grid>
  );
};
