// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { Theme, Typography } from '../../../../base/src';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { appVars, breakpoints, createMixins, createTheme } from '../../../../base/src';
import { createComponents } from '../components';
import { palette } from '../palette';
import { createTypography } from '../typography';

const initialTheme = createTheme({ breakpoints, palette }, { breakpoints, palette, appVars });

const typography = createTypography(initialTheme.breakpoints);
const themeWithTypography = createTheme(
  { breakpoints, palette, typography },
  { breakpoints, palette, typography, appVars },
);

const mixins = createMixins(
  themeWithTypography.breakpoints,
  themeWithTypography.palette,
  themeWithTypography.typography as Typography,
  themeWithTypography.appVars,
  themeWithTypography.zIndex,
);

const components = createComponents(
  themeWithTypography.breakpoints,
  mixins,
  themeWithTypography.palette,
  themeWithTypography.typography as Typography,
);

export const theme = createTheme(
  { breakpoints, palette, typography, mixins, components },
  { breakpoints, palette, typography, mixins, components, appVars, name: 'amfam-adv' },
) as Theme;
