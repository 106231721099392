import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AssignmentLineItem' })((theme) => ({
  container: {
    alignItems: 'center',
  },
  occasionalDriverContainer: {
    marginTop: 24,
  },
  button: {
    marginTop: 24,
  },
  label: {
    paddingTop: 32,
  },
  gridLabel: {
    alignSelf: 'start !important',
  },
}));
