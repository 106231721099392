import { useCallback } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { RadioGroupWithOptions } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { DatePicker } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getPrimaryInsuredStateCode, useField } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/types';

import {
  getCanRecieveDefensiveDriverDiscount,
  getFieldHelpTextByState,
  getRequiresDefensiveDriverCourse,
} from '../../utils';
import { CustomCheckbox } from '../CustomCheckbox';
import { useStyles } from './DefensiveDriverQuestion.styles';

interface Props extends QuestionProps {
  driverRef: string;
  driverAge: number | undefined;
  driverName: string;
}

export const DefensiveDriverQuestion: React.FC<Props> = (props) => {
  const { driverAge, driverName, driverRef } = props;
  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const shouldRenderDiscount = getCanRecieveDefensiveDriverDiscount(driverAge || 0, stateCode);
  const shouldRenderSubQuestions = getRequiresDefensiveDriverCourse(stateCode);

  const { classes } = useStyles();

  const defensiveDriver = useField(`${driverRef}.discount.defensiveDriver`);
  const defensiveDriverCourseCompletionDate = useField(
    `${driverRef}.discount.defensiveDriverCourseCompletionDate`,
  );
  const defensiveDriverCourseCompletionDueToViolationInd = useField(
    `${driverRef}.discount.defensiveDriverCourseCompletionDueToViolationInd`,
  );
  useAddFields({
    [defensiveDriver.key]: defensiveDriver,
    [defensiveDriverCourseCompletionDate.key]: defensiveDriverCourseCompletionDate,
    [defensiveDriverCourseCompletionDueToViolationInd.key]:
      defensiveDriverCourseCompletionDueToViolationInd,
  });

  const handleCourseCompletionDueToViolationIndComplete = useCallback(
    async (value: AnswerValue) => {
      defensiveDriverCourseCompletionDueToViolationInd.validateUpdateAndPatch(value);
      if (!value) {
        defensiveDriverCourseCompletionDate.validateUpdateAndPatch(null);
      }
    },
    [defensiveDriverCourseCompletionDueToViolationInd, defensiveDriverCourseCompletionDate],
  );

  const handleDefensiveDriverChange = useCallback(
    async (value: AnswerValue) => {
      defensiveDriver.validateUpdateAndPatch(value);
      if (!value) {
        defensiveDriverCourseCompletionDate.validateUpdateAndPatch(null);
        defensiveDriverCourseCompletionDueToViolationInd.validateUpdateAndPatch(null);
      }
    },
    [
      defensiveDriver,
      defensiveDriverCourseCompletionDate,
      defensiveDriverCourseCompletionDueToViolationInd,
    ],
  );

  if (!defensiveDriver.exists || !shouldRenderDiscount) return null;

  return (
    <>
      <CustomCheckbox
        actionOnChange={handleDefensiveDriverChange}
        tooltipText={getFieldHelpTextByState(stateCode)}
        field={defensiveDriver}
        title='Defensive Driver'
        key={`${driverRef}-${defensiveDriver.key}`}
      />
      {defensiveDriver.value && shouldRenderSubQuestions && (
        <Grid
          container
          spacing={2}
          className={classes.root}
          key={`${driverRef}-defensiveDriverQuestionContainer`}
        >
          <Grid item xs={12} className={classes.questionItem}>
            <RadioGroupWithOptions
              {...defensiveDriverCourseCompletionDueToViolationInd.props}
              id={`defensiveDriverCourseCompletionDueToViolationInd-${driverRef}`}
              label={`Has ${driverName} completed a course as a result of moving traffic violations?`}
              variant='yesNoButton'
              trackingName='defensiveDriverCourseCompletionDueToViolations'
              trackingLabel={defensiveDriverCourseCompletionDueToViolationInd.props.value}
              actionOnComplete={handleCourseCompletionDueToViolationIndComplete}
              value={defensiveDriverCourseCompletionDueToViolationInd.props.value || false}
            />
          </Grid>
          {defensiveDriverCourseCompletionDueToViolationInd.props.value && (
            <Grid item xs={4} className={classes.questionItem}>
              <DatePicker
                {...defensiveDriverCourseCompletionDate.props}
                hidePicker
                id={`defensiveDriverCourseCompletionDate-${driverRef}`}
                label='Course Completion Certification Date'
                trackingName='defensiveDriverCourseCompletionDate'
                trackingLabel={
                  defensiveDriverCourseCompletionDate.props.value
                    ? dayjs(defensiveDriverCourseCompletionDate.props.value).format('YYYY')
                    : ' '
                }
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
