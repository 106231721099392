import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoLossesAndViolationsPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  buttonsPanel: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 8,
  },
  policyHeader: {
    ...theme.typography.h4,
  },
  secondaryText: {
    ...theme.typography.body1,
    color: theme.palette.text.tertiary,
    margin: '8px 0px',
  },
  addButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 20,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  alertContainer: {
    padding: '6px, 8px, 6px, 16px',
    marginTop: 24,
    width: '962px',
    height: '68px',
  },
  policyLossesContainer: {
    paddingTop: '10px',
    marginTop: 32,
  },
}));
