import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type {
  Breakpoints,
  ComponentsOptions,
  MixinsOverrides,
  Palette,
  Typography,
} from '../../../../base/src';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { createComponents as createBaseComponents } from '../../../../base/src';

export const createComponents = (
  breakpoints: Breakpoints,
  mixins: MixinsOverrides,
  palette: Omit<Palette, 'mode'>,
  typography: Typography,
): ComponentsOptions => {
  const baseComponents = createBaseComponents(breakpoints, mixins, palette, typography);

  const components: ComponentsOptions = {
    Button: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'initial',
        },
        iconText: {
          height: 40,
        },
        primary: {
          backgroundColor: palette.secondary.main,
          '&:hover': {
            backgroundColor: palette.secondary.dark,
          },
        },
        success: {
          backgroundColor: palette.secondary.main,
          '&:hover': {
            backgroundColor: palette.secondary.dark,
          },
        },
        outlinePrimary: {
          color: palette.secondary.dark,
          '&:hover': {
            color: palette.secondary.dark,
            backgroundColor: palette.secondary.light,
          },
        },
        outlineSecondary: {
          color: palette.secondary.dark,
          border: `1px solid ${palette.secondary.border}`,
          '&:hover': {
            color: palette.secondary.dark,
            backgroundColor: palette.secondary.light,
          },
        },
      },
    },
    Dialog: {
      styleOverrides: {
        fullScreenButton: {
          textTransform: 'capitalize',
        },
      },
    },
    Checkbox: {
      styleOverrides: {
        icon: {
          width: 20,
          height: 20,
        },
        iconChecked: {
          borderWidth: 2,
          backgroundColor: palette.other.body,
          color: palette.primary.main,
        },
      },
    },
    RadioGroup: {
      styleOverrides: {
        classicRadio: {
          color: palette.text.primary,
        },
      },
    },
    PartnerLogo: {
      styleOverrides: {
        image: {
          minWidth: 125,
          minHeight: 44,
        },
      },
    },
  };

  return merge({}, baseComponents, components);
};
