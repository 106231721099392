import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { ensureStringArray } from '@ecp/utils/common';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import {
  createRef,
  getAddressInfo,
  getFieldValue,
  getPrimaryInsuredAddressInfo,
  getPrimaryInsuredMailingAddressInfo,
  updateAnswers,
  useField,
  useInitialPrimaryAddressRefFromRelate,
  useMailingAddressRef,
  usePreviousPrimaryAddressRef,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector, useStore } from '@ecp/features/sales/shared/store/utils';

import {
  buildAddressLabel,
  isAddressComplete,
  isAddressUnique,
  updatePrimaryInsuredAddress,
  useAddressFields,
} from '../../utils';
import { AddressQuestions } from '../AddressQuestions';
import { useStyles } from './RiskAddressQuestion.styles';

export interface AddressOptions {
  label: string;
  value: string;
}
interface Props {
  isValidAddress: boolean;
}

export const RiskAddressQuestion: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isValidAddress } = props;
  const store = useStore();
  const { classes } = useStyles();

  const staticSelectedPrimaryInsuredAddressRef = useField(
    `static.selected.primaryInsuredAddress.ref`,
  );
  const [isAddressChanged, setIsAddressChanged] = useState<boolean>(false);

  const primaryAddressRef = usePrimaryAddressRef();
  const primaryInsuredAddressInfo = useSelector(getPrimaryInsuredAddressInfo);

  const primaryMailingAddressRef = useMailingAddressRef();
  const primaryInsuredMailingAddressInfo = useSelector(getPrimaryInsuredMailingAddressInfo);

  const primaryMailingdAddressTypes = ensureStringArray(
    getFieldValue(store.getState(), `${primaryMailingAddressRef}.addressTypes`),
  );

  const initialPrimaryInsuredAddresseRefFromRelate = useInitialPrimaryAddressRefFromRelate();
  const initialPrimaryInsuredRiskAddressesInfoFromRelate = useSelector((state) =>
    getAddressInfo(state, initialPrimaryInsuredAddresseRefFromRelate),
  );

  const primaryInsuredAddressTypesFromRelate = ensureStringArray(
    getFieldValue(store.getState(), `${initialPrimaryInsuredAddresseRefFromRelate}.addressTypes`),
  );

  const previousPrimaryAddressRef = usePreviousPrimaryAddressRef();

  const isNewRiskAddressSelected =
    primaryAddressRef !== initialPrimaryInsuredAddresseRefFromRelate &&
    primaryAddressRef !== primaryMailingAddressRef;

  const isNewRiskAddressComplete = isAddressComplete(primaryInsuredAddressInfo);
  const isInitialPrimaryRiskAddressComplete = isAddressComplete(
    initialPrimaryInsuredRiskAddressesInfoFromRelate,
  );
  const isMailingAddressComplete = isAddressComplete(primaryInsuredMailingAddressInfo);

  const {
    address: { line1, line2, city, state, zipcode },
  } = useAddressFields(primaryAddressRef);

  useAddFields({
    [line1.key]: line1,
    [line2.key]: line2,
    [city.key]: city,
    [state.key]: state,
    [zipcode.key]: zipcode,
  });

  useAddConditionValues({
    conditionalFields: [line1, line2, city, state, zipcode],
    isExcluded: () => !!isNewRiskAddressComplete,
  });

  const {
    address: {
      line1: initialPrimaryInsuredRiskAddressesInfoFromRelateLine1,
      line2: initialPrimaryInsuredRiskAddressesInfoFromRelateLine2,
      city: initialPrimaryInsuredRiskAddressesInfoFromRelateCity,
      state: initialPrimaryInsuredRiskAddressesInfoFromRelateState,
      zipcode: initialPrimaryInsuredRiskAddressesInfoFromRelateZipcode,
    },
  } = useAddressFields(initialPrimaryInsuredAddresseRefFromRelate);

  useAddConditionValues({
    conditionalFields: [
      initialPrimaryInsuredRiskAddressesInfoFromRelateLine1,
      initialPrimaryInsuredRiskAddressesInfoFromRelateLine2,
      initialPrimaryInsuredRiskAddressesInfoFromRelateCity,
      initialPrimaryInsuredRiskAddressesInfoFromRelateState,
      initialPrimaryInsuredRiskAddressesInfoFromRelateZipcode,
    ],
    isExcluded: () => primaryAddressRef !== initialPrimaryInsuredAddresseRefFromRelate,
  });

  const {
    address: {
      line1: primaryInsuredMailingAddressLine1,
      line2: primaryInsuredMailingAddressLine2,
      city: primaryInsuredMailingAddressCity,
      state: primaryInsuredMailingAddressState,
      zipcode: primaryInsuredMailingAddressZipcode,
    },
  } = useAddressFields(primaryMailingAddressRef);

  useAddConditionValues({
    conditionalFields: [
      primaryInsuredMailingAddressLine1,
      primaryInsuredMailingAddressLine2,
      primaryInsuredMailingAddressCity,
      primaryInsuredMailingAddressState,
      primaryInsuredMailingAddressZipcode,
    ],
    isExcluded: () => isMailingAddressUnique && primaryAddressRef !== primaryMailingAddressRef,
  });

  const {
    address: {
      line1: previouSelectedNewAddressLine1,
      line2: previouSelectedNewAddressLine2,
      city: previouSelectedNewAddressCity,
      state: previouSelectedNewAddressState,
      zipcode: previouSelectedNewAddressZipcode,
    },
  } = useAddressFields(previousPrimaryAddressRef);

  useAddConditionValues({
    conditionalFields: [
      previouSelectedNewAddressLine1,
      previouSelectedNewAddressLine2,
      previouSelectedNewAddressCity,
      previouSelectedNewAddressState,
      previouSelectedNewAddressZipcode,
    ],
    isExcluded: () => !isNewRiskAddressSelected,
  });

  const uniqueRiskAddressOptions: AddressOptions[] = [];

  /*
   * Case : Add the Initial Primary Address info coming from relate as the option
   */

  if (initialPrimaryInsuredAddresseRefFromRelate) {
    uniqueRiskAddressOptions.push({
      label: buildAddressLabel(initialPrimaryInsuredRiskAddressesInfoFromRelate),
      value: initialPrimaryInsuredAddresseRefFromRelate,
    });
  }

  /*
   * Case : Add Mailing Address as option if it is not same as Initial Primary Address coming from relate
   */

  const isMailingAddressUnique = isAddressUnique(
    primaryInsuredMailingAddressInfo,
    initialPrimaryInsuredRiskAddressesInfoFromRelate,
  );

  if (primaryMailingAddressRef && isMailingAddressUnique) {
    uniqueRiskAddressOptions.push({
      label: buildAddressLabel(primaryInsuredMailingAddressInfo),
      value: primaryMailingAddressRef,
    });
  }

  if (primaryInsuredAddressInfo.isLocked) {
    uniqueRiskAddressOptions.push({
      label: buildAddressLabel(primaryInsuredAddressInfo),
      value: primaryAddressRef,
    });
  }

  /*
   * Add New Address option in the list
   */
  if (
    (!primaryInsuredAddressInfo.isLocked &&
      isInitialPrimaryRiskAddressComplete &&
      primaryMailingAddressRef &&
      isMailingAddressComplete) ||
    (isInitialPrimaryRiskAddressComplete && !primaryMailingAddressRef)
  ) {
    uniqueRiskAddressOptions.push({
      label: 'Add New Address',
      value: isNewRiskAddressSelected ? primaryAddressRef : 'Add New Address',
    });
  }

  const onRiskAddressChange = useCallback(
    async (value: string) => {
      setIsAddressChanged(true);
      if (previousPrimaryAddressRef) {
        await dispatch(
          updateAnswers({
            answers: {
              [`${previousPrimaryAddressRef}.addressTypes`]: 'UNASSIGNED',
            },
          }),
        );
      }

      let updatedAnswers = {};
      if (value === 'Add New Address') {
        const newAddressRef = !previousPrimaryAddressRef
          ? await dispatch(createRef('address'))
          : previousPrimaryAddressRef;

        updatedAnswers = updatePrimaryInsuredAddress(
          primaryAddressRef,
          primaryMailingAddressRef,
          initialPrimaryInsuredAddresseRefFromRelate,
          newAddressRef,
          primaryInsuredAddressTypesFromRelate,
          primaryMailingdAddressTypes,
        );

        await dispatch(
          updateAnswers({
            answers: { ...updatedAnswers },
          }),
        );
      } else {
        updatedAnswers = updatePrimaryInsuredAddress(
          primaryAddressRef,
          primaryMailingAddressRef,
          initialPrimaryInsuredAddresseRefFromRelate,
          value,
          primaryInsuredAddressTypesFromRelate,
          primaryMailingdAddressTypes,
        );

        await dispatch(
          updateAnswers({
            answers: { ...updatedAnswers },
          }),
        );
      }
    },
    [
      dispatch,
      initialPrimaryInsuredAddresseRefFromRelate,
      previousPrimaryAddressRef,
      primaryAddressRef,
      primaryInsuredAddressTypesFromRelate,
      primaryMailingAddressRef,
      primaryMailingdAddressTypes,
    ],
  );

  return (
    <GridItem>
      <Grid>
        <GridItem>
          <Select
            {...staticSelectedPrimaryInsuredAddressRef.props}
            label='Risk Address'
            id='Risk Address'
            options={uniqueRiskAddressOptions}
            value={primaryAddressRef}
            actionOnChange={onRiskAddressChange}
            disabled={primaryInsuredAddressInfo.isLocked}
          />
        </GridItem>
      </Grid>
      <Grid>
        {!primaryInsuredAddressInfo.isLocked && (
          <Grid className={classes.addressBox}>
            <AddressQuestions
              addressRef={primaryAddressRef}
              label='New Risk Address'
              trackingName='risk_address'
              addressType='PRIMARY'
              isValidAddress={isValidAddress}
              isAddressChanged={isAddressChanged}
            />
          </Grid>
        )}
      </Grid>
    </GridItem>
  );
};
