/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { unique } from '@ecp/utils/common';

import { GridItem, Select, Snackbar, SnackbarAlert, TooltipWithIcon } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { useVehicleDiscountsByKey } from '@ecp/features/sales/quotes/auto';
import { Dialog } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getDriverRefs,
  getPrimaryInsuredPersonRef,
  getVehicles,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { VehicleDiscountOptions } from '@ecp/sales/lob/auto';
import type { AnswerValue, Field, Fields } from '@ecp/types';

import type { ConflictingField } from '../../types';
import { useDiscountsUtil } from '../../utils';
import { RemoveDiscountModal } from '../RemoveDiscountModal';
import { useStyles } from './ProgramDiscountsQuestion.styles';

const getErrorText = (programDiscounts: Fields): string => {
  const result = unique(
    Object.values(programDiscounts).reduce((acc, field) => {
      const errorField = field as Field;
      if (errorField?.errors?.length) {
        return acc.concat(errorField.errors);
      }

      return acc;
    }, [] as string[]),
  ).join(', ');

  return result;
};

export const ProgramDiscountsQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const [removedSnackbarMessage, setRemovedSnackbarMessage] = useState('');
  const vehicles = useSelector(getVehicles);
  const pniRef = useSelector(getPrimaryInsuredPersonRef);
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const {
    props: { value: name = '' },
  } = usePersonField('firstName');

  const [dialogType, setDialogType] = useState<'NONE' | 'ENROLL' | 'REMOVE'>('NONE');
  const [snackType, setSnackType] = useState<'NONE' | 'ENROLLED' | 'REMOVED'>('NONE');

  const programDiscounts = useVehicleDiscountsByKey(vehicles, 'ubiProgram.optIn');

  const ubiProgram = useField('discount.auto.ubiProgram.type');
  useAddFields({ ubiProgram });
  useInitValues({ [ubiProgram.key]: 'NONE' });

  const paperless = useField('discount.auto.paperless');
  const autoPay = useField('discount.auto.autoPay');
  const qualifiesForMilesMyWay = autoPay.value && paperless.value;

  const programDiscountsErrors = useMemo(() => getErrorText(programDiscounts), [programDiscounts]);

  const driverRefs = useSelector(getDriverRefs);
  const driverDiscountFields = driverRefs.map((driverRef) => {
    const awayAtSchool = useField(`${driverRef}.discount.awayAtSchool`);
    const awayAtSchoolCountry = useField(`${driverRef}.discount.awayAtSchoolCountry`);
    const awayAtSchoolZipCode = useField(`${driverRef}.discount.awayAtSchoolZip`);

    return {
      [`${awayAtSchool.key}`]: awayAtSchool,
      [`${awayAtSchoolCountry.key}`]: awayAtSchoolCountry,
      [`${awayAtSchoolZipCode.key}`]: awayAtSchoolZipCode,
    };
  });

  const fieldsToAdd = driverDiscountFields.reduce((acc, obj) => Object.assign(acc, obj), {});
  useAddFields(fieldsToAdd);
  const conflictsArray: ConflictingField[] = Object.keys(fieldsToAdd)
    .filter((key) => {
      // country defaults to USA in the question, so we will not handle in conflicts
      return !fieldsToAdd[key].key.includes('awayAtSchoolCountry');
    })
    .map((key) => {
      let newValue: boolean | null = false;
      if (fieldsToAdd[key].key.includes('awayAtSchoolZip')) {
        newValue = null;
      }

      return { field: fieldsToAdd[key], newValue: newValue };
    });
  const { showRemoveDialog, dismissRemove, handleDriverDiscountChange, handleConflicts } =
    useDiscountsUtil(ubiProgram, conflictsArray, 'MILES_MY_WAY', true);

  const handleTelematicsChange = useCallback(
    (newValue: AnswerValue): void => {
      const hasConflicts = handleDriverDiscountChange(newValue);
      const shouldUpdateValue =
        (!hasConflicts && qualifiesForMilesMyWay && newValue === 'MILES_MY_WAY') ||
        newValue !== 'MILES_MY_WAY';
      if (shouldUpdateValue) {
        ubiProgram.props.actionOnComplete(newValue);
      } else if (!hasConflicts && !qualifiesForMilesMyWay && newValue === 'MILES_MY_WAY') {
        setDialogType('ENROLL');
      }
    },
    [handleDriverDiscountChange, qualifiesForMilesMyWay, ubiProgram.props],
  );

  const onRemoveConflicts = useCallback(
    (discountSubMessage?: string): void => {
      handleConflicts();
      if (discountSubMessage) {
        setRemovedSnackbarMessage(discountSubMessage);
      }
      if (!qualifiesForMilesMyWay) {
        setDialogType('ENROLL');
      } else {
        ubiProgram.props.actionOnComplete('MILES_MY_WAY');
      }
    },
    [handleConflicts, qualifiesForMilesMyWay, ubiProgram.props],
  );

  /**
   * When miles my way is selected, and autopay OR Paperless are then un-selected,
   *   bring up the dialog to remove MMW as they will not qualify for it.
   *
   * Did not implement in the paperless and auto pay change handlers as I
   *   wanted to keep all of the logic in this component.
   */
  useEffect(() => {
    if (!qualifiesForMilesMyWay && ubiProgram.value === 'MILES_MY_WAY') {
      setDialogType('REMOVE');
    }
    // Only execute when paperless or auto pay values update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualifiesForMilesMyWay]);

  const onCancelDialogAction = useCallback(() => {
    // If user cancels (or closes) the Remove MMW dialog, set Paperless and auto pay back to YES
    if (dialogType === 'REMOVE') {
      [autoPay, paperless].forEach((question) => {
        if (!question.value) question.props.actionOnComplete(true);
      });
    }
    setDialogType('NONE');
  }, [autoPay, dialogType, paperless]);

  const onPrimaryDialogAction = useCallback(() => {
    if (dialogType === 'ENROLL') {
      [autoPay, paperless].forEach((question) => {
        if (!question.value) question.props.actionOnComplete(true);
      });
      ubiProgram.props.actionOnComplete('MILES_MY_WAY');
      setSnackType('ENROLLED');
    } else if (dialogType === 'REMOVE') {
      ubiProgram.props.actionOnComplete('NONE');
      setSnackType('REMOVED');
    }
    setDialogType('NONE');
  }, [autoPay, dialogType, paperless, ubiProgram.props]);

  const enrollDialogText = (
    <p>
      You indicated that {name} plans to enroll in MilesMyWay. To enroll in MilesMyWay, {name} must
      also sign up for recurring payments of the auto premium through automatic withdrawal from a
      checking or savings account and enroll in paperless billing.
    </p>
  );
  const removeDialogText = (
    <>
      <p>
        You indicated that {name} plans to enroll in MilesMyWay. To enroll in MilesMyWay, {name}{' '}
        must also be enrolled in paperless billing and sign up for recurring payments of the auto
        premium through automatic withdrawal from a checking or savings account.
      </p>
      <br />
      <p>
        By removing paperless billing or by not signing up for recurring payments, MilesMyWay will
        be removed as {name}'s telematics program.
      </p>
    </>
  );
  const milesMyWayDialog = (
    <Dialog
      actionButtonLabel={dialogType === 'ENROLL' ? 'ENROLL' : 'REMOVE'}
      titleText={
        dialogType === 'ENROLL' ? 'Enroll in Autopay and Paperless?' : 'Remove MilesMyWay?'
      }
      textButtonLabel='Cancel'
      open={dialogType !== 'NONE'}
      onClose={onCancelDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onPrimaryDialogAction}
    >
      {dialogType === 'ENROLL' ? enrollDialogText : removeDialogText}
    </Dialog>
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setSnackType('NONE');
        setRemovedSnackbarMessage('');
      }
    },
    [],
  );

  const snackbarDefault = (snackType === 'REMOVED' || removedSnackbarMessage) && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open
      autoHideDuration={3000}
      message={
        snackType === 'REMOVED' ? 'MilesMyWay removed.' : `${removedSnackbarMessage} removed.`
      }
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = snackType === 'ENROLLED' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message='Enrolled in Autopay and Paperless.'
      hideActionButton
    />
  );

  if (!ubiProgram.exists) return null;

  return (
    <>
      {milesMyWayDialog}
      {snackType === 'ENROLLED' ? snackbarSuccess : snackbarDefault}
      <GridItem xs={12} topSpacing='sm'>
        <Select
          {...ubiProgram.props}
          id='ubiProgramDiscount'
          fullWidth={false}
          groupLabel={
            <>
              Does {name} plan to enroll in a Telematics Program?
              <TooltipWithIcon title='Program Level Discounts' />
            </>
          }
          trackingName='ubi_type_selection'
          actionOnChange={handleTelematicsChange}
          error={programDiscountsErrors}
        />
        {ubiProgram.exists && ubiProgram.value === 'MILES_MY_WAY' && (
          <VehicleDiscountOptions
            discountKey='ubiProgram.optIn'
            vehicleDiscounts={programDiscounts}
            discountLabel='Select vehicles for MilesMyWay Program?'
            helpText='Miles My Way Helptext'
            discountName='VehiclesInGarage'
            parentDiscount={ubiProgram}
            trackingName='telematics_checkbox_group'
            isRequired
          />
        )}
        <RemoveDiscountModal
          showDialog={showRemoveDialog}
          closeDialog={dismissRemove}
          onRemove={onRemoveConflicts}
          selectedDiscountName='MilesMyWay'
          conflictingDiscountNames={['Away At School']}
          driverName={name}
        />
      </GridItem>
    </>
  );
};
