import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isEmpty } from '@ecp/utils/common';

import { GridItem, TextField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Field } from '@ecp/types';

import { useStyles } from './CommercialPolicyNumberQuestion.styles';

interface Props extends QuestionProps {
  pniRef: string;
}

export const CommercialPolicyNumberQuestion: React.FC<Props> = (props) => {
  const { pniRef } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const commercialPolicyNumber: Field = useField(`${pniRef}.commercialPolicyNumber`);
  const otherInsuredObjectsAndPolicies = useField(`${pniRef}.otherInsuredObjectsAndPolicies`);

  useAddFields({ commercialPolicyNumber });

  const multiProductDiscountValues = otherInsuredObjectsAndPolicies.value as string[];
  const isCommercialPolicySelected =
    multiProductDiscountValues?.includes('COMMERCIAL_AUTO') || false; // if multiProductDiscountValues is undefined, default to false

  const handleCommercialPolicyChange = useCallback(
    async (value: string) => {
      commercialPolicyNumber.props.actionOnChange(value);
      if (value.length > (commercialPolicyNumber.question.maxLength || 20)) {
        dispatch(
          setFormErrorsChangedByField({
            key: commercialPolicyNumber.key,
            errors: ['Enter a shorter Policy Number.'],
          }),
        );
      }
    },
    [dispatch, commercialPolicyNumber],
  );

  const handleCommercialPolicyComplete = useCallback(
    async (value: string) => {
      // Check that there are no commercial policy errors and value exists before updating
      if (isEmpty(commercialPolicyNumber.errors) && !isEmpty(value)) {
        commercialPolicyNumber.props.actionOnComplete(value);
      } else if (isEmpty(value)) {
        dispatch(
          setFormErrorsChangedByField({
            key: commercialPolicyNumber.key,
            errors: ['Required field'],
          }),
        );
      }
    },
    [dispatch, commercialPolicyNumber],
  );
  if (!isCommercialPolicySelected) {
    // IF Policy is deselected/never selected: clear commercial policy field errors
    dispatch(
      setFormErrorsResetByField({
        key: commercialPolicyNumber.key,
      }),
    );
    commercialPolicyNumber.update(null);
  }

  if (
    !otherInsuredObjectsAndPolicies.exists ||
    !commercialPolicyNumber.exists ||
    isEmpty(multiProductDiscountValues) ||
    !isCommercialPolicySelected
  ) {
    return null;
  }

  return (
    <GridItem xs={12} className={classes.subQuestion}>
      <TextField
        {...commercialPolicyNumber.props}
        key={commercialPolicyNumber.key}
        groupLabel='Commercial Auto Policy Number'
        aria-label='Commercial Auto Policy Number'
        trackingName={commercialPolicyNumber.question.description}
        actionOnChange={handleCommercialPolicyChange}
        actionOnComplete={handleCommercialPolicyComplete}
        trackingLabel={GoogleAnalyticsLabels.REDACTED}
      />
    </GridItem>
  );
};
