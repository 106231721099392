import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicySummaryDriverCard' })((theme) => ({
  vehicleDriverTitle: theme.typography.body1Bold,
  vehicleDriverList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  vehicleDriverContent: theme.typography.body2,
}));
