import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { FoundationTypeMultiSelectionQuestion } from './FoundationTypeMultiSelectionQuestion';
import { FoundationTypeSingleSelectionQuestion } from './FoundationTypeSingleSelectionQuestion';

export type FoundationTypeQuestionProps = QuestionProps & {
  displayTypeForHomeBasementPercentageFinished?: 'Slider' | 'Select';
};

export const FoundationTypeQuestion: React.FC<FoundationTypeQuestionProps> = (props) => {
  const { displayType = 'RadioGroupWithOptions', ...rest } = props;

  switch (displayType) {
    case 'CheckBoxGroup':
      return <FoundationTypeMultiSelectionQuestion {...rest} />;
    case 'RadioGroupWithOptions':
      return <FoundationTypeSingleSelectionQuestion {...rest} />;
    default:
      return null;
  }
};
