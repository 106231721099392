import { useCallback } from 'react';

import { FormControlLabel, Grid } from '@mui/material';

import { GridItem, ReadOnlyField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Checkbox } from '@ecp/features/sales/shared/components';
import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import { useStyles } from './EquipmentBreakdownCoverage.styles';

export const EquipmentBreakdownCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const { carrierName, product } = props;
  const coveragePrefix = `${carrierName}.${product}.coverages`;
  const limitField = useFieldWithPrefix(coveragePrefix)('equipmentBreakdown.limit');
  const deductibleField = useFieldWithPrefix(coveragePrefix)('equipmentBreakdown.deductible');

  useAddFields({ [limitField.key]: limitField });
  useAddFields({ [deductibleField.key]: deductibleField });

  // Equipment Breakdown Limit and Deductible fields will have two options
  const limitFieldSelectedOption = limitField?.question?.options?.find(
    (limitOption) => limitOption.value !== 'NoCoverage',
  );

  const deductibleFieldSelectedOption = deductibleField?.question?.options?.find(
    (deductibleOption) => deductibleOption.value !== 'NoCoverage',
  );

  // No validation needed because the values are read only and user can't modify it
  const handleChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      if (newChecked) {
        limitField?.update(limitFieldSelectedOption?.value);
        limitField?.patch(limitFieldSelectedOption?.value);
        deductibleField?.update(deductibleFieldSelectedOption?.value);
        deductibleField?.patch(deductibleFieldSelectedOption?.value);
      } else {
        limitField?.update('NoCoverage');
        limitField?.patch('NoCoverage');
        deductibleField?.update('NoCoverage');
        deductibleField?.patch('NoCoverage');
      }
    },
    [deductibleField, deductibleFieldSelectedOption, limitField, limitFieldSelectedOption],
  );

  if (!limitField.exists || !deductibleField.exists) return null;

  // The main coverage checkbox will be checked if the limit and deductible have the selected values
  const isChecked =
    limitField.value === limitFieldSelectedOption?.value &&
    deductibleField.value === deductibleFieldSelectedOption?.value;

  const { title: limitTitle = '', helpText: limitHelpText } = limitField.question;
  const { title: deductibleTitle = '', helpText: deductibleHelpText } = deductibleField.question;

  // TODO Probably with a new story - premium amount
  return (
    <div>
      <Grid container justifyItems='space-between'>
        <GridItem
          xs={12}
          className={isChecked ? classes.selectedMainCoverage : classes.mainCoverage}
        >
          <FormControlLabel
            control={
              <Checkbox
                className={classes.formControlCheckBox}
                id='equipmentBreakdownCoverage'
                checked={isChecked}
                onChange={handleChange}
                trackingName='equipmentBreakdownCoverage'
                trackingLabel={isChecked.toString()}
              />
            }
            label={<div className={classes.coverageName}>Equipment Breakdown</div>}
            className={classes.formControlLabel}
          />
        </GridItem>
        {isChecked && (
          <Grid container item className={classes.subCoverageRoot}>
            <GridItem xs={12} className={classes.subCoverage}>
              <ReadOnlyField
                label={limitTitle}
                helperText={limitHelpText}
                value={limitFieldSelectedOption?.label.toString()}
                id={`equipmentBreakdown${limitTitle}`}
                classes={classes}
              />
            </GridItem>

            <GridItem xs={12} className={classes.subCoverage}>
              <ReadOnlyField
                label={deductibleTitle}
                helperText={deductibleHelpText}
                value={deductibleFieldSelectedOption?.label.toString()}
                id={`equipmentBreakdown${deductibleTitle}`}
                classes={classes}
              />
            </GridItem>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
