import { Grid } from '@mui/material';

import { PremiumToggle } from '@ecp/features/sales/checkout';
import { StateFeesSection } from '@ecp/features/sales/shared/components';
import {
  DEFAULT_AUTO_POLICY_DURATION,
  DEFAULT_HOME_POLICY_DURATION,
  PaymentPlan,
} from '@ecp/features/sales/shared/constants';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductDisplayNameFromProduct, type Product } from '@ecp/features/shared/product';

import { getOfferDetailsForProduct } from '../../selectors';
import { FullPaymentView } from '../FullPaymentView';
import { MonthlyPaymentView } from '../MonthlyPaymentView';
import { useStyles } from './PremiumBoxCard.styles';

interface Props {
  offerProduct: Product;
  recalculate?: boolean;
  showProcess: boolean;
  effectiveDate: string;
  premiumPlan: PaymentPlan;
}

export const PremiumBoxDetailsMonoline: React.FC<Props> = (props) => {
  const { offerProduct, recalculate, showProcess, effectiveDate, premiumPlan } = props;
  const { classes } = useStyles();

  const productName = getProductDisplayNameFromProduct(offerProduct);

  const offerDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, offerProduct),
  );

  if (!offerDetails) return null;

  const { monthlyPremium, fullPremium } = offerDetails;

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12} className={classes.toggleContainer}>
          <PremiumToggle size='large' />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <p className={classes.policyDurationHeaderColumn}>
            <span className={classes.policyDurationHeader}>{productName}</span>{' '}
            {`${
              offerDetails.policyDuration === 'Annual'
                ? DEFAULT_HOME_POLICY_DURATION
                : offerDetails.policyDuration === 'HalfYear'
                ? DEFAULT_AUTO_POLICY_DURATION
                : offerDetails.policyDuration
            }-mo policy`}
          </p>
        </Grid>
        {premiumPlan === PaymentPlan.FULL_PREMIUM ? (
          <FullPaymentView
            recalculate={recalculate}
            showProcess={showProcess}
            effectiveDate={effectiveDate}
            fullPremium={fullPremium}
          />
        ) : (
          <MonthlyPaymentView
            offerProduct={offerProduct}
            recalculate={recalculate}
            showProcess={showProcess}
            effectiveDate={effectiveDate}
            monthlyPremium={monthlyPremium}
          />
        )}
        <StateFeesSection premiumPlan={premiumPlan} offerDetails={offerDetails} showStateFees />
      </Grid>
    </>
  );
};
