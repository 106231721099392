import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { isEmpty } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { Snackbar, SnackbarAlert } from '@ecp/components';
import {
  useCreateSecondaryHeatingSource,
  useRemoveAllSecondaryHeatingSources,
  useRemoveSecondaryHeatingSource,
  useSecondaryHeatingSources,
} from '@ecp/features/sales/quotes/property/home';
import { Dialog, RadioGroupWithOptions, Resource } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getAllHeatingSourceRefs,
  getQuestion,
  isAnyApiInProgress,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useLoading } from '@ecp/features/sales/shared/utils/web';
import type { AnswerValue, Field, Fields } from '@ecp/types';

import { usePropertyField } from '../../utils';
import { HeatingSystemForm } from './HeatingSystemForm';
import { useStyles } from './HeatingSystemQuestion.styles';

export interface HeatingSystemData {
  fuelType: AnswerValue;
  fuelTypeDescription: AnswerValue;
  systemType: AnswerValue;
}

export const HeatingSystemQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const heatingSystem = usePropertyField('heatingSystem');
  const primarySystemTypeField = usePropertyField('heating.systemType');
  const primaryFuelTypeField = usePropertyField('heating.fuelType');
  const primaryFuelTypeDescriptionField = usePropertyField('heating.fuelTypeDescription');
  const allHeatingSystemRefs = useSelector(getAllHeatingSourceRefs);
  const numHeatingSystems = allHeatingSystemRefs.length;
  const addSecondaryHeatingSystem = useCreateSecondaryHeatingSource();
  const removeSecondaryHeatingSystem = useRemoveSecondaryHeatingSource();
  const removeAllSecondaryHeatingSystems = useRemoveAllSecondaryHeatingSources();
  const heatingSystemValues = useSecondaryHeatingSources(allHeatingSystemRefs);
  const secondarySystemTypeQuestion = useSelector(
    getQuestion('secondaryHeatingSource.<id>.systemType'),
  );
  const secondaryFuelTypeQuestion = useSelector(
    getQuestion('secondaryHeatingSource.<id>.fuelType'),
  );
  const [editHeatingSystemRef, setEditHeatingSystemRef] = useState('');
  const [removeHeatingSystemRef, setRemoveHeatingSystemRef] = useState('');
  // Used to determine if cancel should delete the ref or leave it be
  const [newRef, setNewRef] = useState('');
  const [snackbarInfoMessage, setSnackbarInfoMessage] = useState('');
  const [showSnackbarAlert, setShowSnackbarAlert] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removeAllDialogOpen, setRemoveAllDialogOpen] = useState(false);
  const shouldWait = useSelector(isAnyApiInProgress);
  const { isLoading, withLoading } = useLoading(shouldWait);
  const [heatingSystemDataBeforeEdit, setHeatingSystemDataBeforeEdit] = useState<
    HeatingSystemData | undefined
  >();
  const [heatingSystemFields, setHeatingSystemFields] = useState<Fields | undefined>();

  const { label = heatingSystem?.question?.title, trackingName = 'has_heating_system_question' } =
    props;

  const handleAddHeatingSystem = useCallback(async () => {
    // Either edit primary heating fields or create new secondary ref
    if (numHeatingSystems === 0) {
      setEditHeatingSystemRef('property.heating');
      setNewRef('property.heating');
    } else {
      const newHeatingSystem = addSecondaryHeatingSystem();
      const { heatingSourceRef } = newHeatingSystem;
      await newHeatingSystem.done;
      setEditHeatingSystemRef(heatingSourceRef);
      setNewRef(heatingSourceRef);
    }
    trackClick({ action: 'AddBusinessTypeButton', label: 'AddBusinessType' });
  }, [addSecondaryHeatingSystem, numHeatingSystems, setEditHeatingSystemRef, setNewRef]);

  const handleCancelHeatingSystem = useCallback(() => {
    // Save old values
    if (!isEmpty(heatingSystemFields)) {
      const { fuelType, fuelTypeDescription, systemType } = heatingSystemFields as Fields;
      if (fuelType) {
        const fuelTypeField = fuelType as Field;
        fuelTypeField.validateUpdateAndPatch(heatingSystemDataBeforeEdit?.fuelType);
      }
      if (fuelTypeDescription) {
        const fuelTypeDescriptionField = fuelTypeDescription as Field;
        fuelTypeDescriptionField.validateUpdateAndPatch(
          heatingSystemDataBeforeEdit?.fuelTypeDescription,
        );
      }
      if (systemType) {
        const systemTypeField = systemType as Field;
        systemTypeField.validateUpdateAndPatch(heatingSystemDataBeforeEdit?.systemType);
      }
    }
    trackClick({ action: 'CancelHeatingSystemButton', label: 'CancelHeatingSystem' });
    setEditHeatingSystemRef('');
    setHeatingSystemFields(undefined);
    setHeatingSystemDataBeforeEdit(undefined);
    setCancelDialogOpen(false);
    setSnackbarInfoMessage('Changes discarded.');
  }, [
    heatingSystemDataBeforeEdit,
    heatingSystemFields,
    setCancelDialogOpen,
    setEditHeatingSystemRef,
    setHeatingSystemDataBeforeEdit,
    setHeatingSystemFields,
    setSnackbarInfoMessage,
  ]);

  const handleSaveHeatingSystem = useCallback(() => {
    setEditHeatingSystemRef('');
    setNewRef('');
    setShowSnackbarAlert(true);
  }, [setEditHeatingSystemRef, setNewRef, setShowSnackbarAlert]);

  const removePrimaryHeatingSystem = useCallback(() => {
    primarySystemTypeField?.validateUpdateAndPatch(null);
    primaryFuelTypeField?.validateUpdateAndPatch(null);
    primaryFuelTypeDescriptionField?.validateUpdateAndPatch(null);
  }, [primarySystemTypeField, primaryFuelTypeField, primaryFuelTypeDescriptionField]);

  const removeHeatingSystemAction = withLoading(async (ref: string) => {
    // Remove primary heating system
    if (ref === 'property.heating') {
      removePrimaryHeatingSystem();
      // Only set the yes/no question to false if the edit form was open
      if (editHeatingSystemRef !== '') {
        heatingSystem?.props.actionOnComplete(false);
      }
    }
    // Remove the secondary heating system
    else {
      await removeSecondaryHeatingSystem(ref);
    }
  });

  const handleRemoveHeatingSystem = useCallback(() => {
    removeHeatingSystemAction(removeHeatingSystemRef);
    trackClick({ action: 'RemoveHeatingSystemButton', label: 'RemoveHeatingSystem' });
    // If we just removed the primary heating system, open a new edit form
    if (removeHeatingSystemRef === 'property.heating' && editHeatingSystemRef === '') {
      setEditHeatingSystemRef('property.heating');
      setNewRef('property.heating');
    } else {
      setEditHeatingSystemRef('');
      setNewRef('');
      setHeatingSystemDataBeforeEdit(undefined);
    }
    setRemoveHeatingSystemRef('');
    setRemoveDialogOpen(false);
    setSnackbarInfoMessage('Heating system removed.');
  }, [
    editHeatingSystemRef,
    removeHeatingSystemRef,
    removeHeatingSystemAction,
    setEditHeatingSystemRef,
    setHeatingSystemDataBeforeEdit,
    setNewRef,
    setRemoveDialogOpen,
    setRemoveHeatingSystemRef,
    setSnackbarInfoMessage,
  ]);

  const handleRemoveAllHeatingSystems = withLoading(async () => {
    await removeAllSecondaryHeatingSystems();
    removePrimaryHeatingSystem();
    heatingSystem?.props.actionOnComplete(false);
    setEditHeatingSystemRef('');
    setNewRef('');
    setRemoveAllDialogOpen(false);
    setSnackbarInfoMessage('All heating systems removed.');
  });

  const handleEditHeatingSystem = useCallback(
    (heatingSystemRef: string) => {
      if (editHeatingSystemRef || isLoading) return;
      setEditHeatingSystemRef(heatingSystemRef);
    },
    [editHeatingSystemRef, isLoading, setEditHeatingSystemRef],
  );

  const handleHeatingSystemComplete = useEvent(async (value: AnswerValue) => {
    if (!value) {
      if (numHeatingSystems === 1) {
        // Only open remove dialog if data entered
        if (
          primarySystemTypeField?.value ||
          primaryFuelTypeField?.value ||
          primaryFuelTypeDescriptionField?.value
        ) {
          handleRemoveDialogOpen('property.heating');
        } else {
          removePrimaryHeatingSystem();
          setNewRef('');
          setEditHeatingSystemRef('');
          setHeatingSystemDataBeforeEdit(undefined);
          heatingSystem?.props.actionOnComplete(value);
        }
      } else if (numHeatingSystems > 1) {
        setRemoveAllDialogOpen(true);
      } else {
        heatingSystem?.props.actionOnComplete(value);
      }
    }
    if (value && numHeatingSystems === 0) {
      handleAddHeatingSystem();
      heatingSystem?.props.actionOnComplete(value);
    }
  });

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setSnackbarInfoMessage('');
      }
    },
    [setSnackbarInfoMessage],
  );

  const handleAlertSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setShowSnackbarAlert(false);
      }
    },
    [setShowSnackbarAlert],
  );

  const handleRemoveDialogOpen = useCallback(
    (heatingSystemRef: string) => {
      setRemoveHeatingSystemRef(heatingSystemRef);
      setRemoveDialogOpen(true);
    },
    [setRemoveHeatingSystemRef, setRemoveDialogOpen],
  );

  const handleRemoveDialogClose = useCallback(() => {
    setRemoveDialogOpen(false);
    setRemoveHeatingSystemRef('');
  }, [setRemoveDialogOpen, setRemoveHeatingSystemRef]);

  const handleRemoveAllDialogClose = useCallback(() => {
    setRemoveAllDialogOpen(false);
  }, [setRemoveAllDialogOpen]);

  const handleCancelDialogOpen = useCallback(
    (heatingSystemRef: string, heatingSystemFields?: Fields) => {
      let isChanged = false;
      if (heatingSystemDataBeforeEdit && heatingSystemFields) {
        const fieldKeys = Object.keys(heatingSystemDataBeforeEdit);
        for (const key of fieldKeys) {
          const previousValue = heatingSystemDataBeforeEdit[key];
          const currentValue = heatingSystemFields[key]?.value;
          if ((previousValue || currentValue) && previousValue !== currentValue) {
            isChanged = true;
            break;
          }
        }
      }
      // Don't show remove dialog if newly created and no data entered
      if (newRef !== '' && !isChanged) {
        removeHeatingSystemAction(heatingSystemRef);
        setNewRef('');
        setEditHeatingSystemRef('');
      }
      // Show remove dialog if newly created and data entered
      else if (newRef !== '' && isChanged) {
        handleRemoveDialogOpen(heatingSystemRef);
      }
      // Show cancel dialog if edit has changes
      else if (isChanged) {
        setHeatingSystemFields(heatingSystemFields);
        setCancelDialogOpen(true);
      }
      // Don't show cancel dialog if edit has no changes
      else {
        setEditHeatingSystemRef('');
        setHeatingSystemFields(undefined);
        setHeatingSystemDataBeforeEdit(undefined);
      }
    },
    [
      handleRemoveDialogOpen,
      heatingSystemDataBeforeEdit,
      newRef,
      removeHeatingSystemAction,
      setEditHeatingSystemRef,
      setHeatingSystemDataBeforeEdit,
      setHeatingSystemFields,
      setCancelDialogOpen,
    ],
  );

  const handleCancelDialogClose = useCallback(() => {
    setCancelDialogOpen(false);
    setRemoveHeatingSystemRef('');
    setHeatingSystemFields(undefined);
  }, [setHeatingSystemFields, setCancelDialogOpen, setRemoveHeatingSystemRef]);

  const getHeatingSystemByRef = useCallback(
    (ref: string) => {
      return heatingSystemValues.find((heatingSystem) => heatingSystem.ref === ref);
    },
    [heatingSystemValues],
  );

  const renderHeatingSystemItem = useCallback(
    (item: string) => {
      const heatingSystem = getHeatingSystemByRef(item);
      const isPrimary = item === 'property.heating';
      const actualSystemTypeQuestion = isPrimary
        ? primarySystemTypeField?.question
        : secondarySystemTypeQuestion;
      const actualFuelTypeQuestion = isPrimary
        ? primaryFuelTypeField?.question
        : secondaryFuelTypeQuestion;
      const systemTypeTitle = isPrimary
        ? 'Primary Heating System Type'
        : 'Secondary Heating System Type';
      const systemTypeLabel = actualSystemTypeQuestion?.options?.find(
        (systemType) => systemType.value === heatingSystem?.systemType,
      )?.label as string;
      const fuelTypeLabel = actualFuelTypeQuestion?.options?.find(
        (fuelType) => fuelType.value === heatingSystem?.fuelType,
      )?.label as string;
      let fuelType = fuelTypeLabel;
      if (heatingSystem?.fuelTypeDescription)
        fuelType = fuelType + ` - ${heatingSystem?.fuelTypeDescription}`;

      return (
        <>
          <Resource.Item title={systemTypeTitle} value={systemTypeLabel} xs={6} />
          <Resource.Item title='Fuel Type' value={fuelType} xs={6} />
        </>
      );
    },
    [
      primaryFuelTypeField,
      getHeatingSystemByRef,
      secondaryFuelTypeQuestion,
      secondarySystemTypeQuestion,
      primarySystemTypeField,
    ],
  );

  const shouldHideDelete = useCallback(
    (item: string) => {
      return item === 'property.heating' && numHeatingSystems > 1;
    },
    [numHeatingSystems],
  );

  const snackbarInfo = (
    <Snackbar
      classes={{ root: classes.snackBar }}
      open={!!snackbarInfoMessage}
      autoHideDuration={3000}
      message={snackbarInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarAlert = (
    <SnackbarAlert
      classes={{ root: classes.snackBar }}
      open={showSnackbarAlert}
      autoHideDuration={3000}
      message='Heating system saved.'
      vertical='bottom'
      horizontal='center'
      onClose={handleAlertSnackbarClose}
      severity='success'
    />
  );

  const removeHeatingSystemDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove heating system?'
      textButtonLabel='CANCEL'
      open={removeDialogOpen}
      onClose={handleRemoveDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleRemoveHeatingSystem}
    >
      <p>Any associated content will be removed.</p>
    </Dialog>
  );

  const removeAllHeatingSystemDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove all heating systems?'
      textButtonLabel='CANCEL'
      open={removeAllDialogOpen}
      onClose={handleRemoveAllDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleRemoveAllHeatingSystems}
    >
      <p>All heating systems will be removed.</p>
    </Dialog>
  );

  const cancelDialog = (
    <Dialog
      actionButtonLabel='DISCARD'
      titleText='Discard changes?'
      textButtonLabel='CANCEL'
      open={cancelDialogOpen}
      onClose={handleCancelDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleCancelHeatingSystem}
    >
      <p>Any unsaved changes will be discarded.</p>
    </Dialog>
  );

  if (!heatingSystem?.exists) return null;

  return (
    <>
      {removeHeatingSystemDialog}
      {removeAllHeatingSystemDialog}
      {cancelDialog}
      {snackbarInfo}
      {snackbarAlert}
      <RadioGroupWithOptions
        {...heatingSystem.props}
        id='HeatingSystem'
        label={label}
        variant='yesNoButton'
        trackingName={trackingName}
        actionOnComplete={handleHeatingSystemComplete}
      />
      {heatingSystem.value && (
        <Grid container className={classes.marginTop}>
          <Resource.List
            items={allHeatingSystemRefs}
            pageErrors={[]}
            editItemRef={editHeatingSystemRef}
            onEdit={handleEditHeatingSystem}
            onDelete={handleRemoveDialogOpen}
            onCancel={handleCancelDialogOpen}
            onNext={handleSaveHeatingSystem}
            editIconText='Edit Heating System'
            deleteIconText='Remove Heating System'
            hideDelete={shouldHideDelete}
            // eslint-disable-next-line react/jsx-no-bind
            form={(formProps) => (
              <HeatingSystemForm
                {...formProps}
                setHeatingSystemBeforeEdit={setHeatingSystemDataBeforeEdit}
              />
            )}
            renderListItem={renderHeatingSystemItem}
          />
          {!editHeatingSystemRef && (
            <Resource.AddButton
              onClick={handleAddHeatingSystem}
              data-testid='heatingSystemAdd'
              isProcessing={false}
              trackingName='heating_system_add'
              trackingLabel='heating_system_add'
              disabled={!!editHeatingSystemRef || isLoading}
            >
              ADD ANOTHER HEATING SYSTEM
            </Resource.AddButton>
          )}
        </Grid>
      )}
    </>
  );
};
