import { Select, Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils/usePropertyField';

export const FullBathQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('fullBaths');

  if (!field) return null;

  const {
    label = field.question?.title,
    displayType,
    trackingName = displayType === 'Slider' ? 'full_baths_slider' : 'full_baths_drop_down',
  } = props;

  if (displayType === 'Slider')
    return <Slider {...field.props} groupLabel={label} trackingName={trackingName} />;

  return (
    <Select
      {...field.props}
      fullWidth={false}
      id='NoOfFullBaths'
      groupLabel={label}
      trackingName={trackingName}
    />
  );
};
