import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import type { Vehicle } from '@ecp/features/sales/shared/types';

import { DeltaOdometerQuestion } from '../DeltaOdometerQuestion';
import { DeltaVehicleVinQuestion } from '../DeltaVehicleVinQuestion';
import { useStyles } from './DeltaVehicleCard.styles';

export interface DeltaVehicleCardProps {
  vehicle: Vehicle;
  index: number;
}

export const DeltaVehicleCard: React.FC<DeltaVehicleCardProps> = (props) => {
  const { classes } = useStyles();
  const { vehicle, index } = props;
  const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;

  const header = <p className={classes.headerRight}>{vehicleName}</p>;

  const body = (
    <Grid container>
      <GridItem topSpacing='xs' key={index} xs={12} md={12}>
        <DeltaVehicleVinQuestion vehicle={vehicle} />
      </GridItem>
      <GridItem topSpacing='xs' key={index} xs={12} md={12}>
        <DeltaOdometerQuestion vehicle={vehicle} />
      </GridItem>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <div className={classes.vehicleSection}>
        {header}
        {body}
      </div>
    </div>
  );
};
