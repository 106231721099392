import { trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { Link } from '@ecp/components';
import { useNavigateToPage } from '@ecp/features/sales/shared/routing';

import { useStyles } from './AppFooter.styles';

interface Props {
  action: string;
  label: string;
  url: string;
  text: string;
  numberOfLinks: number;
  index: number;
}

export const AppFooterLinks: React.FC<Props> = (props) => {
  const { action, label, url, text, numberOfLinks, index } = props;
  const { classes } = useStyles();

  const navigateToPage = useNavigateToPage(url);

  const navigateTo = useEvent(() => {
    trackClick({ action, label });
    navigateToPage();
  });

  return (
    <>
      <Link component='button' onClick={navigateTo}>
        {text}
      </Link>
      {index < numberOfLinks - 1 && <span className={classes.linkDivider}>|</span>}
    </>
  );
};
