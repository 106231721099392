import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoDeltaForm' })((theme) => ({
  ...theme.mixins.formAll,
  content: {
    margin: 0,
    width: '100%',
  },
  body: {
    padding: '0 0 20px 8px',
    width: '100%',
    marginLeft: 0,
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      padding: '0 20px 20px 8px',
    },
  },
  header: {
    marginTop: 20,
    [theme.breakpoints.up('md')]: {
      marginTop: 40,
    },
  },
  dataGrids: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'none',
      flexBasis: '100%',
    },
  },
  verticalDivider: {
    height: '82%',
    backgroundColor: theme.palette.other.divider,
    width: 1,
    marginTop: 24,
    marginLeft: 20,
  },
  horizontalDivider: {
    backgroundColor: theme.palette.other.divider,
    marginTop: 25,
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
    height: 4,
    borderBottomWidth: 0,
  },
  helpIcon: {
    fontSize: 20,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    pointerEvents: 'all',
    verticalAlign: 'middle',
  },
  driverDiv: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
  headerRight: { ...theme.typography.h3, paddingTop: 20 },
  subTextWrapper: {
    fontSize: 16,
    color: theme.palette.text.tertiary,
    marginTop: 5,
  },
  deleteButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  checkBoxSection: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-start',
    padding: '0 40px',
  },
  checkBoxTitle: {
    display: 'inline',
    marginLeft: 10,
  },
  snackBarWidth: {
    maxWidth: 400,
  },
}));
