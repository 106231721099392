import { useCallback } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { getVehicleDescription } from '@ecp/features/sales/quotes/auto';
import { useAddTPI } from '@ecp/features/sales/quotes/auto';
import { Button } from '@ecp/features/sales/shared/components';
import { getVehicles, navigateToAddTPI } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconCardAuto, IconUIPlus } from '@ecp/themes/base';

import { useStyles } from './AutoTPIPageForm.styles';

export const AutoTPIPageForm: React.FC = () => {
  const { classes } = useStyles();

  const vehicles = useSelector(getVehicles);
  const addTPI = useAddTPI();
  const dispatch = useDispatch();

  const handleAddTPI = useCallback(
    async (vehicleRef: string) => {
      const newTPIRef = addTPI(vehicleRef);
      const { tpiRef } = newTPIRef;
      await newTPIRef.updateTPIVehicleAddressRef;
      await dispatch(
        navigateToAddTPI({ ref: tpiRef, replace: false, referencePage: 'AddVehicleTPI' }),
      );
    },
    [addTPI, dispatch],
  );

  return (
    <GridItem>
      <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
        <Box>
          <div className={classes.productIcon}>
            <IconCardAuto />
          </div>
        </Box>
        <Typography className={classes.productTitle}>Auto Policy?</Typography>
      </GridItem>
      <GridItem>
        {vehicles.map((vehicle, index) => (
          <GridItem key={index} className={classes.card}>
            <Grid container key={vehicle.ref}>
              <GridItem xs={12} md={6}>
                <Typography className={classes.contentTitle}>
                  {getVehicleDescription(vehicle)}
                </Typography>
                {vehicle.vin && (
                  <Typography
                    className={classes.contentSubTitle}
                  >{`VIN: ${vehicle.vin}`}</Typography>
                )}
              </GridItem>
              <GridItem xs={12} md={6} className={classes.columnRight}>
                <Button
                  className={classes.button}
                  variant='outlinePrimary'
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => handleAddTPI(vehicle.ref)}
                  trackingName='sni_details_continue'
                  trackingLabel={GoogleAnalyticsLabels.CONTINUE}
                >
                  <IconUIPlus className={classes.plusIcon} />
                  ADD TPI
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
        ))}
      </GridItem>
    </GridItem>
  );
};
