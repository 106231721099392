import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  vehicleRef: string;
}

export const VehicleCoOwnsQuestion: React.FC<Props> = (props) => {
  const {
    label = 'Person(s) other than named insured owns or co-owns this vehicle?',
    trackingName = 'VehicleCoOwnershipButton',
    vehicleRef,
  } = props;

  const coOwnsVehicle = useField(`${vehicleRef}.otherThanNICoOwnedVehicleInd`);
  useInitValues({ [coOwnsVehicle.key]: false });
  useAddFields({ coOwnsVehicle });

  if (!coOwnsVehicle.exists) return null;

  return (
    <div>
      <RadioGroupWithOptions
        {...coOwnsVehicle.props}
        label={label}
        id='coOwnsVehicle'
        variant='yesNoButton'
        trackingName={trackingName}
      />
    </div>
  );
};
