import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MilitaryDeploymentsQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    margin: '15px 0px 15px',
  },
  firstRow: theme.mixins.tableFirstRow,
  tableBody: theme.mixins.simpleTableBody,

  deploymentInstance: {
    border: `1px solid ${theme.palette.grey['600']}`,
    borderRadius: '4px',
    marginBottom: 8,
  },
  hasAffiliationsContainer: {
    overflowX: 'auto',
  },
  boxContainer: {
    marginBottom: 8,
    marginTop: 5,
  },
  itemContainer: {
    marginBottom: 20,
    marginTop: 15,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 5,
    padding: 5,
  },

  cancelButton: {
    padding: 20,
  },

  snackBarStyle: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  headerText: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  itemText: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  deleteIcon: {
    marginBottom: 20,
  },
}));
