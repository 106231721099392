import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const RenovationInLast15YearsQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('renovation.renovationLast15Years');

  if (!field) return null;

  const { label = field.question?.title, trackingName = 'renovation_in_last_15years_selection' } =
    props;

  return (
    <RadioGroupWithOptions
      {...field.props}
      id='RenovationInLast15Years'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
