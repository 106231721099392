import { Fragment } from 'react';

import { Divider, Grid } from '@mui/material';

import { getAge } from '@ecp/utils/date';

import { GridItem } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getDrivers, getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  AwayAtSchoolQuestion,
  AwayAtSchoolSubQuestions,
  getCanRecieveDefensiveDriverDiscount,
} from '@ecp/sales/lob/auto';
import {
  DefensiveDriverQuestion,
  GoodStudentQuestion,
  YoungVolunteerQuestion,
} from '@ecp/sales/lob/auto';

import { useStyles } from './DriverDiscountsForm.styles';

export const DriverDiscountsForm: React.FC<QuestionProps> = (props) => {
  const drivers = useSelector(getDrivers);
  const { classes } = useStyles();
  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const allDiscounts = drivers.map((driver, index) => {
    const hasSameFirstName =
      drivers.filter((driverElt) => {
        return driverElt.firstName === driver.firstName;
      }).length > 1;
    let displayname = driver.firstName;
    if (hasSameFirstName) {
      if (driver.middleName) {
        displayname = `${driver.firstName} ${driver.middleName}`;
      } else if (driver.suffix) {
        displayname = `${driver.firstName} ${driver.suffix}`;
      }
    }
    const age = getAge(driver.dateOfBirth);
    if (!age || (age > 24 && !getCanRecieveDefensiveDriverDiscount(age, stateCode))) return null;

    return (
      <Fragment key={index}>
        <GridItem key={`${driver.ref}-discounts`} xs={12}>
          <h3
            className={classes.driverText}
            key={`${driver.ref}-header`}
          >{`${displayname}'s Discounts`}</h3>
          <span className={classes.textTertiary} key={`${driver.ref}-optional`}>
            {' '}
            (optional)
          </span>
          {/* Away at School Discount is not available when only 1 driver on a submission  */}
          {drivers.length > 1 && (
            <GridItem topSpacing='lg' xs={12} key={`${driver.ref}-awayAtSchool`}>
              <>
                <AwayAtSchoolQuestion
                  driverRef={driver.ref}
                  driverAge={age}
                  driverName={displayname}
                  key={`${driver.ref}-awayAtSchoolComp`}
                />
                <AwayAtSchoolSubQuestions driverRef={driver.ref} />
              </>
            </GridItem>
          )}
          <GridItem topSpacing='lg' key={`${driver.ref}-goodStudent`} xs={12}>
            <GoodStudentQuestion
              driverRef={driver.ref}
              driverAge={age}
              driverName={displayname}
              key={`${driver.ref}-goodStudentComp`}
            />
          </GridItem>
          <GridItem topSpacing='lg' xs={12} key={`${driver.ref}-youngVolunteer`}>
            <YoungVolunteerQuestion
              driverRef={driver.ref}
              driverAge={age}
              driverName={displayname}
              key={`${driver.ref}-youngVolunteerComp`}
            />
          </GridItem>
          <GridItem topSpacing='lg' xs={12} key={`${driver.ref}-defensiveDriverQuestion`}>
            <DefensiveDriverQuestion
              driverRef={driver.ref}
              driverAge={age}
              driverName={displayname}
              key={`${driver.ref}-defensiveDriverQuestion`}
            />
          </GridItem>
        </GridItem>
        <Divider className={classes.divider} key={`${driver.ref}-divider`} />
      </Fragment>
    );
  });

  return (
    <Grid container>
      <GridItem xs={12} />
      {allDiscounts}
    </Grid>
  );
};
