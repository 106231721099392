import { memo } from 'react';

import { Grid } from '@mui/material';

import { type Item, TooltipWithIcon } from '@ecp/components';
import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getDrivers,
  getOfferDetailsForProduct,
  getOfferProductsSelectedByType,
  getVehicles,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { type AutoProduct } from '@ecp/features/shared/product';
import { IconAfiProductAuto } from '@ecp/themes/partners/amfam-adv';
import type { OptionMetadata } from '@ecp/types';

import type { PolicySummaryCardProps } from '../../../../common';
import {
  DiscountsAccordion,
  PolicySummaryCard,
  useIsCustomizeCoveragesEnabled,
} from '../../../../common';
import { QuoteCoverages } from '../../../../common/components/QuoteCoverages';
import { useStyles } from './AutoCoveragesForm.styles';
import { type AutoCoveragesFormProps } from './types';
import {
  getApplicableDriverDiscounts,
  getApplicableVehicleDiscounts,
  getAutoParentGroupNames,
  getAutoPolicyCoverageGroupNames,
  useAutoCoveragesForm,
  useGetAutoPolicyCoverageFields,
  useVehicleCoverageFields,
} from './util';
import { VehicleAndDriverDiscounts } from './VehicleAndDriverDiscounts';
import { VehicleCoveragesForm } from './VehicleCoveragesForm';

export const AutoCoveragesForm: React.FC<AutoCoveragesFormProps> = memo((props) => {
  const { classes, cx } = useStyles();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);
  const drivers = useSelector(getDrivers);
  const vehicles = useSelector(getVehicles);

  const offerAutoInfo = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );
  const shouldDisplayISG =
    autoOfferProduct && offerAutoInfo && offerAutoInfo.details && offerAutoInfo.details.appliedISG;
  // Pull the applied discounts from the offer, remove the word 'Discount', map them to the proper type to be used by the component later
  const appliedDiscountsFromOffer = offerAutoInfo?.details?.appliedDiscounts?.map((discount) => {
    return { title: `${discount.replace('Discount', '')}` };
  }) as OptionMetadata[];
  const autoCoverageFields = {
    policyCoverageFields: useGetAutoPolicyCoverageFields(autoOfferProduct, drivers),
    vehicleCoverageFields: useVehicleCoverageFields(vehicles, autoOfferProduct),
  };
  // TODO: These discounts need to be grabbed from the offer not the answers change this when the API updates
  const autoDiscountItems: Partial<Record<AutoProduct, Item[]>> = {};
  const applicableDriverDiscounts = useSelector(getApplicableDriverDiscounts);
  const applicableVehicleDiscounts = useSelector(getApplicableVehicleDiscounts);
  const isCustomizeCoveragesEnabled = useIsCustomizeCoveragesEnabled?.();
  const { autoPolicyCoverages, autoVehicleCoverages } = useAutoCoveragesForm(
    autoCoverageFields,
    props,
  );

  if (!(autoOfferProduct && autoPolicyCoverages.length && autoVehicleCoverages.length)) return null;
  const autoPolicyCoverageGroupNames = getAutoPolicyCoverageGroupNames();
  const autoParentGroupNames = getAutoParentGroupNames();

  autoDiscountItems[autoOfferProduct] = appliedDiscountsFromOffer; // use the discounts from offer if available

  const renderPolicySummary = (): React.ReactElement => {
    const coverageGroups = autoVehicleCoverages.reduce(
      (acc, vehicle) => {
        const { coverages, description } = vehicle;
        acc.push({
          title: `Coverages for the  ${description}`,
          items: [...coverages],
        });

        return acc;
      },
      [
        {
          title: 'Coverages for everyone on the policy',
          items: autoPolicyCoverages,
        },
      ] as NonNullable<PolicySummaryCardProps['coverageGroups']>,
    );

    return (
      <>
        <Grid container className={cx(classes.addSpacing, classes.productHeading)}>
          <IconAfiProductAuto className={classes.productIcon} />
          <h2>Auto policy</h2>
        </Grid>
        <PolicySummaryCard
          path={PagePath.ADD_EDIT_DRIVERS}
          summaryType='driver'
          title='Drivers'
          trackingName='EditDrivers'
        />
        <PolicySummaryCard
          path={PagePath.ADD_EDIT_VEHICLES}
          summaryType='vehicle'
          title='Vehicles'
          trackingName='EditVehicles'
        />
        <PolicySummaryCard
          discountItems={autoDiscountItems?.[autoOfferProduct]}
          path={PagePath.AUTO_DISCOUNTS}
          summaryType='discounts'
          title='Auto Discounts'
          trackingName='EditAutoDiscounts'
          product='auto'
        />
        <PolicySummaryCard
          coverageGroups={coverageGroups}
          path={PagePath.QUOTES}
          summaryType='coverages'
          title='Auto coverages'
          trackingName='EditAutoCoverages'
        />
      </>
    );
  };

  const renderCoverages = (): React.ReactElement => {
    const footer =
      applicableDriverDiscounts.length || applicableVehicleDiscounts.length ? (
        <VehicleAndDriverDiscounts
          autoProductKey={autoOfferProduct}
          applicableDriverDiscounts={applicableDriverDiscounts}
          applicableVehicleDiscounts={applicableVehicleDiscounts}
        />
      ) : undefined;

    return (
      <>
        <Grid container className={cx(classes.addSpacing, classes.productHeading)}>
          <IconAfiProductAuto className={classes.productIcon} />
          <h2>Auto policy</h2>
        </Grid>
        {shouldDisplayISG && (
          <Grid container className={classes.isgScoreContainer}>
            <Grid item xs={7}>
              <p className={classes.isgScore}>
                Applied Insurance Score Group (ISG)
                <TooltipWithIcon
                  className={classes.policyToolTip}
                  title='Adjustments to start date could result in adjustments to price.'
                />
              </p>
            </Grid>
            <Grid item xs={5}>
              <p className={classes.isgScore}>{offerAutoInfo?.details.appliedISG}</p>
            </Grid>
          </Grid>
        )}
        {autoDiscountItems && autoDiscountItems[autoOfferProduct]?.length ? (
          <DiscountsAccordion
            title='Applied Discounts'
            discountItems={autoDiscountItems[autoOfferProduct]}
            footer={footer}
            trackingName='auto_discounts_button'
            product='auto'
          />
        ) : undefined}
        {autoPolicyCoverages && autoPolicyCoverages?.length ? (
          <QuoteCoverages
            coverageItems={autoPolicyCoverages}
            parentCoverageGroupingList={autoParentGroupNames}
            subsectionCoverageGroupingList={autoPolicyCoverageGroupNames}
          />
        ) : undefined}
        <VehicleCoveragesForm vehicles={autoVehicleCoverages} />
      </>
    );
  };

  return <>{isCustomizeCoveragesEnabled ? renderPolicySummary() : renderCoverages()}</>;
});
