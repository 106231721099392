import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RoofClassQuestion' })((theme) => ({
  roofClassWidth: {
    width: 360,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
