import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ThirdPartyInterestPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 0,
  },
  next: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  button: {
    ...theme.typography.body4,
    fontSize: '13px',
    fontWeight: 500,

    borderRadius: 4,
    padding: '4px 10px',
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  content: {
    margin: 0,
    width: '100%',
  },
  card: {
    padding: '12px 16px 12px 16px',
    width: '100%',
    border: `1px solid ${theme.palette.other.divider}`,
    borderRadius: '4px',
    marginTop: 12,
  },
  contentTitle: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  contentSubTitle: { ...theme.typography.body2, color: theme.palette.text.secondary },
  columnRight: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  divider: {
    margin: '40px 0px 40px 0px',
    width: '100%',
  },
  productTitleSection: {
    display: 'flex',
    alignItems: 'center',
  },
  productIcon: {
    '& svg': {
      width: 40,
      height: 40,
      ...theme.mixins.setColorSvg(theme.palette.icon.hover),
      marginRight: 16,
    },
  },
  productTitle: {
    ...theme.typography.body4Bold,
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '32px',
  },
  plusIcon: {
    ...theme.mixins.setColorSvg(theme.palette.text.link),
    width: '18px',
    height: '18px',
  },
}));
