import { useCallback } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus, RENTERS_POLICY_HOUSEHOLD_LOSSES } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { getHasLossesInThePastFiveYears, updatePageStatus } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { RentersPropertyDetailsForm } from '../../forms';

export const RentersPropertyDetailsPage: React.FC = () => {
  const priorLossesAndViolations = useSelector((state: RootStore) =>
    getHasLossesInThePastFiveYears(state, RENTERS_POLICY_HOUSEHOLD_LOSSES),
  );
  const dispatch = useDispatch();
  const nextPage = priorLossesAndViolations ? PagePath.RENTERS_LOSSES : PagePath.RENTERS_DISCOUNTS;
  const navigateToNextPage = useNavigateToPage(nextPage);
  const handleNext = useCallback(async () => {
    if (!priorLossesAndViolations) {
      dispatch(updatePageStatus(NavStatus.VALID, PagePath.RENTERS_LOSSES));
    }
    await navigateToNextPage();
  }, [dispatch, navigateToNextPage, priorLossesAndViolations]);

  // TODO: Add a analyticsElement title for this page.
  return (
    <Page
      title='Rental property details'
      subTitle=''
      analyticsElement=''
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.RENTERS_PROPERTY_DETAILS} />,
      }}
    >
      <RentersPropertyDetailsForm onNext={handleNext} />
    </Page>
  );
};
