import { RadioGroupWithOptions } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';
export const AddOtherCoveragesQuestion: React.FC<QuestionProps> = (props) => {
  const addOtherStructures = usePropertyField('addOtherStructuresInd', true);

  if (!addOtherStructures) return null;

  const {
    groupLabel = addOtherStructures.question?.title,
    trackingLabel = 'add_other_structures_question',
  } = props;

  return (
    <RadioGroupWithOptions
      {...addOtherStructures.props}
      label={groupLabel}
      variant='yesNoButton'
      trackingName={trackingLabel}
      value={addOtherStructures.value}
    />
  );
};
