import { useCallback } from 'react';

import { Dialog } from '@ecp/features/sales/shared/components';

interface RemoveDiscountModal {
  showDialog: boolean;
  conflictingDiscountNames: string[];
  selectedDiscountName: string;
  driverName: string;
  closeDialog: () => void;
  onRemove: (discountSubMessage?: string) => void;
  customMessaging?: { title?: string; line1: string; line2?: string };
}

export const RemoveDiscountModal: React.FC<RemoveDiscountModal> = (props) => {
  const {
    showDialog,
    conflictingDiscountNames,
    selectedDiscountName,
    driverName,
    closeDialog,
    onRemove,
    customMessaging,
  } = props;

  // There may be more than one conflicting, so this will format for title and dialog insertion
  //  e.g. Good Student and Young Volunteer
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subMessage = (conjunction: 'and' | 'or'): string => {
    const numberOfConflictingDiscounts = conflictingDiscountNames.length;
    if (numberOfConflictingDiscounts === 1) {
      return conflictingDiscountNames[0];
    } else if (numberOfConflictingDiscounts === 2) {
      return `${conflictingDiscountNames[0]} ${conjunction} ${conflictingDiscountNames[1]}`;
    } else if (numberOfConflictingDiscounts > 2) {
      let message = '';
      conflictingDiscountNames.forEach((conflictingDiscount, index) => {
        if (index < numberOfConflictingDiscounts - 1) message += `${conflictingDiscount}, `;
        else message += `${conjunction} ${conflictingDiscount}`;
      });

      return message;
    } else return '';
  };

  const defaultTitleText = `Remove ${subMessage('and')} discount?`;
  const removeTitleText = customMessaging?.title ? customMessaging.title : defaultTitleText;
  const defaultLine1 = `You selected ${subMessage(
    'and',
  )} for ${driverName}. ${selectedDiscountName} is unavailable when ${subMessage(
    'or',
  )} is selected.`;
  const defaultLine2 = `To select ${selectedDiscountName}, ${subMessage('and')} will be removed.`;
  const dialog = (
    <>
      <p>{customMessaging ? customMessaging.line1 : defaultLine1}</p>
      <br />
      <p>{customMessaging ? customMessaging.line2 || '' : defaultLine2}</p>
    </>
  );

  const handleClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const handleActionButtonClick = useCallback(() => {
    onRemove(subMessage('and'));
  }, [onRemove, subMessage]);

  if (!showDialog) {
    return null;
  }

  return (
    <Dialog
      actionButtonLabel='Remove'
      textButtonLabel='Cancel'
      titleText={removeTitleText}
      actionButtonOnClick={handleActionButtonClick}
      open={showDialog}
      onClose={handleClose}
      buttonPlacement='right'
    >
      {dialog}
    </Dialog>
  );
};
