import { Dialog } from '@ecp/features/sales/shared/components';

interface Props {
  dialogStat: boolean;
  toggleDialog(): void;
  dialogSubmit(): void;
}

export const PolicyCenterDialog: React.FC<Props> = (props) => {
  const { dialogStat, toggleDialog, dialogSubmit } = props;

  return (
    <Dialog
      buttonPlacement='right'
      open={dialogStat}
      actionButtonLabel='CONTINUE'
      textButtonLabel='Cancel'
      titleText='Continue quote in PolicyCenter?'
      textButtonOnClick={toggleDialog}
      actionButtonOnClick={dialogSubmit}
      hideTitleCloseButton
    >
      <p>All provided information will be available</p>
    </Dialog>
  );
};
