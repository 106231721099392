import { env } from '@ecp/env';

import { useStyles } from './AutoDeltaTopBanner.styles';

export const AutoDeltaTopBanner: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <h1 className={env.static.isAgent ? classes.headingAgent : classes.heading}>
        Additional information
      </h1>
    </div>
  );
};
