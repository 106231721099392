import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicyLossQuestions' })((theme) => ({
  ...theme.mixins.formAll,
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
    marginTop: 8,
  },
}));
