import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'EquipmentBreakdownCoverage' })((theme) => ({
  formControlCheckBox: {
    marginRight: 30,
  },
  coverageName: theme.typography.body4,
  formControlLabel: {
    padding: 20,
  },
  mainCoverage: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
  },
  selectedMainCoverage: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  subCoverage: {
    padding: '20px 6px 20px 0px',
  },
  subCoverageRoot: {
    marginLeft: 74,
  },
  label: {
    color: theme.palette.text.secondary,
  },
}));
