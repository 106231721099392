/* eslint-disable react/jsx-no-bind */
import { Fragment, useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { Checkbox, GridItem } from '@ecp/components';
import { Dialog } from '@ecp/features/sales/shared/components';
import type { Driver } from '@ecp/features/sales/shared/types';

import { useStyles } from './DeltaProgramsCard.styles';

interface Props {
  open: boolean;
  onClose(): void;
  optInDriver: Driver[];
  onSubmitDialog(value: Driver[]): void;
}

export const AddPersonOnDriveMyWayDialog: React.FC<Props> = (props) => {
  const { open, onClose, optInDriver, onSubmitDialog } = props;
  const { classes } = useStyles();
  const [optInDriversRef, setOptInDriversRef] = useState<string[]>([]);

  const handleAddPersonClose = useCallback((): void => {
    onClose();
  }, [onClose]);

  // Send back optedIn drivers to the DriveMyWay Program Section to update the View
  const handleSaveAddPerson = useCallback(async (): Promise<void> => {
    const driveMyWayDrivers: Driver[] = optInDriver.filter(({ ref: driverRef }) =>
      optInDriversRef.some((ref) => driverRef === ref),
    );
    onSubmitDialog(driveMyWayDrivers);
    onClose();
  }, [onClose, onSubmitDialog, optInDriver, optInDriversRef]);

  // Add/Remove drivers ref into state when checbox checked/unchecked
  const handleDriverSelection = useCallback(
    (driverRef: string): void => {
      const existRef = optInDriversRef.includes(driverRef);
      if (!existRef) {
        setOptInDriversRef([...optInDriversRef, driverRef]);
      } else {
        optInDriversRef.splice(optInDriversRef.indexOf(driverRef), 1);
        setOptInDriversRef([...optInDriversRef]);
      }
    },
    [optInDriversRef],
  );

  const dialog = (
    <Grid container xs={12} md={12} spacing={2} className={classes.checkBoxSection}>
      {optInDriver.map((driver) => (
        <Fragment key={driver.ref}>
          <Grid item xs={12} md={12}>
            <GridItem topSpacing='xs' xs={12} md={12}>
              <Checkbox key={driver.ref} actionOnChange={() => handleDriverSelection(driver.ref)} />
              <label
                className={classes.checkBoxTitle}
              >{`${driver.firstName} ${driver.lastName}`}</label>
            </GridItem>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );

  return (
    <Dialog
      actionButtonLabel='Add Person'
      textButtonLabel='CANCEL'
      titleText='DriveMyWay Add Person'
      actionButtonOnClick={handleSaveAddPerson}
      disableActionButton={optInDriversRef.length === 0 ? true : false}
      open={open}
      onClose={handleAddPersonClose}
      buttonPlacement='right'
      trackingNameCloseIcon='FireplaceStoveButtonXtoClose'
      trackingLabelCloseIcon='X'
    >
      {dialog}
    </Dialog>
  );
};
