import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ParentsPolicyNumberQuestion' })((theme) => ({
  oneColumn: {
    maxWidth: '50%',
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    padding: 16,
  },
}));
