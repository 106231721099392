import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PropertyDetailsForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },
  topSectionContainer: {
    marginTop: 15,
  },
  sectionContainer: {
    marginBottom: 40,
    minWidth: 800,
  },
  next: {
    ...theme.mixins.formAll.next,
    marginTop: 0,
  },
  horizontalDivider: {
    width: '100%',
    padding: '32px 32px 32px 0px',
  },
}));
