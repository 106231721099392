import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ProtectiveDeviceQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    margin: '15px 0px 15px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
  },
  protectiveDeviceQuestionContainer: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}));
