import { useCallback } from 'react';

import { useEffectOnce } from '@ecp/utils/react';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  setFormErrorsChangedByField,
  useDeltaField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { getPersonInfo } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/types';

import { useStyles } from './DeltaCosctoMembershipNumberQuestion.styles';

interface Props extends QuestionProps {
  niRef: string;
}

export const DeltaCosctoMembershipNumberQuestion: React.FC<Props> = (props) => {
  const { niRef } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const usePersonField = useFieldWithPrefix(niRef, 'person.<id>');
  const discountCostcoMembershipNumber = usePersonField(
    'additionalInformation.costcoMembershipNumber',
  );
  const deltaCostcoMembershipNumber = useDeltaField(niRef, 'costcoMembershipNumber');
  const { firstName, lastName } = useSelector((state) => getPersonInfo(state, niRef));

  const onMembershipNumberComplete = useCallback(
    (membershipNum: AnswerValue): void => {
      if (membershipNum) {
        deltaCostcoMembershipNumber.props.actionOnComplete(membershipNum);
        discountCostcoMembershipNumber.validateUpdateAndPatch(membershipNum);
      } else {
        // EDSP-11036 Since SAPI is not sending the requiredness, making these changes
        dispatch(
          setFormErrorsChangedByField({
            key: deltaCostcoMembershipNumber.key,
            errors: ['Costco Membership Number is required.'],
          }),
        );
      }
    },
    [deltaCostcoMembershipNumber, discountCostcoMembershipNumber, dispatch],
  );

  const onUpdateMembershipNumber = useCallback(
    (membershipNum: string): void => {
      if (membershipNum) deltaCostcoMembershipNumber.validateUpdateAndPatch(membershipNum);
    },
    [deltaCostcoMembershipNumber],
  );

  useEffectOnce(() => {
    if (discountCostcoMembershipNumber.props.value) {
      onUpdateMembershipNumber(discountCostcoMembershipNumber.props.value);
    }
  });

  useAddFields({ deltaCostcoMembershipNumber });
  if (!deltaCostcoMembershipNumber.exists) return null;

  return (
    <>
      <p className={classes.headerRight}>{`${firstName} ${lastName}`}</p>
      <GridItem topSpacing='sm' xs={12}>
        <NumberFormat
          {...deltaCostcoMembershipNumber.props}
          actionOnComplete={onMembershipNumberComplete}
          fullWidth={false}
          label='Costco Member Number'
          formatType='costcoMembershipNumber'
        />
      </GridItem>
    </>
  );
};
