import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import type { GeoAddress } from '@ecp/utils/geo';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import {
  useUpdateVehicleKeptAddressRef,
  useVehicleKeptAddressRef,
} from '@ecp/features/sales/quotes/auto';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { usePrefillFlowDetermined } from '@ecp/features/sales/shared/routing';
import {
  checkPolicyType,
  getFieldErrors,
  getLineOfBusiness,
  useForm,
  usePrimaryAddressRef,
  useUpdatePNIInsuredType,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { useUpdatePriorAddressType } from '../../utils';
import { useStyles } from './InsuredInformationPageForm.styles';
import { InsuredInformationPageFormQuestions } from './InsuredInformationPageFormQuestions';

interface SubmitParams {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  prefillFlowDetermined: boolean;
  primaryAddressRef: string;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
}

interface Props {
  geoAddressSuggestions: GeoAddress[];
  setGeoAddressSuggestions(value: GeoAddress[]): void;
  onNext: () => Promise<void>;
  lineOfBusiness: LineOfBusiness;
  isValidAddress: boolean;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    prefillFlowDetermined,
    primaryAddressRef,
    setIsSubmitting,
    validateForm,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);

    if (
      !getFieldErrors(getState(), `${primaryAddressRef}.zipcode`).length &&
      !getFieldErrors(getState(), `${primaryAddressRef}.line1`).length &&
      !getFieldErrors(getState(), `${primaryAddressRef}.line2`).length &&
      validateForm().isValid
    ) {
      // Patch form values on Continue button click
      // This would be helpful for fields with defaults
      await patchFormValues();

      if (getFieldErrors(getState(), `${primaryAddressRef}.state`).length) {
        setIsSubmitting(false);
        goToFirstError();

        return;
      }

      await onNext();
      if (!prefillFlowDetermined) {
        // show spinner on continue button
        setIsSubmitting(true);
      }
    }

    setIsSubmitting(false);
  };

export const InsuredInformationPageForm: React.FC<Props> = (props) => {
  const { onNext, isValidAddress } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const prefillFlowDetermined = usePrefillFlowDetermined();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getConditions = useGetConditionValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  // for Bundle policies, we will need to ensure, if a user designates
  // an address for their vehicle that is separate from their primary address,
  // that those values exist & are valid
  const primaryAddressRef = usePrimaryAddressRef();
  const updateVehicleKeptAddressRef = useUpdateVehicleKeptAddressRef();
  const updatePNIInsuredType = useUpdatePNIInsuredType();
  const vehicleKeptAddressReference = useVehicleKeptAddressRef();
  const lob = useSelector(getLineOfBusiness);
  const updatePriorAddressType = useUpdatePriorAddressType();

  const handleSubmit = useCallback(async () => {
    await dispatch(checkPolicyType());
    updatePriorAddressType();
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        prefillFlowDetermined,
        primaryAddressRef,
        setIsSubmitting,
        validateForm,
      }),
    );
    if (
      (lob === LineOfBusiness.BUNDLE || lob === LineOfBusiness.BUNDLE_AUTO_RENTERS) &&
      !vehicleKeptAddressReference
    ) {
      updateVehicleKeptAddressRef(true);
    }
    updatePNIInsuredType();
  }, [
    updatePriorAddressType,
    dispatch,
    onNext,
    patchFormValues,
    prefillFlowDetermined,
    primaryAddressRef,
    validateForm,
    lob,
    vehicleKeptAddressReference,
    updatePNIInsuredType,
    updateVehicleKeptAddressRef,
  ]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <InsuredInformationPageFormQuestions isValidAddress={isValidAddress} />
          <GridItem topSpacing='lg' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='continue'
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='person_page_continue'
              analyticsElement='choice.personPage.continueButton'
              type='submit'
              fullWidth
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
