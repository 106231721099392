import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { useAllowAddingSNI } from '@ecp/features/sales/quotes/auto';
import { Form } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import {
  getDriverInfo,
  getPrimaryInsuredPersonRef,
  updatePageStatus,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { InsuranceQuestions } from '../../components/InsuranceQuestions/InsuranceQuestions';
import { useStyles } from './AutoPriorCarrierPageForm.styles';

interface Props {
  onNext?: () => Promise<void>;
  nextPageInstructions?: string;
}

export const AutoPriorCarrierPageForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const primaryInsuredPersonRef = usePniRef(); // primaryInsured.driver.ref does not exist for us so we have to get it this way
  const drivers = useSelector(getDriverInfo);
  const pniPerson = useSelector(getPrimaryInsuredPersonRef);
  const pniDriver = drivers.find((driver) => {
    return (driver.personRef = pniPerson);
  });

  // TODO: Check that duplicate name (lines 39-53) works when SNI page is enabled in ADVECP-1788

  let displayname = pniDriver?.firstName || '';
  // IF: the driver (assumed to be SNI here) has the same first name as the PNI...
  const hasSameFirstName = drivers.some((driver) => {
    return driver.firstName === pniDriver?.firstName;
  });

  if (hasSameFirstName) {
    /* THEN, set the displayed name of the PNI to be the first name and their middle or suffix
    to distinguish them from the secondary driver, and use that name on the insuranceQuestions component */
    displayname = pniDriver?.middleName
      ? `${pniDriver?.firstName} ${pniDriver?.middleName}`
      : `${pniDriver?.firstName} ${pniDriver?.suffix}`;
  }

  const handleSubmit = useCallback(async () => {
    dispatch(updatePageStatus(NavStatus.VALID));
    if (onNext) await onNext();
  }, [dispatch, onNext]);

  return (
    <div className={classes.root}>
      <Form>
        <Grid container>
          <Grid container xs={12}>
            <InsuranceQuestions
              driverRef={pniDriver?.ref || ''}
              personRef={primaryInsuredPersonRef}
              isPni
              allowAddSNI={useAllowAddingSNI(primaryInsuredPersonRef)}
              unambiguousName={displayname}
              onNext={handleSubmit}
            />

            {/* TODO: Update/Add SNI Prior Carrier when SNI task ADVECP-1788 is done
           {secondaryInsuredPersonRef && (
            <InsuranceQuestions
              driverRef={secondaryInsuredDriverRef}
              personRef={secondaryInsuredPersonRef}
              isPni={false}
              allowAddSNI={false}
            />
          )} */}
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
