import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { getPniPersonInfo } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { AutoDiscountsPageForm } from '../../forms';

export const AutoDiscountsPage: React.FC = () => {
  const { firstName } = useSelector(getPniPersonInfo);

  const subtitle = `Help us determine what discounts ${firstName} may be eligible for.`;

  const navigateToNextPage = useNavigateToNextPage();

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.AUTO_DISCOUNTS} />,
        }}
        title='Auto Discounts'
        subTitle={subtitle}
        analyticsElement='choice.discountPage.page'
      >
        <AutoDiscountsPageForm onNext={navigateToNextPage} policyType='auto' />
      </Page>
    </div>
  );
};
