import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { Snackbar } from '@ecp/components';
import { Dialog, RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { AnswerValue } from '@ecp/types';

import { usePropertyField } from '../../utils';
import { ConstructionActualCompletionDate } from './ConstructionActualCompletionDate';
import { ConstructionEstimatedCompletionDate } from './ConstructionEstimatedCompletionDate';
import { ConstructionStartDate } from './ConstructionStartDate';
import { EstimatedConstructionCost } from './EstimatedConstructionCost';
import { useStyles } from './HomeUnderConstructionQuestion.styles';

export const HomeUnderConstructionQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const renovationInLast15Years = usePropertyField('renovation.renovationLast15Years');
  const underConstructionField = usePropertyField('construction.underConstructionInd');
  const startDateField = usePropertyField('construction.constructionStartDate', true);
  const estimatedCompletionDateField = usePropertyField(
    'construction.constructionEstimatedCompletionDate',
    true,
  );
  const constructionCostField = usePropertyField('construction.constructionCost', true);
  const actualCompletionDateField = usePropertyField(
    'construction.constructionActualCompletionDate',
    true,
  );
  const [actionType, setActionType] = useState<'NONE' | 'REMOVE'>('NONE');
  const [actionInfoMessage, setActionInfoMessage] = useState('');

  const resetConstructionDetails: () => void = useCallback(async () => {
    startDateField?.props.actionOnComplete(null);
    estimatedCompletionDateField?.props.actionOnComplete(null);
    constructionCostField?.props.actionOnComplete(null);
    actualCompletionDateField?.props.actionOnComplete(null);
  }, [
    actualCompletionDateField?.props,
    constructionCostField?.props,
    estimatedCompletionDateField?.props,
    startDateField?.props,
  ]);

  const [dialogStat, setDialogStat] = useState(false);
  const [resetConstructionValues, setResetConstructionValues] = useState(false);

  const hasAnyFieldValue = useCallback(() => {
    const hasAnyFieldValue = [
      startDateField?.props.value,
      estimatedCompletionDateField?.props.value,
      constructionCostField?.props.value,
      actualCompletionDateField?.props.value,
    ].some(Boolean);
    if (!dialogStat) setDialogStat(hasAnyFieldValue);

    return hasAnyFieldValue;
  }, [
    actualCompletionDateField?.props.value,
    constructionCostField?.props.value,
    estimatedCompletionDateField?.props.value,
    startDateField?.props.value,
    dialogStat,
  ]);

  const handleOnSelection = useCallback(
    async (value: AnswerValue) => {
      if (!hasAnyFieldValue() || value) {
        underConstructionField?.validateUpdateAndPatch(value);
        setResetConstructionValues(!value);
      }
    },
    [underConstructionField, hasAnyFieldValue],
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setActionInfoMessage('');
        setActionType('NONE');
      }
    },
    [],
  );

  const snackbarDefault = actionType === 'REMOVE' && (
    <Snackbar
      open
      autoHideDuration={3000}
      message={actionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const onCloseDialogAction = useCallback(() => {
    setDialogStat(false);
    if (!renovationInLast15Years?.props.value)
      renovationInLast15Years?.props.actionOnComplete(true);
  }, [renovationInLast15Years]);

  const changeConstructionState = useCallback((): void => {
    underConstructionField?.validateUpdateAndPatch(false);
  }, [underConstructionField]);

  const onRemoveDialogAction = useCallback(() => {
    setDialogStat(false);
    setResetConstructionValues(true);
    setTimeout(changeConstructionState);
    setActionType('REMOVE');
    setActionInfoMessage('New home details removed.');
  }, [changeConstructionState]);

  const removeDialog = useMemo(
    () => (
      <Dialog
        actionButtonLabel='REMOVE'
        titleText='Remove new home details?'
        textButtonLabel='Cancel'
        open={dialogStat}
        onClose={onCloseDialogAction}
        buttonPlacement='right'
        actionButtonOnClick={onRemoveDialogAction}
      >
        <div>Any associated content will be removed.</div>
      </Dialog>
    ),
    [dialogStat, onCloseDialogAction, onRemoveDialogAction],
  );

  const displayUnderConstructionQuestion = useMemo(
    () => renovationInLast15Years?.exists && !renovationInLast15Years?.props.value,
    [renovationInLast15Years],
  );
  if (!displayUnderConstructionQuestion && underConstructionField?.props.value) {
    resetConstructionDetails();
    underConstructionField?.validateUpdateAndPatch(false);

    return null;
  }
  const {
    label = underConstructionField?.question?.title,
    trackingName = 'new_home_under_construction_selection',
  } = props;

  return (
    <>
      {displayUnderConstructionQuestion && (
        <RadioGroupWithOptions
          {...underConstructionField?.props}
          id='DaycareOnPremises'
          label={label}
          variant='yesNoButton'
          trackingName={trackingName}
          actionOnComplete={handleOnSelection}
        />
      )}
      {underConstructionField?.exists && underConstructionField?.props.value && (
        <Grid className={classes.details}>
          <ConstructionStartDate resetValues={resetConstructionValues} />
          <ConstructionEstimatedCompletionDate resetValues={resetConstructionValues} />
          <EstimatedConstructionCost resetValues={resetConstructionValues} />
          <ConstructionActualCompletionDate resetValues={resetConstructionValues} />
        </Grid>
      )}
      {removeDialog}
      {snackbarDefault}
    </>
  );
};
