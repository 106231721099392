import { Grid } from '@mui/material';

import { getDrivers } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './PolicySummaryDriverCard.styles';

export const PolicySummaryDriverCard: React.FC = () => {
  const { classes } = useStyles();
  const drivers = useSelector(getDrivers);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <ul className={classes.vehicleDriverList}>
          {drivers.map(({ firstName, lastName }, i) => (
            <li key={`${lastName}${i}`} className={classes.vehicleDriverContent}>
              {firstName} {lastName}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};
