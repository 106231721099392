import { upperFirst } from '@ecp/utils/common';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField, usePersonValues, usePniRef } from '@ecp/features/sales/shared/store';

import { useStyles } from './RecurringPaymentQuestion.styles';

interface Props extends QuestionProps {
  defaultValue: boolean;
  lob: string;
}

export const RecurringPaymentQuestion: React.FC<Props> = (props) => {
  const {
    defaultValue,
    lob,
    trackingName = 'auto_pay_selection',
    helperText = 'This discount is only available when paid through a bank account. It does not apply to credit card payments.',
  } = props;
  const { classes } = useStyles();

  const pniRef = usePniRef();
  const { firstName } = usePersonValues(pniRef);
  const label = `Does ${firstName} plan to sign up for recurring payments of the ${upperFirst(
    lob,
  )} premium through automatic withdrawal from a checking or savings account?`;

  const autoPay = useField(`discount.${lob}.autoPay`);
  useInitValues({ [autoPay.key]: defaultValue });
  useAddFields({ autoPay });

  if (!autoPay.exists) return null;

  return (
    <div className={classes.helperText}>
      <RadioGroupWithOptions
        {...autoPay.props}
        label={label}
        helperText={helperText}
        id='AutoPay'
        variant='yesNoButton'
        trackingName={trackingName}
      />
    </div>
  );
};
