import { useCallback } from 'react';

import { useShowMoreOrLess } from '@ecp/components';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { AnswerValue } from '@ecp/types';

import { usePropertyField } from '../../utils';

export const RoofMaterialQuestion: React.FC<QuestionProps> = (props) => {
  const roofMaterial = usePropertyField('roof.material');
  const roofMaterialDescription = usePropertyField('roof.materialDescription');

  const { label = roofMaterial?.question?.title, trackingName = 'roof_material_button' } = props;

  const { showMore, displayOptions, handleClick } = useShowMoreOrLess(
    roofMaterial?.props.options,
    4,
    'RoofMaterialMoreChoices',
    undefined,
    true,
  );

  const handleRoofMaterialComplete = useCallback(
    async (value: AnswerValue) => {
      roofMaterial?.validateUpdateAndPatch(value);
      // clear out roofMaterialDescription value if RoofMaterial is not ROOF.MATERIAL.OTHER
      if (roofMaterialDescription?.props.value && !['ROOF.MATERIAL.OTHER'].includes(value)) {
        roofMaterialDescription?.validateUpdateAndPatch(null);
      }
    },
    [roofMaterialDescription, roofMaterial],
  );

  if (!roofMaterial) return null;

  return (
    <RadioGroupWithOptions
      {...roofMaterial.props}
      options={displayOptions}
      id='roof.material'
      label={label}
      showMoreOrLessProps={{
        showMore,
        onClick: handleClick,
        groupTestId: 'RoofMaterialMoreChoices',
      }}
      trackingName={trackingName}
      cardSize='small'
      sortByKey
      actionOnComplete={handleRoofMaterialComplete}
    />
  );
};
