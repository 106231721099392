import type { RouteProps } from '@ecp/utils/routing';
import { Redirect, Route } from '@ecp/utils/routing';

import { CheckoutErrorPage, PostBindPage } from '@ecp/features/sales/checkout';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { ErrorNotFoundPage } from '@ecp/features/sales/shell';

import {
  AddressSuggestionsPage,
  AddThirdPartyInterestPage,
  AgentCheckoutPage,
  AgentCheckoutRetryPage,
  AgentLoginPage,
  InsuredInformationPage,
  LandingPage,
  PolicySummaryPage,
  QuotePage,
  ThirdPartyInterestPage,
  ThirdPartyReportsPage,
} from '../../common';
import {
  AutoDeltaPage,
  AutoDiscountsPage,
  AutoLossesAndViolationsPage,
  AutoPriorCarrierPage,
  DriverAssignmentPage,
  DriversPage,
  VehiclesPage,
} from '../../lob/autoLine';
import {
  HomeBasicPage,
  HomeDiscountsPage,
  HomeEligibilityPage,
  HomeExteriorPage,
  HomeInteriorPage,
  RentersDeltaPage,
  RentersDiscountsPage,
  RentersLossesPage,
  RentersPropertyDetailsPage,
} from '../../lob/propertyLine';
import { ProtectedRoute } from '../ProtectedRoute';
export const dynamicRoutes = [
  // Agent pages
  <Route path={PagePath.AGENT_LOGIN} component={AgentLoginPage} />,

  //  Common pages - Product selection or Landing page
  <ProtectedRoute path={PagePath.LANDING} component={LandingPage} />,
  //  Common pages - PNI info and policy address page
  <ProtectedRoute path={PagePath.PERSON} component={InsuredInformationPage} />,
  //  Common pages - Address correction page
  <ProtectedRoute
    path={PagePath.PERSON_ADDRESS}
    component={AddressSuggestionsPage as RouteProps['component']}
  />,

  // Auto pages
  <ProtectedRoute path={PagePath.ADD_EDIT_DRIVERS} component={DriversPage} />,
  <ProtectedRoute path={PagePath.ADD_EDIT_VEHICLES} component={VehiclesPage} />,
  <ProtectedRoute path={PagePath.DRIVER_ASSIGNMENT} component={DriverAssignmentPage} />,
  <ProtectedRoute path={PagePath.AUTO_PRIOR_INSURANCE} component={AutoPriorCarrierPage} />,
  <ProtectedRoute
    path={PagePath.AUTO_LOSSES_AND_VIOLATIONS}
    component={AutoLossesAndViolationsPage}
  />,
  <ProtectedRoute path={PagePath.AUTO_DISCOUNTS} component={AutoDiscountsPage} />,

  // Homeowners pages
  <ProtectedRoute path={PagePath.HOME_ELIGIBILITY} component={HomeEligibilityPage} />,
  <ProtectedRoute path={PagePath.HOME_BASIC} component={HomeBasicPage} />,
  <ProtectedRoute path={PagePath.HOME_EXTERIOR} component={HomeExteriorPage} />,
  <ProtectedRoute path={PagePath.HOME_INTERIOR} component={HomeInteriorPage} />,
  <ProtectedRoute path={PagePath.HOME_DISCOUNTS} component={HomeDiscountsPage} />,

  // Renters pages
  <ProtectedRoute
    path={PagePath.RENTERS_PROPERTY_DETAILS}
    component={RentersPropertyDetailsPage}
  />,
  <ProtectedRoute path={PagePath.RENTERS_LOSSES} component={RentersLossesPage} />,
  <ProtectedRoute path={PagePath.RENTERS_DISCOUNTS} component={RentersDiscountsPage} />,

  // Common pages - Offers page
  <ProtectedRoute path={PagePath.QUOTES} component={QuotePage} />,

  // Auto Delta
  <ProtectedRoute path={PagePath.AUTO_DELTA} component={AutoDeltaPage} />,

  // Renters Delta
  <ProtectedRoute path={PagePath.RENTERS_DELTA} component={RentersDeltaPage} />,

  // Common pages -  TPI & TPR pages
  <ProtectedRoute path={PagePath.THIRD_PARTY_INTEREST} component={ThirdPartyInterestPage} />,
  <ProtectedRoute path={PagePath.ADD_THIRD_PARTY_INTEREST} component={AddThirdPartyInterestPage} />,
  <ProtectedRoute path={PagePath.THIRD_PARTY_REPORTS} component={ThirdPartyReportsPage} />,

  // Common pages -  Policy summary page
  <ProtectedRoute path={PagePath.COVERAGES} component={PolicySummaryPage} />,

  // Common pages -  Iagree/Pay/Sign/Bind confirmation
  <ProtectedRoute path={PagePath.CHECKOUT_RETRY} component={AgentCheckoutRetryPage} />,
  <ProtectedRoute path={PagePath.CHECKOUT_ERROR} component={CheckoutErrorPage} />,
  <ProtectedRoute path={PagePath.CHECKOUT} component={AgentCheckoutPage} />,
  <ProtectedRoute path={PagePath.POST_BIND} component={PostBindPage as RouteProps['component']} />,

  <Route path={PagePath.DEFAULT}>
    <Redirect to={PagePath.LANDING} replace />
  </Route>,
  // Fallback page
  <Route component={ErrorNotFoundPage} />,
];
