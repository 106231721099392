import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'DownloadSendQuoteButtons',
})((theme) => ({
  premuimActionButton: {
    marginTop: 20,
    marginBottom: 10,
  },
  premiumBoxButtonContainer: {
    textAlign: 'center',
  },
  buttonsItem: {
    paddingTop: 0,
  },
  icon: {
    paddingRight: 4,
    paddingBottom: 3,
  },
}));
