import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutSection' })((theme) => ({
  container: {
    maxWidth: theme.breakpoints.values.md,
  },
  acknowledgementContainer: {
    padding: '0 40px 15px',
  },
  acknowledgementMobileContainer: {
    padding: '30px 20px 15px',
  },
  policySummaryContainer: {
    [theme.breakpoints.down('md')]: {
      margin: '0 0 30px 0',
    },
  },
}));
