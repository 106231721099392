import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { useStyles } from './PaperlessBillingQuestion.styles';

interface Props extends QuestionProps {
  defaultValue: boolean;
  lob: string;
}

export const PaperlessBillingQuestion: React.FC<Props> = (props) => {
  const {
    defaultValue,
    lob,
    label = 'Do you plan to enroll in paperless billing?',
    trackingName = 'paperless_billing_selection',
    helperText = 'You will be able to enroll after you purchase your policy.',
  } = props;
  const { classes } = useStyles();

  const paperless = useField(`discount.${lob}.paperless`);

  useInitValues({ [paperless.key]: defaultValue });
  useAddFields({ paperless });

  if (!paperless.exists) return null;

  return (
    <div className={classes.helperText}>
      <RadioGroupWithOptions
        {...paperless.props}
        label={label}
        helperText={helperText}
        id='Paperless'
        variant='yesNoButton'
        trackingName={trackingName}
      />
    </div>
  );
};
