import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import { getDriverQuestions } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Driver } from '@ecp/features/sales/shared/types';
import type { Fields } from '@ecp/types';

const driverFormKeys: {
  [key in PrefillFlow]: Array<{ type: 'person' | 'driver' | 'priorInsurance'; key: string }>;
} = {
  [PrefillFlow.LONG]: [
    { type: 'person', key: 'firstName' },
    { type: 'person', key: 'lastName' },
    { type: 'person', key: 'dob' },
    { type: 'person', key: 'gender' },
    { type: 'person', key: 'married' },
    { type: 'driver', key: 'license.ageFirstLicensed' },
    { type: 'driver', key: 'license.status' },
    { type: 'person', key: 'phone' },
    { type: 'person', key: 'email' },
    { type: 'driver', key: 'sr22FinancialRespFiling' },
    { type: 'person', key: 'incidents' },
    { type: 'driver', key: 'person.ref' },
  ],
  [PrefillFlow.SHORT]: [
    { type: 'person', key: 'firstName' },
    { type: 'person', key: 'lastName' },
    { type: 'person', key: 'dob' },
    { type: 'person', key: 'gender' },
    { type: 'person', key: 'married' },
    { type: 'driver', key: 'license.ageFirstLicensed' },
    { type: 'driver', key: 'license.status' },
    { type: 'driver', key: 'license.number' },
    { type: 'driver', key: 'license.state' },
    { type: 'driver', key: 'person.ref' },
  ],
};

export const useGetDriverFieldsForValidations = (drivers: Driver[], flow: PrefillFlow): Fields => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) =>
    getDriverQuestions(state, drivers, dispatch, driverFormKeys[flow], flow),
  );
};
