import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { Button } from '@ecp/components';
import { Select } from '@ecp/features/sales/shared/components';
import { updateAnswers } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import { IconUIDelete } from '@ecp/themes/base';
import type { Field, Option } from '@ecp/types';

import { useStyles } from './AssignmentLineItem.styles';

interface FieldAnswers {
  key: string;
  value?: AnswerValue;
}

type Props = {
  dropDownItems?: Option[];
  field: Field;
  occasionalField: Field;
  showOccasionalOperator: boolean;
  label: string;
  labelRef?: string;
  answers: FieldAnswers[];
  occasionalFieldAnswers: FieldAnswers[];
  actionOnChange: (value: AnswerValue, field: Field, label: string) => void;
  mismatchedValues: boolean;
  index: number;
  numberOfVehicles: number;
  setInfoMessage: Dispatch<SetStateAction<string | undefined>>;
};

export const AssignmentLineItem: React.FC<Props> = (props) => {
  const {
    label,
    dropDownItems,
    field,
    showOccasionalOperator,
    occasionalField,
    answers,
    occasionalFieldAnswers,
    actionOnChange,
    mismatchedValues,
    labelRef,
    index,
    setInfoMessage,
    numberOfVehicles,
  } = props;
  const { classes } = useStyles();
  const [occasionalDriversState, setOccasionalDriversState] = useState(['']);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!mismatchedValues && numberOfVehicles === 1) {
      // assign occasional drivers if only one vehicle
      const primaryDriver = field.props.value;
      defaultOccasionalDrivers(primaryDriver);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const occasionalFieldValues = occasionalField?.props?.value;
    setOccasionalDriversState(occasionalFieldValues ? occasionalFieldValues : ['']);
  }, [occasionalField?.props?.value]);

  /**
   * We are going to filter the dropdown items based on the total answers
   * of all the fields.
   */
  const filteredDropDownItems = useMemo(
    (): Option[] | undefined =>
      dropDownItems?.filter((item) => {
        /**
         * If we are looking at the currently selected value we want to return true
         * since we always want the value we have selected for the current field to show
         * up
         */
        if (item.value === field?.value) {
          return true;
        }

        /**
         * We have a list of answers for all the other fields and if we can match
         * an answer that has been selected in another dropdown to an option
         * then we want to remove that option
         */
        const foundAnswer = !!answers.concat(occasionalFieldAnswers).find((answer) => {
          // occasional driver value will be an array
          if (Array.isArray(answer.value)) {
            return answer.value.includes(item.value);
          }

          return answer.value === item.value;
        });

        /**
         * In the case of Driver Assignment we need to be able to assign
         * Drivers -> Vehicles and Vehicles -> Drivers BUT the question that
         * needs to be answered is vehicle.<id>.primary.driver.ref which will
         * always need a driver ref.
         *
         * In the case where we have vehicles in the dropdown we are calling a
         * mismatch and making sure we are sending the appropriate data to the
         * dropdown.
         */
        if (mismatchedValues) {
          const foundMismatchAnswer = !!answers.find((answer) => {
            return answer.value && answer.key.includes(item.value);
          });

          return !foundAnswer && !foundMismatchAnswer;
        }

        /**
         * We want to keep the dropdown item if the foundAnswer is undefined
         */
        return !foundAnswer;
      }),
    [answers, dropDownItems, field, mismatchedValues, occasionalFieldAnswers],
  );

  /**
   * We are going to filter the dropdown items based on the total answers
   * of all the fields.
   */
  const filteredOccasionalDropDownItems = useCallback(
    (occasionalDriverRef: string): Option[] | undefined =>
      dropDownItems?.filter((item) => {
        /**
         * If we are looking at the currently selected value we want to return true
         * since we always want the value we have selected for the current field to show
         * up
         */
        if (item.value === occasionalDriverRef) {
          return true;
        }

        /**
         * We have a list of answers for all the other fields and if we can match
         * an answer that has been selected in another dropdown to an option
         * then we want to remove that option
         */
        const foundAnswer = !!answers.concat(occasionalFieldAnswers).find((answer) => {
          if (Array.isArray(answer.value)) {
            return answer.value.includes(item.value);
          }

          return answer.value === item.value;
        });

        /**
         * We want to keep the dropdown item if the foundAnswer is undefined
         */
        return !foundAnswer;
      }),
    [answers, dropDownItems, occasionalFieldAnswers],
  );

  const defaultOccasionalDrivers = useCallback(
    async (primaryDriverRef: AnswerValue) => {
      // primary driver is not set yet no need to set occasional drivers
      if (!primaryDriverRef) return;
      const existingOccasionalDrivers = occasionalField.props.value;
      // all occasional drivers are already set
      if (dropDownItems?.length === existingOccasionalDrivers?.length + 1) return;
      const occasionalDrivers = dropDownItems
        ?.map((item) => item.value)
        ?.filter((driverRef) => driverRef !== primaryDriverRef);

      if (showOccasionalOperator && occasionalDrivers) {
        await dispatch(
          updateAnswers({
            answers: { [occasionalField.key]: occasionalDrivers },
          }),
        );
      }
    },
    [
      dispatch,
      dropDownItems,
      occasionalField?.key,
      occasionalField?.props?.value,
      showOccasionalOperator,
    ],
  );

  const handleAssignmentLineItemOnChange = useCallback(
    async (value: AnswerValue) => {
      if (!actionOnChange) return;
      await actionOnChange(value, field, labelRef || label);
      if (!mismatchedValues && numberOfVehicles === 1) {
        // assign occasional drivers if only one vehicle
        defaultOccasionalDrivers(value);
      }
    },
    [
      actionOnChange,
      defaultOccasionalDrivers,
      field,
      label,
      labelRef,
      mismatchedValues,
      numberOfVehicles,
    ],
  );

  const handleOccasionalAssignmentLineItemOnChange = useCallback(
    async (value: AnswerValue, currentValue: string) => {
      let valueToBeUpdated = value;
      const allOccasionalDrivers = occasionalField.props.value;
      if (allOccasionalDrivers) {
        const occasionalDriversWithoutCurrentValue = (allOccasionalDrivers as string[]).filter(
          (item) => item !== currentValue,
        );
        occasionalDriversWithoutCurrentValue.push(value as string);
        valueToBeUpdated = occasionalDriversWithoutCurrentValue.join(',');
      }
      await dispatch(
        updateAnswers({
          answers: { [occasionalField.key]: valueToBeUpdated },
        }),
      );
    },
    [occasionalField?.props?.value, occasionalField?.key, dispatch],
  );

  const removeOccasionalDriver = useCallback(async () => {
    const lastOccasionalDriverRefToBeRemoved =
      occasionalDriversState[occasionalDriversState.length - 1];
    const allOccasionalDrivers = occasionalField.props.value;
    const updatedOccasionalDrivers = (allOccasionalDrivers as string[]).filter(
      (item) => item !== lastOccasionalDriverRefToBeRemoved,
    );
    await dispatch(
      updateAnswers({
        answers: { [occasionalField.key]: updatedOccasionalDrivers },
      }),
    );
    setInfoMessage('Occasional driver removed.');
  }, [
    dispatch,
    occasionalDriversState,
    occasionalField?.key,
    occasionalField?.props?.value,
    setInfoMessage,
  ]);

  const addAnotherOccasionalDriver = useCallback(() => {
    if (!filteredOccasionalDropDownItems('')?.length) {
      setInfoMessage('The maximum number of occasional drivers have been added to this vehicle.');

      return;
    }
    if (occasionalDriversState.includes('')) return;
    setOccasionalDriversState(occasionalDriversState.concat(['']));
  }, [filteredOccasionalDropDownItems, occasionalDriversState, setInfoMessage]);

  const occasionalDriversSection = (): JSX.Element => {
    const occasionalDriversContent = occasionalDriversState.map(
      (occasionalDriver, occasionalDriverIndex) => {
        const occasionalDriverNumber =
          occasionalDriverIndex === 0 ? '' : ` ${occasionalDriverIndex + 1}`;
        const label = `Occasional Driver${occasionalDriverNumber} (optional)`;
        const options = filteredOccasionalDropDownItems(occasionalDriver) || [];

        return (
          options.length > 0 && (
            <Grid className={classes.occasionalDriverContainer}>
              <Select
                value={occasionalDriver}
                options={options}
                id={`occasionalDriverAssignmentSelect-${index}${occasionalDriverIndex}`}
                // eslint-disable-next-line react/jsx-no-bind
                actionOnChange={(value) =>
                  handleOccasionalAssignmentLineItemOnChange(value, occasionalDriver)
                }
                label={label}
                required={false}
              />
            </Grid>
          )
        );
      },
    );

    return (
      <>
        {occasionalDriversContent}
        {occasionalDriversState.length > 1 && (
          <Button
            className={classes.button}
            icon={<IconUIDelete />}
            onClick={removeOccasionalDriver}
            variant='iconTextMedium'
          >
            Remove Occasional Driver
          </Button>
        )}
        {occasionalDriversContent && (
          <Button
            className={classes.button}
            variant='outlineSecondary'
            onClick={addAnotherOccasionalDriver}
          >
            + ADD ANOTHER OCCASIONAL DRIVER
          </Button>
        )}
      </>
    );
  };

  return (
    <Grid container spacing='30px' className={classes.container}>
      <Grid item xs={12} md={6} className={mismatchedValues ? '' : classes.gridLabel}>
        <p className={mismatchedValues ? '' : classes.label}>{label}</p>
      </Grid>

      <Grid item xs={12} md={6}>
        <Select
          {...field?.props}
          options={filteredDropDownItems}
          id={`driverAssignmentSelect-${index}`}
          actionOnChange={handleAssignmentLineItemOnChange}
          label={mismatchedValues ? null : 'Primary Driver'}
        />
        {showOccasionalOperator && occasionalDriversSection()}
      </Grid>
    </Grid>
  );
};
