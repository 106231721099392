import { RadioGroupWithOptions, Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';
import { useStyles } from './WashingMachineLocationQuestion.styles';

export const WashingMachineLocationQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const field = usePropertyField('washingMachineLocation');

  if (!field) return null;

  const {
    label = field.question?.title,
    displayType,
    trackingName = displayType === 'Select'
      ? 'washing_machine_location_dropdown'
      : 'washing_machine_location_selection',
  } = props;

  return displayType === 'Select' ? (
    <Select
      {...field.props}
      fullWidth={false}
      id='washingMachineLocation'
      groupLabel={label}
      sortByKey
      trackingName={trackingName}
      inputButtonAriaLabel='washingMachineLocation'
    />
  ) : (
    <RadioGroupWithOptions
      {...field.props}
      className={classes.washingMachineLocationWidth}
      id='washingMachineLocation'
      label={label}
      sortByKey
      variant='radioCard'
      trackingName={trackingName}
    />
  );
};
