import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'InsuredInformationPageFormQuestions' })((theme) => ({
  ...theme.mixins.formAll,
  helpBoxContent: {
    padding: 0,
  },
  subHeader: theme.typography.body4,
  header: { ...theme.typography.body4 },
  label: {
    ...theme.typography.body3,
  },
  value: {
    ...theme.typography.body4,
  },
}));
