import { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';

import { Snackbar, SnackbarAlert } from '@ecp/components';
import { getDriverLabel } from '@ecp/features/sales/quotes/auto';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import {
  questionExists,
  submitProofDraft,
  updateAnswers,
  useForm,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AnswerValue,
  Driver,
  PageErrors,
  VehicleBasic,
} from '@ecp/features/sales/shared/types';
import { IconUIRefresh as ResetIcon, useIsMobile } from '@ecp/themes/base';
import type { Field, Fields, Option } from '@ecp/types';

import { useGetLineItems } from '../../utils';
import { AssignmentLineItem } from './AssignmentLineItem/AssignmentLineItem';
import { useStyles } from './DriverAssignmentForm.styles';

export interface DriverAssignmentFormProps {
  vehicles: VehicleBasic[];
  drivers: Driver[];
  onNext: () => Promise<void>;
  onBack: () => Promise<void>;
  nextPageInstructions: string;
  driverAssignmentFields: Fields;
  occasionalDriverAssignmentFields: Fields;
}

type AssignmentQuestionItem = (
  | { assignmentType: 'driver'; driver: Driver }
  | { assignmentType: 'vehicle'; vehicle: VehicleBasic }
) & {
  dropDownOptions: Option[];
  question: Field;
  occasionalQuestion: Field;
};

type GetLabelArgs = ['driver', Driver] | ['vehicle', VehicleBasic];
type GetLabel = (...args: GetLabelArgs) => string;
const getLabel: GetLabel = (type, item) =>
  type === 'driver' ? getDriverLabel(item) : `${item.year} ${item.make} ${item.model}`;

export const DriverAssignmentForm: React.FC<DriverAssignmentFormProps> = (props) => {
  const isMobile = useIsMobile();
  const { classes } = useStyles();
  const {
    onNext,
    nextPageInstructions,
    driverAssignmentFields,
    occasionalDriverAssignmentFields,
    vehicles,
    drivers,
    onBack,
  } = props;

  const [pageErrors, setPageErrors] = useState<PageErrors[]>([]);
  const [open, setOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);
  const [isResetting, setIsResetting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        // snackbar will always show only one at a time (last in the array)
        // This will show the next snackbar on dismiss, until there are no errors left
        setPageErrors(pageErrors.slice(0, -1));
        if (pageErrors.length - 1 === 0) {
          setOpen(false);
        }
      }
    },
    [pageErrors],
  );

  const occasionalDriverQuestionExists = useSelector((state: RootStore) =>
    questionExists('vehicle.<id>.occasional.driver.ref')(state),
  );

  /**
   * We need to get all the answers and the associated key and pass those into
   * the component for filtering
   */
  const fieldAnswers = Object.keys(driverAssignmentFields).map((key) => {
    return { key, value: driverAssignmentFields[key]?.value as AnswerValue };
  });

  const occasionalFieldAnswers = Object.keys(occasionalDriverAssignmentFields).map((key) => {
    return { key, value: occasionalDriverAssignmentFields[key]?.value as AnswerValue };
  });

  const assignedDrivers = fieldAnswers.filter((field) => !!field.value).map((field) => field.value);
  const assignedOccasionalDrivers = occasionalFieldAnswers
    .filter((field) => !!field.value)
    .map((field) => field.value)
    .flat();
  const unassignedDrivers = drivers.filter((driver) => {
    return !assignedDrivers.concat(assignedOccasionalDrivers).includes(driver.ref);
  });

  /**
   * The number of questions present will be equal to the number of vehicles
   * since the question format is vehicle.<id>.blah.blah
   * Regardless of how the UI displays it we really need to just
   * assign a driver to each vehicle.
   * However, in the case where there are more drivers than vehicles there will
   * be fewer questions so 1 or more questions will not get answered
   */
  const lineItems: AssignmentQuestionItem[] = useGetLineItems(
    driverAssignmentFields,
    occasionalDriverAssignmentFields,
    drivers,
    vehicles,
  );
  const initValues = useRef({});
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues,
    fields: { ...driverAssignmentFields },
    /**
     * Each question that we display will need to be required override
     */
    conditions: lineItems.map((item) => {
      return { conditionalFields: [item.question], isRequiredOverride: () => true };
    }),
  });

  const checkForPageErrors = useCallback(() => {
    const errors: PageErrors[] = [];
    if (unassignedDrivers.length > 1) {
      errors.push({
        message: 'All drivers must be assigned to a vehicle.',
        id: 'unassignedDriver',
      });
    } else if (unassignedDrivers.length === 1) {
      const displayName = getDriverLabel(unassignedDrivers[0]);
      errors.push({
        message: `${displayName} must be assigned to a vehicle.`,
        id: 'unassignedDriver',
      });
    }
    setPageErrors(errors);

    return errors.length > 0;
  }, [unassignedDrivers]);

  // One snackbar displays at a time; pull from the top, and dismiss button will remove it from the top
  const currentError = pageErrors[pageErrors.length - 1];
  const snackbarError = pageErrors.length > 0 && (
    <SnackbarAlert
      open={open}
      vertical='bottom'
      horizontal='center'
      onClose={handleSnackbarClose}
      severity='error'
      message={currentError.message}
    />
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setInfoMessage(undefined);
      }
    },
    [],
  );

  const snackbarDefault = infoMessage && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!infoMessage}
      autoHideDuration={3000}
      message={infoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const saveDriverAssignment = useCallback(async () => {
    const hasPageErrors = checkForPageErrors();
    if (validateForm().isValid && !hasPageErrors) {
      setIsSubmitting(true);
      await patchFormValues();
      await dispatch(
        submitProofDraft({
          policyTypes: ['auto'],
        }),
      );
      await onNext();
      setIsSubmitting(false);
    } else {
      setOpen(true);
      setIsSubmitting(false);
    }
  }, [checkForPageErrors, validateForm, patchFormValues, dispatch, onNext]);

  const isSelectingDriver = drivers.length > vehicles.length;

  const occasionalOperatorExists =
    occasionalDriverQuestionExists && drivers.length > vehicles.length;

  const handleAssignmentLineItemOnChange = useCallback(
    async (value: AnswerValue, field: Field, labelRef: string, reset?: boolean) => {
      if (isSelectingDriver) {
        field.props.actionOnChange(value);
      } else {
        const fieldKeys = Object.keys(driverAssignmentFields);
        const key = fieldKeys.filter((fkey) => fkey.includes(value as string))[0];

        const vehicleField = driverAssignmentFields[key] as Field;

        // we must remove the answer from any previous fields
        fieldKeys.forEach((fkey) => {
          if (fkey !== key) {
            const driverAssignmentField = driverAssignmentFields[fkey] as Field;
            if (driverAssignmentField.value === labelRef) {
              driverAssignmentField.props.actionOnComplete(null);
            }
          }
        });

        vehicleField?.props.actionOnChange(labelRef);
      }

      if (!reset) {
        trackClick({ action: 'AssignPrimaryDriver', label: `<${value}; ${labelRef}>` });
      }
    },
    [driverAssignmentFields, isSelectingDriver],
  );

  const resetAllItems = useCallback(async () => {
    // At a point in time, one driver will be unassigned. This will prevent the normal autoassign from happening when resetting.
    setIsResetting(true);
    for await (const item of lineItems) {
      await handleAssignmentLineItemOnChange(
        null,
        item.question,
        item.assignmentType === 'driver' ? item.driver.ref : item.vehicle.ref || '',
        true,
      );
      if (item.occasionalQuestion && item.occasionalQuestion.exists) {
        await dispatch(
          updateAnswers({
            answers: { [item.occasionalQuestion.key]: null },
          }),
        );
      }
    }
    setIsResetting(false);
  }, [dispatch, handleAssignmentLineItemOnChange, lineItems]);

  const goBack = useCallback(async () => {
    await patchFormValues();
    await onBack();
  }, [onBack, patchFormValues]);

  //  remove vehicle primary driver ref for the deleted driver
  useEffect(() => {
    Object.keys(driverAssignmentFields).forEach((key) => {
      const question = driverAssignmentFields[key] as Field;
      if (question.value) {
        const exist = drivers.some((driver) => driver.ref === question.value);
        if (!exist) {
          question.validateUpdateAndPatch(null);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(occasionalDriverAssignmentFields).forEach((key) => {
      const question = occasionalDriverAssignmentFields[key] as Field;
      const occasionalDrivers = question.value as string[];
      if (question && occasionalDrivers) {
        const exist = drivers.some((driver) => occasionalDrivers.includes(driver.ref));
        if (!occasionalOperatorExists || !exist) {
          dispatch(
            updateAnswers({
              answers: { [key]: null },
            }),
          );
        }
      }
    });
  }, [
    dispatch,
    driverAssignmentFields,
    drivers,
    occasionalDriverAssignmentFields,
    occasionalOperatorExists,
  ]);

  //  Hanlde automatic assignment incase of single unassigned driver and single unassigned vehicle.
  useEffect(() => {
    /**
     * Return if resetting, otherwise this auto assign is executed as soon as the first driver is reset
     *    and one of the drivers will end up not being reset
     */
    if (isResetting) return;
    // find unassigned vehicles
    const unassignedVehicles: Field<AnswerValue>[] = [];
    Object.keys(driverAssignmentFields).forEach((key) => {
      const eachDriverAssignmentField = driverAssignmentFields[key] as Field;
      const driverAssignmentFieldValue = eachDriverAssignmentField.value as string;
      // find out vehicle ref which is not assigned to any driver.
      // if driverAssignmentFieldValue is not null/undefined then corresponding vehicle is unassigned.
      if (!driverAssignmentFieldValue) {
        vehicles.forEach((field) => {
          if (`${field.ref}.primary.driver.ref` === eachDriverAssignmentField.key) {
            unassignedVehicles.push(eachDriverAssignmentField);
          }
        });
      }
    });

    if (unassignedDrivers.length === 1 && unassignedVehicles.length === 1) {
      // automatic assignment will happen on actionOnChange
      unassignedVehicles[0]?.props?.actionOnChange(unassignedDrivers[0].ref);
    }
  }, [
    dispatch,
    driverAssignmentFields,
    drivers,
    fieldAnswers,
    occasionalFieldAnswers,
    unassignedDrivers,
    vehicles,
    vehicles.length,
    isResetting,
  ]);

  return (
    <div className={classes.root}>
      <Form>
        <Grid container>
          <Grid item xs={12}>
            <h2>Assign primary driver</h2>
            <p className={classes.description}>Select the person who drives the vehicle the most</p>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.card}>
              {!isMobile && (
                <Grid container spacing='30px'>
                  <Grid item xs={6}>
                    <p className={classes.cardHeader}>{isSelectingDriver ? 'Vehicle' : 'Driver'}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className={classes.cardHeader}>
                      {isSelectingDriver ? 'Drivers' : 'Primary Vehicle'}
                    </p>
                  </Grid>
                </Grid>
              )}
              {lineItems.map((item, index) => {
                const getLabelArgs: GetLabelArgs =
                  item.assignmentType === 'driver'
                    ? ['driver', item.driver]
                    : ['vehicle', item.vehicle];

                return (
                  <Fragment key={index + 1}>
                    {index > 0 && <Divider aria-hidden='true' className={classes.divider} />}
                    <AssignmentLineItem
                      dropDownItems={item.dropDownOptions}
                      field={item.question}
                      showOccasionalOperator={occasionalOperatorExists}
                      occasionalField={item.occasionalQuestion}
                      answers={fieldAnswers}
                      occasionalFieldAnswers={occasionalFieldAnswers}
                      label={getLabel(...getLabelArgs)}
                      labelRef={
                        item.assignmentType === 'driver' ? item.driver.ref : item.vehicle.ref
                      }
                      mismatchedValues={!isSelectingDriver}
                      actionOnChange={handleAssignmentLineItemOnChange}
                      index={index}
                      setInfoMessage={setInfoMessage}
                      numberOfVehicles={vehicles.length}
                    />
                  </Fragment>
                );
              })}

              <Divider aria-hidden='true' className={classes.divider} />
              <Grid container justifyContent='flex-start'>
                <Grid md={6} />
                <Grid md={6}>
                  <Button
                    data-testid='resetPrimaryDriversButton'
                    variant='iconText'
                    onClick={resetAllItems}
                    color='primary'
                    className={classes.actionButton}
                    trackingName='ResetPrimaryDriversButton'
                    trackingLabel='ResetPrimaryDrivers'
                    analyticsElement='choice.driverAssignment.resetPrimaryDriversButton'
                    isProcessing={isPatchFormInProgress}
                  >
                    <ResetIcon className={classes.resetIcon} />
                    Reset all assigned {isSelectingDriver ? 'drivers' : 'vehicles'}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <NextPageInstructions divider>{nextPageInstructions}</NextPageInstructions>
          </Grid>
          <Grid container columnSpacing='30px'>
            <Grid item sm={12} md='auto'>
              <Button
                className={classes.button}
                data-testid='addDriverOrVehicle'
                variant='outlinePrimary'
                onClick={goBack}
                trackingName='AddDriversVehicleButton'
                trackingLabel='AddDriversVehicles'
                analyticsElement='choice.driverAssignmentPage.addDriverOrVehicle'
                disabled={isPatchFormInProgress}
              >
                Back
              </Button>
            </Grid>
            <Grid item sm={12} md='auto'>
              <Button
                className={classes.button}
                data-testid='saveAndContinue'
                variant='primary'
                onClick={saveDriverAssignment}
                trackingName='AutoAssignmentContinueButton'
                trackingLabel={GoogleAnalyticsLabels.CONTINUE}
                isProcessing={isPatchFormInProgress || isSubmitting}
                analyticsElement='choice.driverAssignmentPage.saveAndContinueButton'
              >
                Save & continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
      {snackbarError}
      {snackbarDefault}
    </div>
  );
};
