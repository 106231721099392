import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { useDeltaField, useField, usePniRef } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct } from '@ecp/features/shared/product';
import type { AnswerValue, Field } from '@ecp/types';

export interface DeltaMilesMyWayPhoneQuestionProps {
  autoProduct: AutoProduct;
}
export interface ProgramPhoneOptions {
  label: string;
  value: string;
}

// TODO: Need to merge this component with DeltaDriveMyWayPhoneQuestion
export const DeltaMilesMyWayPhoneQuestion: React.FC<DeltaMilesMyWayPhoneQuestionProps> = (
  props,
) => {
  const { autoProduct } = props;
  const milesMyWayPhone: Field = useField(
    `static.delta.coverageOption.${autoProduct}.ubiProgramPhone`,
  );
  const milesMyWayNewPhone: Field = useDeltaField(
    `coverageOption.${autoProduct}`,
    'ubiProgramPhone',
  );
  const pniRef = usePniRef();

  const pniPhone = useSelector((state: RootStore) => state.inquiry.answers[`${pniRef}.phone`]) as
    | string
    | undefined;

  useInitValues(
    pniPhone
      ? { [milesMyWayPhone.key]: pniPhone, [milesMyWayNewPhone.key]: pniPhone }
      : { [milesMyWayPhone.key]: 'Add New Phone', [milesMyWayNewPhone.key]: null },
  );
  useAddFields({ milesMyWayPhone, milesMyWayNewPhone });

  const getPhoneOptions = (options: string[]): ProgramPhoneOptions[] =>
    options.map((value) => ({
      label: value,
      value,
    }));

  const onPhoneOptionChange = useCallback(
    (value: AnswerValue): void => {
      milesMyWayPhone.props.actionOnChange(value);
      if (value === pniPhone) {
        milesMyWayNewPhone.props.actionOnComplete(pniPhone);
      } else {
        milesMyWayNewPhone.validateUpdateAndPatch(null);
      }
    },
    [milesMyWayPhone, milesMyWayNewPhone, pniPhone],
  );

  if (!milesMyWayPhone.exists) return null;

  return (
    <Grid container columnSpacing={1}>
      {pniPhone && (
        <GridItem topSpacing='xs' md={5} xs={12}>
          <Select
            {...milesMyWayPhone.props}
            label='Phone Number'
            id='milesMyWayPhone'
            options={getPhoneOptions(pniPhone ? [pniPhone, 'Add New Phone'] : ['Add New Phone'])}
            trackingName='miles_my_way_phone'
            actionOnChange={onPhoneOptionChange}
          />
        </GridItem>
      )}
      {milesMyWayPhone.value === 'Add New Phone' && (
        <GridItem topSpacing='xs' md={5} xs={12}>
          <NumberFormat
            {...milesMyWayNewPhone.props}
            label={pniPhone ? 'New Phone' : 'Phone Number'}
            trackingName='miles_my_way_new_phone'
            formatType='phone'
          />
        </GridItem>
      )}
    </Grid>
  );
};
