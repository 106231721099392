import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { Select } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useDeltaField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import type { AnswerValue } from '@ecp/types';

interface Props extends QuestionProps {
  driverRef: string;
}

export const DeltaDriverNonOperatorDescription: React.FC<Props> = (props) => {
  const {
    driverRef,
    trackingName = 'delta_driver_non_operator_description_question',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
  } = props;
  const nonOperatorDescription = useDeltaField(driverRef, 'nonOperatorDescription');
  const otherNonOperatorDescription = useDeltaField(driverRef, 'otherNonOperatorDescription');
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const dlStatus = useDriverField('driverStatus');

  useAddFields({ [`${nonOperatorDescription.key}`]: nonOperatorDescription });

  const actionOnChange = useCallback(
    (value: AnswerValue): void => {
      if (nonOperatorDescription.value !== 'OTHER' && otherNonOperatorDescription.value) {
        otherNonOperatorDescription.props.actionOnComplete(null);
      }
      nonOperatorDescription.props.actionOnChange(value);
    },
    [nonOperatorDescription, otherNonOperatorDescription],
  );

  if (dlStatus.value !== 'NON_OPERATOR' || !nonOperatorDescription.exists) return null;

  return (
    <Select
      {...(nonOperatorDescription.props as OptionProps)}
      label='Reason for Non-Operator'
      id='nonOperatorDescription'
      actionOnChange={actionOnChange}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};
