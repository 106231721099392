import { Typography } from '@mui/material';

import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const ResidenceTypeQuestion: React.FC<QuestionProps> = (props) => {
  const residencyTypeField = usePropertyField('singleOrMultiFamily');

  if (!residencyTypeField) return null;

  const { label = residencyTypeField.question?.title, trackingName = 'residence_type_question' } =
    props;

  return (
    <Select
      {...residencyTypeField.props}
      id='residenceType'
      groupLabel={<Typography variant='body4'>{label}</Typography>}
      trackingName={trackingName}
      inputButtonAriaLabel='residenceType'
      fullWidth={false}
    />
  );
};
