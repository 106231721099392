import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useDeltaField } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

interface Props extends QuestionProps {
  driverRef: string;
}

export const DeltaDriverAgeFirstLicensedQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    trackingName = 'delta_driver_age_first_licensed_question',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
  } = props;
  const ageFirstLicensed = useDeltaField(driverRef, 'license.ageFirstLicensed');

  useAddFields({ [`${ageFirstLicensed.key}`]: ageFirstLicensed });

  const handleDriverFirstLicensedComplete = useCallback(
    (value: AnswerValue): void => {
      ageFirstLicensed.props.actionOnComplete(value);
    },
    [ageFirstLicensed.props],
  );

  if (!ageFirstLicensed.exists) return null;

  return (
    <NumberFormat
      {...ageFirstLicensed.props}
      actionOnComplete={handleDriverFirstLicensedComplete}
      format='##'
      label='Age First Licensed'
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};
