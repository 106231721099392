import { useEffect } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { PropertyDeltaPageForm } from '../../forms';
import { useStyles } from './RentersDeltaPage.styles';

export const RentersDeltaPage: React.FC = () => {
  const { classes } = useStyles();
  useEffect(() => {
    trackSapiAnalyticsEvent({
      element: 'choice.RentersDeltaPage.page',
      event: 'render',
      eventDetail: 'true',
    });
  }, []);

  const handleNext = useNavigateToNextPage();

  return (
    <div className={classes.root}>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.RENTERS_DELTA} />,
        }}
        title='Additional information'
        mainWithoutSidebar
      >
        <PropertyDeltaPageForm onNext={handleNext} />
      </Page>
    </div>
  );
};
