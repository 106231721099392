import { useCallback } from 'react';

import { Grid, Typography } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Button, Select, TextField } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  useAddressFieldValue,
  useField,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';

import { useStyles } from './SearchTPIForm.styles';

// Pass TPI Id and Product so as to use it for adding tpi after serach
interface Props {
  refId: string;
  product?: Product;
}

export const SearchTPIForm: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const tpiNameQuestion = useField('static.searchTpi.name');
  // Use this for serach by number
  // const tpiNumberQuestion = useField('static.searchTpi.number');
  const tpiStateQuestion = useField('static.searchTpi.state');
  const tpiCountryQuestion = useField('static.searchTpi.country');
  const tpiCityQuestion = useField('static.searchTpi.city');

  const primaryAddress = usePrimaryAddressRef();
  const primaryAddressState = useAddressFieldValue(primaryAddress, 'state');

  useAddFields({
    [`${tpiNameQuestion.key}`]: tpiNameQuestion,
    // [`${tpiNumberQuestion.key}`]: tpiNumberQuestion,
    [`${tpiStateQuestion.key}`]: tpiStateQuestion,
    [`${tpiCountryQuestion.key}`]: tpiCountryQuestion,
    [`${tpiCityQuestion.key}`]: tpiCityQuestion,
  });
  useInitValues({ [tpiStateQuestion.key]: primaryAddressState, [tpiCountryQuestion.key]: 'US' });

  useAddConditionValues({
    conditionalFields: [tpiCityQuestion],
    isExcluded: () => true,
  });

  const navigateToTPIPage = useNavigateToPage(PagePath.THIRD_PARTY_INTEREST, {
    replace: true,
    removeQuery: false,
    replaceProfileAdd: true,
  });

  const handleCancel = useCallback(async () => {
    // TODO: Refactor this not to be LOB specific
    // await removeTPI(currentTPIRef, vehicleRef.ref);
    await navigateToTPIPage();
  }, [navigateToTPIPage]);

  return (
    <Grid container className={classes.card}>
      <GridItem xs={12}>
        <Typography className={classes.cardTitle}> Search TPI</Typography>
      </GridItem>
      <GridItem xs={12} md={8}>
        <TextField
          {...tpiNameQuestion.props}
          label='Organization Name'
          trackingName='tpi_organization_name'
        />
      </GridItem>
      {/* <GridItem xs={12} md={8}>
        <TextField
          {...tpiNumberQuestion.props}
          label='Organization number'
          trackingName='tpi_organization_number'
        />
      </GridItem> */}
      <GridItem xs={12} md={8}>
        <TextField
          {...tpiNameQuestion.props}
          label='Organization Name'
          trackingName='tpi_organization_name'
        />
      </GridItem>
      <Grid container item md={8}>
        <GridItem topSpacing='sm' className={classes.columnLeft} xs={12} md={6}>
          <Select
            {...(tpiStateQuestion.props as OptionProps)}
            id='State'
            label='State'
            trackingName='tpi_state'
            autoComplete='address-level1'
          />
        </GridItem>
        <GridItem topSpacing='sm' className={classes.columnRight} xs={12} md={6}>
          <Select
            {...(tpiCountryQuestion.props as OptionProps)}
            id='Country'
            label='Country'
            trackingName='tpi_country'
            autoComplete='address-level1'
          />
        </GridItem>
      </Grid>
      <GridItem topSpacing='sm' xs={12} md={8}>
        <TextField
          {...tpiCityQuestion.props}
          label='Organization City (optional)'
          trackingName='tpi_organization_name'
        />
      </GridItem>
      <Grid container>
        <GridItem topSpacing='sm' className={classes.addButton}>
          <Button className={classes.next} variant='primary' trackingName='tpi_search_continue'>
            Search
          </Button>
        </GridItem>
        <GridItem topSpacing='sm' className={classes.cancelButton}>
          <Button
            className={classes.next}
            variant='iconTextMedium'
            onClick={handleCancel}
            trackingName='tpi_search_cancel'
          >
            Cancel
          </Button>
        </GridItem>
      </Grid>
    </Grid>
  );
};
