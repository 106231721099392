import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, TextField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Field } from '@ecp/types';

import { useStyles } from './ParentsPolicyNumberQuestion.styles';

interface Props extends QuestionProps {
  pniRef: string;
}

export const ParentsPolicyNumberQuestion: React.FC<Props> = (props) => {
  const { pniRef } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const parentPolicyNumber: Field = useField(`${pniRef}.parentsPolicyNumber`);
  const otherInsuredObjectsAndPolicies = useField(`${pniRef}.otherInsuredObjectsAndPolicies`);

  useAddFields({ parentPolicyNumber });

  const multiProductDiscountValues = otherInsuredObjectsAndPolicies.value as string[];
  const isParentPolicySelected = multiProductDiscountValues?.includes('PARENT_POLICY');

  if (!isParentPolicySelected) {
    dispatch(
      setFormErrorsResetByField({
        key: parentPolicyNumber.key,
      }),
    );
  }

  const handleParentsPolicyNumberComplete = useCallback(
    async (value: AnswerValue) => {
      parentPolicyNumber.validateUpdateAndPatch(value);
      if (!value) {
        parentPolicyNumber.validateUpdateAndPatch(null);
        dispatch(
          setFormErrorsChangedByField({ key: parentPolicyNumber.key, errors: ['Required field'] }),
        );
      }
    },
    [dispatch, parentPolicyNumber],
  );
  if (
    !otherInsuredObjectsAndPolicies.exists ||
    !multiProductDiscountValues ||
    !isParentPolicySelected ||
    !parentPolicyNumber.exists
  ) {
    return null;
  }

  return (
    <GridItem xs={12} className={classes.oneColumn}>
      <TextField
        {...parentPolicyNumber.props}
        label='Parent Policy Number '
        actionOnComplete={handleParentsPolicyNumberComplete}
        trackingName='parentPolicyNumber'
        trackingLabel={GoogleAnalyticsLabels.REDACTED}
      />
    </GridItem>
  );
};
