import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Snackbar' })((theme) => ({
  root: {
    height: 32,
    maxWidth: 300,
    padding: '0px 12px',
    textAlign: 'center',
  },
  alertContent: {
    ...theme.typography.body1,
    alignItems: 'center',
  },
}));
