import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import {
  useAddConditionValues,
  useAddFields,
  useGetConditionValues,
  useInitValues,
} from '@ecp/features/sales/form';
import {
  Form,
  RadioGroupWithOptions,
  Resource,
  Select,
  TextField,
} from '@ecp/features/sales/shared/components';
import { getShownFields, useField, useForm } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Fields } from '@ecp/types';

import type { HeatingSystemData } from './HeatingSystemQuestion';
import { useStyles } from './HeatingSystemQuestion.styles';

const INVALID_FUEL_TYPES_FOR_AREA_HEATING = ['GEO_THERM', 'SOLAR'];

interface Props {
  itemRef: string;
  onCancel: (businessRef: string, businessFields: Fields) => void;
  onNext: () => void;
  setHeatingSystemBeforeEdit: Dispatch<SetStateAction<HeatingSystemData | undefined>>;
}

export const HeatingSystemForm: React.FC<Props> = (props) => {
  const {
    onCancel,
    onNext: onSave,
    itemRef: editHeatingSourceRef,
    setHeatingSystemBeforeEdit,
  } = props;
  const { classes } = useStyles();
  const getCondition = useGetConditionValues();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fuelType = useField(`${editHeatingSourceRef}.fuelType`);
  const fuelTypeDescription = useField(`${editHeatingSourceRef}.fuelTypeDescription`);
  const systemType = useField(`${editHeatingSourceRef}.systemType`);
  const fields = {
    [fuelType.key]: fuelType,
    [fuelTypeDescription.key]: fuelTypeDescription,
    [systemType.key]: systemType,
  };

  useInitValues({
    [fuelType.key]: fuelType.question.defaultValue,
    [systemType.key]: systemType.question.defaultValue,
  });

  useAddFields(fields);

  // Needed to use the hide attribute
  const dispatch = useDispatch();
  const filteredFields = dispatch(getShownFields({ fields })) as Fields;

  // Requirement is to filter out invalid options for 'area' heating system type
  const fuelTypeOptions =
    systemType.value === 'CENTRAL'
      ? fuelType.question.options
      : fuelType.question.options?.filter(
          (fuelType) => !INVALID_FUEL_TYPES_FOR_AREA_HEATING.includes(fuelType.value),
        );

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    fields: {
      secondaryHeatingSource: {
        fuelType,
        fuelTypeDescription,
        systemType,
      },
    },
    conditions: getCondition(),
  });

  // Required if they've started to add a secondary heating source
  useAddConditionValues({
    conditionalFields: [systemType],
    isRequiredOverride: () => true,
  });

  const handleFuelTypeChange = useCallback(
    (value: AnswerValue): void => {
      // If value is not OTHER, clear out the description field
      if (value !== 'OTHER') {
        fuelTypeDescription.validateAndPatch(null);
      }
      fuelType.props.actionOnComplete(value);
    },
    [fuelType, fuelTypeDescription],
  );

  const handleCancelHeatingSystems = useCallback((): void => {
    onCancel(editHeatingSourceRef, {
      fuelType,
      fuelTypeDescription,
      systemType,
    });
  }, [onCancel, editHeatingSourceRef, fuelType, fuelTypeDescription, systemType]);

  const handleSaveHeatingSystems = useCallback(async () => {
    const { isValid } = validateForm();
    if (isValid) {
      setIsSubmitting(true);
      await patchFormValues();
      onSave();
      setIsSubmitting(false);
    }
    trackClick({
      action: 'HeatingSystemSaveButton',
      label: `${fuelType.value}`,
    });
  }, [fuelType, onSave, patchFormValues, validateForm]);

  useEffect(() => {
    // Storing fields before user edits.
    setHeatingSystemBeforeEdit({
      fuelType: fuelType.value,
      fuelTypeDescription: fuelTypeDescription.value,
      systemType: systemType.value,
    });
    // this executes when the form first opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const heatingSystemForm = (
    <Grid container item xs={12} spacing={4}>
      <GridItem xs={12} md={6}>
        <RadioGroupWithOptions
          {...systemType.props}
          id='systemType'
          label={<Typography variant='body1'>{systemType.question?.title}</Typography>}
          disabled={isPatchFormInProgress}
          trackingName='system_type_selection'
          variant='radioCard'
        />
      </GridItem>
      {filteredFields && filteredFields[fuelType.key] && (
        <GridItem xs={12}>
          <Select
            {...fuelType.props}
            id='fuelType'
            groupLabel={<Typography variant='body1'>{fuelType.question?.title}</Typography>}
            disabled={isPatchFormInProgress}
            trackingName='fuel_type_selection'
            options={fuelTypeOptions}
            fullWidth={false}
            actionOnChange={handleFuelTypeChange}
          />
        </GridItem>
      )}
      {filteredFields && filteredFields[fuelTypeDescription.key] && (
        <GridItem xs={12}>
          <TextField
            {...fuelTypeDescription.props}
            id='fuelTypeDescription'
            label={<Typography variant='body1'>{fuelTypeDescription.question?.title}</Typography>}
            disabled={isPatchFormInProgress}
            fullWidth={false}
          />
        </GridItem>
      )}
    </Grid>
  );

  return (
    <Form className={classes.fullWidth}>
      {heatingSystemForm}
      <Grid container justifyContent='flex-end' className={classes.marginTop}>
        <GridItem>
          <Resource.CancelButton
            onClick={handleCancelHeatingSystems}
            trackingName='remove_heating_system'
            trackingLabel='remove_heating_system'
          >
            CANCEL
          </Resource.CancelButton>
          <Resource.SaveButton
            onClick={handleSaveHeatingSystems}
            isProcessing={isPatchFormInProgress || isSubmitting}
            trackingName='save_heating_system'
            trackingLabel='save_heating_system'
          >
            SAVE
          </Resource.SaveButton>
        </GridItem>
      </Grid>
    </Form>
  );
};
