import { configureStore } from '@reduxjs/toolkit';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { initializeAgentAuth } from '@ecp/utils/auth';
import { handleEnvOverrides } from '@ecp/utils/env';
import * as flags from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';
import { localStorage, sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { throwOnExceedingInitializationLimit } from '@ecp/features/sales/shared/misc';
import { initializeLogger } from '@ecp/features/sales/shared/utils/logger';
import { storeConfiguration } from '@ecp/features/sales/shell';

const initializeOptimizely = async (): Promise<void> => {
  const handleError = (reason?: string, error?: Error): void => {
    datadogLog({
      logType: 'error',
      message: reason
        ? `Optimizely SDK failed with ${reason} - ${error?.message}`
        : `Optimizely SDK failed with non ok result - ${error?.message}`,
      context: {
        logOrigin: 'apps/sales/edsp-asp/src/initialize.ts',
        functionOrigin: 'initializeOptimizely',
      },
      error,
    });
  };

  await flags
    .initialize({ sdkKey: env.optimizely.sdkKey, storage: sessionStorage })
    .then((result) => {
      if (!result?.success) handleError(result?.reason);
    })
    .catch((error) => {
      handleError('Optimizely SDK failed with unknown error', error);
    });

  flags.updateUserAttributes({
    baseExp: env.static.baseExp,
    expId: env.static.expId,
    partnerName: env.static.partnerName,
    opcoName: env.static.opcoName,
    testAudience: env.optimizely.testAudience,
  });
};

// Add feature flags and AB tests initialization as well
export const initializeGlobal = async (): Promise<void> => {
  // eslint-disable-next-line no-console
  console.log(
    `Platform Build: ${env.static.releaseVersion} - ${new Date(
      env.static.releaseTimestamp,
    ).toLocaleString()}`,
  );

  sessionStorage.setNamespace(env.static.expId);
  localStorage.setNamespace(env.static.expId);

  handleEnvOverrides();

  initializeLogger();

  interactionId.initialize({ storage: sessionStorage });

  if (env.mockapi) {
    const { registerMockServer } = await import('@ecp/utils/mock/browser');
    await registerMockServer();
  }

  if (env.optimizely.enabled) await initializeOptimizely();

  if (!env.azureClientId) {
    initializeAgentAuth(env.agentAuthBaseUrl, env.static.expId);
  } else {
    await (
      initializeAgentAuth as unknown as typeof import('../../../../libs/utils/auth/src/agentAuth/agentAuth.amfam-adv').initialize
    )({
      expId: env.static.expId,
      clientId: env.azureClientId,
      authority: env.azureAuthority,
      redirectEndpoint: env.azureRedirectEndpoint,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const initializeAndGetStore = () => {
  const defaultMiddlewareOptions = {
    immutableCheck: true,
    serializableCheck: true,
  };

  const store = configureStore(storeConfiguration(defaultMiddlewareOptions));

  window.store = store;

  throwOnExceedingInitializationLimit(store);

  return store;
};
