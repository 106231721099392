import { useCallback, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { RENTERS_POLICY_HOUSEHOLD_LOSSES } from '@ecp/features/sales/shared/constants';
import { submitProofDraft, useForm } from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import {
  BusinessOnPremisesQuestion,
  DaycareOnPremisesQuestion,
  HigherThanThreeStoriesQuestion,
  HomeBuiltOnSlopeQuestion,
  NoOfOccupantsQuestion,
  NumberOfDaysRentedQuestion,
  OccupancyTypeQuestion,
  RentedToOthersQuestion,
  ResidenceTypeQuestion,
} from '@ecp/sales/lob/property';

import { HouseholdPriorLossQuestion } from '../../../../common';
import { useStyles } from './RentersPropertyDetailsForm.styles';

interface SubmitParams {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
}

interface Props {
  onNext: () => Promise<void>;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();
      await dispatch(
        submitProofDraft({
          policyTypes: ['renters'],
        }),
      );
      await onNext();
    }

    setIsSubmitting(false);
  };

export const RentersPropertyDetailsForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getFields = useGetFields();
  const dispatch = useDispatch();
  const getConditions = useGetConditionValues();
  const getInitValues = useGetInitValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  const handleSubmit = useCallback(async () => {
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
      }),
    );
  }, [dispatch, onNext, patchFormValues, validateForm]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <h2>Eligibility</h2>

          <GridItem topSpacing='sm' xs={12}>
            <NoOfOccupantsQuestion label='How many people live in the rental unit?' />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <HouseholdPriorLossQuestion
              priorLossKey={RENTERS_POLICY_HOUSEHOLD_LOSSES}
              priorLossHelpLabel='Has a member of the household had a property loss in the past 5 years?'
              secondaryText=''
            />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <ResidenceTypeQuestion label='What type of rental unit is it?' />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <OccupancyTypeQuestion label='How will this rental unit be used?' />
          </GridItem>

          <Divider orientation='horizontal' className={classes.horizontalDivider} />

          <GridItem topSpacing='sm' xs={12}>
            <HigherThanThreeStoriesQuestion />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <HomeBuiltOnSlopeQuestion />
          </GridItem>

          <Divider orientation='horizontal' className={classes.horizontalDivider} />

          <GridItem topSpacing='sm' xs={12}>
            <RentedToOthersQuestion label='Is the rental unit rented to others?' />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <NumberOfDaysRentedQuestion />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <DaycareOnPremisesQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <BusinessOnPremisesQuestion />
          </GridItem>

          {/*
          TODO: ADD THIS WHEN SNI COMES IN
          <Divider orientation='horizontal' className={classes.horizontalDivider} />
          <GridItem topSpacing='lg' xs={12}>
            <SecondaryNamedInsuredQuestion />
          </GridItem> */}

          <GridItem topSpacing='lg' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='continue'
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='person_living_continue'
              analyticsElement='choice.personLivingPage.continueButton'
              type='submit'
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
