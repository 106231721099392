import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import type { Driver } from '@ecp/features/sales/shared/types';

import { DeltaDriverAgeFirstLicensedQuestion } from '../DeltaDriverAgeFirstLicensedQuestion';
import { DeltaDriverLicenseNumberQuestion } from '../DeltaDriverLicenseNumberQuestion';
import { DeltaDriverLicenseStateQuestion } from '../DeltaDriverLicenseStateQuestion';
import { useStyles } from './DeltaDriverCard.styles';

export interface DeltaDriverCardProps {
  driver: Driver;
  index: number;
}

export const DeltaDriverCard: React.FC<DeltaDriverCardProps> = (props) => {
  const { classes } = useStyles();
  const { driver } = props;

  const header = <p className={classes.headerRight}>{`${driver.firstName} ${driver.lastName}`}</p>;

  const body = (
    <Grid container columnSpacing={1}>
      <GridItem topSpacing='xs' xs={12} md={6}>
        <DeltaDriverLicenseNumberQuestion driverRef={driver.ref} />
      </GridItem>
      <GridItem topSpacing='xs' xs={12} md={3}>
        <DeltaDriverLicenseStateQuestion driverRef={driver.ref} />
      </GridItem>
      <GridItem topSpacing='xs' xs={12} md={3}>
        <DeltaDriverAgeFirstLicensedQuestion driverRef={driver.ref} />
      </GridItem>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <div className={classes.driverDiv}>
        {header}
        {body}
      </div>
    </div>
  );
};
