import { Typography } from '@mui/material';

import { Select } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const NoOfMortgagesQuestion: React.FC<QuestionProps> = (props) => {
  const noOfMortgages = usePropertyField('noOfMortgages');
  if (!noOfMortgages) return null;
  const { groupLabel = noOfMortgages.question?.title, trackingName = 'no_of_mortgages_question' } =
    props;

  return (
    <Select
      {...noOfMortgages.props}
      id='NoOfMortgages'
      groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
      trackingName={trackingName}
      inputButtonAriaLabel='NoOfMortgages'
      fullWidth={false}
    />
  );
};
