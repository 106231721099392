import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'GaragesFormQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    margin: '15px 0px 15px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
  },
  radiobox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& div': {
      flexDirection: 'column',
      '& label': {
        width: '50%',
        '& button': {
          textAlign: 'left',
          width: '100%',
          marginBottom: 10,
          display: 'block',
        },
      },
    },
  },
  div: {
    flexDirection: 'column',
  },
  gridRoot: {
    width: '100%',
  },
}));
