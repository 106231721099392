import { RadioGroupWithOptions } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const MineSubsidenceDamageQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyField('unrepairedMineSubDamageInd');

  if (!field) return null;

  const { trackingName = 'unrepired_mine_subsidence_damage', label = field.question?.title } =
    props;

  return (
    <RadioGroupWithOptions
      {...field.props}
      variant='yesNoButton'
      trackingName={trackingName}
      label={label}
    />
  );
};
