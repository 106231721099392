import { IncidentQuestionsHelpText } from '../IncidentQuestionsHelpText';

export interface Metadata {
  renderDamageAmountAsText: boolean;
  getIncidentAmountLabel: string;
  accidentHelpText: { [stateCode: string]: string };
  helpText: (stateCode: string) => JSX.Element | string;
}

export const accidentHelpText: { [stateCode: string]: string } = {
  NC: `No need to enter the violations where you have been granted a ‘Prayer for Judgement’ by the state.`,
};

export const helpText = (stateCode: string): JSX.Element | string => {
  const text = `We’ll confirm the details with third-party reports prior to purchase to determine your final quote, which may impact your premium. `;
  const link = `View incident descriptions`;
  const dialogContent = (
    <>
      <p style={{ marginBottom: '20px' }}>
        In an accident a driver is either at-fault or not at-fault. If you were found more than 50%
        at-fault in an accident, and it resulted in damage to property exceeding $1,000 and/or any
        bodily injury, you would be considered at-fault. Insurance companies use this information to
        accurately rate policies.
      </p>
      <p>
        <h5>California At Fault Guide</h5>
        Consider these potential scenarios involving you and the vehicle you were driving:
        <ol style={{ marginTop: '0' }}>
          <li>
            Was your vehicle lawfully parked at the time of the accident? (A vehicle rolling from a
            parked position is not considered to be lawfully parked.)
          </li>
          <li>
            Was your vehicle struck in the rear by another vehicle, and you were not convicted of a
            moving traffic violation in connection with the accident?
          </li>
          <li>
            You were not convicted of a moving traffic violation and the operator of another vehicle
            involved in the accident was convicted of a moving traffic violation?
          </li>
          <li>
            Was your vehicle damaged as a result of contact with a vehicle operated by a “hit and
            run” operator of another vehicle and the accident was reported to legal authorities
            within a reasonable time after the accident?
          </li>
          <li>
            Was this a collision with an actively falling (moving) object, such as an item that fell
            off of a vehicle immediately in front of you?
          </li>
          <li>
            Were you responding to a call of duty as a paid or volunteer member of any police or
            fire department, first aid squad, or of any law enforcement agency, while performing any
            other governmental function in a public emergency?
          </li>
          <li>
            Was the accident a solo vehicle accident that was principally caused by a hazardous
            condition of which a driver, in the exercise of reasonable care, would not have noticed
            (for example, “black ice”) or in the exercise of reasonable care could not have avoided
            (for example, avoiding a child running into the street)?
          </li>
        </ol>
      </p>
    </>
  );

  switch (stateCode) {
    case 'CA':
      return (
        <IncidentQuestionsHelpText
          helperText={text}
          linkText={link}
          dialogContent={dialogContent}
        />
      );
    default:
      return text;
  }
};

/**
 * We need this workaround for now because SAPI doesn't have the capability to fix this on their side.
 * They can't remove the “driver.<id>.insurance.prior.*” questions because they are needed for the PNI.
 */

const metadata: Metadata = {
  renderDamageAmountAsText: false,
  getIncidentAmountLabel: 'Total claim amount',
  accidentHelpText,
  helpText,
};

export default metadata;
