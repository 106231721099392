import { useCallback, useState } from 'react';

import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';
import { PolicyCenterDialog } from '../common/PolicyCenterDialog';

export const DaycareOnPremisesQuestion: React.FC<QuestionProps> = (props) => {
  const daycareOnPremisesField = usePropertyField('dayCareOnPremisesInd');

  const [dialogStat, setDialogStat] = useState(false);

  const handleOnSelection = useCallback(
    async (value: boolean) => {
      setDialogStat(value);
      daycareOnPremisesField?.validateUpdateAndPatch(value);
    },
    [daycareOnPremisesField],
  );

  const toggleDialog = useCallback(() => {
    setDialogStat((prev: boolean) => {
      if (prev) daycareOnPremisesField?.validateUpdateAndPatch(false);

      return !prev;
    });
  }, [daycareOnPremisesField]);

  const handleDialogSubmit = useCallback(() => {
    setDialogStat(!dialogStat);
    // TODO: handle redirection to Policy Center Page
  }, [dialogStat]);

  if (!daycareOnPremisesField) return null;

  const {
    label = daycareOnPremisesField.question?.title,
    trackingName = 'DaycareOnPremisesButton',
  } = props;

  return (
    <>
      <RadioGroupWithOptions
        {...daycareOnPremisesField.props}
        id='DaycareOnPremises'
        label={label}
        variant='yesNoButton'
        trackingName={trackingName}
        actionOnComplete={handleOnSelection}
      />
      {dialogStat && (
        <PolicyCenterDialog
          dialogStat={dialogStat}
          toggleDialog={toggleDialog}
          dialogSubmit={handleDialogSubmit}
        />
      )}
    </>
  );
};
