import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import { useIsMobile } from '@ecp/themes/base';

import { useStyles } from '../LandingPageForm.styles';

interface CallToActionProps {
  isNewQuoteInProgress: boolean;
  onStartNewQuoteClick: () => void;
}

export const CallToAction: React.FC<CallToActionProps> = (props) => {
  const { classes } = useStyles();
  const { isNewQuoteInProgress, onStartNewQuoteClick } = props;
  const isMobile = useIsMobile();

  return (
    <Grid container>
      <GridItem xs={isMobile ? 12 : 0} topSpacing='sm'>
        <Button
          className={classes.next}
          variant='primary'
          onClick={onStartNewQuoteClick}
          data-testid='continue'
          trackingName='ContinueButton'
          analyticsElement='choice.landingPage.continueButton'
          type='submit'
          isProcessing={isNewQuoteInProgress}
        >
          Start a new quote
        </Button>
      </GridItem>
    </Grid>
  );
};
