import { Divider, Grid } from '@mui/material';

import { EstimatedTooltip, PremiumToggle } from '@ecp/features/sales/checkout';
import { StateFeesSection } from '@ecp/features/sales/shared/components';
import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  type AutoProduct,
  getProductDisplayNameFromProduct,
  type PropertyProduct,
} from '@ecp/features/shared/product';

import { getOfferDetailsForProduct } from '../../selectors';
import { FullPaymentView } from '../FullPaymentView';
import { MonthlyPaymentView } from '../MonthlyPaymentView';
import { useStyles } from './PremiumBoxCard.styles';

interface Props {
  autoOfferProduct: AutoProduct;
  propertyOfferProduct: PropertyProduct;
  autoEffectiveDate: string;
  propertyEffectiveDate: string;
  isMVRActive?: boolean;
  premiumPlan: PaymentPlan;
  recalculate?: boolean;
  showProcess: boolean;
}
//* TODO: this bundle component needs a relook when we start bundle its functional but could be better put together */
export const PremiumBoxDetailsBundle: React.FC<Props> = (props) => {
  const {
    autoOfferProduct,
    propertyOfferProduct,
    autoEffectiveDate,
    propertyEffectiveDate,
    isMVRActive,
    premiumPlan,
    recalculate,
    showProcess,
  } = props;
  const { classes } = useStyles();
  const autoOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );
  const propertyOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );

  const propertyProductName = getProductDisplayNameFromProduct(propertyOfferProduct);

  if (!autoOfferDetails && !propertyOfferDetails) return null;

  const renderMonthlyView = (
    <Grid container className={classes.bundlePremiumContainer} justifyContent='center'>
      {autoOfferDetails && (
        <>
          <Grid
            container
            item
            xs={6}
            md={5}
            lg={12}
            className={classes.leftColumn}
            alignContent='center'
          >
            <Grid item xs={12}>
              <p className={classes.policyDurationBundleHeaderColumn}>
                <span className={classes.policyDurationHeader}>Auto</span>{' '}
                {`${autoOfferDetails.policyDuration}-mo policy`}
                {!isMVRActive && <EstimatedTooltip inline />}
              </p>
            </Grid>

            <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' className={classes.premiumsDivider} />
            </Grid>
            <MonthlyPaymentView
              offerProduct={autoOfferProduct}
              recalculate={recalculate}
              showProcess={showProcess}
              effectiveDate={autoEffectiveDate}
              monthlyPremium={autoOfferDetails.monthlyPremium}
            />
            <StateFeesSection
              premiumPlan={premiumPlan}
              offerDetails={autoOfferDetails}
              showStateFees
            />
          </Grid>

          <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
            <Divider aria-hidden='true' className={classes.premiumsDivider} />
          </Grid>
        </>
      )}

      {propertyOfferDetails && (
        <Grid
          container
          item
          xs={6}
          md={5}
          lg={12}
          className={classes.rightColumn}
          alignContent='center'
        >
          <Grid item xs={12}>
            <p className={classes.policyDurationBundleHeaderColumn}>
              <span className={classes.policyDurationHeader}>{propertyProductName}</span>{' '}
              {`${propertyOfferDetails.policyDuration}-mo policy`}
            </p>
          </Grid>

          <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
            <Divider aria-hidden='true' className={classes.premiumsDivider} />
          </Grid>

          <MonthlyPaymentView
            offerProduct={propertyOfferProduct}
            recalculate={recalculate}
            showProcess={showProcess}
            effectiveDate={propertyEffectiveDate}
            monthlyPremium={propertyOfferDetails.monthlyPremium}
          />
          <StateFeesSection
            premiumPlan={premiumPlan}
            offerDetails={propertyOfferDetails}
            showStateFees
          />
        </Grid>
      )}
    </Grid>
  );

  const renderFullPaymentView = (
    <Grid container className={classes.bundlePremiumContainer} justifyContent='center'>
      {autoOfferDetails && (
        <>
          <Grid
            container
            item
            xs={6}
            md={5}
            lg={12}
            className={classes.leftColumn}
            alignContent='center'
          >
            <Grid item xs={12}>
              <p className={classes.policyDurationBundleHeaderColumn}>
                <span className={classes.policyDurationHeader}>Auto</span>{' '}
                {`${autoOfferDetails.policyDuration}-mo policy`}
              </p>
            </Grid>

            <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' className={classes.premiumsDivider} />
            </Grid>
            <FullPaymentView
              recalculate={recalculate}
              showProcess={showProcess}
              effectiveDate={autoEffectiveDate}
              fullPremium={autoOfferDetails.fullPremium}
            />
            <StateFeesSection
              premiumPlan={premiumPlan}
              offerDetails={autoOfferDetails}
              showStateFees
            />
          </Grid>
          <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
            <Divider aria-hidden='true' className={classes.premiumsDivider} />
          </Grid>
        </>
      )}

      {propertyOfferDetails && (
        <Grid
          container
          item
          xs={6}
          md={5}
          lg={12}
          className={classes.rightColumn}
          alignContent='center'
        >
          <Grid item xs={12}>
            <p className={classes.policyDurationBundleHeaderColumn}>
              <span className={classes.policyDurationHeader}>{propertyProductName}</span>{' '}
              {`${propertyOfferDetails.policyDuration}-mo policy`}
            </p>
          </Grid>

          <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
            <Divider aria-hidden='true' className={classes.premiumsDivider} />
          </Grid>

          <FullPaymentView
            recalculate={recalculate}
            showProcess={showProcess}
            effectiveDate={propertyEffectiveDate}
            fullPremium={propertyOfferDetails.fullPremium}
          />
          <StateFeesSection
            premiumPlan={premiumPlan}
            offerDetails={propertyOfferDetails}
            showStateFees
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12} className={classes.toggleContainer}>
          <PremiumToggle size='large' />
        </Grid>
      </Grid>
      {premiumPlan === PaymentPlan.FULL_PREMIUM ? renderFullPaymentView : renderMonthlyView}
    </>
  );
};
