import { useCallback, useMemo } from 'react';

import { ensureStringArray } from '@ecp/utils/common';

import {
  deleteInquiryRef,
  getAllValues,
  getAnswer,
  getQuestion,
  getValueForPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers } from '@ecp/features/sales/shared/types';
import type { Option } from '@ecp/types';

interface GarageOptions {
  typeMap: Record<string, string>;
  sizeMap: Record<string, string>;
}

export interface Garage {
  type: string;
  size: string;
  ref: string;
}

const arrayToObject = (array: Option[]): Record<string, string> =>
  array.reduce((obj, item) => {
    obj[item.value] = item.label as string;

    return obj;
  }, {} as Record<string, string>);

export const useGarageOptions = (): GarageOptions => {
  const groupQuestion = useSelector(getQuestion('garage.<id>.size'));
  const sizeMap = useMemo(() => {
    return arrayToObject(groupQuestion.options || []);
  }, [groupQuestion?.options]);

  const typeQuestion = useSelector(getQuestion('garage.<id>.type'));
  const typeMap = useMemo(() => {
    return arrayToObject(typeQuestion.options || []);
  }, [typeQuestion?.options]);

  return { typeMap, sizeMap };
};

const getGarages = (
  ref: string,
  allValues: Answers,
  typeMap: Record<string, string>,
  sizeMap: Record<string, string>,
): Garage => {
  const getString = getValueForPrefix<string>(ref, allValues);

  return {
    ref,
    type: typeMap[getString('type')],
    size: sizeMap[getString('size')],
  };
};

export const useRemoveAllGarages = (garagesRefsKey: string): (() => void) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const garagesRefsValue =
    (useSelector((state: RootStore) => getAnswer(state, garagesRefsKey)) as Array<string>) || [];

  return useCallback(() => {
    if (garagesRefsValue && garagesRefsValue.length > 0) {
      garagesRefsValue.forEach(async (ref) => {
        await dispatch(deleteInquiryRef({ refType: 'garage', refId: ref.split('.')[1] }));
      });
    }
  }, [dispatch, garagesRefsValue]);
};

export const useGarages = (): Garage[] => {
  const allValues = useSelector(getAllValues);
  const { typeMap, sizeMap } = useGarageOptions();
  const refs: string[] = ensureStringArray(allValues[`property.garage.ref`]);

  return refs.map((ref) => getGarages(ref, allValues, typeMap, sizeMap));
};

export const useGarageRefValues = (garagesRefsKey: string): string[] => {
  return (
    (useSelector((state: RootStore) => getAnswer(state, garagesRefsKey)) as Array<string>) || []
  );
};
