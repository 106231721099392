import { useCallback } from 'react';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  DRIVER_INTL,
  getInvalidDlRefs,
  INVALID_DL_DRIVER_REFS,
} from '@ecp/features/sales/quotes/auto';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { updateAnswers, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import type { AnswerValue } from '@ecp/types';

interface Props extends QuestionProps {
  driverRef: string;
  helperText?: string;
  possessiveName: string;
  ifNameOrYou: string;
}

export const DriverLicenseStatusQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    id = 'LicenseStatus',
    label,
    dataTestId = 'driverLicenseStatus',
    trackingName = 'license_status',
    trackingLabel,
    placeholder = 'Select Driver License Status',
    helperText,
    possessiveName,
  } = props;
  const dispatch = useDispatch();
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const dlStatus = useDriverField('license.status');
  const dlState = useDriverField('license.state');
  const dlNumber = useDriverField('license.number');

  useInitValues({ [dlStatus.key]: dlStatus.question.defaultValue });
  useAddFields({ dlStatus });
  const invalidDlRefs = useSelector(getInvalidDlRefs);

  const statusActionOnChange: (value: AnswerValue) => void = useCallback(
    async (value: AnswerValue) => {
      if (value === dlStatus.value) return;
      if (dlNumber.value) {
        dlNumber.props.actionOnComplete(null);
      }
      dlStatus.props.actionOnChange(value);
      if (value === DRIVER_INTL) {
        dlState.props.actionOnChange(null);
        // remove driverRef from invalidDlRefs because we don't have validations on International DL
        if (invalidDlRefs.includes(driverRef)) {
          invalidDlRefs.splice(invalidDlRefs.indexOf(driverRef), 1);
          await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }));
        }
      }
    },
    [
      dispatch,
      dlNumber.props,
      dlNumber.value,
      dlState.props,
      dlStatus.props,
      dlStatus.value,
      driverRef,
      invalidDlRefs,
    ],
  );

  if (!dlStatus.exists) return null;

  return (
    <Select
      {...(dlStatus.props as OptionProps)}
      actionOnChange={statusActionOnChange}
      fullWidth={false}
      placeholder={placeholder}
      id={id}
      inputButtonAriaLabel='Drivers License Status'
      label={label}
      groupLabel={`What's ${possessiveName} driver's license status?`}
      data-testid={dataTestId}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      helperText={helperText}
    />
  );
};
