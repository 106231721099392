import { Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem, ReadOnlyField } from '@ecp/components';
import { PRIMARY_INSURED_PERSON_LOCK } from '@ecp/features/sales/shared/constants';
import {
  DateOfBirthQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import { useField, useFieldWithPrefix, usePniRef } from '@ecp/features/sales/shared/store';

import {
  MaritalStatusQuestion,
  PriorAddressQuestions,
  RiskAddressQuestion,
} from '../../../components';
import { useStyles } from './InsuredInformationPageFormQuestions.styles';

interface Props {
  isValidAddress: boolean;
}

export const InsuredInformationPageFormQuestions: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { isValidAddress } = props;

  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const firstName = usePersonField('firstName');
  const lastName = usePersonField('lastName');
  const primaryInsuredPersonLock = useField(PRIMARY_INSURED_PERSON_LOCK);
  const isLocked = Boolean(primaryInsuredPersonLock.props.value);

  // Enabled this flag only in local to render FirstName and LastName component
  const isPniFieldsEditable = flagValues[FeatureFlags.PNI_NAME_FIELDS_EDITABLE];

  return (
    <>
      <GridItem xs={12}>
        <Grid container columnSpacing={4}>
          {isPniFieldsEditable ? (
            <GridItem topSpacing='sm' xs={4} className={classes.header}>
              <FirstNameQuestion personRef={pniRef} disabled={isLocked} addHelperText={false} />
            </GridItem>
          ) : (
            <GridItem topSpacing='sm' xs={4} className={classes.header}>
              <ReadOnlyField
                label='First Name'
                value={firstName.props.value}
                classes={classes}
                id='firstName'
              />
            </GridItem>
          )}
          <GridItem topSpacing='sm' xs={2} className={classes.header}>
            <MiddleNameQuestion personRef={pniRef} disabled={isLocked} label='MI (optional)' />
          </GridItem>
          {isPniFieldsEditable ? (
            <GridItem topSpacing='sm' xs={3.5} className={classes.header}>
              <LastNameQuestion personRef={pniRef} disabled={isLocked} />
            </GridItem>
          ) : (
            <GridItem topSpacing='sm' xs={3.5} className={classes.header}>
              <ReadOnlyField
                label='Last Name'
                value={lastName.props.value}
                id='lastName'
                classes={classes}
              />
            </GridItem>
          )}
          <GridItem topSpacing='sm' xs={2.5}>
            <SuffixQuestion personRef={pniRef} disabled={isLocked} />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <Grid container columnSpacing={4}>
          <GridItem topSpacing='sm' xs={4} className={classes.header}>
            <DateOfBirthQuestion
              id='DOB'
              personRef={pniRef}
              disabled={isLocked}
              hidePicker={false}
            />
          </GridItem>
          <GridItem topSpacing='sm' xs={4} className={classes.header}>
            <MaritalStatusQuestion personRef={pniRef} label='Marital Status' />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <RiskAddressQuestion isValidAddress={isValidAddress} />
      </GridItem>
      <GridItem topSpacing='lg' xs={6}>
        <EmailQuestion personRef={pniRef} />
      </GridItem>
      <GridItem topSpacing='sm'>
        <PriorAddressQuestions />
      </GridItem>
    </>
  );
};
