import { useCallback, useRef, useState } from 'react';

import { Divider, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, RadioGroupWithOptions } from '@ecp/components';
import { useGetConditionValues, useGetFields, useInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { NavStatus, OfferType } from '@ecp/features/sales/shared/constants';
import {
  applyDelta,
  getIsBundleForOfferProductsSelected,
  getLineOfBusiness,
  updateOffers,
  updateOfferType,
  useField,
  useForm,
} from '@ecp/features/sales/shared/store';
import { updatePageStatus } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';
import type { Field } from '@ecp/types';

import { AutoTPIPageForm } from '../../../lob/autoLine';
import { PropertyTPIPageForm } from '../../../lob/propertyLine';
import { useStyles } from './ThirdPartyInterestPageForm.styles';

export interface ThirdPartyInterestPageFormProps {
  onNext: () => void;
}

export const ThirdPartyInterestPageForm: React.FC<ThirdPartyInterestPageFormProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasThirdPartyInterestInd: Field = useField('static.delta.hasThirdPartyInterestInd');
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const selectedProduct = useSelector(getLineOfBusiness);
  const dispatch = useDispatch();
  const initValues = useRef({});
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();

  useInitValues({ [hasThirdPartyInterestInd.key]: false });
  const { isPatchFormInProgress } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });
  const handleSubmit = useCallback(async () => {
    // TODO: Check for form validity to submit any information
    setIsSubmitting(true);
    dispatch(updatePageStatus(NavStatus.VALID));
    // ADVECP-4010
    // Apply Delta on this page so that we wait for all delta answers to be answered

    if (isBundle) {
      await dispatch(applyDelta({}));
    } else if (selectedProduct === LineOfBusiness.AUTO) {
      await dispatch(applyDelta({ policyTypes: ['auto'] }));
    } else if (selectedProduct === LineOfBusiness.HOME) {
      await dispatch(applyDelta({ policyTypes: ['home'] }));
    }

    // ADVECP-4010
    // Set offer type on answers so that SAPI knows when to rate vs when not to rate
    // When OFFER_WITHOUT_PREMIUM SAPI would just return coverages but not premiums
    // Premiums are returned only when OFFER_WITH_PREMIUM
    await dispatch(updateOfferType({ offerType: OfferType.OFFER_WITH_PREMIUM }));
    await dispatch(updateOffers({ force: true }));

    await onNext();

    setIsSubmitting(false);
  }, [isBundle, selectedProduct, dispatch, onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container className={classes.content}>
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              {...hasThirdPartyInterestInd.props}
              label='Do any policies have third-party interests that need to be included?'
              variant='yesNoButton'
              trackingName='PriorInsurance'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          {hasThirdPartyInterestInd.value && (
            <>
              <Divider className={classes.divider} />
              <GridItem>
                <Typography className={classes.contentTitle}>
                  Add a third-party interest where applicable.
                </Typography>
                <Typography className={classes.contentSubTitle}>
                  At least one TPI must be added.
                </Typography>
              </GridItem>
              <GridItem xs={12}>
                {(selectedProduct === LineOfBusiness.AUTO ||
                  selectedProduct === LineOfBusiness.BUNDLE) && <AutoTPIPageForm />}
                {(selectedProduct === LineOfBusiness.HOME ||
                  selectedProduct === LineOfBusiness.BUNDLE) && <PropertyTPIPageForm />}
              </GridItem>
            </>
          )}
          <Divider className={classes.divider} />
          <GridItem>
            <Button
              className={classes.next}
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              trackingName='tpi_details_continue'
              trackingLabel={GoogleAnalyticsLabels.CONTINUE}
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
